import { RoutePaths } from "@/router";
import { IpHolderListType, IpType, ip_detail, ip_list_top, ip_recommend_list } from "@/services";
import { message, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Pagination, Virtual } from "swiper";
import { useTranslation } from "react-i18next";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import Tag from "@/components/Tag";
import { Consumer } from "@/App";

function MainHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [ipHolderData, setIpHolderData] = useState<IpHolderListType>();
  // const [nftRecommendData, setNftRecommendData] =
  //   useState<NftRecommendListType>();
  const [detailData, setDetailData] = useState<object>();
  const [modelType, setModelType] = useState<string | null>();
  const [ipRVisible, setIpRVisible] = useState(false);
  const [ipRecommendList, setIpRecommendList] = useState<IpType[]>([]);
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

  type IpInfo = {
    ipName: string;
  };
  interface DataType {
    ipId: number;
    ipBlockchainId: string;
    ipImageUrl: string;
    key: string;
    ipName: string;
    age: number;
    address: string;
    tagId: string;
    ipInfos: IpInfo[];
    ownerId: number;
  }
  async function getData(page: number) {
    const res = await ip_list_top(page);
    if (res) {
      setTableData(res.data.list);
    } else {
      showErrorMsg(res, t);
    }
  }

  // const getIpHolderList = async (page: number) => {
  //   const res = await ip_holder_list(page);
  //   if (res) {
  //     setIpHolderData(res);
  //     setIpHolderList(res.data.list);
  //   }
  //   return res
  // }
  const getIpRecommendList = async () => {
    const res = await ip_recommend_list();
    if (res) {
      setIpRecommendList(res.data);
    } else {
      showErrorMsg(res, t);
    }
  };

  // const getNftRecommendList = async (page: number) => {
  //   const res = await nft_recommend_list(page);
  //   if (res) {
  //     setNftRecommendData(res);
  //   }
  // };

  const openDetail = async function (ipBlockchainId: string) {
    navigate(`/ip/${ipBlockchainId}`);
  };

  useEffect(() => {
    getData(page);
    // getIpHolderList(page);
    getIpRecommendList();
    // getNftRecommendList(page);
  }, []);

  let pageArr: number[] = [];
  for (let i = 0; i < ipHolderData?.data?.pages!; i++) {
    pageArr.push(i);
  }

  //判断是否显示Tooltip
  const moreLength = (value: any) => {
    let len = 0;
    if (value != null) {
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
          len += 19;
        } else {
          len += 11;
        }
      }
      if (len > 209) {
        return true;
      }
    }
    return false;
  };

  return (
    <Consumer>
      {(ctx) => (
        <div className="minHeight">
          <div>
            {ctx.width > 768 ? (
              <>
                <div className="main-page-header mt-14">
                  <div className="relative h-full">
                    <div className="main-hero-body mt-10">
                      <Row className="main-container justify-center">
                        <Swiper
                          className="h-auto"
                          loop
                          modules={[Autoplay, Virtual, Pagination, A11y]}
                          spaceBetween={40}
                          slidesPerView={3}
                          pagination={{ clickable: true }}
                          onSwiper={(swiper) => console.log(swiper)}
                          onSlideChange={() => console.log("slide change")}
                          autoplay={{
                            delay: 3000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                          }}>
                          {ipRecommendList.map((item, index) => (
                            <SwiperSlide key={`swiper${index}`} virtualIndex={index}>
                              <div className="flex items-center relative w-slide h-slide md:w-slideMd md:h-slideMd lg:w-slideLg lg:h-slideLg xl:w-slideXl xl:h-slideXl 2xl:w-slide2xl 2xl:h-slide2xl">
                                <button
                                  className="flex justify-center items-center w-full h-full rounded-3xl"
                                  key={`ipRecommendList${index}`}
                                  onClick={() => {
                                    openDetail(item.ipBlockchainId);
                                  }}>
                                  <img
                                    src={item.ipRecommendUrl}
                                    alt=""
                                    style={{ objectFit: "cover" }}
                                    className="w-full h-full rounded-3xl"
                                  />
                                  {/* <div className="h-20 absolute top-1 right-1">
                          <Tag tagIdStr={item.tagId} />
                        </div> */}
                                </button>
                                <div style={{ userSelect: "text" }}>
                                  <button
                                    style={{
                                      backgroundColor: "#13C1F1",
                                      left: "20%",
                                      display: "block",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      userSelect: "text",
                                    }}
                                    onClick={() => {
                                      openDetail(item.ipBlockchainId);
                                    }}
                                    className="px-2 flex absolute -bottom-16 w-20 h-11 md:w-60 md:h-12 text-base font-bold text-white rounded-3xl justify-center items-center">
                                    <Tooltip
                                      placement="topLeft"
                                      title={
                                        moreLength(item.ipInfos[languageId - 1]?.ipName) &&
                                        item.ipInfos[languageId - 1]?.ipName
                                      }
                                      style={{ position: "absolute" }}>
                                      {item.ipInfos[languageId - 1]?.ipName}
                                    </Tooltip>
                                  </button>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="main-container">
                  <div>
                    <div
                      style={{ userSelect: "text" }}
                      className="w-full h-auto text-black font-bold text-lg whitespace-pre-wrap">
                      {t("page.HOME_ipBio")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="main-container">
            <div className="flex w-full justify-start items-center mt-5">
              <span style={{ userSelect: "text" }} className="fs_h3 mb-10">
                {t("page.HOME_ip-listings")}
              </span>
            </div>
            <div className="w-full flex justify-center items-center">
              {ctx.width > 768 ? (
                <div className="w-full grid grid-cols-5 gap-1 gap-y-10 justify-items-center items-center">
                  {tableData.map((item, index) => (
                    <div className="flex flex-col justify-center items-center" key={`ipList${index}`}>
                      <div className="flex flex-col w-full h-80 justify-center items-center" key={index}>
                        <button className="w-11/12 h-4/6">
                          <img
                            src={item.ipImageUrl}
                            alt=""
                            style={{ maxHeight: "256px", objectFit: "cover" }}
                            className="w-full ipRounded"
                            onClick={() => {
                              openDetail(item.ipBlockchainId);
                            }}
                          />
                        </button>
                        <div className="mt-5 ml-5 w-full">
                          <div
                            className="text-lg text-black w-full mt-2"
                            style={{
                              width: "213px",
                              display: "block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              userSelect: "text",
                            }}>
                            <Tooltip
                              placement="topLeft"
                              title={
                                moreLength(item.ipInfos[languageId - 1]?.ipName) && item.ipInfos[languageId - 1]?.ipName
                              }
                              style={{ position: "absolute" }}>
                              {i18n.language === "ja"
                                ? item?.ipInfos[0]?.ipName
                                : i18n.language === "en"
                                ? item?.ipInfos[1]?.ipName
                                : item?.ipInfos[2]?.ipName}
                            </Tooltip>
                          </div>
                          {/* <div className="w-full h-20">
                      <Tag tagIdStr={item.tagId} />
                    </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full grid grid-cols-1 gap-1 gap-y-10 justify-items-center items-center px-3">
                  {tableData.map((item, index) => (
                    <>
                      {index >= 3 ? (
                        <></>
                      ) : (
                        <div className="flex flex-col justify-center items-center" key={`ipList${index}`}>
                          <div className="flex flex-col w-full h-auto justify-center items-center" key={index}>
                            <button className="w-full h-auto">
                              <img
                                src={item.ipImageUrl}
                                alt=""
                                style={{objectFit: "cover" }}
                                className="w-full ipRounded"
                                onClick={() => {
                                  openDetail(item.ipBlockchainId);
                                }}
                              />
                            </button>
                            <div className="mt-5 w-full flex justify-start items-center">
                              <div
                                className="text-lg text-black w-full mt-2"
                                style={{
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  userSelect: "text",
                                }}>
                                 {i18n.language === "ja"
                                    ? item?.ipInfos[0]?.ipName
                                    : i18n.language === "en"
                                    ? item?.ipInfos[1]?.ipName
                                    : item?.ipInfos[2]?.ipName}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
            <div style={{ userSelect: "text" }}>
              <button
                style={{ userSelect: "text" }}
                onClick={() => navigate(RoutePaths.IP_LIST)}
                className="my-14 text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo text-lg font-bold w-48 h-8 rounded-3xl">
                {t("page.HOME_more")}
              </button>
            </div>
          </div>
        </div>
      )}
    </Consumer>
  );
}

const Home = () => {
  return (
    <>
      <MainHome />
    </>
  );
};

export default Home;
