import { Steps } from "antd";
import {useTranslation} from "react-i18next";
const JpnftSteps = (props: any) => {
  const { t } = useTranslation();
  const { Step } = Steps;
  const stepsData = [
    {
      key: 0,
      title: t("page.NFTREQUESTFIR_application-1"),
    },
    {
      key: 1,
      title: t("page.NFTREQUESTFIR_service-fee-payment"),
    },
    {
      key: 2,
      title: t("page.NFTREQUESTFIR_application-2"),
    },
    {
      key: 3,
      title: t("page.NFTREQUESTFIR_issue-nft"),
    },
  ];
  const StepItem = stepsData.map((item: any, index: number) => {
    if (index < props.current) {
      return (
        <Step
          key={item.title}
          title={item.title}
          icon={
            <div className="flex justify-center items-center text-white text-base rounded-full w-8 h-8 bg-jpnft-buttonTo">
              <span>{index + 1}</span>
            </div>
          }
        />
      );
    } else {
      return <Step key={item.title} title={item.title} />;
    }
  });
  return (
    <Steps current={props.current - 1} labelPlacement="vertical">
      {StepItem}
    </Steps>
  );
};

export default JpnftSteps;
