
import { createContext } from "react"

export type IAppContext = {
  util: {
    isMobile: CallableFunction
    numberFormat: CallableFunction
  }
  width: number
  state: {
    dispatch: CallableFunction
  }
}

export const AppContext = createContext<IAppContext>({
    util: {
      isMobile: () => {},
      numberFormat: ()=> {},
    },
    width: 0,
    state: {
      dispatch: () => {
      },
    }
  }
)
