import React, {useEffect, useRef, useState} from 'react';
import {ip_nft_list} from "@/services";
import clsx from "clsx";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Tooltip} from "antd";
import { showErrorMsg } from '@/utils/error';
import i18n from "@/locales";
import {useAccountState} from "@/store";

interface DataType {
    ipId: number;
    ipholderName: string;
    userName: string;
    nftImage: string;
    ipNftId: number;
    nftName: string;
    price: number;
    ipBlockchainId: string;
    ipTransactionUrl: string;
    ipName: string;
}

const NftList = () => {
    const { t } = useTranslation();
    const param: any = useParams();
    const navigate = useNavigate();
    const {state} = useLocation();
    const accountState = useAccountState();
    const [tableData, setTableData] = useState<DataType[]>([]);
    const fileReF = useRef<HTMLInputElement | null>(null);
    const [status, setStatus] = useState<string>('1');
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    useEffect(() => {
        if(state=="2"){
            setStatus("2")
            getData("2");
        }else{
            getData(status=="2"?status:"");
        }
        //滚动条到顶部
        window.scrollTo(0, 0);
    }, [languageId])

    async function getData(value: string) {
        const res = await ip_nft_list(
            value,
            fileReF.current?.value,
            param.ipId,
            languageId
        );
        if (res && res.code == 0) {
            setTableData(res.data);
        } else {
            showErrorMsg(res, t);
        }
    }

    //判断是否显示Tooltip
    const moreLength = (value:any) => {
        let len = 0;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 3;
                } else {
                    len += 2;
                }
            }
            if(len>33){
                return true;
            }
        }
        return false;
    }

    const selectIp = () => {
        if (status == "1") {
            getData("");
        } else {
            getData("2");
        }
    }

    const selectWorks = (value: string) => {
        if (value == "1") {
            setStatus("1")
            getData("");
        } else {
            setStatus("2")
            getData("2");
        }
    }

    return (
        <div className="minHeight text-left">
            <div className="main-container">
                <div className="px-20" style={{userSelect:"text"}}>
                    <button className="fs_h2 pt-10">
                        <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                             onClick={() => window.history.back()}
                             xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">
                            <path
                                d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                p-id="2097"></path>
                        </svg>
                    </button>
                    <div className="fs_h2 pt-10 text-center">{t("page.NFTLIST_nft-creation-list")}</div>
                    <div className="pt-10" style={{display: "inline-block"}}>
                        <div style={{position: "absolute"}}>
                            <svg style={{marginTop: "5px", marginLeft: "10px"}} className="icon" viewBox="0 0 1024 1024"
                                 version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="3500" width="20" height="20">
                                <path
                                    d="M383.3 699.8C217.4 699.8 82.5 564.9 82.5 399S217.4 98.2 383.3 98.2 684.1 233.1 684.1 399 549.2 699.8 383.3 699.8z m0-552.6c-138.8 0-251.7 112.9-251.7 251.7s112.9 251.7 251.7 251.7c138.8 0 251.8-112.9 251.8-251.7s-113-251.7-251.8-251.7z"
                                    fill="#333333" p-id="3501"></path>
                                <path d="M558.272 600.948l33.412-35.975 349.791 324.87-33.412 35.976z" fill="#333333"
                                      p-id="3502"></path>
                            </svg>
                        </div>
                        <input
                            ref={fileReF}
                            style={{ border: "1px #DBDBDB solid", outline: "none",backgroundColor:"white",zIndex: "2",paddingLeft:"40px" }}
                            type="search"
                            className="rounded-tl-3xl rounded-bl-3xl h-8 w-80 text-black pl-6"
                            placeholder={t("page.IPLIST_keyword-search")}
                        />
                        <button
                            className="h-8 w-16 rounded-3xl"
                            onClick={() => selectIp()}
                            style={{ backgroundColor: "#13c1f1",zIndex: "1",marginLeft:"-15px" }}
                        >
                            {t("page.IPLIST_search")}
                        </button>

                    </div>
                    <div className="text-black pt-10" style={{display: "inline-block", float: "right"}}>
                        <button onClick={() => selectWorks("2")} className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                    backgroundColor: clsx(status.includes("2") ? "#13c1f1" : "white"),
                                    color: clsx(status.includes("2") ? "white" : "#333333")
                                }}>
                            {t("page.NFTLIST_sales")}
                        </button>
                        <button onClick={() => selectWorks("1")} className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                    backgroundColor: clsx(status == "1" ? "#13c1f1" : "white"),
                                    color: clsx(status == "1" ? "white" : "#333333")
                                }}>
                            {t("page.NFTREQUESTLIST_all")}
                        </button>
                    </div>
                    <div>
                        <div className="pt-10 pb-10">
                            {tableData?.map(function (
                                item,
                                index
                            ) {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            padding: "10px",
                                            display: "inline-block",
                                            width: "25%",
                                        }}
                                    >
                                        <div style={{borderRadius: "15px"}} className="w-52 h-56">
                                            <button>
                                                <img
                                                    onClick={()=>navigate(`/nft/${item.ipBlockchainId}/${item.ipNftId}`,{ state: item })}
                                                    src={item.nftImage}
                                                    className="rounded-3xl h-full w-full cover"
                                                    style={{userSelect:"none"}}
                                                />
                                            </button>
                                            <div className="text-center">
                                                <div className="fs_18_bold text-center text-black w-full mt-2"
                                                     style={{
                                                         width: "213px",
                                                         display: "block",
                                                         whiteSpace: "nowrap",
                                                         overflow: "hidden",
                                                         textOverflow: "ellipsis",
                                                         userSelect:"text"
                                                     }}>
                                                    <Tooltip placement="topLeft"
                                                             title={moreLength(item.nftName) && item.nftName}
                                                             style={{position: "absolute"}}
                                                    >

                                                        {item.nftName}
                                                    </Tooltip>
                                                </div>
                                                <div className="fs_16 text-jpnft-grey text-center w-full mt-2"
                                                     style={{
                                                         width: "213px",
                                                         display: "block",
                                                         whiteSpace: "nowrap",
                                                         overflow: "hidden",
                                                         textOverflow: "ellipsis",
                                                         userSelect:"text"
                                                     }}>
                                                    <Tooltip placement="topLeft" title={item.userName!=null?moreLength(item.userName) && item.userName :moreLength(item.ipholderName) && item.ipholderName}
                                                             style={{position:"absolute"}}
                                                    >
                                                        {item.userName!=null?item.userName:item.ipholderName}
                                                    </Tooltip>
                                                </div> <div className="fs_18_bold h-6 pt-2">
                                                    {item.price}
                                                    <span className="fs_16" style={{fontWeight: "400"}}>{" "}
                                                        ETH
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NftList;