import { Get, Sessions } from "@/utils";

export type Erc721Proxy = {
  data: {
    collectionId: number;
    collectionAddress: string;
    name: string;
    creatorId: number;
    royalty: number;
  };
};
export const contract_erc721Proxy = async (
  nftId: number
): Promise<Erc721Proxy | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/contract/erc721Proxy", { nftId: nftId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as Erc721Proxy;
  } else {
    return JSON.parse(res.error);
  }
};

export const contract_addresses = async (
  network: number
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/contract/addresses", { network: network }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    return JSON.parse(res.error);
  }
};
