import { providers } from "ethers";

// export const DefaultApiUrl = process.env.REACT_APP_API_DOMAIN + "/tcb";
export const DefaultApiUrl = "/api";
export const domain = process.env.REACT_APP_API_DOMAIN;
export const port = process.env.REACT_APP_API_PORT;
export const normal = process.env.REACT_APP_AVATAR_NORMAL;
export const ipLogoUrl = process.env.REACT_APP_IPLOG_NORMAL;
export const ipBannerUrl = process.env.REACT_APP_IPBANNER_NORMAL;
export const contractUrl = process.env.REACT_APP_CONTRACT_ADDRESS;
export const dappLink = "https://metamask.app.link/dapp/" + domain + "/";

export const shareUrl = "https://" + domain + "/back/share?t=";

export const NetWorks: { [key: string]: string } = {
    Mainnet: "0x1", // 1
    Ropsten: "0x3", // 3
    Rinkeby: "0x4", // 4
    Goerli: "0x5", // 5
    Kovan: "0x2a", // 42
    Mumbai: "0x13881", // 80001
    Polygon: "0x89", // 137,
};

