/* Login.ts */
import {RoutePaths} from "@/router";
import {Button, message, notification} from "antd";
import isMobile from "ismobilejs";
import {getSigner} from "@/utils";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAccountState, useAppDispatch} from "@/store";
import {Actions} from "@/store/reducers/account";
import {getRandomNum, loginApi,} from "@/services";
import {useTranslation} from "react-i18next";


type UserInfo = {
    address: string,
}

const Login = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState<UserInfo>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    async function onHandleLogin() {
        if (isMobile(window.navigator).any) {
            // await mobilePay();
        } else {
            await metaMaskPay();
        }
    }

    async function metaMaskPay() {
        let signer = await getSigner();
        if (signer) {
            debugger
            const res01 = await getRandomNum();
            let ethAddress = await signer.getAddress();
            let chainId = await signer.getChainId();
            if (res01.data) {
                let signature = await signer.signMessage(res01.data.randomCode);
                // jwt
                const res02 = await loginApi({
                    randomCodeToken: res01.data.randomCodeToken,
                    signatureData: signature,
                    walletAddress: ethAddress,
                });
                if (res02.data) {
                    if (res02.data.avatar) {
                        navigate(RoutePaths.HOME);
                        message.info(t("com1"));
                    } else {
                        navigate(RoutePaths.PROFILE);
                        message.info(t("com1"));
                    }
                    debugger
                    dispatch(
                        Actions.login({
                            address: ethAddress,
                            chain: chainId,
                            isMetamask: true,
                            userId: res02.data ? res02.data.userId : null,
                            avatar: res02.data.avatar ? res02.data.avatar : null,
                            avatarUrl: res02.data.avatarUrl ? res02.data.avatarUrl : null,
                            userName: res02.data.userName ? res02.data.userName : null,
                            userDetails: res02.data.userDetails ? res02.data.userDetails : null,
                            twitterUrl: res02.data.twitterUrl ? res02.data.twitterUrl : null,
                            linktreeUrl: res02.data.linktreeUrl ? res02.data.linktreeUrl : null,
                            openseaUrl: res02.data.openseaUrl ? res02.data.openseaUrl : null,
                            userType: res02.data.userType
                        })
                    );
                } else {
                    notification.warning({
                        message: "Login failed!",
                    });
                }

            }
        }
    }

    return (
        <div className="bg-black text-left minHeight">
            <div className="main-container">
                <div className="text-center justify-center items-center space-y-4 pt-28	">
                    <div
                        className="flex px-2 sm:py-4 w-smDiv md:w-mdDiv lg:w-lgDiv xl:w-xlDiv text-lg md:text-3xl text-left text-white font-bold">
                        {t("login1")}
                    </div>
                    <div
                        className="flex px-2 py-4 w-smDiv md:w-mdDiv lg:w-lgDiv xl:w-xlDiv text-xs md:text-base   text-left text-white">
                        {t("login2")}
                    </div>
                    <Button className="min-w-[50%]" size="large" ghost onClick={() => onHandleLogin()}>
                        <div>
                            METAMASK
                        </div>
                    </Button>
                    <p className="ant-space-align-center">
                        {t("login3")}
                    </p>
                    <Button className="min-w-[50%]" size="large" ghost>
                        <div>
                            CoRecika Wallet
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;