import React, { useEffect, useRef, useState } from "react";
import {Button, Input, message, Modal, notification, Tooltip} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAccountState } from "@/store";
import { RoutePaths } from "@/router";
import {
  NftDetails,
  nft_details,
  nft_cancel_sell,
  ip_nft_list,
} from "@/services";
import { all_order_info } from "@/services/order";
import { nftBuy, nftSell } from "@/utils";
import { BigNumber, ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import clsx from "clsx";
import Menu from "../Mypage/Menu";
import copy from "clipboard-copy";
import {contractUrl} from "@/constants";
import isMobile from "ismobilejs";

interface DataType {
  ipId: number;
  ipholderName: string;
  userName: string;
  nftImage: string;
  ipNftId: number;
  nftName: string;
  price: number;
  ipBlockchainId: string;
  ipTransactionUrl: string;
  ipName: string;
}
declare let window: any;
interface orderListInfo {
  orderId: number;
  network: number;
  tokenId: number;
  makerId: number;
  takerId: number;
  price: number;
  orderStatus: number;
  saleStartDate: string;
  saleEndDate: string;
  makerSig: string;
  sellSalt: string;
  lockingDate: string;
  created: string;
  creatTime: string;
  formAddress: string;
  toAddress: string;
  fromName: string;
  toName: string;
}

const NftDetailInfo = () => {
  const { TextArea } = Input;
  const accountState = useAccountState();
  const [nftData, setNftData] = useState<NftDetails>();
  const [orderInfo, setOrderInfo] = useState<orderListInfo[] | null>();
  const { ipNftId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tableData, setTableData] = useState<DataType[]>([]);
  //
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [priceStatus, setPriceStatus] = useState(true);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const salt = useRef<BigNumber>(BigNumber.from(0));
  const orderHash = useRef<string>();
  const { t } = useTranslation();
  const [ens, setEns] = useState("");
  const [usdPrice, setUsdPrice] = useState(0);
  const [jpyPrice, setJpyPrice] = useState(0);
  const param: any = useParams();
  const languageId =
      i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
  const textAreaHeight = document.getElementById('details')?.scrollHeight + "px";
  // const provider = await web3ModalRef.current.connect();
  const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : null;

  const mobileFlg:any = isMobile(window.navigator).any ? true : false;

  const fetchNftDetail = async () => {
    //ipNftId
    const res = await nft_details(ipNftId);
    if (res && res.data) {
      setNftData(res);
      // setEns(await setENSOrAddress(res.data.ethAddress, provider));
      fetchOrderInfo();
      const res02 = await ip_nft_list(
          "2",
          "",
          String(res.data.ipId),
          languageId
      );
      if (res02 && res02.code == 0) {
        setTableData(res02.data? res02.data.slice(0, 4) : null);
      } else {
        showErrorMsg(res02, t);
      }
    }
  };

  //ens域名解析
  const setENSOrAddress = async (address: any, provider: any) => {
    if (provider){
      const name = await provider.lookupAddress(address);
      // If the address has an ENS set the ENS or else just set the address
      console.log(name, "name");
      if (name) {
        return name;
      } else {
        return "";
      }
    }else {
      return "";
    }
  };

  /**
   * 購入オーダー情報
   */
  const fetchOrderInfo = async () => {
    // const china = parseInt(NetWorks[accountState.network!], 16)
    const res = await all_order_info(ipNftId!);
    if (res.data) {
      setOrderInfo(res.data);
      // for (let i=0; i<orderInfo?.length; i++){
      //     res.data[i].fromName= setENSOrAddress(res.data[i].formAddress,provider);
      //     res.data[i].toName= setENSOrAddress(res.data[i].toAddress,provider);
      // }
    } else {
      showErrorMsg(res, t);
    }
  };
  const openDetail = async function (ipBlockchainId: string,) {
    navigate(`/ip/${ipBlockchainId}`);
  };

  useEffect(() => {
    //滚动条到顶部
    window.scrollTo(0, 0);
    fetchNftDetail();
    getChartData();
  }, [languageId]);

  const handleCancel = () => {
    setIsPriceModalOpen(false);
  };
  const onHandelSell = () => {
    if (!accountState.isLogin) {
      message.warning(t("msg.RIGHTREQFIR_4"));
      return;
    }
    setIsPriceModalOpen(true);
  };
  const sell = async () => {
    if (!accountState.network) {
      message.warning(
          "Initialaze data load failed, please refresh current page again!"
      );
      return;
    }
    if (!accountState.isLogin) {
      message.warning(t("check_login_sync"));
      return;
    }
    const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    const res = await nftSell(
        parseInt(ipNftId!),
        nftData?.data.price.toString()!,
        t,
        accountState,
        salt,
        orderHash,
        languageId
    );
    if (res) {
      setPriceStatus(false);
    }
  };

  const sellEnd = async () => {
    setIsPriceModalOpen(false);
    fetchNftDetail();
    //navigate(RoutePaths.HOME);
  };

  const buy = async () => {
    setIsApproveModalOpen(false);
    const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    await nftBuy(
        parseInt(ipNftId!),
        orderInfo?.[orderInfo?.length - 1].price!,
        t,
        accountState,
        languageId
    );
    navigate(RoutePaths.HOME);
  };
  const onHandelBuy = () => {
    if (!accountState.isLogin) {
      message.warning(t("msg.RIGHTREQFIR_4"));
      return;
    }
    setIsApproveModalOpen(true);
  };

  //获取汇率
  const getChartData = () => {
    fetch(
        // "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH&tsyms=USD"
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=JPY,USD"
    )
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          console.log(myJson.USD)
          setUsdPrice(myJson.USD);
          setJpyPrice(myJson.JPY);
        });
  };



  const subStr = (str: string) => {
    if (str && str.length > 15) {
      return str.substring(0, 15) + "...";
    } else {
      return str;
    }
  };

  const newNft = (item: DataType) => {
    navigate(`/nft/${item.ipBlockchainId}/${item.ipNftId}`,{ state: item })
    window.location.reload();
  };

  const subStrTo6 = (str: string) => {
    if (str && str.length > 6) {
      return str.substring(0, 6) + "...";
    } else {
      return str;
    }
  };
  const subStrForm = (str: string) => {
    if (str && str.length > 8) {
      return (
          str.substring(0, 5) +
          "..." +
          str.substring(str.length - 4, str.length - 1)
      );
    } else {
      return str;
    }
  };

  //判断是否显示Tooltip
  const moreLength = (value:any) => {
    let len = 0;
    if(value!=null){
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
          len += 3;
        } else {
          len += 2;
        }
      }
      if(len>27){
        return true;
      }
    }
    return false;
  }

  const showMenu =
      state && state.fromMenu && state.fromMenu === true ? true : false;

  const cancelSell = async () => {
    const ipNftId = nftData?.data.ipNftId;
    const res = await nft_cancel_sell(ipNftId!);
    if (res) {
      navigate(RoutePaths.MY_PAGE, {
        state: { show: state.show, status: "2" },
      });
    } else {
      message.error("NFT sale cancel failed !");
    }
  };

  const copyBlockchainInfo = async (e: any) => {
    e.stopPropagation();
    copy(String(nftData?.data.tokenId!)).then(() => {
      notification.success({ message: t("msg.NFT_3") });
    });
  };

  const formatUSD = (number: Number) => {
    var formattedNumber = number.toFixed(2);
    formattedNumber = "$" + formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
  };

  const formatJPY = (number: Number) => {
    var formattedNumber = number.toFixed(0);
    formattedNumber = "¥" + formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
  };
  
  return (
      <div className="minHeight">
        <div className={clsx(showMenu ? "main-container flex" : "main-container")}>
          <div className="flex w-full justify-start">
            {showMenu && <Menu show={state.show} status="2" />}
            {nftData && (
                <div
                    className={clsx("w-full h-auto", showMenu ? "mt-24" : "mt-10")}
                >
                  {/**top */}
                  <div className={clsx(mobileFlg && "flex-col","flex space-x-10 w-full h-auto justify-center items-start")}>
                    {/**top left */} 
                    <div
                        style={{ width: mobileFlg?"100%":"400px", height: "400px" }}
                        className="relative bg-white rounded-lg border-2 border-jpnft-border flex justify-center items-center"
                    >
                      <div
                          style={{ width: "300px", height: "300px" ,}}
                          className="flex justify-center items-center"
                      >
                        <img
                            src={
                              nftData.data.tipNftInfos && i18n.language === "ja"
                                  ? nftData.data.tipNftInfos[0]?.nftImage
                                  : i18n.language === "en"
                                      ? nftData.data.tipNftInfos[1]?.nftImage
                                      : nftData.data.tipNftInfos[2]?.nftImage
                            }
                            style={{ maxHeight: "300px", objectFit: "cover" }}
                            className="w-full rounded-lg border border-jpnft-border"
                        />
                      </div>
                      {showMenu && (
                          <div className="pb-2 absolute left-0 -top-16">
                            <button className="fs_h2">
                              <svg
                                  className="icon"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  onClick={() =>
                                      navigate(RoutePaths.MY_PAGE, {
                                        state: { show: state.show, status: "2" },
                                      })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  p-id="2096"
                                  width="32"
                                  height="32"
                              >
                                <path
                                    d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                    p-id="2097"
                                ></path>
                              </svg>
                            </button>
                          </div>
                      )}
                    </div>

                    {/**top right */}
                    <div
                        style={{
                          width: "300px",
                          maxHeight: "300px",
                          background: "#D4F6FF",
                          userSelect:"text",
                          margin: mobileFlg ? "10px auto" : ""
                        }}
                        className="rounded-3xl"
                    >
                      <div className="flex flex-col justify-start items-start w-full px-8 py-8">
                        {/**nft name */}
                        <div className="text-black text-lg font-bold text-left">
                          {nftData.data.tipNftInfos && i18n.language === "ja"
                              ? nftData.data.tipNftInfos[0]?.nftName
                              : i18n.language === "en"
                                  ? nftData.data.tipNftInfos[1]?.nftName
                                  : nftData.data.tipNftInfos[2]?.nftName}
                        </div>
                        <div className="mt-2 text-black text-sm flex">
                          <div className="text-left w-16 font-bold" style={{minWidth:"50px"}}>
                            {t("page.NFTCREATED_creator")}
                          </div>
                          <div className="pl-1 w-full text-left">
                            {nftData.data.creatorIpholderInfos === null ||
                            nftData.data.creatorIpholderInfos === undefined ||
                            nftData.data.creatorIpholderInfos.length === 0
                                ? subStr(nftData.data.creatorName)
                                : i18n.language === "ja"
                                    ? subStr(
                                        nftData.data.creatorIpholderInfos[0]?.ipholderName
                                    )
                                    : i18n.language === "en"
                                        ? subStr(
                                            nftData.data.creatorIpholderInfos[1]?.ipholderName
                                        )
                                        : subStr(
                                            nftData.data.creatorIpholderInfos[2]?.ipholderName
                                        )}
                          </div>
                        </div>
                        <div className="mt-2 text-black text-sm flex">
                          <div className="text-left w-16 font-bold" style={{minWidth:"50px"}}>
                            {t("page.NFTCREATED_owner")}
                          </div>
                          <div className="pl-1 w-full text-left">
                            {nftData.data.ownerIpholderInfos === null ||
                            nftData.data.ownerIpholderInfos === undefined ||
                            nftData.data.ownerIpholderInfos.length === 0
                                ? subStr(nftData.data.ownerName)
                                : i18n.language === "ja"
                                    ? subStr(
                                        nftData.data.ownerIpholderInfos[0]?.ipholderName
                                    )
                                    : i18n.language === "en"
                                        ? subStr(
                                            nftData.data.ownerIpholderInfos[1]?.ipholderName
                                        )
                                        : subStr(
                                            nftData.data.ownerIpholderInfos[2]?.ipholderName
                                        )}
                          </div>
                        </div>
                        {/**販売中 */}
                        {nftData?.data.saleStatus === 2 && (
                            <>
                              <div className="mt-2 text-black w-full flex">
                                <div className="w-12 text-left text-sm font-bold" style={{minWidth:"50px"}}>
                                  {t("page.NFTCREATED_price")}
                                </div>
                                <div className="pl-1"><div className="w-full text-lg font-bold text-left">{`${nftData.data.price} ETH`}</div>
                                  <span className="pr-5">{formatJPY(nftData.data.price * jpyPrice)}</span>
                                  <span className="">{formatUSD(nftData.data.price * usdPrice)}</span>
                                </div>
                              </div>
                            </>
                        )}

                        {mobileFlg ?
                            <div>
                              <div className="w-full flex flex-col justify-center items-center mt-5">
                                <button disabled className="fs_16_white w-60 h-12 rounded-3xl disabled:bg-jpnft-disable"> {t("page.NFTCREATED_buy")}</button>
                                <p className="w-full text-red-500 text-base mt-3">{t("page.NFTCREATED_mobile_not_pay")}</p>
                              </div>
                          </div>
                            :
                        <div className="w-full flex justify-center items-center">
                          {nftData?.data.saleStatus === 2 ? (
                              <div className="w-full flex flex-col  mt-3">
                                {accountState.userId !== nftData?.data.ownerId ? (
                                    <div>
                                      <button
                                          className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-12 rounded-3xl"
                                          onClick={onHandelBuy}
                                      >
                                        {t("page.NFTCREATED_buy")}
                                      </button>
                                    </div>
                                ) : (
                                    <div>
                                      <button
                                          className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-12 rounded-3xl mt-3"
                                          onClick={cancelSell}
                                      >
                                        {t("page.NFTCREATED_cancel")}
                                      </button>
                                    </div>
                                )}
                              </div>
                          ) : (
                              accountState.userId === nftData?.data.ownerId && showMenu && (
                                  <div className="w-full flex flex-col">
                                    <div>
                                      <Button
                                          onClick={() =>
                                              window.open(
                                                  "mailto:?subject=&cc=&subject=主题&body=内容"
                                              )
                                          }
                                          style={{
                                            marginTop: "25px",
                                            background: "#13c1f1",
                                            width: "240px",
                                            borderRadius: "20px",
                                            color: "white",
                                            fontSize: "16px",
                                            height: "48px",
                                          }}
                                      >
                                        {t("page.NFTCREATED_edit")}
                                      </Button>
                                    </div>
                                    <div className="w-full mt-5">
                                      <button
                                          onClick={onHandelSell}
                                          className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-12 rounded-3xl"
                                      >
                                        {t("page.NFTCREATED_sale")}
                                      </button>
                                    </div>
                                  </div>
                              )
                          )}
                        </div>
                        }
                      </div>
                    </div>
                  </div>

                  {/**bottom */}
                  <div className={clsx(mobileFlg && "flex-col","flex space-x-10 w-full  justify-center items-start mb-10")}>
                    <div style={{ width: "740px" }} className="">
                      <div style={{ width: "740px",userSelect:"text" }} className="mt-10 ">
                        {/**紹介 */}
                        <div className={clsx(mobileFlg && "w-screen","w-full flex flex-col justify-start items-start")}>
                          <div className="text-lg font-bold text-black">
                            {t("page.NFTCREATED_introduce")}
                          </div>
                          <div className="text-black w-full">
                            <TextArea
                                readOnly
                                style={{
                                  borderColor: "transparent",
                                  backgroundColor: "transparent",
                                  resize: "none",
                                  fontSize: "18px",
                                  width: "100%",
                                  overflow: "hidden",
                                  wordBreak: "break-all",
                                  height: textAreaHeight
                                }}
                                value={
                                  nftData.data.tipNftInfos && i18n.language === "ja"
                                      ? nftData.data.tipNftInfos[0]?.nftDetails
                                      : i18n.language === "en"
                                          ? nftData.data.tipNftInfos[1]?.nftDetails
                                          : nftData.data.tipNftInfos[2]?.nftDetails
                                }
                                id="details"
                            />
                          </div>
                        </div>
                        <div className={clsx(mobileFlg ? "flex-col w-auto" : "w-full"," flex justify-start items-start mt-5")}>
                          {/**IP情報 */}
                          <div style={{paddingRight:"20px"}} className="w-1/2 flex flex-col justify-start items-start mt-5">
                            <div className="text-lg font-bold text-black">
                              {t("page.NFTCREATED_ip-information")}
                            </div>
                            <div className="w-full flex">
                              <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                <div>{t("page.NFTCREATED_ip-name")}</div>
                              </div>
                              <div className="text-lg text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                <button
                                    style={{
                                      color: "#13c1f1",
                                      textDecoration: "underline",
                                      wordBreak: "break-all",
                                    }}
                                    onClick={() => {
                                      openDetail(param.ipBlockchainId);
                                    }}
                                >
                                  {i18n.language === "ja"
                                      ? nftData.data?.tipInfos[0]?.ipName
                                      : i18n.language === "en"
                                          ? nftData.data?.tipInfos[1]?.ipName
                                          : nftData.data?.tipInfos[2]?.ipName}
                                </button>
                              </div>
                            </div>
                            {/**IP保有者 */}
                            <div className="w-full flex flex-col justify-start items-start mt-1">
                              <div className="w-full flex">
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{t("page.NFTCREATED_ip-holder")}</div>
                                </div>
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div className="w-full text-left">
                                    {nftData.data?.ipholderInfos
                                        ? i18n.language === "ja"
                                            ? subStr(
                                                nftData.data?.ipholderInfos[0].ipholderName
                                            )
                                            : i18n.language === "en"
                                                ? subStr(
                                                    nftData.data?.ipholderInfos[1].ipholderName
                                                )
                                                : subStr(
                                                    nftData.data?.ipholderInfos[2].ipholderName
                                                )
                                        : nftData.data?.ipOwnerName}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/**詳細 */}
                          <div className="w-1/2 flex flex-col justify-start items-start mt-5">
                            <div className="text-lg font-bold text-black">
                              {t("page.NFTCREATED_details")}
                            </div>
                            <div className="w-full flex">
                              <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                <div>{t("page.NFTCREATED_contract")}</div>
                              </div>
                              <div className="pl-4 text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                <button
                                    style={{
                                      color: "#13c1f1",
                                      textDecoration: "underline",
                                      wordBreak: "break-all",
                                    }}
                                    onClick={() =>
                                        window.open(contractUrl + "/" + nftData.data.contractAddress + "#readContract")
                                    }
                                >
                                  {nftData.data.contractAddress &&
                                      `${nftData.data.contractAddress.substring(
                                          0,
                                          4
                                      )}...${nftData.data.contractAddress.substring(
                                          nftData.data.contractAddress.length - 4,
                                          nftData.data.contractAddress.length
                                      )}`}
                                </button>
                              </div>
                            </div>

                            {/**トークンID */}
                            <div className="w-full flex flex-col justify-start items-start mt-1">
                              <div className="w-full flex">
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{t("page.NFTCREATED_token-id")}</div>
                                </div>
                                <div className="pl-4 text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <button onClick={copyBlockchainInfo}>{nftData.data.tokenId.toString().length <= 16 ? nftData.data.tokenId : nftData.data.tokenId.toString().substring(0, 16)+"..."}</button>
                                </div>
                              </div>
                            </div>

                            {/**トーク標準 */}
                            <div className="w-full flex flex-col justify-start items-start mt-1">
                              <div className="w-full flex">
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{t("page.NFTCREATED_token-type")}</div>
                                </div>
                                <div className="pl-4 text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>ERC-{nftData.data.tokenType}</div>
                                </div>
                              </div>
                            </div>

                            {/**チェーン */}
                            <div className="w-full flex flex-col justify-start items-start mt-1">
                              <div className="w-full flex">
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{t("page.NFTCREATED_blockchina")}</div>
                                </div>
                                <div className="pl-4 text-lg text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>Ethereum</div>
                                </div>
                              </div>
                            </div>

                            {/**クリエイター収益 */}
                            <div className="w-full flex flex-col justify-start items-start mt-1">
                              <div className="w-full flex">
                                <div className="text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{t("page.NFTCREATED_creator-income")}</div>
                                </div>
                                <div className="pl-4 text-lg  text-black w-1/2 flex justify-start items-start border-b border-jpnft-border">
                                  <div>{nftData.data?.royalty} % </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                            style={{ width: "740px", height: "auto" }}
                            className="items-start mt-20"
                        >
                          <div className="fs_h3 text-left pb-2">
                            {t("page.NFTDETAIL_item-activity")}
                          </div>
                          <div className={clsx(mobileFlg && "nft-info-table-overflow","rounded-3xl border-2")}>
                            <table
                                className="text-black rounded-3xl text-left"
                                style={{ width: "100%" }}
                            >
                              <thead>
                              <tr
                                  style={{
                                    backgroundColor: "#13c1f1",
                                    borderColor: "#d3d3d3",
                                  }}
                                  className="h-12 text-white border-b rounded-3xl
                                       "
                              >
                                <td
                                    className="pl-4 rounded-tl-3xl"
                                    style={{ width: "20%" }}
                                >
                                  {t("page.NFTDETAIL_event")}
                                </td>
                                <td className="pl-4" style={{ width: "20%" }}>
                                  {t("page.NFTDETAIL_price")}
                                </td>
                                <td className="pl-4" style={{ width: "20%" }}>
                                  {t("page.NFTDETAIL_from")}
                                </td>
                                <td className="pl-4" style={{ width: "20%" }}>
                                  {t("page.NFTDETAIL_to")}
                                </td>
                                <td
                                    className="pl-4 rounded-tr-3xl"
                                    style={{ width: "20%" }}
                                >
                                  {t("page.NFTDETAIL_date-hour")}
                                </td>
                              </tr>
                              </thead>
                              <tbody>
                              {orderInfo?.map((item, index) => {
                                return (
                                    <tr key={index} className="h-12 border-b">
                                      {/*取引*/}
                                      <td
                                          className="pl-4"
                                          style={{ background: "#eafbff" }}
                                      >
                                        {t("page.NFTDETAIL_sale")}
                                      </td>
                                      {/*価格*/}
                                      <td className="pl-4">{item.price} ETH</td>
                                      {/*出品者*/}
                                      <td
                                          className="pl-4"
                                          style={{ background: "#eafbff" }}
                                      >
                                        {subStrForm(
                                            item.fromName !== ""
                                                ? item.formAddress
                                                : item.fromName
                                        )}
                                      </td>
                                      {/*購入者*/}
                                      <td className="pl-4">
                                        {subStrForm(
                                            item.toName !== ""
                                                ? item.toAddress
                                                : item.toName
                                        )}
                                      </td>
                                      {/*日時*/}
                                      <td
                                          className="pl-4"
                                          style={{ background: "#eafbff" }}
                                      >
                                        {item.creatTime}
                                      </td>
                                    </tr>
                                );
                              })}
                              <tr className="h-12 border-b">
                                {/*取引*/}
                                <td
                                    className="pl-4 rounded-bl-3xl"
                                    style={{ background: "#eafbff" }}
                                >
                                  {t("page.NFTDETAIL_minted")}
                                </td>
                                {/*価格*/}
                                <td className="pl-4">
                                  {nftData?.data.price} ETH
                                </td>
                                {/*出品者*/}
                                <td
                                    className="pl-4"
                                    style={{ background: "#eafbff" }}
                                >
                                  -
                                </td>
                                {/*購入者*/}
                                <td className="pl-4">
                                  {subStrForm(
                                      nftData?.data.ethAddress !== ""
                                          ? nftData?.data.ethAddress
                                          : nftData?.data.creatorName
                                  )}
                                </td>
                                {/*日時*/}
                                <td
                                    className="pl-4 rounded-br-3xl"
                                    style={{ background: "#eafbff" }}
                                >
                                  {nftData.data.createdTime}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                          {nftData?.data.saleStatus === 2 && tableData && tableData.length !== 0 && (
                              <div
                                  className="fs_h3 text-left mt-20"
                                  style={{
                                    display: clsx(tableData.length > 0 ? "" : "none"),
                                  }}
                              >
                                 {t("page.NFTDETAIL_other-nfts")}
                              </div>
                          )}
                          { nftData?.data.saleStatus === 2 && tableData && tableData.length !== 0 && (
                        <>
                          <div className="mt-2 text-left">
                            {tableData?.map(function (item, index) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    padding: "10px",
                                    display: "inline-block",
                                    width: "25%",
                                  }}
                                >
                                  <div
                                    className="w-44 h-44 border-jpnft-border"
                                  >
                                    <button>
                                      <img
                                          onClick={()=>newNft(item)}
                                          style={{ borderRadius: "15px",userSelect:"none" }}
                                          src={item.nftImage}
                                          className="h-full w-full cover"
                                      />
                                    </button>
                                    <div className="fs_18_bold text-center text-black w-full mt-2"
                                         style={{
                                           width: "160px",
                                           display: "block",
                                           whiteSpace: "nowrap",
                                           overflow: "hidden",
                                           textOverflow: "ellipsis",
                                           userSelect:"text"
                                         }}>
                                      <Tooltip placement="topLeft"
                                               title={moreLength(item.nftName) && item.nftName}
                                               style={{position: "absolute"}}
                                      >

                                        {item.nftName}
                                      </Tooltip>
                                    </div>
                                    <div className="fs_16 text-jpnft-grey text-center w-full mt-2"
                                         style={{
                                           width: "160px",
                                           display: "block",
                                           whiteSpace: "nowrap",
                                           overflow: "hidden",
                                           textOverflow: "ellipsis",
                                           userSelect:"text"
                                         }}>
                                      <Tooltip placement="topLeft" title={item.userName!=null?moreLength(item.userName) && item.userName :moreLength(item.ipholderName) && item.ipholderName}
                                               style={{position:"absolute"}}
                                      >
                                        {item.userName!=null?item.userName:item.ipholderName}
                                      </Tooltip>
                                    </div>
                                    <div
                                        className="fs_18_bold h-6 pt-5 text-center"
                                        style={{
                                          display: clsx(
                                              item.price ? "" : "none"
                                          ),
                                        }}
                                    >
                                      {item.price}
                                      <span
                                          className="fs_16 pl-2"
                                          style={{ fontWeight: "400" }}
                                      >
                                          ETH
                                        </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {mobileFlg === false && <button
                            onClick={() =>navigate("/nftList/" + nftData?.data.ipId,{ state: "2" })}
                            className="text-white bg-jpnft-buttonTo from-yellow-300 to-jpnft-buttonTo fs_16_white w-40 h-8 rounded-3xl mt-10 mb-5"
                          >
                            {t("page.IPDETAIL_more")}
                          </button>}
                        </>
                      )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}

            <Modal open={isPriceModalOpen} footer={null} onCancel={handleCancel}>
              {priceStatus ? (
                  <div className="w-full flex flex-col">
                    <div className="text-lg fs_h3 text-center">
                      {t("page.NFTDETAIL_list-of-sale")}
                    </div>
                    <div className="text-lg fs_16">
                      <div className="border-b pt-5 pb-5">
                        <div
                            className="w-2/3 fs_16_bold"
                            style={{ display: "inline-block" }}
                        >
                          {t("page.NFTDETAIL_sell-price")}
                        </div>
                        <div
                            className="w-1/3 fs_18_bold"
                            style={{ display: "inline-block" }}
                        >
                          {nftData?.data.price} ETH
                        </div>
                      </div>
                      <div className="border-b pt-5 pb-5">
                        <div
                            className="w-2/3 fs_16_bold"
                            style={{ display: "inline-block" }}
                        >
                          <div>{t("page.NFTPAYINFO_primary-sales-royalty")}</div>
                          <div>
                            {t("page.NFTPAYINFO_primary-sales-royalty-description")}
                          </div>
                        </div>
                        <div
                            className="w-1/3 fs_18_bold pt-2"
                            style={{ display: "inline-block" }}
                        >
                          {nftData?.data.royalty}%
                        </div>
                      </div>
                      <div className="border-b pt-5 pb-5">
                        <div
                            className="w-2/3 fs_16_bold"
                            style={{ display: "inline-block" }}
                        >
                          <div>{t("page.NFTPAYINFO_secondary-sales-royalty")}</div>
                          <div>
                            {t(
                                "page.NFTPAYINFO_secondary-sales-royalty-description"
                            )}
                          </div>
                        </div>
                        <div
                            className="w-1/3 fs_18_bold"
                            style={{ display: "inline-block" }}
                        >
                          {nftData?.data.royalty2}%
                        </div>
                      </div>
                      {/* <div className="border-b pt-5 pb-5">
                  <div
                    className="w-2/3 fs_16_bold"
                    style={{ display: "inline-block" }}
                  >
                    {t("page.NFTDETAIL_primary-sales")}
                  </div>
                  <div
                    className="w-1/3 fs_18_bold"
                    style={{ display: "inline-block" }}
                  >
                    {0} ETH
                  </div>
                </div> */}
                      <div className="pt-5 text-center">
                        <button
                            onClick={sell}
                            className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-8 rounded-3xl"
                        >
                          {t("page.NFTDETAIL_confirm")}
                        </button>
                      </div>
                    </div>
                  </div>
              ) : (
                  <div className="w-full flex flex-col text-center">
                    <div className="text-lg fs_h3 ">
                      {" "}
                      {t("page.NFTDETAIL_item-listed")}
                    </div>
                    <div className="text-lg fs_16">
                      <div className="pt-5 pl-10 pr-10">
                        <img
                            src={nftData?.data.tipNftInfos[languageId - 1]?.nftImage}
                            style={{
                              height: "400px",
                              width: "400px",
                              textAlign: "center",
                            }}
                            className="rounded-3xl"
                        />
                      </div>
                      <div className="pt-5 ">
                        <button
                            onClick={sellEnd}
                            className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-8 rounded-3xl"
                        >
                          {t("page.NFTDETAIL_view-listing")}
                        </button>
                      </div>
                    </div>
                  </div>
              )}
            </Modal>
            <Modal
                open={isApproveModalOpen}
                footer={null}
                onCancel={() => setIsApproveModalOpen(false)}
            >
              <div className="grid grid-rows-3 grid-flow-col">
                <div className="row-span-6">
                  <div className="fs_16" style={{ display: "inline-block" }}>
                    <img
                        src={
                          nftData &&
                          nftData.data.tipNftInfos &&
                          i18n.language === "ja"
                              ? nftData.data.tipNftInfos[0]?.nftImage
                              : i18n.language === "en"
                                  ? nftData && nftData.data.tipNftInfos[1]?.nftImage
                                  : nftData && nftData.data.tipNftInfos[2]?.nftImage
                        }
                        alt=""
                        width={180}
                        height={200}
                        className=" rounded-md"
                    />
                  </div>
                </div>

                <div className="col-span-8 text-left pt-2">
                  <div
                      className="w-2/6 fs_16"
                      style={{ display: "inline-block" }}
                  >
                    {t("page.NFTDETAIL_nft-name")}
                  </div>
                  <div
                      className="w-4/6 fs_16"
                      style={{ display: "inline-block" }}
                  >
                    {nftData && nftData.data.tipNftInfos && i18n.language === "ja"
                        ? nftData.data.tipNftInfos[0]?.nftName
                        : i18n.language === "en"
                            ? nftData && nftData.data.tipNftInfos[1]?.nftName
                            : nftData && nftData.data.tipNftInfos[2]?.nftName}
                  </div>
                  <div
                      className="w-2/6 fs_16"
                      style={{ display: "inline-block" }}
                  >
                    {t("page.NFTDETAIL_price")}
                  </div>
                  <div
                      className="w-4/6 fs_16"
                      style={{ display: "inline-block" }}
                  >
                    {orderInfo?.[orderInfo?.length - 1].price} ETH
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center items-center">
                <button
                    onClick={buy}
                    className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-60 h-8 rounded-3xl"
                >
                  {t("page.NFTDETAIL_confirm-purchase")}
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
  );
};

export default NftDetailInfo;
