import { Button, Form, message, notification } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  HolderInfo,
  ip_royalty_file_download,
  ip_royalty_details,
  ip_holder_list_info,
  ip_royalty_request,
  RequestDetailInfo,
} from "@/services";
import React, { useEffect, useRef, useState } from "react";
import JpnftSteps from "@/components/JpnftSteps";
import NftMessage from "@/components/NftMessage";
import { RoutePaths } from "@/router";
import { useAccountState } from "@/store";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { normal } from "@/constants";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";

const NftApplication2 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const data: any = location.state;
  const account = useAccountState();
  const navigate = useNavigate();
  const { irRequestId } = useParams();
  const [ipHolderInfo, setIpHolderInfo] = useState<HolderInfo | null>();
  const [requestDetail, setRequestDetail] = useState<RequestDetailInfo>();
  const [form] = Form.useForm();
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
  //イメージファイル
  const [nftImageFileName, setNftImageFileName] = useState<string>("");
  const [nftImageFile, setNftImageFile] = useState<File>();

  //NFTファイル
  const [nftDescFileName, setNftDescFileName] = useState<string>("");
  const [nftDescFile, setNftDescFile] = useState<File>();

  const nftImageFileReF = useRef<HTMLInputElement | null>(null);
  const nftDescFileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDetail = async function () {
      if (irRequestId) {
        const irDetailsRes = await ip_royalty_details(irRequestId);
        if (irDetailsRes) {
          setRequestDetail(irDetailsRes);
          if (irDetailsRes?.data.fromInfo.fromUserType == 3) {
            const userId = irDetailsRes?.data.fromInfo.fromUserId;
            const languageId =
              i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
            const res01 = await ip_holder_list_info(userId);
            if (res01) {
              setIpHolderInfo(res01);
            } else {
              showErrorMsg(res01, t);
            }
          }
        }
      }
    };
    getDetail();
  }, []);

  const downloadFile = async (
    fileId: number | undefined,
    fileName: string | undefined
  ) => {
    if (!fileId) {
      return;
    }
    const fName: string | undefined = fileName;
    const result = await ip_royalty_file_download(fileId);
    if (result && result.code > 0) {
      let msg = Object.keys(result.msg)[0];
      message.error(msg);
      return;
    }
    if (result) {
      var blob = new Blob([result]);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      if (typeof fName === "string") {
        downloadElement.download = fName;
      }
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    }
  };

  const formatPrice = (price: number) => {
    let res = String(price)
      .split("")
      .reverse()
      .reduce((prev, next, index) => {
        return (index % 3 ? next : next + ",") + prev;
      });
    return res + "円";
  };

  function uploadImgFile() {
    if (nftImageFileReF.current) {
      nftImageFileReF.current.click();
    }
  }
  function uploadNftFile() {
    if (nftDescFileRef.current) {
      nftDescFileRef.current.click();
    }
  }

  const uploadImg = async (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      // let fileType = fileName
      //   .substring(fileName.lastIndexOf(".") + 1)
      //   .toLowerCase();
      const isLt2M = file.size / 1024 / 1024 < 10;
      // const isImg =
      //   fileType === "jpg" ||
      //   fileType === "jpeg" ||
      //   fileType === "png" ||
      //   fileType === "gif" ||
      //   fileType === "bmp"
      //     ? true
      //     : false;
      if (!isLt2M) {
        message.error(t("msg.RIGHTAPPRFIR_8"));
        return;
      }
      setNftImageFileName(fileName);
      setNftImageFile(file);
      notification.success({
        message: t("msg.USER_1"),
      });
    }
  };

  const uploadNFT = async (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      // let fileType = fileName
      //   .substring(fileName.lastIndexOf(".") + 1)
      //   .toLowerCase();
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error(t("msg.RIGHTAPPRFIR_8"));
      }
      setNftDescFileName(fileName);
      setNftDescFile(file);
      notification.success({
        message: t("msg.RIGHTREQSEC_1"),
      });
    }
  };

  const onFinish = async (cancel: boolean) => {
    if (!cancel) {
      if (nftDescFile === undefined || nftImageFile === undefined) {
        message.warning(t("msg.RIGHTREQSEC_5"));
        return;
      }
    }
    if (account.isLogin) {
      const params: any = {
        irRequestId: irRequestId,
        nftDescFile: nftDescFile,
        nftImageFile: nftImageFile,
        cancelFlg: cancel,
      };
      const res = await ip_royalty_request(params);
      if (res) {
        console.log(res);
        message.success(t("msg.IPREQ_6"));
        navigate(RoutePaths.NFT_APPLICATION_LIST);
      } else {
        message.error(t("msg.IPREQ_7"));
      }
    } else {
      message.error(t("msg.RIGHTREQFIR_4"));
      navigate(RoutePaths.HOME);
    }
  };

  return (
    <div className="minHeight">
      <div className="main-container flex" style={{userSelect:"text"}}>
        <input
          ref={nftImageFileReF}
          accept="*"
          type="file"
          onChange={(event) => uploadImg(event)}
          style={{ display: "none" }}
        />
        <input
          ref={nftDescFileRef}
          accept="*"
          type="file"
          onChange={(event) => uploadNFT(event)}
          style={{ display: "none" }}
        />
        <Menu/>
        <div className="w-4/5 text-center pl-10 pr-30">
          <div className="py-7 fs_h1">
            {t("page.NFTREQUESTSEC_nft-license-application")}
          </div>

          <div className="px-14">
            <JpnftSteps current={data.stepNum} />
          </div>
          <div className="w-full pt-10">
            <Form form={form} layout="vertical" autoComplete="off">
              <div
                className="rounded-2xl pt-10"
                style={{ backgroundColor: "#F8CE0F" }}
              >
                <div className="fs_h2">
                  {t("page.NFTREQUESTSEC_Payment-Successful")}
                </div>
                <div className="fs_16 pb-5">
                  {t("page.NFTREQUESTSEC_Payment-Successful-description")}
                </div>
              </div>

              <div className="mt-20">
                <div className="fs_h3 text-left">
                  {t("page.NFTREQUESTSEC_application-2-information")}
                </div>
                <div className="flex space-x-1 w-full justify-start items-center">
                  <span className="fs_18_bold mt-5">
                    {t("page.NFTREQUESTSEC_finalized-creation-proposal")}
                  </span>
                  <span
                    style={{ fontSize: "18px", fontWeight: 800 }}
                    className="text-red-500 mt-6"
                  >
                    ※
                  </span>
                </div>

                <div className="fs_16 text-left">
                  {t(
                    "page.NFTREQUESTSEC_finalized-creation-proposal-description"
                  )}
                </div>
                <div className="flex w-full justify-start mt-2">
                  <Button
                    shape="round"
                    size="large"
                    style={{
                      minWidth: "125px",
                      background: "#13c1f1",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      userSelect:"text"
                    }}
                    onClick={() => uploadNftFile()}
                  >
                    {t("page.NFTREQUESTSEC_choose-proposal-file")}
                  </Button>
                  <span className="fs_16 ml-5 h-full self-center">
                    {nftDescFileName}
                  </span>
                </div>

                <div className="flex space-x-1 w-full justify-start items-center">
                  <span className="fs_18_bold mt-5">
                    {t("page.NFTREQUESTSEC_finalized-nft-image")}
                  </span>
                  <span
                    style={{ fontSize: "18px", fontWeight: 800 }}
                    className="text-red-500 mt-6"
                  >
                    ※
                  </span>
                </div>
                <div className="fs_16 text-left">
                  {t("page.NFTREQUESTSEC_finalized-nft-image-description")}
                </div>
                <div className="flex w-full justify-start mt-2">
                  <Button
                    shape="round"
                    size="large"
                    style={{
                      minWidth: "125px",
                      background: "#13c1f1",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      userSelect:"text"
                    }}
                    onClick={() => uploadImgFile()}
                  >
                    {t("page.NFTREQUESTSEC_choose-proposal-file")}
                  </Button>
                  <span className="fs_16 ml-5 h-full self-center">
                    {nftImageFileName}
                  </span>
                </div>
                <div className="flex w-full justify-center items-center space-x-5 pt-5">
                  <button
                    onClick={() => onFinish(true)}
                    className="text-jpnft-buttonTo font-bold w-56 h-12 rounded-3xl text-base border-4 border-jpnft-buttonTo"
                  >
                    {t("page.NFTREQUESTSEC_cancel-application")}
                  </button>
                  <button
                    onClick={() => onFinish(false)}
                    className="text-white font-bold bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-56 h-12 rounded-3xl"
                  >
                    {t("page.NFTREQUESTSEC_apply")}
                  </button>
                </div>
              </div>
              <div className="mt-10 w-full">
                <NftMessage userId={requestDetail?.data.fromInfo.fromUserId} />
              </div>
              <div
                className="rounded-2xl mt-20"
                style={{ backgroundColor: "#D4F6FF" }}
              >
                <div className="pt-10">
                  <div className="fs_h3 text-left pl-10">
                    {t("page.NFTAPPROVALSEC_service-fee-payment")}
                  </div>
                  <div className="flex w-full h-10  px-10 mt-5">
                    <div className="w-full h-full bg-jpnft-buttonTo rounded-tl-3xl rounded-tr-3xl">
                      <div className="h-full fs_16_white flex justify-center items-center">
                        {t("page.NFTREQUESTSEC_royalty-information")}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full px-10">
                    <div className="flex flex-col h-full w-5/12 border-t border-b border-l border-jpnft-border bg-jpnft-tbHeaderBg text-left">
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTREQUESTSEC_nft-creation-fee")}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="flex flex-col text-base text-jpnft-tbFtColor font-semibold">
                          <div>
                            {t("page.NFTREQUESTSEC_primary-sales-royalty")}
                          </div>
                          <div>
                            {t(
                              "page.NFTREQUESTSEC_primary-sales-royalty-description"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="flex flex-col text-base text-jpnft-tbFtColor font-semibold">
                          <div>
                            {t("page.NFTREQUESTSEC_secondary-sales-royalty")}
                          </div>
                          <div>
                            {t(
                              "page.NFTREQUESTSEC_secondary-sales-royalty-description"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTAPPROVALFIR_sales-royalty-distribution")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col bg-white border border-jpnft-border h-full w-7/12 text-left">
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail &&
                            formatPrice(requestDetail?.data.fee)}
                        </div>
                      </div>

                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail?.data.royalty}%
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail?.data.royalty2}%
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail?.data.royaltyN}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex w-full h-10  px-10">
                    <div className="w-full h-full bg-jpnft-buttonTo">
                      <div className="h-full fs_16_white flex justify-center items-center">
                        {t("page.NFTPAYED_payment-information")}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full pl-10 pr-10">
                    <div className="flex flex-col h-full w-5/12 border-t border-b border-l border-jpnft-border rounded-bl-3xl bg-jpnft-tbHeaderBg text-left">
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_transaction-number")}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_transfer-amount")}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_transfer-deadline")}
                        </div>
                      </div>
                      <div className="w-full px-5 h-72 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t(
                            "page.NFTPAYINFO_recipient-bank-account-information"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col bg-white border border-jpnft-border h-full w-7/12  rounded-br-3xl text-left">
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail?.data.irRequestId}
                        </div>
                      </div>

                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail &&
                            formatPrice(requestDetail?.data.fee)}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          {requestDetail?.data.paymentDueDate}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-72 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor">
                          <div>
                            {requestDetail?.data.bankInfo?.jpBankInfo.map(
                              (item, index) => {
                                return (
                                  <div key={`bankIndex_${index}`}>
                                    {item.bankInfo}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="mt-5">
                            {requestDetail?.data.bankInfo?.enBankInfo.map(
                              (item, index) => {
                                return (
                                  <div key={`bankIndex_${index}`}>
                                    {item.bankInfo}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-10">
                  <div className="fs_h3 text-left pl-10">
                    {t("page.NFTPAYINFO_application-1-information")}
                  </div>
                  <div
                    className="flex w-full mt-5 pl-10 pb-10 pr-10"
                    style={{ height: "600px" }}
                  >
                    <div className="flex flex-col h-full w-1/3 border-t border-b border-l border-jpnft-border bg-jpnft-tbHeaderBg rounded-tl-3xl  rounded-bl-3xl text-left">
                      {requestDetail?.data.fromInfo.fromUserType === 2 ? (
                        <>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.NFTPAYINFO_username")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.NFTPAYINFO_twitter")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.NFTPAYINFO_linktree")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.NFTPAYINFO_opensea")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.NFTPAYINFO_self-introduction")}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="w-full border-b border-jpnft-border px-5 flex justify-start items-center"
                            style={{ height: "272px" }}
                          >
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_ip-profile-picture")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_company-name-individual-name")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_address")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_phone-number")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_email-address")}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPHOLDER_organization-type")}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_application-1-information")}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_nft-creation-proposal")}
                        </div>
                      </div>
                      <div className="w-full px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {t("page.NFTPAYINFO_nft-image-draft")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col border border-jpnft-border h-full w-2/3 rounded-tr-3xl  rounded-br-3xl text-left">
                      {requestDetail?.data.fromInfo.fromUserType == 2 ? (
                        <>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              <div
                                className="h-16"
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "top",
                                }}
                              >
                                <img
                                  className="w-full h-full" style={{userSelect:"none"}}
                                  src={
                                    normal +
                                    "/" +
                                    requestDetail?.data.fromInfo.fromAvatar!
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "top",
                                }}
                                className="pt-5 pl-3"
                              >
                                {requestDetail?.data.fromInfo.fromName}
                              </div>
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {requestDetail?.data.ipInfo.twitterUrl}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {requestDetail?.data.ipInfo.linktreeUrl}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {requestDetail?.data.ipInfo.openseaUrl}
                            </div>
                          </div>
                          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {requestDetail?.data.fromInfo.fromDetails}
                            </div>
                          </div>
                        </>
                      ) : (
                          <>
                            <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                              <div className="text-base text-jpnft-tbFtColor font-semibold">
                                <img
                                    src={normal + "/" + ipHolderInfo?.data.ipholders[languageId-1].avatar}
                                    style={{height: "165px",userSelect:"none" }}
                                    className="rounded-md"
                                />
                              </div>
                            </div>
                            <div
                                className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                              <div
                                  className="text-base text-jpnft-tbFtColor font-semibold">
                                {ipHolderInfo?.data.ipholders[languageId-1].ipholderName}
                              </div>
                            </div>
                            <div
                                className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                              <div
                                  className="text-base text-jpnft-tbFtColor font-semibold">
                                {`${ipHolderInfo?.data.ipholders[languageId-1].postCode === undefined ? '' : '〒' + ipHolderInfo?.data.ipholders[languageId-1].postCode}　${ipHolderInfo?.data.ipholders[languageId-1].address === undefined ? '' : ipHolderInfo?.data.ipholders[languageId-1].address}`}
                              </div>
                            </div>
                            <div
                                className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                              <div
                                  className="text-base text-jpnft-tbFtColor font-semibold">
                                {ipHolderInfo?.data.ipholders[languageId-1]?.tel}
                              </div>
                            </div>
                            <div
                                className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                              <div
                                  className="text-base text-jpnft-tbFtColor font-semibold">
                                {ipHolderInfo?.data.ipholders[languageId-1]?.email}
                              </div>
                            </div>
                            <div
                                className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                              <div
                                  className="text-base text-jpnft-tbFtColor font-semibold">
                                {ipHolderInfo?.data.userType === undefined ? '' : ipHolderInfo?.data.userType === 2 ? languageId==1?'個人':languageId==2?"Individual":"个人"  : languageId==1?'法人':languageId==2?"Company":"法人"}
                              </div>
                            </div>
                          </>
                      )}
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          {requestDetail?.data.collectionInfo.name}
                        </div>
                      </div>
                      <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                          <div className="rounded-md w-max">
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "#13c1f1",
                              }}
                              className="fs_16_bold"
                              onClick={() =>
                                downloadFile(
                                  requestDetail?.data.nftInfo.kariNftDescFileId,
                                  requestDetail?.data.nftInfo
                                    .kariNftDescFileName
                                )
                              }
                            >
                              {" "}
                              {requestDetail?.data.nftInfo.kariNftDescFileName}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                        <div className="rounded-md w-max">
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#13c1f1",
                            }}
                            className="fs_16_bold"
                            onClick={() =>
                              downloadFile(
                                requestDetail?.data.nftInfo.kariImageFileId,
                                requestDetail?.data.nftInfo.kariImageFileName
                              )
                            }
                          >
                            {" "}
                            {requestDetail?.data.nftInfo.kariImageFileName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="rounded-3xl pt-10 mb-20"
                  style={{ backgroundColor: "#D4F6FF" }}
                >
                  <div className="fs_h3 pb-2 text-left pl-10">
                    {t("page.NFTPAYINFO_ip-information")}
                  </div>
                  <div className="grid grid-rows-3 grid-flow-col pl-10">
                    <div className="row-span-4">
                      <div
                          className="w-52"
                          style={{ borderRadius: "15px" }}
                      >
                        <img
                            src={requestDetail?.data.ipInfo.ipImageUrl}
                            className="h-full cover rounded-3xl" style={{userSelect:"none"}}
                        />
                      </div>
                      <div className="text-left w-52 pt-3 pb-10">
                        {requestDetail?.data.ipInfo.ipInfos[languageId-1].ipName}
                      </div>
                    </div>
                    <div className="col-span-2 w-96 text-left ">
                      <div className="fs_h3">{t("page.NFTREQUESTFIR_ip-holder")}</div>
                      <div className="fs_16">
                        {requestDetail?.data.ipInfo?.ipholders[languageId-1].ipholderName}
                      </div>
                    </div>
                    <div className="row-span-2 col-span-2 text-left py-5 pr-5">
                      <div className="fs_h3 w-96">{t("page.NFTREQUESTFIR_important-nft-creation")}</div>
                      <TextArea readOnly
                                className="fs_16"
                                value={requestDetail?.data.ipInfo?.ipInfos[languageId-1].ipContent} rows={4}
                                style={{borderColor: "#D4F6FF",backgroundColor:"#D4F6FF",resize:"none"}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftApplication2;
