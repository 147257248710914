import { Button, Input, message, notification, Radio, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "@/router";
import {
  collectionList,
  ip_royalty_request,
} from "@/services";
import { useEffect, useRef, useState } from "react";
import { Steps } from "antd";
import JpnftSteps from "@/components/JpnftSteps";
import TextArea from "antd/lib/input/TextArea";
import { useAccountState } from "@/store";
import IpHolderInfo from "@/components/IpHolderInfo";
import {useTranslation} from "react-i18next";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";

const NftApplication = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAccountState();
  const [collectionData, setCollectionData] = useState([]);
  const location = useLocation();
  const data: any = location.state;
  const accountState = useAccountState();
  //新規コレクション名
  const [collectionName, setCollectionName] = useState();
  //コレクションID
  const [collectionId, setCollectionId] = useState("");

  //仮イメージファイル
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File>();

  //仮NFTファイル
  const [nftFileName, setNftFileName] = useState<string>("");
  const [nftFile, setNftFile] = useState<File>();

  const imgFileReF = useRef<HTMLInputElement | null>(null);
  const nftFileReF = useRef<HTMLInputElement | null>(null);
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

  const { Step } = Steps;
  const { Option } = Select;

  const [collectionType, setCollectionType] = useState<number>(1);
  const options = [
    { label: t("page.NFTREQUESTFIR_new-collection"), value: 1 },
    { label: t("page.NFTREQUESTFIR_existing-collection"), value: 2 },
  ];
  const collectionTypeChange = (e: any) => {
    const type = parseInt(e.target.value);
    setCollectionType(type);
  };

  const collectionNameChange = (e: any) => {
    setCollectionName(e.target.value);
  };

  const handleChange = (value: string) => {
    setCollectionId(value);
  };

  const fetchCollectionList = async () => {
    const res = await collectionList();
    if (res && res.data) {
      setCollectionData(res.data);
      console.log(accountState,"accpunt")
    }else{
     message.error(t(Object.keys(res.msg)[0]));
    }
  };

  const onFinish = async () => {
    if (
      nftFile === undefined ||
      file === undefined ||
      (collectionType === 1 && collectionName === "")
    ) {
      message.warning(t("msg.RIGHTREQSEC_5"));
      return;
    }
    if (account.isLogin) {
      if (file) {
        let params: any = {
          ipBlockchainId: data.ipBlockchainId,
          kariRequestFile: file,
          kariNftDescFile: nftFile,
        };
        if (collectionType === 1) {
          params["collectionName"] = collectionName;
        } else {
          params["collectionId"] = collectionId;
        }
        const res = await ip_royalty_request(params);
        if (res) {
          console.log(res);
          message.success(t("msg.IPREQ_6"));
          navigate(RoutePaths.NFT_APPLICATION_LIST);
        } else {
          message.error(t("msg.IPREQ_7"));
        }
      } else {
        message.error(t("msg.RIGHTREQFIR_3"));
      }
    } else {
      message.error(t("msg.RIGHTREQFIR_4"));
      navigate(RoutePaths.HOME);
    }
  };

  function uploadImgFile() {
    if (imgFileReF.current) {
      imgFileReF.current.click();
    }
  }
  function uploadNftFile() {
    if (nftFileReF.current) {
      nftFileReF.current.click();
    }
  }

  const uploadImg = async (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      // let fileType = fileName
      //   .substring(fileName.lastIndexOf(".") + 1)
      //   .toLowerCase();
      const isLt2M = file.size / 1024 / 1024 < 10;
      // const isImg =
      //   fileType === "jpg" ||
      //   fileType === "jpeg" ||
      //   fileType === "png" ||
      //   fileType === "gif" ||
      //   fileType === "bmp"
      //     ? true
      //     : false;
      if (!isLt2M) {
        message.error(
          t("msg.RIGHTAPPRFIR_8")
        );
        return;
      }
      setFileName(fileName);
      setFile(file);
      notification.success({
        message: t("msg.USER_1"),
      });
    }
  };

  const uploadNFT = async (e: any) => {
    debugger
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      // let fileType = fileName
      //   .substring(fileName.lastIndexOf(".") + 1)
      //   .toLowerCase();
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error(t("msg.RIGHTAPPRFIR_8"));
      }
      setNftFile(file);
      setNftFileName(fileName);
      notification.success({
        message: t("msg.RIGHTREQSEC_1"),
      });
    }
  };

  useEffect(() => {
    fetchCollectionList();
    //滚动条到顶部
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="minHeight">
      <div className="main-container flex">
        <Menu/>
        <div className="w-4/5 text-center pl-10 pr-30" style={{userSelect:"text"}}>
          <input
            ref={imgFileReF}
            accept="*"
            type="file"
            onChange={(event) => uploadImg(event)}
            style={{ display: "none" }}
          />
          <input
            ref={nftFileReF}
            accept="*"
            type="file"
            onChange={(event) => uploadNFT(event)}
            style={{ display: "none" }}
          />
          <div className="py-5 fs_h1">{t("page.NFTREQUESTFIR_nft-license-application")}</div>
          <div className="px-14">
            <JpnftSteps current={1} />
          </div>
          <div className="flex w-full space-x-8 mt-16">
            <div className="w-1/3 h-auto py-5 bg-jpnft-ipDetailsBg rounded-xl">
              <div className="flex w-full justify-start px-8">
                <span className="fs_18_bold">{t("page.NFTREQUESTFIR_ip-information")}</span>
              </div>
              <div className="px-8 pt-2 w-48">
                <img
                  src={data?.ipImageUrl}
                  className="w-full cover rounded-3xl"
                  alt=""
                  style={{userSelect:"none"}}
                />
              </div>
              <div className="px-8 pt-2 flex w-full justify-start">
                <span className="fs_16">{data?.ipInfos[languageId-1]?.ipName}</span>
              </div>

              <div className="flex w-full justify-start px-8 pt-3">
                <span className="fs_18_bold">{t("page.NFTREQUESTFIR_ip-holder")}</span>
              </div>
              <div className="px-8 pt-2 flex w-full justify-start text-left w-56">
                <span className="fs_16" style={{wordBreak: "break-all"}}>{data?.ipHolders[languageId-1].ipholderName}</span>
              </div>
              <div className="flex w-full justify-start px-8 pt-3">
                <span className="fs_18_bold">{t("page.NFTREQUESTFIR_important-nft-creation")}</span>
              </div>
              <div className="px-8 pt-2 flex w-full justify-start">
                <TextArea readOnly
                      className="fs_16"
                      value={data?.ipInfos[languageId-1].ipContent} rows={4}
                      style={{borderColor: "#D4F6FF",backgroundColor:"#D4F6FF",resize:"none",padding:"4px 4px"}}
                      />
              </div>
            </div>

            {/**right part */}
            <div className="w-2/3 h-auto">
              <div className="flex w-full justify-start">
                <span className="fs_h3">{t("page.NFTREQUESTFIR_application-1-information")}</span>
              </div>
              <div className="flex space-x-1 w-full justify-start">
                <span className="fs_18_bold mt-6">{t("page.NFTREQUESTFIR_collection-name")}</span>
                <span
                  style={{ fontSize: "18px", fontWeight: 800 }}
                  className="text-red-500 mt-6"
                >
                  ※
                </span>
              </div>
              <div className="flex flex-col w-full justify-start items-start mt-1">
                <Radio.Group
                  options={options}
                  defaultValue={1}
                  onChange={(e) => {
                    collectionTypeChange(e);
                  }}
                />
                {collectionType === 1 && (
                  <div className="w-full mt-3">
                    <Input
                      style={{ borderRadius: "12px" }}
                      width={"100%"}
                      placeholder={t("page.NFTREQUESTFIR_within-50-characters")}
                      onChange={(e) => collectionNameChange(e)}
                    />
                  </div>
                )}
                {collectionType === 2 && (
                  <div className="flex w-full mt-3 justify-start">
                    <Select
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderRadius: "12px",
                      }}
                    >
                      {collectionData.map((item: any) => {
                        return (
                          <Option
                            value={item.collectionId}
                            key={item.collectionId}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                )}
              </div>
              <div>
                <div className="flex space-x-1 w-full justify-start">
                  <span className="fs_18_bold mt-6">{t("page.NFTREQUESTFIR_nft-creation-proposal")}</span>
                  <span
                    style={{ fontSize: "18px", fontWeight: 800 }}
                    className="text-red-500 mt-6"
                  >
                    ※
                  </span>
                </div>
                <div className="flex">
                  {/*<a*/}
                  {/*  href="https://en.wikipedia.org/wiki/Non-fungible_token"*/}
                  {/*  target="_blank"*/}
                  {/*  className="text-base underline"*/}
                  {/*>*/}
                  {/*  NFT*/}
                  {/*</a>*/}
                  {/*<span style={{ color: "#40a9ff", fontSize: "16px" }}>*/}
                  {/*  作成企画書*/}
                  {/*</span>*/}
                  <span className="fs_16 text-left">
                    {t("page.NFTREQUESTFIR_nft-creation-proposal-description")}
                  </span>
                </div>
                <div className="flex w-full justify-start mt-2" style={{userSelect:"text"}}>
                  <Button
                    shape="round"
                    size="large"
                    style={{
                      minWidth: "125px",
                      background: "#13c1f1",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      userSelect:"text"
                    }}
                    onClick={() => uploadNftFile()}
                  >
                    {t("page.NFTREQUESTFIR_choose-a-file")}
                  </Button>
                  <span className="fs_16 ml-5 h-full self-center">
                    {nftFileName}
                  </span>
                </div>
              </div>

              <div>
                <div className="flex space-x-1 w-full justify-start">
                  <span className="fs_18_bold mt-6">{t("page.NFTREQUESTFIR_nft-image-draft")}</span>
                  <span
                    style={{ fontSize: "18px", fontWeight: 800 }}
                    className="text-red-500 mt-6"
                  >
                    ※
                  </span>
                </div>
                <div className="flex">
                  <span className="fs_16">
                    {t("page.NFTREQUESTFIR_upload-image")}
                  </span>
                </div>
                <div className="flex w-full justify-start mt-2" style={{userSelect:"text"}}>
                  <Button
                    shape="round"
                    size="large"
                    style={{
                      minWidth: "125px",
                      background: "#13c1f1",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      userSelect:"text"
                    }}
                    onClick={() => uploadImgFile()}
                  >
                    {t("page.NFTREQUESTFIR_choose-a-file")}
                  </Button>
                  <span className="fs_16 ml-5 h-full self-center">
                    {fileName}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full mt-10">
            <div className="w-1/3"></div>
            <div className="w-2/3 h-auto">
              {accountState.userType === 3 ?
                  <>
                    <Button onClick={() =>
                        window.open("mailto:?subject=&cc=&subject=主题&body=内容")
                    }
                            style={{background:"#13c1f1",width:"150px",borderRadius:"20px",color:"white",fontSize:"16px",height:"35px",
                              position:"absolute",
                              marginLeft:"7%",
                              userSelect:"text"}} >
                      {t("page.NFTREQUESTFIR_edit")}
                    </Button>
                  <IpHolderInfo/>
                  </>
                  :
                  <>
                    <div className="flex w-full justify-between">
                      <div className="fs_h3">{t("page.NFTREQUESTFIR_user-information")}</div>
                      <Button
                          onClick={() => window.open("mailto:?subject=&cc=&subject=主题&body=内容")}
                          style={{
                            background: "#13c1f1",
                            width: "150px",
                            borderRadius: "20px",
                            color: "white",
                            fontSize: "16px",
                            height: "35px",
                            userSelect:"text"
                          }}
                      >
                        {t("page.NFTREQUESTFIR_edit")}
                      </Button>
                    </div>
                    <div className="w-full">
                      <div
                          className="grid grid-cols-2 border border-jpnft-border rounded-md mt-5 text-base text-jpnft-tbFtColor font-semibold text-left">
                        <div className=" bg-jpnft-tbHeaderBg px-3 rounded-tl-md border-b border-jpnft-border h-24 py-2">
                          {t("page.NFTREQUESTFIR_username")}
                        </div>
                        <div className="flex justify-start items-center px-3 border-b border-jpnft-border h-24">
                          <div className=" max-h-full w-32">
                            <img
                                className="w-full h-full" style={{userSelect:"none"}}
                                src={accountState.avatarUrl!}/>
                          </div>
                          <div>{accountState.userName}</div>
                        </div>
                        <div
                            className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">
                          <span>{t("page.NFTREQUESTFIR_twitter")}</span>
                        </div>
                        <div
                            className="flex justify-start items-center px-3 border-b border-jpnft-border h-12 break-words">
                          <a href={accountState?.twitterUrl} target="_blank">
                            {accountState?.twitterUrl}
                          </a>
                        </div>
                        <div
                            className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">
                          <span>{t("page.NFTREQUESTFIR_linktree")}</span>
                        </div>
                        <div className="flex justify-start items-center px-3 border-b border-jpnft-border h-12">
                          {accountState?.linktreeUrl}
                        </div>
                        <div
                            className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">
                          <span>{t("page.NFTREQUESTFIR_opensea")}</span>
                        </div>
                        <div className="flex justify-start items-center px-3 border-b border-jpnft-border h-12">
                          {accountState.openseaUrl}
                        </div>
                        <div className=" bg-jpnft-tbHeaderBg px-3 rounded-bl-md border-b border-jpnft-border h-64 py-2">
                          {t("page.USER_bio")}
                        </div>
                        <div className="flex justify-start items-center px-3 border-b border-jpnft-border h-64">
                          <TextArea readOnly
                                    className="fs_16_bold"
                                    value={accountState.userDetails ? accountState.userDetails : ""} rows={10}
                                    style={{
                                      borderColor: "white",
                                      backgroundColor: "white",
                                      resize: "none",
                                      padding: "4px 0px"
                                    }}/>
                        </div>
                      </div>
                    </div>
                  </>}
            </div>
          </div>

          <div className="w-full flex justify-center items-center py-20">
            <button
              onClick={() => onFinish()}
              className="text-white font-bold bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-56 h-12 rounded-3xl"
            >
              {t("page.NFTREQUESTSEC_apply")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftApplication;
