import {Link, useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import {Actions} from "@/store/reducers/account";
import {useAccountState, useAppDispatch} from "@/store";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import {normal} from "@/constants";
import clsx from "clsx";
import {ip_detail, ip_holder_info, ip_list} from "@/services";
import {showErrorMsg} from "@/utils/error";
import i18n from "@/locales";

import Profile from "@/pages/UserInfo/Profile";
import NftCreationList from "@/pages/NFT/NftCreationList";
import NftBoughtList from "@/pages/NFT/NftBoughtList";
import ProfileIpholder from "@/pages/UserInfo/ProfileIpholder";
import IPForm from "@/pages/IP/IPForm";
import AdminMyPage from "@/pages/Mypage/AdminMyPage";
import {fail} from "assert";
import Analytics from "@/components/Analytics";

interface DataType {
    ownerId: number;
    ipId: number;
    ipBlockchainId: string;
    ipholderName: string;
    ipImageUrl: string;
    ipName: string;
    price: number;
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

const Menu = (props: any) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const accountState = useAccountState();
    const [tableData, setTableData] = useState<DataType[]>([]);
    const [status, setStatus] = useState<string>("0");
    const [show, setShow] = useState<string>("0");
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

    function logout() {
        dispatch(Actions.logout());
        navigate(RoutePaths.HOME);
    }

    const [admin, setAdmin] = useState<boolean>(false);
    const userInfo = async () => {
        //const res = await getUserType(account.name);
        console.log(accountState.userType);
        if (accountState.userType == 2) {
            navigate(RoutePaths.PROFILE);
        } else if (accountState.userType == 3) {
            navigate(RoutePaths.PROFILE_IPHOLDER);
        } else {
        }
    };

    const onChangeStatus = async (value: string) => {
        setStatus(value);
    }
    const onChangeShow = async (value: string) => {
        //1:ipholderInfo  2:userInfo 3:ユーザー情報  4:購入NFT一览   5:作成NFT一览  6:IP窓口情報  7:IP窓口登録申請
        setShow(value);
        if (value == "8") {
            setShow("6");
            setAdmin(false)
        }
        if (value == "2") {
            const res = await ip_list("", "", accountState.userId + "", languageId);
            if (res) {
                setTableData(res.data);
            } else {
                showErrorMsg(res, t);
            }
        }
    }

    const openDetail = async function (ipBlockchainId: string, ownerId: number) {
        console.log(ipBlockchainId, "ip");
        navigate(RoutePaths.IP_DETAIL + `/${ownerId}/${ipBlockchainId}`);
    };

    const [ipHolderInfo, setIpHolderinfo] = useState<{
        data: {
            userId: number;
            ethAddress: string;
            proxyAddress: string;
            ipholderName: string;
            postCode: string;
            address: string;
            tel: string;
            email: string;
            avatar: string;
            userType: number;
        }
    }>();
    const getIpHolderInfo = async () => {
        const res = await ip_holder_info(accountState.userId!, languageId);
        if (res) {
            setIpHolderinfo(res);
        } else {
            showErrorMsg(res, t);
        }
    }

    useEffect(() => {
        if (accountState.userType == 3) {
            getIpHolderInfo();
        }
        setShow(props.show);
        setStatus(props.status);
    }, [languageId]);

    return (
        <div className="w-1/5 fs_16_bold text-left rounded-xl mt-5"
             style={{backgroundColor: "#d4f6ff", maxHeight: "620px"}}>
            <div className="">
                <button className="py-2 px-4 max-h-full w-54" style={{userSelect:"none"}}>
                    <img
                        onClick={() => navigate(RoutePaths.MY_PAGE, {
                            state: {
                                show: accountState.userType == 3 ?"6":accountState.userType == 1 ? "8" : "3",
                                status: "0"
                            }})}
                        className="w-56 h-56" style={{borderRadius: "150px"}}
                        src={accountState.userType !== 2 ? normal + "/" + ipHolderInfo?.data.avatar : accountState.avatarUrl!}/>
                </button>
                <div className="py-2 px-5 text-center w-auto"
                     style={{wordBreak: "break-all",userSelect:"text"}}>{accountState.userType !== 2 ? ipHolderInfo?.data.ipholderName : accountState.userName}</div>
                {accountState.userType !== 2 &&
                    <div className="pl-10"
                         style={{backgroundColor: clsx(status == "1" ? "#eafbff" : ""),userSelect:"text"}}>
                        <button className="fs_18_bold" onClick={() => onChangeStatus("1")}>IP</button>
                        {status == "1" &&
                            <div className="pl-3">
                                <button className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                                        style={{
                                            width: "80%",
                                            backgroundColor: clsx(show == "2" ? "white" : "")
                                        }}
                                        onClick={() => navigate(RoutePaths.MY_PAGE, {
                                            state: {
                                                show: "2",
                                                status: "1"
                                            }
                                        })}>{t("page.MYPAGE_registered-ip")}
                                </button>
                                <div className="pl-3 py-2 fs_18_bold">{t("page.MYPAGE_anti-piracy")}</div>
                                <button className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                                        style={{
                                            width: "80%",
                                            backgroundColor: clsx(show == "10" ? "white" : "")
                                        }}
                                        onClick={() => navigate(RoutePaths.MY_PAGE, {
                                            state: {
                                                show: "10",
                                                status: "1"
                                            }
                                        })}>{t("page.MYPAGE_analytics")}
                                </button>

                            </div>
                        }
                    </div>
                }
                <div className="pl-10 py-2"
                     style={{backgroundColor: clsx(status == "2" ? "#eafbff" : ""),userSelect:"text"}}>
                    <button className="fs_18_bold" onClick={() => onChangeStatus("2")}>NFT</button>
                    {status == "2" &&
                        <div className="pl-3">
                            <button className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                                    style={{
                                        width: "80%",
                                        backgroundColor: clsx(show == "4" ? "white" : "")
                                    }}
                                    onClick={() => navigate(RoutePaths.MY_PAGE, {
                                        state: {
                                            show: "4",
                                            status: "2"
                                        }
                                    })}>{t("page.MYPAGE_purchased-nft")}
                            </button>
                            <button className="pl-3 text-left py-2 pb-2 rounded-3xl fs_18_bold"
                                    style={{
                                        width: "80%",
                                        backgroundColor: clsx(show == "5" ? "white" : "")
                                    }}
                                    onClick={() => navigate(RoutePaths.MY_PAGE, {
                                        state: {
                                            show: "5",
                                            status: "2"
                                        }
                                    })}>{t("page.MYPAGE_create-nft")}
                            </button>
                        </div>
                    }
                </div>
                <div style={{userSelect:"text"}}><button className="pl-10 py-2 fs_18_bold" style={{userSelect:"text"}} onClick={() => logout()}>{t("page.MYPAGE_logout")}</button></div>
            </div>
        </div>
    );
};
export default Menu;
