import {IpHolderInfo, nft_get_chat, nft_update_chat, sendInquiry} from "@/services";
import React, {useEffect, useState, useRef} from "react";
import {Button, Form, message, notification} from "antd";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {ReactEmojiEditor, ReactEmojiShow} from 'react-emotor'
import {useAccountState} from "@/store";
import TextArea from "antd/lib/input/TextArea";
import {RoutePaths} from "@/router";
import {normal} from "@/constants";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import clsx from "clsx";

interface DataType {
    chatInfo: string;
    irChatId: number;
    irRequestId: number;
    senderId: number;
    avatar: string;
    ipholders:IpHolderInfo[];
}

const NftMessage = (props: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const accountState = useAccountState();
    const emotor = useRef();
    const {irRequestId} = useParams();
    const [mychat, setMychat] = useState<string>("");
    const [nftChatInfo, setNftChatInfo] = useState<DataType[]>();
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    const getNftMessage = async () => {
        const res = await nft_get_chat(parseInt(irRequestId!));
        if (res.data) {
            setNftChatInfo(res.data);
        }else{
            showErrorMsg(res,t);
        }
    }
    useEffect(() => {
        getNftMessage();
    }, []);

    const contentOnChange = async (content: any) => {
        setMychat(content);
    }

    const myClean = async () => {
        // @ts-ignore
        emotor.current.clean();
    }

    const updateMessage = async (values: any) => {
        const params = {
            chatInfo: mychat,
            senderId: accountState.userId,
            irRequestId: irRequestId,
        }
        debugger
        const res = await nft_update_chat(params);
        if (res) {
            const res = await nft_get_chat(parseInt(irRequestId!));
            if (res.data) {
                myClean();
                setNftChatInfo(res.data);
            }else{
                showErrorMsg(res,t);
            }
        }else{
            showErrorMsg(res,t);
        }
    }

    const onFinish = async () => {
        debugger
        const params = {
            chatInfo: form.getFieldValue(['message']),
            senderId: accountState.userId,
            irRequestId: irRequestId,
        }
        const res = await nft_update_chat(params);
        if (res) {
            const res = await nft_get_chat(parseInt(irRequestId!));
            if (res.data) {
                form.setFieldValue(['message'],"");
                setNftChatInfo(res.data);
            }else{
                showErrorMsg(res,t);
            }
        }else{
            showErrorMsg(res,t);
        }
    };
    return <div className="w-full mt-10 pt-10 text-left rounded-3xl" style={{backgroundColor: "#EEEEEE",userSelect:"text"}}>
        <div className="fs_18_bold pl-10">{t("page.NFTAPPROVALFIR_message")}</div>
        {nftChatInfo && <div className="mt-5">
            {nftChatInfo?.map((item, index) => {
                return <div>{item.senderId !== accountState.userId ? <div style={{paddingLeft: "5%"}}>
                        <img className="rounded-3xl w-16 h-16"
                             style={{display: "inline-block", verticalAlign: "middle", marginBottom: "10px",userSelect:"none"}}
                             src={clsx(item.avatar==null?normal  + "/" + item.ipholders[languageId-1].avatar:normal  + "/" + item.avatar)}/>
                        <ReactEmojiShow
                            className="react-emoji-show"
                            name="chatInfo"
                            content={item.chatInfo}
                        />
                        {/*<TextArea readOnly*/}
                        {/*          className="react-emoji-show"*/}
                        {/*          rows={4}*/}
                        {/*          style={{display: "inline-block",paddingLeft: "2%",margin:"0 2% 2% 2%",*/}
                        {/*              borderRadius: "10px",verticalAlign: "middle",*/}
                        {/*              width: "85%",borderColor: "white", backgroundColor: "white", resize: "none"}}*/}
                        {/*          value={item.chatInfo}/>*/}
                    </div>
                    : <div>
                        <ReactEmojiShow
                            className="react-emoji-show"
                            name="chatInfo"
                            content={item.chatInfo}
                        />

                        {/*<TextArea readOnly*/}
                        {/*          className="react-emoji-show"*/}
                        {/*          style={{display: "inline-block",paddingLeft: "2%",margin:"0 2% 2% 2%",*/}
                        {/*              borderRadius: "10px",verticalAlign: "middle",*/}
                        {/*              width: "85%",borderColor: "white", backgroundColor: "white", resize: "none"}}*/}
                        {/*          value={item.chatInfo}/>*/}

                        <img className="rounded-3xl w-16 h-16"
                             style={{display: "inline-block", marginBottom: "10px", verticalAlign: "middle",userSelect:"none"}}
                             src={clsx(item.avatar==null?normal  + "/" + item.ipholders[languageId-1].avatar:normal  + "/" + item.avatar)}/>
                    </div>
                }

                </div>
            })
            } </div>}
        <Form form={form} layout="vertical" onFinish={onFinish}
              autoComplete="off">
        <div className="pt-5" style={{width: "95%", paddingLeft: "5%"}}>
            {/*<ReactEmojiEditor*/}
            {/*    ref={emotor}*/}
            {/*    placeholder="申請内容に関するコメントや質問など、今後のやりとりに関して依頼されたいことがあれば、メッセ-ジ機能を利用してご連絡ください。"*/}
            {/*    onChange={(content: any) => contentOnChange(content)}*/}
            {/*/>*/}

                <Form.Item name="message" className="fs_16_bold">
                    <TextArea style={{borderRadius: "20px",borderColor:"white",backgroundColor: "white"}} rows={4}
                              placeholder={t("page.NFTAPPROVALFIR_message-description")}/>
                </Form.Item>


        </div>
        <div className="text-center pt-10 pb-10">
            <Button name="newChat" className="w-40 h-8"
                    style={{background: "#13c1f1", fontWeight: "800", color: "white", borderRadius: "20px",userSelect:"text"}}
                    onClick={() => onFinish()}>
                {t("page.NFTAPPROVALFIR_send-message")}
            </Button>
        </div>
        </Form>
    </div>
};
export default NftMessage;



