import React from 'react'
import { Link } from 'react-router-dom'

const NotFound: React.FC = () => {
    return (
        <div className="main-content">
            <p>お探しのページは見つかりませんでした。</p>
            <Link to='/login'>ログイン画面へ</Link>
        </div>
    )
}

export default NotFound