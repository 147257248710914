import React, {useEffect, useRef, useState} from 'react';
import { nft_listGroup } from "@/services";
import {useLocation, useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {message, Tooltip} from "antd";
import { showErrorMsg } from '@/utils/error';
import i18n from "@/locales";
import {useAccountState} from "@/store";
import clsx from "clsx";

interface DataType {
    ipName: string;
    nftList: NftList[];
}

interface NftList {
    ipId: number;
    ipholderName: string;
    userName: string;
    nftImage: string;
    ipNftId: number;
    nftName: string;
    price: number;
    ipBlockchainId: string;
    ipTransactionUrl: string;
    ipName: string;
}

const NftCreationList = (props:any) => {
    const { t } = useTranslation();
    const {state} = useLocation();
    const {showTitle,fromMenu,show} = props;
    const navigate = useNavigate();
    const accountState = useAccountState();
    const [tableData, setTableData] = useState<DataType[]>([]);
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    const fileReF = useRef<HTMLInputElement | null>(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, [languageId])

    async function getData() {
        const res = await nft_listGroup(fileReF.current?.value,accountState?.userId+"",languageId);
        if (res) {
            setTableData(res.data);
            console.log(res.data,tableData)
        }else{
            showErrorMsg(res,t);
        }
    }

    const selectIp = () => {
       getData();
    }

    //计算字符宽度,字符占2个,文字占3个，合计长度34或33
    const charLength = (value:any) => {
        let len = 0;
        let n = 0;
        const k = 0x80;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 3;
                } else {
                    len += 2;
                }
                if(len>33){
                    debugger
                    value = value.substring(0,i-1)+"..."
                    return value;
                }
            }
        }
        return value;
    }
    //判断是否显示Tooltip
    const moreLength = (value:any) => {
        let len = 0;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 3;
                } else {
                    len += 2;
                }
            }
            if(len>33){
                return true;
            }
        }
        return false;
    }

    const nftListMap =(nftList: NftList[])=>{
        return <div>
            {nftList?.map(function (
                item2,
                index2
            ) {
                return (
                    <div
                        key={index2}
                        style={{
                            padding: "10px",
                            display: "inline-block",
                            width: "23%",
                        }}
                        className="mx-2">
                        <div style={{borderRadius: "15px"}} className="w-52 h-56">
                            <button className="w-52 h-56">
                            <img
                                onClick={()=>navigate(`/nft/${item2.ipBlockchainId}/${item2.ipNftId}`,{ state: {
                                        'ipId':item2.ipId,
                                        'ipBlockchainId':item2.ipBlockchainId,
                                        'ipNftId':item2.ipNftId,
                                        'fromMenu': fromMenu && fromMenu === true ? true:false,
                                        'show':show

                                    } })}
                                src={item2.nftImage}
                                className="rounded-3xl h-full w-full cover" style={{userSelect:"none"}}
                            />
                            </button>
                            <div className={clsx(showTitle?"text-center pl-1":"text-center")} style={{wordBreak:"break-all"}}>
                                <Tooltip placement="topLeft" title={moreLength(item2.nftName) && item2.nftName}
                                         style={{position:"absolute"}}
                                >
                                    {
                                        <div className="fs_18_bold h-auto pt-3">{charLength(item2.nftName)}</div>
                                    }
                                </Tooltip>

                                <Tooltip placement="topLeft" title={item2.userName!=null?moreLength(item2.userName) && item2.userName :moreLength(item2.ipholderName) && item2.ipholderName}
                                         style={{position:"absolute"}}
                                >
                                    {
                                        <div className="fs_16 h-auto pt-2">{charLength(item2.userName!=null?charLength(item2.userName):charLength(item2.ipholderName))}</div>
                                    }
                                </Tooltip>
                                <div className="fs_18_bold pt-2">
                                    {item2.price}
                                    <span className="fs_16" style={{fontWeight: "400"}}>{" "}
                                        ETH
                                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    }

    return (
        <div className="minHeight text-left">
            <div className="main-container" style={{userSelect:"text"}}>
                <div className={clsx(showTitle?"":"px-20")}>
                    {!showTitle &&
                        <div className="pb-10">
                            <button className="fs_h2 pt-10">
                                <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                     onClick={() => window.history.back()}
                                     xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">
                                    <path
                                        d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                        p-id="2097"></path>
                                </svg>
                            </button>
                            <div className="fs_h2 pt-10 text-center">{t("page.NFTCREATIONLIST_nft-creation-list")}</div>
                        </div>
                    }
                    <div style={{display: "inline-block"}}>
                        <div style={{position: "absolute"}}>
                            <svg style={{marginTop: "5px", marginLeft: "10px"}} className="icon" viewBox="0 0 1024 1024"
                                 version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="3500" width="20" height="20">
                                <path
                                    d="M383.3 699.8C217.4 699.8 82.5 564.9 82.5 399S217.4 98.2 383.3 98.2 684.1 233.1 684.1 399 549.2 699.8 383.3 699.8z m0-552.6c-138.8 0-251.7 112.9-251.7 251.7s112.9 251.7 251.7 251.7c138.8 0 251.8-112.9 251.8-251.7s-113-251.7-251.8-251.7z"
                                    fill="#333333" p-id="3501"></path>
                                <path d="M558.272 600.948l33.412-35.975 349.791 324.87-33.412 35.976z" fill="#000000"
                                      p-id="3502"></path>
                            </svg>
                        </div>
                        <input
                            ref={fileReF}
                            style={{ border: "1px #DBDBDB solid", outline: "none",backgroundColor:"white",zIndex: "2",paddingLeft:"40px" }}
                            type="search"
                            className="rounded-tl-3xl rounded-bl-3xl h-8 w-80 text-black pl-6"
                            placeholder={t("page.IPLIST_keyword-search")}
                        />
                        <button
                            className="h-8 w-16 rounded-3xl"
                            onClick={() => selectIp()}
                            style={{ backgroundColor: "#13c1f1",zIndex: "1",marginLeft:"-15px" }}
                        >
                            {t("page.IPLIST_search")}
                        </button>

                    </div>
                    <div>
                        <div className="pt-10">
                            {tableData?.map(function (
                                item,
                                index
                            ) {
                                return (
                                    <div
                                        key={index}
                                    >
                                    <div className="fs_h3 pt-10">
                                        {item.ipName}
                                    </div>
                                        {nftListMap(item.nftList)}

                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NftCreationList;