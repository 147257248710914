import { Button, Form, Input, message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "@/router";
import { AccountUpdate, checkMail, sendMail, uploadAvatar } from "@/services";
import { useEffect, useRef, useState } from "react";
import { Actions } from "@/store/reducers/account";
import { useAccountState, useAppDispatch } from "@/store";
import { Sessions } from "@/utils";
import {useTranslation} from "react-i18next";
import { showErrorMsg } from "@/utils/error";
import {normal} from "@/constants";

const { TextArea } = Input;

const Profile = () => {
    const { t } = useTranslation();
    const fileReF = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();
    const accountState = useAccountState();
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState<string>("common_avatar.png");
    const [avatarUrl, setAvatarUrl] = useState<string | null>(normal + "/common_avatar.png");

    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0);
        debugger
        if (accountState.avatar) {
            setAvatar(accountState.avatar);
            setAvatarUrl(accountState.avatarUrl?accountState.avatarUrl:normal + "/" + avatar);
        } else {
            setAvatar("common_avatar.png");
            setAvatarUrl(normal + "/common_avatar.png");
        }
    }, [accountState])

    const onFinish = async () => {
        if (avatarUrl === "" || avatarUrl === undefined) {
            // message.error(t("msg.USER_4"));
            // return;
            setAvatar("common_avatar.png");
            setAvatarUrl(normal + "/common_avatar.png");
        }
        if(form.getFieldValue(['username'])==""){
            message.error(t("msg.IPREQ_1"));
            return;
        }
        if(form.getFieldValue(['message'])==""){
            message.error(t("msg.IPREQ_2"))
            return;
        }
        // @ts-ignore
        const res = await AccountUpdate({
            avatar: avatar,
            userName: form.getFieldValue(['username']),
            userDetails: form.getFieldValue(['message']),
            mail: form.getFieldValue(['mail']),
            twitterUrl: form.getFieldValue(['twitterUrl']),
            linktreeUrl: form.getFieldValue(['linktreeUrl']),
            openseaUrl: form.getFieldValue(['openseaUrl'])

        });
        if (res.data) {
            dispatch(Actions.update({
                userName: form.getFieldValue(['username']),
                userDetails: form.getFieldValue(['message']),
                avatar: avatar,
                avatarUrl: avatarUrl,
                twitterUrl:form.getFieldValue(['twitterUrl']),
                linktreeUrl:form.getFieldValue(['linktreeUrl']),
                openseaUrl: form.getFieldValue(['openseaUrl']),
                email: form.getFieldValue(['mail'])
            }))
            notification.success({
                message: "update success",
            });
            console.log(Sessions.decode(Sessions.read("jpnft")))
            navigate(RoutePaths.HOME);
        }else{
            message.error(t(res[0].key));
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const sendMailCode = async () => {

        console.log(form.getFieldsValue(['mail']),"test");
        const mailForm: any = form.getFieldsValue(['mail']);
        if (mailForm && mailForm['mail']) {
            const res = await sendMail(mailForm['mail']);
            debugger
            if (res && res.code === 0) {
                message.info(t("msg.USER_7"));
            }else{
                message.error(t(Object.keys(res.msg)[0]));
            }
        }
    }

    const checkMailCode = async () => {
        const mailForm: any = form.getFieldsValue(['mail', 'verificationCode']);
        if (mailForm && mailForm['mail'] && mailForm['verificationCode']) {
            const params = {
                email: mailForm['mail'],
                verificationCode: mailForm['verificationCode'],
            }
            const res = await checkMail(params);
            if (res && res.code === 0) {
                message.info(t("msg.USER_9"));
            }else{
                showErrorMsg(res,t);
            }
        } else {
            message.error(t("msg.USER_10"));
        }
    }

    const uploadImg = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            console.log(fileName.substring(fileName.lastIndexOf(".")));
            let fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("msg.USER_2"));
            }

            if (fileType == "jpg" || fileType == "bmp" || fileType == "png" || fileType == "jpeg") {

                const res = await uploadAvatar({ photo: file });
                if (res) {
                    console.log(res)
                    notification.warning({
                        message: t("msg.USER_1"),
                    })
                    if (res.data) {
                        setAvatar(res.data.photoName);
                        setAvatarUrl(res.data.previewUrl)
                    }
                }else{
                   message.error(t(Object.keys(res.msg)[0]));
                }
                e.target.value = '';
            } else {
                notification.warning({
                    message: t("msg.USER_3"),
                })
            }

        }
    }

    const onUploadImg = () => {
        console.log(fileReF.current)
        if (fileReF.current) {
            fileReF.current.click();
        }
    }

    const deleteImg = () => {
        console.log(fileReF.current)
        setAvatarUrl("");
    }

    return (
        <div className="minHeight">
            <input ref={fileReF} accept="image/*" type="file" onChange={(event) => uploadImg(event)}
                className="hidden" />
            <div className="main-container">
                <div className="px-20 mt-20" style={{userSelect:"text"}}>
                    <div className="fs_h1">
                        {t("page.USER_user-information")}
                    </div>
                    <Form form={form} layout="vertical" onFinishFailed={onFinishFailed}
                        initialValues={{
                            username: accountState.userName,
                            message: accountState.userDetails,
                            mail: accountState.email,
                            twitterUrl: accountState.twitterUrl,
                            linktreeUrl: accountState.linktreeUrl,
                            openseaUrl: accountState.openseaUrl,
                        }}
                        autoComplete="off">
                        <div className="text-left pb-2 fs_h2">{t("page.USER_public-information")}</div>
                        <div className="grid grid-rows-3 grid-flow-col">
                            <div className="row-span-6">
                                <div className="w-52 h-56" style={{ border: "1px #333333 solid", borderRadius:"15px"}}>
                                    <div
                                        style={{position:"absolute",paddingLeft:"174px",}}>
                                        <Button onClick={() => deleteImg()} style={{width:"30px", borderRadius:"15px"}}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 45 45"
                                            style={{marginLeft: "-23px",marginTop: "-5px", borderRadius:" 0 15px 0 15px"}}>
                                            <path d="M0,0H45A20,20,0,0,1,65,20V65a0,0,0,0,1,0,0H20A20,20,0,0,1,0,45V0A0,0,0,0,1,0,0Z" fill="#ccc"/>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="35"
                                                 height="35"
                                                 viewBox="-10 -10 40 40">
                                                <path d="M694.253,323.5l-10.96-10.96L673.04,322.789a2.5,2.5,0,0,1-3.535-3.535L679.758,309,669.5,298.747a2.5,2.5,0,0,1,3.535-3.535l10.253,10.253,10.96-10.96a2.5,2.5,0,0,1,3.536,3.535L686.828,309l10.96,10.96a2.5,2.5,0,1,1-3.536,3.536Z"
                                                      transform="translate(-668.772 -293.772)" fill="#fff"/>
                                            </svg>
                                        </svg>
                                        </Button>
                                    </div>
                                    <div className="grid h-full items-center">
                                        {/*<div onClick={() => onUploadImg()}>*/}
                                        {/*    <img src={avatarUrl!} className=" h-full cover" />*/}
                                        {/*</div>*/}
                                        {avatarUrl ? <div onClick={() => onUploadImg()}>
                                            <button>
                                            <img src={avatarUrl} className=" h-full cover" style={{userSelect:"none"}} />
                                            </button>
                                        </div> :
                                            <div>
                                                <Button shape="round" size="large"
                                                    style={{ minWidth: "125px", background:"#13c1f1", color:"#FFFFFF",fontWeight:"800",fontSize:"16px",userSelect:"text"}}
                                                    onClick={() => onUploadImg()}>
                                                    {t("page.USER_upload-picture")}
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <Form.Item name="username" className="fs_h3"  label={t("page.USER_username")}>
                                    <Input style={{borderRadius:"20px",fontWeight:400}}/>
                                </Form.Item>
                            </div>
                            <div className="col-span-2">
                                <Form.Item name="twitterUrl" className="fs_h3" label={t("page.USER_twitter")}>
                                    <Input style={{borderRadius:"20px",fontWeight:400}} placeholder="https://twitter.com/XXXX"/>
                                </Form.Item>
                            </div>
                            <div className="col-span-2">
                                <Form.Item name="linktreeUrl" className="fs_h3" label={t("page.USER_linktree")}>
                                    <Input style={{borderRadius:"20px",fontWeight:400}} placeholder="https://linktree.ee/XXXX"/>
                                </Form.Item>
                            </div>
                            <div className="col-span-2">
                                <Form.Item name="openseaUrl" className="fs_h3" label={t("page.USER_opensea")}>
                                    <Input style={{borderRadius:"20px",fontWeight:400}} placeholder="https://opensea.io.collection/XXXX"/>
                                </Form.Item>
                            </div>
                            <div className="row-span-2 col-span-2">
                                <Form.Item name="message" className="fs_h3" label={t("page.USER_bio")}>
                                    <TextArea style={{borderRadius:"20px",fontWeight:400}} rows={4} />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="text-left pb-2 fs_h2">{t("page.USER_non-public-information")}</div>

                        <div className="mt-6">
                        <Form.Item name="mail" className="fs_h3" label={t("page.USER_email-address")} style={{ margin: "0px 0px -32px" }}>
                            <Input style={{width:"66%",borderRadius:"20px",marginRight:"8px",float:"left",fontWeight:400}} />
                        </Form.Item>
                        <Button shape="round" style={{width:"32%", background: "#13c1f1", color: "white",float:"right",userSelect:"text"}}
                                onClick={() => sendMailCode()}>
                            {t("page.USER_send-authentication-code")}
                        </Button>
                        </div>

                        <div className="mt-6">
                            <Form.Item name="verificationCode" className="fs_h3" label={t("page.USER_authentication-code")} style={{ margin: "50px 0px -32px"}}>
                                <Input style={{width:"66%",borderRadius:"20px",marginRight:"8px",float:"left",fontWeight:400}} />
                            </Form.Item>
                            <Button shape="round" className="text-xl font-bold"  style={{width:"32%",background: "#13c1f1", color:"white",float:"right",userSelect:"text"}}
                                    onClick={() => checkMailCode()}>
                                {t("page.USER_register-authentication-code")}
                            </Button>
                        </div>

                        <Form.Item style={{paddingTop:"60px"}}>
                            <div className="grid gap-2 grid-cols-4 my-3">
                                <div></div>
                                <div className="text-right">
                                    <button className="text-white w-40 h-8 rounded-3xl fs-16 font-semibold"
                                            style={{ background: "white", borderColor: "#13c1f1",boxShadow:"inset 0px 0px 0px 3px", color:"#13c1f1"}}
                                            onClick={() => navigate(RoutePaths.HOME)}>
                                        {t("page.USER_skip")}
                                    </button>
                                </div>
                                <div className="text-left">
                                    <button className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo w-40 h-8 rounded-3xl font-semibold" onClick={()=>onFinish()}>
                                        {t("page.USER_save")}
                                    </button>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>

                </div>
            </div>
        </div>
    );
};

export default Profile;