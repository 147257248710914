import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "@/router";
import { Actions } from "@/store/reducers/account";
import { useAccountState, useAppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { normal } from "@/constants";
import clsx from "clsx";
import { ip_holder_info, ip_list, NoticeInfo, NoticeManageData, NoticeObj, NoticeObj2, notice_delete, notice_details, notice_manage_list, notice_registerOrUpdate } from "@/services";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";

import Profile from "@/pages/UserInfo/Profile";
import NftCreationList from "@/pages/NFT/NftCreationList";
import NftBoughtList from "@/pages/NFT/NftBoughtList";
import ProfileIpholder from "@/pages/UserInfo/ProfileIpholder";
import AdminMyPage from "@/pages/Mypage/AdminMyPage";
import Analytics from "@/components/Analytics";
import { DatePicker, Form, Input, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import "dayjs/locale/zh-cn";
import locale_zh from "antd/es/date-picker/locale/zh_CN";
import locale_jp from "antd/es/date-picker/locale/ja_JP";
import locale_en from "antd/es/date-picker/locale/en_US";
import { message } from "antd";

interface DataType {
  ownerId: number;
  ipId: number;
  ipBlockchainId: string;
  ipholderName: string;
  ipImageUrl: string;
  ipName: string;
  price: number;
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

type FormType = {
  noticeId: number;
  jpTitle: string;
  jpLink: string;
  enTitle: string;
  enLink: string;
  zhTitle: string;
  zhLink: string;
  showDate: moment.Moment;
};
const MyPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const accountState = useAccountState();
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [status, setStatus] = useState<string>("0");
  const [show, setShow] = useState<string>("0");
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
  const [noticeDetailsShow, setNoticeDetailsShow] = useState<boolean>(false);
  const [isNewRegsiter, setIsNewRegsiter] = useState<boolean>(false);
  const [noticeManageData, setNoticeManageData] = useState<NoticeManageData>();
  

  const initFormValue = {
    noticeId: 0,
    jpTitle: "",
    jpLink: "",
    enTitle: "",
    enLink: "",
    zhTitle: "",
    zhLink: "",
    showDate: moment(new Date()),
  };

  const [noticeDetails, setNoticeDetails] = useState<FormType>(initFormValue);

  function logout() {
    localStorage.setItem("status", "0");
    localStorage.setItem("show", "0");
    dispatch(Actions.logout());
    navigate(RoutePaths.HOME);
  }
  const [admin, setAdmin] = useState<boolean>(false);
  const userInfo = async () => {
    //const res = await getUserType(account.name);
    console.log(accountState.userType);
    if (accountState.userType == 2) {
      navigate(RoutePaths.PROFILE);
    } else if (accountState.userType == 3) {
      navigate(RoutePaths.PROFILE_IPHOLDER);
    } else {
    }
  };

  const onChangeStatus = async (value: string) => {
    setStatus(value);
    localStorage.setItem("status", value);
  };
  const onChangeShow = async (value: string) => {
    //1:ipholderInfo  2:userInfo 3:ユーザー情報  4:購入NFT一览   5:作成NFT一览  6:IP窓口情報
    setShow(value);
    localStorage.setItem("show", value);
    if (value == "8") {
      setShow("6");
      setAdmin(false);
    }
    if (value == "2") {
      const res = await ip_list("", "", accountState.userId + "", languageId);
      if (res) {
        setTableData(res.data);
      } else {
        showErrorMsg(res, t);
      }
    }
  };

  const openDetail = async function (ipBlockchainId: string) {
    console.log(ipBlockchainId, "ip");
    navigate(`/ip/${ipBlockchainId}`);
  };

  const [ipHolderInfo, setIpHolderinfo] = useState<{
    data: {
      userId: number;
      ethAddress: string;
      proxyAddress: string;
      ipholderName: string;
      postCode: string;
      address: string;
      tel: string;
      email: string;
      avatar: string;
      userType: number;
    };
  }>();
  const getIpHolderInfo = async () => {
    const res = await ip_holder_info(accountState.userId!, languageId);
    if (res) {
      setIpHolderinfo(res);
    } else {
      showErrorMsg(res, t);
    }
  };

  const fetchNoticeManageData = async (page: number) => {
    const res = await notice_manage_list(page);
    if (res) {
      setNoticeManageData(res);
    }
  };
  const fetchNoticeDetails = async (noticeId: number) => {
    const res = await notice_details(noticeId);
    return res;
  };

  useEffect(() => {
    if (accountState.userType === 1) {
      fetchNoticeManageData(1);
    }
    if (accountState.userType == 3) {
      getIpHolderInfo();
    }
    //页面刷新后menu不变
    if (
      (localStorage.getItem("show") != "0" && location.state?.show == localStorage.getItem("show")) ||
      !location.state
    ) {
      // @ts-ignore
      onChangeShow(localStorage.getItem("show"));
      console.log(localStorage.getItem("show"), "1");
    } else {
      onChangeShow(location.state?.show);
    }
    if (
      (localStorage.getItem("status") != "0" && location.state?.status == localStorage.getItem("status")) ||
      !location.state
    ) {
      // @ts-ignore
      onChangeStatus(localStorage.getItem("status"));
      console.log(localStorage.getItem("status"), "3");
    } else {
      onChangeStatus(location.state?.status);
    }
  }, [languageId]);

  /**
   * 新規または更新
   * @param values 
   */
  const onFinish = async (values: FormType) => {
    console.log("Success:", values);
    const noticeInfoArr:NoticeInfo[] = [{
      langvageId:1,
      noticeTitle:values.jpTitle,
      externalUrl:values.jpLink
    },
    {
      langvageId:2,
      noticeTitle:values.enTitle,
      externalUrl:values.enLink
    },
    {
      langvageId:3,
      noticeTitle:values.zhTitle,
      externalUrl:values.zhLink
    }];
    const param: NoticeObj2 = {
      noticeId: isNewRegsiter ? null: noticeDetails.noticeId,
      showDate: values.showDate,
      noticeInfo:noticeInfoArr
    };
    const res = await notice_registerOrUpdate(param);
    if(res){
       message.success(t(isNewRegsiter? "msg.NOTICE_save_success":"msg.NOTICE_update_success"));
       await fetchNoticeManageData(noticeManageData?.data.pageNum!);
       setNoticeDetailsShow(false);
       window.scrollTo(0,0);
    }else{
      message.error(t(isNewRegsiter? "msg.NOTICE_save_failed":"msg.NOTICE_update_failed"));
    }
  };

  /**
   * 削除
   */
   const deleteNotice = async (noticeId: number) => {
    const res = await notice_delete(noticeId);
    if(res){
       message.success(t("msg.NOTICE_delete_success"));
       await fetchNoticeManageData(noticeManageData?.data.pageNum!);
       setNoticeDetailsShow(false);
       window.scrollTo(0,0);
    }else{
      message.error(t("msg.NOTICE_delete_failed"));
    }
  };

  return (
    <div className="minHeight">
      <div className="main-container">
        <div className="px-30">
          <div className="flex w-full mt-5">
            <div
              className="w-1/5 fs_16_bold text-left rounded-xl"
              style={{ backgroundColor: "#d4f6ff", maxHeight: "620px" }}>
              {/*<Menu>*/}
              {/*  <Menu.Item>*/}
              {/*    <Link to={RoutePaths.IP_LIST}>ip2</Link>*/}
              {/*  </Menu.Item>*/}
              {/*</Menu>*/}
              <div className="">
                <button className="py-2 px-4 max-h-full w-54">
                  <img
                    onClick={() =>
                      onChangeShow(accountState.userType == 3 ? "6" : accountState.userType == 1 ? "8" : "3")
                    }
                    className="w-56 h-56"
                    style={{ borderRadius: "150px" }}
                    src={
                      accountState.userType !== 2 ? normal + "/" + ipHolderInfo?.data.avatar : accountState.avatarUrl!
                    }
                  />
                </button>
                <div className="py-2 px-5 text-center w-auto" style={{ wordBreak: "break-all", userSelect: "text" }}>
                  {accountState.userType !== 2 ? ipHolderInfo?.data.ipholderName : accountState.userName}
                </div>
                {accountState.userType !== 2 && (
                  <div
                    className="pl-10"
                    style={{ backgroundColor: clsx(status == "1" ? "#eafbff" : ""), userSelect: "text" }}>
                    <button className="fs_18_bold" onClick={() => onChangeStatus("1")}>
                      IP
                    </button>
                    {status == "1" && (
                      <div className="pl-3">
                        <button
                          className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                          style={{
                            width: "80%",
                            backgroundColor: clsx(show == "2" ? "white" : ""),
                          }}
                          onClick={() => onChangeShow("2")}>
                          {t("page.MYPAGE_registered-ip")}
                        </button>
                        <div className="pl-3 py-2 fs_18_bold">{t("page.MYPAGE_anti-piracy")}</div>
                        <button
                          className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                          style={{
                            width: "80%",
                            backgroundColor: clsx(show == "10" ? "white" : ""),
                          }}
                          onClick={() => onChangeShow("10")}>
                          {t("page.MYPAGE_analytics")}
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="pl-10 py-2"
                  style={{ backgroundColor: clsx(status == "2" ? "#eafbff" : ""), userSelect: "text" }}>
                  <button className="fs_18_bold" onClick={() => onChangeStatus("2")}>
                    NFT
                  </button>
                  {status == "2" && (
                    <div className="pl-3">
                      <button
                        className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                        style={{
                          width: "80%",
                          backgroundColor: clsx(show == "4" ? "white" : ""),
                        }}
                        onClick={() => onChangeShow("4")}>
                        {t("page.MYPAGE_purchased-nft")}
                      </button>
                      <button
                        className="pl-3 text-left py-2 pb-2 rounded-3xl fs_18_bold"
                        style={{
                          width: "80%",
                          backgroundColor: clsx(show == "5" ? "white" : ""),
                        }}
                        onClick={() => onChangeShow("5")}>
                        {t("page.MYPAGE_create-nft")}
                      </button>
                    </div>
                  )}
                </div>
                {accountState.userType === 1 && (
                  <div
                    className="pl-10 py-2"
                    style={{ backgroundColor: clsx(status === "3" ? "#eafbff" : ""), userSelect: "text" }}>
                    <button
                      className="fs_18_bold text-left"
                      onClick={() => {
                        onChangeStatus("3");
                        onChangeShow("99");
                      }}>
                      {t("page.MYPAGE_common-management")}
                    </button>
                    {status === "3" && (
                      <div className="pl-3">
                        <button
                          className="pl-3 text-left py-2 rounded-3xl fs_18_bold"
                          style={{
                            width: "80%",
                            backgroundColor: clsx(show === "99" ? "white" : ""),
                          }}
                          onClick={() => {
                            if (noticeDetailsShow) {
                              setNoticeDetailsShow(false);
                            }
                          }}>
                          {t("page.HOME_notice")}
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div style={{ userSelect: "text" }}>
                  <button className="pl-10 py-2 fs_18_bold" style={{ userSelect: "text" }} onClick={() => logout()}>
                    {t("page.MYPAGE_logout")}
                  </button>
                </div>
              </div>
            </div>

            {show == "10" ? (
              <div className="text-center pl-10 w-4/5">
                <Analytics />
              </div>
            ) : show == "2" ? (
              <div className="text-center pl-10 w-4/5">
                <div className="text-left" style={{ userSelect: "text" }}>
                  <button
                    style={{ backgroundColor: "#13c1f1", userSelect: "text" }}
                    onClick={() => navigate(RoutePaths.IP_APPLICATION_LIST, { state: true })}
                    className="rounded-3xl w-52 h-8 fs_16_white ml-3">
                    {t("page.IPREQUESTLIST_ip-listing-application-list")}
                  </button>
                  <button
                    style={{
                      backgroundColor: "#13c1f1",
                      minWidth: "208px",
                      paddingLeft: clsx(languageId == 2 ? "10px" : ""),
                      paddingRight: clsx(languageId == 2 ? "10px" : ""),
                      userSelect: "text",
                    }}
                    onClick={() => navigate(RoutePaths.IP_APPROVAL_LIST, { state: true })}
                    className="rounded-3xl w-auto h-8 fs_16_white ml-3 ml-40">
                    {t("page.IPAPPROVALLIST_ip-listing-application-approval-list")}
                  </button>
                  <div className="pt-10">
                    {tableData?.map(function (item, index) {
                      return (
                        <div
                          key={index}
                          style={{
                            padding: "10px",
                            display: "inline-block",
                            width: "25%",
                          }}>
                          <button>
                            <div style={{ borderRadius: "15px" }} className="w-44 h-48">
                              <img
                                src={item.ipImageUrl}
                                className="rounded-3xl h-full w-full cover"
                                style={{ userSelect: "none" }}
                                onClick={() => {
                                  openDetail(item.ipBlockchainId);
                                }}
                              />
                              <div className="text-center">
                                <div className="fs_18_bold h-8 pt-5">{item.ipName}</div>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })}
                    <div
                      style={{
                        padding: "10px",
                        display: "inline-block",
                        width: "25%",
                        position: "absolute",
                      }}>
                      <button
                        style={{ borderRadius: "15px", border: "1px solid grey" }}
                        onClick={() => navigate(RoutePaths.IP_FORM)}
                        className="w-44 h-48">
                        <div style={{ paddingLeft: "38%" }}>
                          <svg
                            className="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2101"
                            width="40"
                            height="40">
                            <path
                              d="M426.666667 426.666667H85.546667A85.418667 85.418667 0 0 0 0 512c0 47.445333 38.314667 85.333333 85.546667 85.333333H426.666667v341.12c0 47.274667 38.186667 85.546667 85.333333 85.546667 47.445333 0 85.333333-38.314667 85.333333-85.546667V597.333333h341.12A85.418667 85.418667 0 0 0 1024 512c0-47.445333-38.314667-85.333333-85.546667-85.333333H597.333333V85.546667A85.418667 85.418667 0 0 0 512 0c-47.445333 0-85.333333 38.314667-85.333333 85.546667V426.666667z"
                              fill="#2c2c2c"
                              p-id="2102"></path>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : show == "3" ? (
              <div className="w-4/5">
                <Profile />
              </div>
            ) : show == "4" ? (
              <div className="w-4/5">
                <NftBoughtList showTitle={true} fromMenu={true} show="4" />
              </div>
            ) : show == "5" ? (
              <div className="w-4/5" style={{ userSelect: "text" }}>
                <div style={{ userSelect: "text" }}>
                  <button
                    onClick={() => navigate(RoutePaths.NFT_APPLICATION_LIST, { state: accountState.userId })}
                    style={{ float: "left", marginLeft: "120px", backgroundColor: "#13c1f1", userSelect: "text" }}
                    className="rounded-3xl w-52 h-8 fs_16_white ml-3">
                    {t("page.MYPAGE_nft-application-list")}
                  </button>
                  {accountState.userType !== 2 && (
                    <button
                      style={{ backgroundColor: "#13c1f1", userSelect: "text" }}
                      onClick={() => navigate(RoutePaths.NFT_APPROVE_LIST)}
                      className="rounded-3xl w-52 h-8 fs_16_white ml-3">
                      {t("page.MYPAGE_nft-approve-list")}
                    </button>
                  )}
                  <NftCreationList showTitle={true} fromMenu={true} show="5" />
                </div>
              </div>
            ) : show === "99" && noticeDetailsShow === false ? (
              <div className="w-4/5 pl-5">
                <table className="text-black rounded-3xl border-white" style={{ width: "100%" }}>
                  <thead>
                    <tr
                      style={{ backgroundColor: "#13c1f1", borderColor: "#d3d3d3" }}
                      className="h-12 text-white border-b">
                      <td style={{ width: "5%", backgroundColor: "#13c1f1" }} className="text-center rounded-tl-3xl">
                        {t("page.MYPAGE_notice-no")}
                      </td>
                      <td className="pl-2" style={{ width: "70%" }}>
                        {t("page.MYPAGE_notice-name")}
                      </td>
                      <td className="pl-2 text-center rounded-tr-3xl" style={{ width: "25%" }}>
                        {t("page.MYPAGE_notice-show-date")}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {noticeManageData?.data.list.map((item, index) => {
                      return (
                        <tr
                          style={{ borderColor: "#d3d3d3" }}
                          className={clsx("h-12", index === noticeManageData?.data.list.length - 1 ? "" : "border-b")}
                          key={index}>
                          <td
                            style={{ backgroundColor: "#aaedff" }}
                            className={clsx(
                              "text-center",
                              noticeManageData?.data.list.length === index + 1 ? "rounded-bl-3xl" : ""
                            )}>
                            {index + 1}
                          </td>
                          <td
                            className="text-base text-black cursor-pointer text-left"
                            style={{ backgroundColor: "#E1F4F9" }}>
                            <div
                              className="w-full h-full notice-content pl-1"
                              onClick={async () => {
                                const res = await fetchNoticeDetails(item.noticeId!);
                                if (res && res.data) {
                                  const obj = res.data;
                                  const formData: FormType = {
                                    noticeId: obj.noticeId!,
                                    showDate:moment(item.showDate).utcOffset(9),
                                    jpTitle: obj.noticeInfo[0].noticeTitle,
                                    jpLink: obj.noticeInfo[0].externalUrl,
                                    enTitle: obj.noticeInfo[1].noticeTitle,
                                    enLink: obj.noticeInfo[1].externalUrl,
                                    zhTitle: obj.noticeInfo[2].noticeTitle,
                                    zhLink: obj.noticeInfo[2].externalUrl,
                                  };
                                  setNoticeDetails(formData);
                                  setNoticeDetailsShow(true);
                                  setIsNewRegsiter(false);
                                }
                              }}>
                              {languageId === 1
                                ? item.noticeInfo[0].noticeTitle
                                : languageId === 2
                                ? item.noticeInfo[1].noticeTitle
                                : item.noticeInfo[2].noticeTitle}
                            </div>
                          </td>
                          <td
                            className={clsx(
                              "text-base text-black text-center",
                              noticeManageData?.data.list.length === index + 1 ? "rounded-br-3xl" : ""
                            )}
                            style={{ backgroundColor: "#EAFBFF" }}>
                            {moment(item.showDate).utcOffset(9).format("yyyy-MM-DD HH:mm:ss")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {noticeManageData?.data?.pages && noticeManageData?.data?.pages > 1 && (
                  <Table
                    className="newPage"
                    pagination={{
                      total: noticeManageData?.data.total,
                      pageSize: noticeManageData?.data.pageSize,
                      onChange(current: number) {
                        fetchNoticeManageData(current);
                      },
                    }}
                  />
                )}
                <div
                  className={clsx(
                    "w-full flex justify-center items-center",
                    noticeManageData?.data?.pages && noticeManageData?.data?.pages > 1 ? "mb-10" : "my-10"
                  )}>
                  <button
                    className="h-8 w-40 bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo rounded-3xl text-white"
                    onClick={() => {
                      setNoticeDetails(initFormValue);
                      setIsNewRegsiter(true);
                      setNoticeDetailsShow(true);
                    }}>
                    {t("page.MYPAGE_notice-add")}
                  </button>
                </div>
              </div>
            ) : show === "99" && noticeDetailsShow === true ? (
              <div className="w-4/5 px-20">
                <Form onFinish={onFinish} layout="vertical" initialValues={noticeDetails}>
                  <div className="w-full h-auto flex flex-col justify-start">
                    <div className="flex justify-start items-center">
                      <p className="w-auto text-black">{t("page.MYPAGE_notice-japanese")}</p>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-title")}
                        name="jpTitle"
                        rules={[{ required: true, message: t("msg.NOTICE_input-title") }]}>
                        <TextArea rows={3} style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-link")}
                        name="jpLink"
                        rules={[{ required: true, message: t("msg.NOTICE_input-link") }]}>
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full h-auto flex flex-col justify-start mt-5">
                    <div className="flex justify-start items-center">
                      <p className="w-auto text-black">{t("page.MYPAGE_notice-english")}</p>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-title")}
                        name="enTitle"
                        rules={[{ required: true, message: t("msg.NOTICE_input-title") }]}>
                        <TextArea rows={3} />
                      </Form.Item>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-link")}
                        name="enLink"
                        rules={[{ required: true, message: t("msg.NOTICE_input-link") }]}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full h-auto flex flex-col justify-start mt-5">
                    <div className="flex justify-start items-center">
                      <p className="w-auto text-black">{t("page.MYPAGE_notice-chinese")}</p>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-title")}
                        name="zhTitle"
                        rules={[{ required: true, message: t("msg.NOTICE_input-title") }]}>
                        <TextArea rows={3} />
                      </Form.Item>
                    </div>
                    <div className="flex justify-start items-center pl-5">
                      <Form.Item
                        style={{ width: "100%" }}
                        label={t("page.MYPAGE_notice-link")}
                        name="zhLink"
                        rules={[{ required: true, message: t("msg.NOTICE_input-link") }]}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full h-auto flex flex-col justify-start mt-5">
                    <div className="flex justify-start items-center">
                      <Form.Item
                        name="showDate"
                        label={t("page.MYPAGE_notice-show-date")}
                        rules={[{ required: true, message: t("msg.NOTICT_show-date") }]}
                        style={{ width: "100%" }}>
                        <DatePicker
                          format={"yyyy-MM-DD HH:mm:ss"}
                          showTime
                          style={{ width: "100%"}}
                          locale={languageId === 1 ? locale_jp : languageId === 2 ? locale_en : locale_zh}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    {isNewRegsiter ? (
                      <div className="w-full flex space-x-5 justify-center items-center mb-10">
                        <button type="submit" className="h-8 w-40 bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo rounded-3xl text-white">
                          {t("page.MYPAGE_notice-save")}
                        </button>
                      </div>
                    ) : (
                      <div className="w-full flex space-x-5 justify-center items-center mb-10">
                        <button className="h-8 w-40 rounded-3xl bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo text-white"
                          onClick={async ()=>{
                            await deleteNotice(noticeDetails.noticeId);
                           }}
                        >
                          {t("page.MYPAGE_notice-delete")}
                        </button>
                        <button type="submit" className="h-8 w-40 rounded-3xl bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo text-white">
                          {t("page.MYPAGE_notice-update")}
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            ) : show == "6" && !admin ? (
              <div className="w-4/5">
                <ProfileIpholder showTitle={true} />
              </div>
            ) : accountState.userType == 1 ? (
              <div className="w-4/5">
                <AdminMyPage />
              </div>
            ) : accountState.userType == 3 ? (
              <div className="text-center pl-10 w-4/5">
                <div className="flex w-full pl-10" style={{ height: "400px" }}>
                  <div className="flex flex-col h-full w-1/3 bg-jpnft-tbHeaderBg border-t border-b border-l border-jpnft-border rounded-tl-3xl  rounded-bl-3xl text-left">
                    <div
                      className="w-full border-b border-jpnft-border px-5 flex justify-start items-center"
                      style={{ height: "272px" }}>
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPHOLDER_ip-profile-picture")}
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPHOLDER_company-name-individual-name")}
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_address")}</div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPHOLDER_phone-number")}
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPHOLDER_email-address")}
                      </div>
                    </div>
                    <div className="w-full px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPHOLDER_organization-type")}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-full w-2/3 border border-jpnft-border rounded-tr-3xl  rounded-br-3xl text-left">
                    <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        <img
                          src={normal + "/" + ipHolderInfo?.data.avatar}
                          alt=""
                          style={{ height: "160px" }}
                          className="rounded-md"
                        />
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {ipHolderInfo?.data.ipholderName}
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">{`${
                        ipHolderInfo?.data.postCode === undefined ? "" : "〒" + ipHolderInfo?.data.postCode
                      }　${ipHolderInfo?.data.address === undefined ? "" : ipHolderInfo?.data.address}`}</div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">{ipHolderInfo?.data.tel}</div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">{ipHolderInfo?.data.email}</div>
                    </div>
                    <div className="w-full px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {ipHolderInfo?.data.userType === undefined
                          ? ""
                          : ipHolderInfo?.data.userType === 2
                          ? t("page.IPHOLDER_individual")
                          : t("page.IPHOLDER_company")}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo rounded-3xl fs_16_white h-10 w-40 mb-10 mt-5"
                  onClick={() => window.open("mailto:?subject=&cc=&subject=主题&body=内容")}>
                  {t("page.IPHOLDER_edit-information")}
                </button>
              </div>
            ) : (
              <>
                <div className="w-full px-40">
                  {/*<div*/}
                  {/*    className="grid grid-cols-3 border border-jpnft-border rounded-md text-base text-jpnft-tbFtColor font-semibold text-left">*/}
                  {/*    <div*/}
                  {/*        className="bg-jpnft-tbHeaderBg px-3 rounded-tl-md border-b border-jpnft-border h-24 py-2">*/}
                  {/*        {t("page.NFTREQUESTFIR_username")}*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-span-2 flex justify-start items-center px-3 border-b border-jpnft-border h-24">*/}
                  {/*        <div className=" max-h-full w-32">*/}
                  {/*            <img*/}
                  {/*                className="w-full h-full"*/}
                  {/*                src={accountState.avatarUrl!}/>*/}
                  {/*        </div>*/}
                  {/*        <div>{accountState.userName}</div>*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">*/}
                  {/*        <span>{t("page.NFTREQUESTFIR_twitter")}</span>*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-span-2 flex justify-start items-center px-3 border-b border-jpnft-border h-12 break-words">*/}
                  {/*        <a href={accountState?.twitterUrl} target="_blank">*/}
                  {/*            {accountState?.twitterUrl}*/}
                  {/*        </a>*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">*/}
                  {/*        <span>{t("page.NFTREQUESTFIR_linktree")}</span>*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-span-2 flex justify-start items-center px-3 border-b border-jpnft-border h-12">*/}
                  {/*        {accountState?.linktreeUrl}*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="flex justify-start items-center bg-jpnft-tbHeaderBg px-3 border-b border-jpnft-border h-12">*/}
                  {/*        <span>{t("page.NFTREQUESTFIR_opensea")}</span>*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-span-2 flex justify-start items-center px-3 border-b border-jpnft-border h-12">*/}
                  {/*        {accountState.openseaUrl}*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="bg-jpnft-tbHeaderBg px-3 rounded-bl-md border-b border-jpnft-border h-64 py-2">*/}
                  {/*        {t("page.USER_bio")}*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className="col-span-2 flex justify-start items-center px-3 border-b border-jpnft-border h-64">*/}
                  {/*        <TextArea readOnly*/}
                  {/*                  className="fs_16_bold"*/}
                  {/*                  value={accountState.userDetails ? accountState.userDetails : ""}*/}
                  {/*                  rows={10}*/}
                  {/*                  style={{*/}
                  {/*                      borderColor: "transparent",*/}
                  {/*                      backgroundColor: "transparent",*/}
                  {/*                      resize: "none",*/}
                  {/*                      padding: "4px 0px"*/}
                  {/*                  }}/>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  {/*<button*/}
                  {/*    className="bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo rounded-3xl fs_16_white h-10 w-40 mb-10 mt-5"*/}
                  {/*    onClick={() =>*/}
                  {/*        window.open("mailto:?subject=&cc=&subject=主题&body=内容")*/}
                  {/*    }*/}
                  {/*>*/}
                  {/*    {t("page.IPHOLDER_edit-information")}*/}
                  {/*</button>*/}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyPage;
