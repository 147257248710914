import React, { useEffect } from "react";
import "./App.scss";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./pages/Layout/Header";
import Footer from "./pages/Layout/Footer";
import "antd/dist/antd.min.css";
import "swiper/swiper-bundle.css";
import { useAppDispatch, useAccountState } from "@/store";
import { Actions } from "@/store/reducers/account";
import { notification, Spin } from "antd";
import { RoutePaths } from "@/router";
import clsx from "clsx";
import i18n from "./locales";
import { AppContext, IAppContext } from "./hooks";
function App() {
  const account = useAccountState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  console.log(process.env.REACT_APP_WELCOME);

  useEffect(() => {
    // Listen on the accountsChanged/chainChanged event
    // if they are changed, then hint user to re-sign in
    if (
      typeof (window as any).ethereum != "undefined" &&
      typeof (window as any).ethereum._events != "undefined"
    ) {
      if ((window as any).ethereum._events["accountsChanged"] !== undefined) {
        delete (window as any).ethereum._events["accountsChanged"];
      }
      if ((window as any).ethereum._events["accountsChanged"] === undefined) {
        (window as any).ethereum.on("acc  ountsChanged", async () => {
          if (account.signed) {
            notification.warning({
              message: "Wallet account was changed, please sign up again!",
            });
            dispatch(Actions.logout());
            navigate(RoutePaths.HOME, { replace: true });
          }
        });
      }

      if ((window as any).ethereum._events["chainChanged"] !== undefined) {
        delete (window as any).ethereum._events["chainChanged"];
      }
      if ((window as any).ethereum._events["chainChanged"] === undefined) {
        (window as any).ethereum.on("chainChanged", async () => {
          if (account.signed) {
            notification.warning({
              message: "Wallet network was changed, please sign up again!",
            });
            dispatch(Actions.logout());
            navigate(RoutePaths.HOME, { replace: true });
          }
        });
      }
    }
    return () => {
      // Delete the accountsChanged/chainChanged events
      if (
        typeof (window as any).ethereum != "undefined" &&
        typeof (window as any).ethereum._events != "undefined"
      ) {
        if ((window as any).ethereum._events["accountsChanged"] !== undefined) {
          delete (window as any).ethereum._events["accountsChanged"];
        }
        if ((window as any).ethereum._events["chainChanged"] !== undefined) {
          delete (window as any).ethereum._events["chainChanged"];
        }
      }
    };
  }, []);
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  console.log(i18n.language);

  const isMobile = () => {
    const ua = navigator.userAgent.toLowerCase();
    return (
      ua.indexOf("mobile") > -1 ||
      ua.indexOf("iphone") > -1 ||
      ua.indexOf("android") > -1 ||
      ua.indexOf("iemobile") > -1 ||
      ua.indexOf("windows phone") > -1
    );
  };
  const numberFormat = (n: string | undefined) => {
    if (n === undefined) {
      return "";
    }
    const num: string[] = n.toString().split(".");
    var arr = num[0].split("").reverse();
    var res = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      if (i % 3 === 0 && i !== 0) {
        res.push(",");
      }
      res.push(arr[i]);
    }
    res.reverse();
    let result = '';
    if (num[1]) {
      result = res.join("").concat("." + num[1]);
    } else {
      result = res.join("");
    }
    return result;
  }

  const initialContext: IAppContext = {
    util: {
      isMobile: isMobile,
      numberFormat: numberFormat,
    },
    width,
    state: {
      dispatch
    }
  }

  return (
    <div
      className={clsx("App",account.locale === "ja" ?"mPLUSRounded1c": account.locale === "en" ?  "cwTeXYenEN":"cwTeXYenZH")}
      style={{
        backgroundImage: "linear-gradient(220deg, #b7d4ea,white, white)"
      }}
    >
      <Spin spinning={account.loading}>
      <AppContext.Provider value={initialContext}>
          <Header />
          <Outlet />
          <Footer />
      </AppContext.Provider>
      </Spin>
    </div>
  );
}
export const { Consumer } = AppContext;
export default App;
