import { Button, Form } from "antd";
import { ip_holder_info } from "@/services";
import { useEffect, useRef, useState } from "react";
import { useAccountState } from "@/store";
import { useTranslation } from "react-i18next";
import { normal } from "@/constants";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import clsx from "clsx";

const ProfileIpholder = (props: any) => {
  const { t } = useTranslation();
  const { showTitle } = props;
  const languageNum =
    i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
  const [languageId, setLanguageId] = useState<number>(languageNum);
  useRef<HTMLInputElement | null>(null);
  const accountState = useAccountState();
  const [ipHolderInfo, setIpHolderinfo] = useState<{
    data: {
      userId: number;
      ethAddress: string;
      proxyAddress: string;
      ipholderName: string;
      postCode: string;
      address: string;
      tel: string;
      email: string;
      avatar: string;
      userType: number;
      managerName: string;
      managerDepartment: string;
      managerPosition: string;
    };
  }>();
  const [organizationType, setOrganizationType] = useState<string>("");
  const [form] = Form.useForm();

  const getIpHolderInfo = async (languageNum: number) => {
    if (languageId !== languageNum) {
      setLanguageId(languageNum);
    }
    console.log("userid" + accountState.userId);
    const userId = accountState.userId;
    const res = await ip_holder_info(userId!, languageNum);
    if (res) {
      setIpHolderinfo(res);
      let organType = "";
      if (ipHolderInfo?.data.userType === 2) {
        organType =
          languageNum === 1
            ? "個人"
            : languageNum === 2
            ? "Individual"
            : "个人";
        // organType =t("page.IPHOLDER_individual");
      } else {
        organType =
          languageNum === 1 ? "法人" : languageNum === 2 ? "Company" : "企业";
        // organType =t("page.IPHOLDER_company");
      }
      setOrganizationType(organType);
    } else {
      showErrorMsg(res, t);
    }
  };
  useEffect(() => {
    const languageId =
      i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    getIpHolderInfo(languageId);
  }, []);

  return (
    <div className={clsx(showTitle ? "" : "minHeight")}>
      <div className="main-container">
        <div className={clsx(showTitle ? "px-10" : "px-40 mt-8")}>
          <Form form={form} layout="vertical" autoComplete="off" style={{userSelect:"text"}}>
            <div className="w-full">
              <div className="fs_h2">{t("page.IPHOLDER_ip-information")}</div>
              <div className="pt-10">
                <Button
                  className="fs_18_bold"
                  style={{
                    backgroundColor: clsx(
                      languageId === 1 ? "#d4f6ff" : "white"
                    ),
                    borderColor: clsx(languageId === 1 ? "#d4f6ff" : "#c7cdce"),
                    color: "#333333",
                    borderRadius: "10px 10px 0 0",
                    height: "40px",
                    width: "95px",
                    fontWeight: "800",
                    marginRight: "10px",
                    userSelect:"text"
                  }}
                  onClick={() => getIpHolderInfo(1)}
                >
                  日本語
                </Button>
                <Button
                  className="fs_18_bold"
                  style={{
                    backgroundColor: clsx(
                      languageId === 2 ? "#d4f6ff" : "white"
                    ),
                    borderColor: clsx(languageId === 2 ? "#d4f6ff" : "#c7cdce"),
                    color: "#333333",
                    borderRadius: "10px 10px 0 0",
                    height: "40px",
                    width: "95px",
                    marginRight: "10px",
                    fontWeight: "800",
                    userSelect:"text"
                  }}
                  onClick={() => getIpHolderInfo(2)}
                >
                  English
                </Button>
                <Button
                  className="fs_18_bold"
                  style={{
                    backgroundColor: clsx(
                      languageId === 3 ? "#d4f6ff" : "white"
                    ),
                    borderColor: clsx(languageId === 3 ? "#d4f6ff" : "#c7cdce"),
                    color: "#333333",
                    borderRadius: "10px 10px 0 0",
                    marginRight: "53%",
                    height: "40px",
                    width: "95px",
                    fontWeight: "800",
                    userSelect:"text"
                  }}
                  onClick={() => getIpHolderInfo(3)}
                >
                  中文
                </Button>
              </div>

              <div className="flex w-full" style={{ height: "auto" }}>
                <div className="flex flex-col h-full w-1/3 bg-jpnft-tbHeaderBg border-t border-b border-l border-jpnft-border rounded-tl-3xl  rounded-bl-3xl text-left">
                  <div
                    className="w-full border-b border-jpnft-border px-5 flex justify-start items-center"
                    style={{ height: "220px" }}
                  >
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_ip-profile-picture")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_company-name-individual-name")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_address")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {" "}
                      {t("page.IPHOLDER_managerName")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_managerDepartment")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {" "}
                      {t("page.IPHOLDER_managerPosition")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_phone-number")}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_email-address")}
                    </div>
                  </div>
                  <div className="w-full px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {t("page.IPHOLDER_organization-type")}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col h-full w-2/3 border border-jpnft-border rounded-tr-3xl  rounded-br-3xl text-left">
                  <div
                    className="w-full border-b border-jpnft-border px-5 flex justify-start items-center"
                    style={{ height: "220px" }}
                  >
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      <img
                        src={normal + "/" + ipHolderInfo?.data.avatar}
                        alt=""
                        h-56
                        style={{ maxWidth: "100%", maxHeight: "210px",userSelect:"none" }}
                        className=" rounded-md"
                      />
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.ipholderName}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold flex">
                      {ipHolderInfo?.data.postCode !== null &&
                      ipHolderInfo?.data.postCode !== undefined ? (
                        <div>{"〒" + ipHolderInfo?.data.postCode}</div>
                      ) : null}
                      {ipHolderInfo?.data.address !== null &&
                      ipHolderInfo?.data.address !== undefined ? (
                        <div>{ipHolderInfo?.data.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.managerName !== null &&
                      ipHolderInfo?.data.managerName !== undefined
                        ? ipHolderInfo?.data.managerName
                        : ""}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.managerDepartment !== null &&
                      ipHolderInfo?.data.managerDepartment !== undefined
                        ? ipHolderInfo?.data.managerDepartment
                        : ""}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.managerPosition !== null &&
                      ipHolderInfo?.data.managerPosition !== undefined
                        ? ipHolderInfo?.data.managerPosition
                        : ""}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.tel !== null &&
                      ipHolderInfo?.data.tel !== undefined
                        ? ipHolderInfo?.data.tel
                        : ""}
                    </div>
                  </div>
                  <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.email !== null &&
                      ipHolderInfo?.data.email !== undefined
                        ? ipHolderInfo?.data.email
                        : ""}
                    </div>
                  </div>
                  <div className="w-full px-5 h-20 flex justify-start items-center">
                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                      {ipHolderInfo?.data.userType === undefined
                        ? ""
                        : ipHolderInfo?.data.userType === 2
                        ? t("page.IPHOLDER_individual")
                        : t("page.IPHOLDER_company")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo rounded-3xl fs_16_white h-10 w-40 mb-10 mt-5"
              onClick={() =>
                window.open("mailto:?subject=&cc=&subject=主题&body=内容")
              }
            >
              {t("page.IPHOLDER_edit-information")}
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ProfileIpholder;
