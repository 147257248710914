import React, {useEffect, useRef, useState} from 'react';
import {nft_external_list, nft_list} from "@/services";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {message} from "antd";
import { showErrorMsg } from '@/utils/error';

interface ExternalData {
    ipNftId: number;
    nftName: string;
    nftImage: string;
    externalUrl: string;
    externalWebsite: string;
}

const NftExternalList = () => {
    const { t } = useTranslation();
    const {state} = useLocation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<ExternalData[]>([]);
    const fileReF = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        getData();
    }, [])

    async function getData() {
            const res = await nft_external_list(fileReF.current?.value,state);
            if (res) {
                setTableData(res.data);
            }else{
                showErrorMsg(res,t);
            }
    }

    const selectIp = () => {
      getData();
    }

    return (
        <div className="minHeight text-left">
            <div className="main-container">
                <div className="px-20" style={{userSelect:"text"}}>
                    <button className="fs_h2 pt-10">
                        <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                             onClick={() => window.history.back()}
                             xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">
                            <path
                                d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                p-id="2097"></path>
                        </svg>
                    </button>
                    <div className="fs_h2 pt-10 text-center">{t("nftListTitle2")}</div>
                    <div className="pt-10" style={{display: "inline-block"}}>
                        <div style={{position: "absolute"}}>
                            <svg style={{marginTop: "5px", marginLeft: "4px"}} className="icon" viewBox="0 0 1024 1024"
                                 version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="3500" width="20" height="20">
                                <path
                                    d="M383.3 699.8C217.4 699.8 82.5 564.9 82.5 399S217.4 98.2 383.3 98.2 684.1 233.1 684.1 399 549.2 699.8 383.3 699.8z m0-552.6c-138.8 0-251.7 112.9-251.7 251.7s112.9 251.7 251.7 251.7c138.8 0 251.8-112.9 251.8-251.7s-113-251.7-251.8-251.7z"
                                    fill="#333333" p-id="3501"></path>
                                <path d="M558.272 600.948l33.412-35.975 349.791 324.87-33.412 35.976z" fill="#333333"
                                      p-id="3502"></path>
                            </svg>
                        </div>
                        <input ref={fileReF} style={{borderColor: "white", outline: "none"}} type="search"
                               className="rounded-tl-3xl rounded-bl-3xl h-8 w-80 text-black pl-6"
                               placeholder="キーワードから探す"
                        />
                        <button className="h-8 w-16 rounded-3xl" onClick={() => selectIp()}
                                style={{backgroundColor: "#13c1f1"}}>{t("search")}
                        </button>

                    </div>

                    <div>
                        <div className="pt-10">
                            {tableData?.map(function (
                                item,
                                index
                            ) {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            padding: "10px",
                                            display: "inline-block",
                                            width: "25%",
                                        }}
                                    >
                                        <div style={{borderRadius: "15px"}} className="w-52 h-56">
                                            <img
                                                src={item.nftImage}
                                                className="rounded-3xl h-full w-full cover" style={{userSelect:"none"}}
                                                onClick={()=>navigate(`/nftDetailInfo/${item.ipNftId}`,{ state: item })}
                                            />
                                            <div className="text-center">
                                                <div className="fs_18_bold pt-5" style={{minHeight:"50px"}}>{item.nftName}</div>
                                                <div className="h-6 fs_16 pt-5">{item.externalWebsite}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NftExternalList;