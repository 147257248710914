import { useEffect } from "react";
import {useTranslation} from "react-i18next";

const ToEveryOne = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="minHeight">
            <div className="main-container">
                <div className="md:px-60 mt-20 mb-10" style={{userSelect:"text"}}>
                    <div className="font-bold text-2xl text-black md:text-4xl pb-7">
                        {t("page.FOOTER_to-every-one-title")}
                    </div>
                    <div className="fs_16 text-left w-full h-auto text-black font-bold md:text-lg whitespace-pre-wrap pt-3">
                        {t("page.FOOTER_to-every-one")}
                        <button onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdHCk2wceGjtG8DVIBxyfz0a5W70X8gRBZJNxz_WibCgt8k8A/viewform")} style={{textDecoration: "underline",color: "#13c1f1"}}> {t("page.FOOTER_to-every-one1")}</button>
                        {t("page.FOOTER_to-every-one2")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToEveryOne;