import {Table, Modal, message} from 'antd';
import type {TablePaginationConfig} from 'antd/es/table';
import {useEffect, useState} from 'react';
import {ip_details, ipholderSelect, ipRequestList} from "@/services";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import IPForm from "./IPForm"
import IPRoyaltyForm from "@/pages/IP/IPRoyaltyForm";
import {ipLogoUrl} from "@/constants";
import clsx from "clsx";
import {useAccountState} from "@/store";
import {useTranslation} from "react-i18next";
import {showErrorMsg} from '@/utils/error';
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";

interface DataType {
    ipRequestId: number;
    key: string;
    name: string;
    age: number;
    address: string;
    statusDisplay: string;
    ipName: string;
    created: string;
    updated: string;
    ipDetails: string;
    externalUrl: string;
    ipContent: string;
    ipUsage: string;
    hasNft: string;
    twitterUrl: string;
    ipLogo: string;
    ipBanner: string;
    userName: string;
    tags: string[];
    ipInfo: IpInfo[];
    ipFile: IpFile[];
    ipholders: IpHolderType[];
}

type IpHolderType = {
    ipholderName: string;
};

interface IpInfo {
    ipName: string;
    ipDetails: string;
    externalUrl: string;
    ipContent: string;
    ipUsage: string;
    twitterUrl: string;
    langvageId: number;
}

interface IpFile {
    fileName: string;
    ipFileName: string;
    fileId: string;
    ipFileId: string;
    langvageId: number;
}


const IPRequestList = () => {
    const {t} = useTranslation();
    const accountState = useAccountState();
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const [status, setStatus] = useState<string>('1_2_3');
    const [total, setTotal] = useState<number>(0);
    const [tableData, setTableData] = useState<DataType[]>([]);

    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [modelType, setModelType] = useState<string | null>();
    const [detailData, setDetailData] = useState<object>();
    //const [ipHolderName, setIpHolderName] = useState<string>("");

    //1 入口申請　２　入口承認
    const direction = location.pathname === RoutePaths.IP_APPLICATION_LIST ? 1 : 2

    const [visible, setVisible] = useState(false);

    const [ipRVisible, setIpRVisible] = useState(false);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    });

    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

    useEffect(() => {
        window.scrollTo(0, 0);
        getData(page, status, direction);
        console.log(location.state, "menu")
    }, [])

    const openDetail = async function (value: any) {
        if (value.ipRequestId) {
            const res = await ip_details(value.ipRequestId)
            if (res && res.data) {
                setDetailData(res.data)
                setModelType("detail")
                setVisible(true);
            } else {
                showErrorMsg(res, t);
            }
        }
    }

    async function getData(p: number, s: string, d: number) {
        const params = {
            page: p,
            status: s == "0" ? "1_2_3" : s,
            direction: d,
        }
        if (status.includes("1_2_3")) {
            setStatus("0")
        }
        const res = await ipRequestList(params);
        if (res) {
            setTotal(res.data.total);
            setTableData(res.data.list);
            setPagination({
                current: res.data.pageNum,
                pageSize: res.data.pageSize
            })
        } else {
            showErrorMsg(res, t);
        }
    }

    const closeModel = () => {
        setVisible(false);
        getData(page, status, direction);
    }

    const closeParantIpvisModel = () => {
        setIpRVisible(false);
    }

    const closeIpvisModel = () => {
        setIpRVisible(false);
    }

    const copyApplication = () => {
        setVisible(false);
        setIpRVisible(true);
    }

    const newIp = () => {
        setModelType(null)
        setVisible(true)
    }

    const pageUrl = async () => {
        const res = await ipholderSelect(accountState.userId!);
        if (res && res.code == 0) {
            const paramData = {
                showMenu: location.state,
            };
            navigate(RoutePaths.IP_FORM);
        } else {
            showErrorMsg(res, t);
        }
    }

    const selectWorks = (value: string) => {

        if (value == "0") {
            setStatus("0")
            getData(page, "1_2_3", direction);
        } else {
            if (status == "0") {
                setStatus(value);
                getData(page, value, direction);
            } else {
                if (status.includes(value)) {
                    value = status.replaceAll(value, "").replaceAll("_", "");
                    if (value == "") {
                        value = "0";
                    }
                } else {
                    value = value + "_" + status;
                }
                if (value.includes("2") && value.includes("1") && value.includes("3")) {
                    setStatus("0");
                    getData(page, "1_2_3", direction);
                } else {
                    setStatus(value);
                    if (value == "0") {
                        getData(page, "1_2_3", direction);
                    } else {
                        getData(page, value, direction);
                    }
                }
            }
        }
    }

    const ipForm = (item: any) => {
        if (location.pathname == RoutePaths.IP_APPROVAL_LIST) {
            navigate("/IPApproval/" + item.ipRequestId, {state: location.state})
        } else {
            const paramData = {
                ipLogo: item.ipLogo,
                ipBanner: item.ipBanner,
                ipName: item.ipName,
                ipInfo: item.ipInfo,
                ipDetails: item.ipDetails,
                ipType: item.ipType,
                externalUrl: item.externalUrl,
                twitterUrl: item.twitterUrl,
                ipContent: item.ipContent,
                ipUsage: item.ipUsage,
                ipTag: item.ipTag,
                nftRequestFalg: item.nftRequestFalg,
                nftExternalFalg: item.nftExternalFalg,
                fileName: item.fileName,
                fileId: item.fileId,
                ipFileName: item.ipFileName,
                ipFileId: item.ipFileId,
                langvageId: item.langvageId,
                files: item.files,
                ipFiles: item.ipFiles,
                showMenu: showMenu,
            }
            navigate(RoutePaths.IP_FORM_CONFIRM, {state: paramData})
        }
    }

    // @ts-ignore
    return (
        <div className="minHeight text-left">
            <div className={clsx(location.state ? "main-container flex" : "main-container")}>
                {location.state &&
                    <Menu show={"2"} status={"1"}/>
                }
                <div className={clsx(location.state ? "w-4/5 text-center pl-10 pr-30 " : "px-40")} style={{userSelect:"text"}}>
                    <div style={{userSelect:"text"}}>
                        {location.state ?
                            <button className="fs_h2 pb-5 pt-5" style={{float: "left"}}>
                                <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                     onClick={() => navigate(RoutePaths.MY_PAGE, {state: {show: "2", status: "1"}})}
                                     xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">
                                    <path
                                        d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                        p-id="2097"></path>
                                </svg>
                            </button>
                            : <>
                                {location.pathname == RoutePaths.IP_APPLICATION_LIST &&
                                    <div className="text-center text-black text-4xl	font-semibold py-7">
                                        {t("page.IPREQUESTLIST_ip-listing-application-list")}
                                    </div>
                                }
                                {location.pathname == RoutePaths.IP_APPROVAL_LIST &&
                                    <div className="text-center text-black text-4xl	font-semibold py-7">
                                        {t("page.IPAPPROVALLIST_ip-listing-application-approval-list")}
                                    </div>
                                }
                            </>
                        }

                        <div className="text-black px-2 row" style={{backgroundColor: "transparent"}}>
                            <button onClick={() => selectWorks("1")}
                                    className={clsx(languageId==2 ?  "rounded-3xl w-20 h-auto mx-2":"rounded-3xl w-20 h-8 mx-2")}
                                    style={{
                                        backgroundColor: clsx(status.includes("1") ? "#13c1f1" : "white"),
                                        color: clsx(status.includes("1") ? "white" : "#333333")
                                    }}>
                                {t("page.IPREQUESTLIST_awaiting-approval")}
                            </button>
                            <button onClick={() => selectWorks("2")} className="rounded-3xl w-20 h-8 mx-2"
                                    style={{
                                        backgroundColor: clsx(status.includes("2") ? "#13c1f1" : "white"),
                                        color: clsx(status.includes("2") ? "white" : "#333333")
                                    }}>
                                {t("page.IPREQUESTLIST_declined")}
                            </button>
                            <button onClick={() => selectWorks("3")} className="rounded-3xl w-20 h-8 mx-2"
                                    style={{
                                        backgroundColor: clsx(status.includes("3") ? "#13c1f1" : "white"),
                                        color: clsx(status.includes("3") ? "white" : "#333333")
                                    }}>
                                {t("page.IPREQUESTLIST_approved")}
                            </button>
                            <button onClick={() => selectWorks("0")} className="rounded-3xl w-20 h-8 mx-2"
                                    style={{
                                        backgroundColor: clsx(status == "0" ? "#13c1f1" : "white"),
                                        color: clsx(status == "0" ? "white" : "#333333")
                                    }}>
                                {t("page.IPREQUESTLIST_all")}
                            </button>
                        </div>
                    </div>

                    <table className="text-black px-60 rounded-3xl border-white" style={{width: "100%"}}>
                        <thead>
                        <tr style={{backgroundColor: "#13c1f1", borderColor: "#d3d3d3"}}
                            className="h-12 text-white border-b">
                            <td style={{width: "5%", backgroundColor: "#13c1f1"}}
                                className="text-center rounded-tl-3xl">{t("page.IPREQUESTLIST_no")}</td>
                            <td className="pl-2" style={{width: "10%"}}>{t("page.IPREQUESTLIST_status")}</td>
                            {location.pathname == RoutePaths.IP_APPROVAL_LIST &&
                                <td className="pl-2" style={{width: "13%"}}>{t("page.IPAPPROVALLIST_applicant")}</td>
                            }
                            <td className="pl-2" style={{width: "25%"}}>{t("page.IPREQUESTLIST_image")}</td>
                            <td className="pl-2" style={{width: clsx(location.pathname == RoutePaths.IP_APPROVAL_LIST?"25%":"25%")}}>{t("page.IPREQUESTLIST_ip-name")}</td>
                            {location.pathname == RoutePaths.IP_APPROVAL_LIST ?
                                <>
                                    <td className="pl-2"
                                        style={{width: "10%"}}>{t("page.IPREQUESTLIST_application-date")}</td>
                                    <td className="pl-2 rounded-tr-3xl"
                                        style={{width: "10%"}}>{t("page.IPREQUESTLIST_approval-date")}</td>
                                </>
                                :
                                <>
                                    <td className="pl-2"
                                        style={{width: "10%"}}>{t("page.IPAPPROVALLIST_application-date")}</td>
                                    <td className="pl-2 rounded-tr-3xl"
                                        style={{width: "10%"}}>{t("page.IPAPPROVALLIST_approval-date")}</td>
                                </>
                            }


                        </tr>
                        </thead>
                        <tbody>
                        {
                            tableData?.map((item, index) => {
                                return <tr style={{borderColor: "#d3d3d3"}}
                                           className={clsx("h-12", index === tableData.length - 1 ? '' : 'border-b')}
                                           key={index}>
                                    {tableData.length == index + 1 ?
                                        <td style={{backgroundColor: "#aaedff"}}
                                            className="text-center rounded-bl-3xl">{index + 1}</td>
                                        : <td style={{backgroundColor: "#aaedff"}}
                                              className="text-center">{index + 1}</td>}

                                    <td className="pl-2 fs_16_white">
                                        {item.statusDisplay == "承認待ち" ?
                                            <div style={{backgroundColor: "#5db8cd", borderRadius: "5px"}}
                                                    className="w-20">{t("page.IPREQUESTLIST_awaiting-approval")}</div>

                                            : item.statusDisplay == "登録済み" ?
                                                <div style={{backgroundColor: "#5d64c0", borderRadius: "5px"}}
                                                        className="w-20">{t("page.IPREQUESTLIST_approved")}</div>
                                                :
                                                <div style={{backgroundColor: "#ff4a4a", borderRadius: "5px"}}
                                                        className="w-20">{t("page.IPREQUESTLIST_declined")}</div>
                                        }
                                    </td>
                                    {location.pathname == RoutePaths.IP_APPROVAL_LIST &&
                                        <td className="px-1"
                                            style={{backgroundColor: "#eafbff",wordBreak:"break-word"}}>{i18n.language === "ja"
                                            ? item.ipholders[0]?.ipholderName
                                            : i18n.language === "en"
                                                ? item.ipholders[1]?.ipholderName
                                                : item.ipholders[2]?.ipholderName}</td>
                                    }
                                    {item.ipLogo ?
                                        <td className="px-2" style={{backgroundColor: "white"}}>
                                            <div className="w-auto" style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                                                <img src={ipLogoUrl + "/" + item.ipLogo} alt="" className="rounded-md" style={{maxHeight:"280px",userSelect:"none"}}/>
                                            </div>
                                        </td>
                                        : <td></td>}
                                    <td
                                        className="pl-2"
                                        style={{backgroundColor: "#eafbff"}}>
                                        <button
                                            style={{textDecoration: "underline", color: "#70daf7"}}
                                            onClick={() => ipForm(item)}>{i18n.language === "ja"
                                            ? item.ipInfo[0]?.ipName
                                            : i18n.language === "en"
                                                ? item.ipInfo[1]?.ipName
                                                : item.ipInfo[2]?.ipName}</button>
                                    </td>
                                    <td className="pl-2" style={{backgroundColor: "white"}}>{item.created}</td>
                                    {tableData.length == index + 1 ?
                                        <td className="pl-2 rounded-br-3xl"
                                            style={{backgroundColor: "#eafbff"}}>{item.updated}</td>
                                        : <td className="pl-2" style={{backgroundColor: "#eafbff"}}>{item.updated}</td>}

                                </tr>
                            })
                        }
                        </tbody>
                    </table>

                    {location.pathname == RoutePaths.IP_APPLICATION_LIST &&
                        <div className="text-center">
                            <button
                                onClick={() => pageUrl()}
                                style={{marginTop: "50px", marginBottom: "50px"}}
                                className="bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-40 h-8 rounded-3xl">
                                {t("page.IPREQUESTLIST_ip-registry")}
                            </button>
                        </div>
                    }
                    {total > 10 &&
                        <Table
                            className="newPage"
                            pagination={{
                                total: total, //数据总数量
                                pageSize: pagination.pageSize,
                                onChange(current) {
                                    setPage(current);
                                    getData(current, status, direction);
                                },
                            }}
                        />
                    }
                </div>
            </div>

            <Modal
                title="IP新規作成"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => closeModel()}
                width={"70%"} footer={[]}
            >
                <IPForm key={new Date()} detailData={detailData} modelType={modelType}
                        copyApplication={() => copyApplication()} ipCloseModel={() => closeModel()}/>
            </Modal>

            <Modal
                title="IP使用権一次申請画面"
                centered
                visible={ipRVisible}
                onOk={() => setIpRVisible(false)}
                onCancel={() => closeParantIpvisModel()}
                width={"70%"} footer={[]}
            >
                <IPRoyaltyForm key={new Date()} detailData={detailData} modelType={modelType}
                               closeIpvisModel={() => closeIpvisModel()}/>
            </Modal>


        </div>
    );
};

export default IPRequestList;