import {Button, Form, message, notification} from "antd";
import {useLocation, useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "@/router";
import {
  IpRequestDetails,
  ip_complete,
  ip_details,
  ip_reject,
  ip_holder_info, ip_file_download,
} from "@/services";
import React, { useEffect, useState } from "react";
import { metaMaskSigner } from "@/utils";
import { ipBannerUrl, ipLogoUrl } from "@/constants";
import TextArea from "antd/lib/input/TextArea";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";
import IpHolderInfo from "@/components/IpHolderInfo";

const IPApproval = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ipRequestId } = useParams();
  const location = useLocation();
  const [detailData, setDetailData] = useState<IpRequestDetails>();
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState<string>("");
  let [status, setStatus] = useState<string>('1');
  let [status1, setStatus1] = useState<string>('1');
  let [status2, setStatus2] = useState<string>('1');
  const [showMenu,setShowMenu] = useState<boolean>(location.state);
  const [fromUserId,setFromUserId] = useState<number>();

  const getDetail = async function (ipRequestId: string) {
    if (ipRequestId) {
      const res = await ip_details(ipRequestId);
      debugger
      if (res && res.data) {
        const fName =
          res.data.files && res.data.files.length
            ? res.data.files[0].fileName
            : "";
        setFileName(fName);
        setDetailData(res);
        setFromUserId(res.data.fromUserId);
        const userId =
          res.data.fromUserId === undefined
            ? res.data.fromUserId
            : res.data.fromUserId;
            const languageId =
            i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        const res01 = await ip_holder_info(userId,languageId);
        if (res01) {
          // @ts-ignore
          setIpHolderinfo(res01);
        }else{
         showErrorMsg(res,t);
        }
        console.log(res.data.userName, res.data.twitterUrl, "1");
      }else{
        showErrorMsg(res,t);
      }
    }
  };

  const [ipHolderInfo, setIpHolderinfo] = useState<{
    data: {
      userId: number;
      ethAddress: string;
      proxyAddress: string;
      ipholderName: string;
      postCode: string;
      address: string;
      tel: string;
      email: string;
      avatar: string;
      userType: number;
      userName: string;
    };
  }>();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (ipRequestId) {
      getDetail(ipRequestId);
    }
  }, [fromUserId]);

  const onFinish = async () => {
    const id = parseInt(ipRequestId!);
    let approvalSig: string | undefined = "";
    if (detailData?.data.ipHash) {
      approvalSig = await metaMaskSigner(detailData?.data.ipHash);
    } else {
      return;
    }
    let params = {
      ipRequestId: id,
      approvalSig: approvalSig!,
    };
    const res = await ip_complete(params);
    debugger
    if (res && res.code==0) {
      notification.success({
        message: t("msg.IPREQ_6"),
      });
      navigate(RoutePaths.IP_APPROVAL_LIST,{state:showMenu});
    }else{
     message.error(t("msg.IPREQ_7"));
    }
  };

  const reject = async () => {
    console.log("reject");
    let params = {
      ipRequestId: detailData?.data.ipRequestId!,
    };
    const res = await ip_reject(params);
    if (res) {
      notification.success({
        message: t("msg.IPAPPR_6"),
      });
      navigate(RoutePaths.IP_APPROVAL_LIST,{state:showMenu});
    }else{
     message.error(t("msg.NFT_1"));
    }
  };


  const downloadFile = async (fileId: string, fileName: string) => {
    if (!fileId) {
      return;
    }
    const fName: string = fileName;
    const result = await ip_file_download(fileId);
    if (result && result.code > 0) {
      let msg = Object.keys(result.msg)[0];
      message.error(msg);
      return;
    }
    if (result) {
      var blob = new Blob([result]);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    }
  }
  const onChangeTo  = async (language:string,status:number) => {
    if(status==1){
      if(language=="JP"){
        setStatus("1");
      }else if(language=="EN"){
        setStatus("2");
      }else{
        setStatus("3");
      }
    }else if(status==2){
      if(language=="JP"){
        setStatus1("1");
      }else if(language=="EN"){
        setStatus1("2");
      }else{
        setStatus1("3");
      }
    }else{
      if(language=="JP"){
        setStatus2("1");
      }else if(language=="EN"){
        setStatus2("2");
      }else{
        setStatus2("3");
      }
    }
  };

  return (
    <div className="minHeight">
      <div className={clsx(showMenu ? "main-container flex" : "main-container")}>
        {showMenu &&
            <Menu/>
        }
        <div className={clsx(showMenu ? "w-4/5 text-center pl-10 pr-30 " : "px-40")} style={{userSelect:"text"}}>
          <div className="py-7 fs_h1">{t("page.IPAPPROVAL_ip-information-registration-request")}</div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="w-full flex　relative">
              <IpHolderInfo userId={fromUserId}/>
              <div className="inline-block flex absolute " style={{width:"20%",top:195,right:160,userSelect:"text"}}>
                <Button
                    onClick={() =>
                        window.open("mailto:?subject=&cc=&subject=主题&body=内容")
                    }
                    style={{
                      background: "#13c1f1",
                      width: "150px",
                      borderRadius: "20px",
                      color: "white",
                      fontSize: "16px",
                      height: "35px",
                      userSelect:"text"
                    }}
                >
                  {t("page.IPAPPROVAL_edit")}
                </Button>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="rounded-xl">
                <div className="w-full text-left fs_h3 pt-5">{t("page.IPAPPROVAL_ip-information")}</div>
                <div className="flex w-full mt-5" style={{height: "auto"}}>
                  <div className="flex flex-col h-full w-1/3 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPAPPROVAL_ip-holder-picture")}<span className="ml-2 text-red-500">※</span>
                      </div>
                    </div>
                    <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPAPPROVAL_ip-banner")}<span className="ml-2 text-red-500">※</span>
                      </div>
                    </div>
                    <div className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPAPPROVAL_ip-name-title")}
                        <span className="ml-2 text-red-500">※</span>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white flex flex-col h-full w-2/3 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div
                        className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {/* 作品の代表画像 */}
                        <div className="flex w-full h-full items-center">
                          <img
                              src={ipLogoUrl + "/" +  detailData?.data.ipLogo}
                              alt=""
                              style={{width: "150px", height: "160px",userSelect:"none"}}
                              className=" rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                        className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {/* 作品のバナー画像 */}
                        <div className="flex w-full h-full items-center">
                          <img
                              src={ipBannerUrl + "/" +  detailData?.data.ipBanner}
                              alt=""
                              style={{height: "160px",userSelect:"none"}}
                              className="w-full max-h-full rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold" style={{userSelect:"text"}}>
                        {/* 著作物の種類 */}
                        {detailData?.data.ipTag.includes("1") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  userSelect:"text",
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                }}
                            >
                              {t("page.HOME_cartoons")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("2") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_anime")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("3") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_pictures")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("4") &&
                            <Button
                                className="rounded-3xl w-30 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_characters")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("5") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_games")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("6") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_illustrations")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("7") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_music")}
                            </Button>
                        }
                        {detailData?.data.ipTag.includes("8") &&
                            <Button
                                className="rounded-3xl w-20 h-8 mx-2"
                                style={{
                                  backgroundColor: "#ff8a00",
                                  color: "white",
                                  userSelect:"text"
                                }}
                            >
                              {t("page.HOME_other")}
                            </Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-10" style={{userSelect:"text"}}>
                  <Button
                      onClick={() => onChangeTo("JP",1)}
                      className="fs_18_bold"
                      style={{
                        backgroundColor: clsx(status=="1" ? "#d4f6ff" : "white"),
                        borderColor: clsx(status=="1" ? "#d4f6ff" : "#c7cdce"),
                        color: "#333333",
                        borderRadius:"10px 10px 0 0",
                        height:"40px",
                        width:"95px",
                        fontWeight:"800",
                        marginRight:"10px",
                        userSelect:"text"
                      }}>
                    日本語
                  </Button>
                  <Button
                      onClick={() => onChangeTo("EN",1)}
                      className="fs_18_bold"
                      style={{
                        backgroundColor: clsx(status=="2" ? "#d4f6ff" : "white"),
                        borderColor: clsx(status=="2" ? "#d4f6ff" : "#c7cdce"),
                        color: "#333333",
                        borderRadius:"10px 10px 0 0",
                        height:"40px",
                        width:"95px",
                        marginRight:"10px",
                        fontWeight:"800",
                        userSelect:"text"
                      }}>
                    English
                  </Button>
                  <Button
                      onClick={() => onChangeTo("ZH",1)}
                      className="fs_18_bold"
                      style={{
                        backgroundColor: clsx(status=="3" ? "#d4f6ff" : "white"),
                        borderColor: clsx(status=="3" ? "#d4f6ff" : "#c7cdce"),
                        color: "#333333",
                        borderRadius:"10px 10px 0 0",
                        marginRight: "570px",
                        height:"40px",
                        width:"95px",
                        fontWeight:"800",
                        userSelect:"text"
                      }}>
                    中文
                  </Button>
                </div>
                { status=="1"?
                    <div style={{background: "#d4f6ff",color:"#333333"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                      <div className="py-2">
                        <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                        <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].ipName}</div>
                      </div>
                      <div className="py-2">
                        <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                        <TextArea readOnly
                                  className="fs_16 pl-5 mt-3"
                                  value={detailData?.data?.ipInfo[parseInt(status)-1].ipDetails} rows={4}
                                  style={{
                                    borderRadius:"15px",
                                    backgroundColor: "white",
                                    resize: "none",
                                  }}
                        />
                      </div>
                      <div className="py-2">
                        <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                        <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].externalUrl}</div>
                      </div>
                      <div className="pt-2"  style={{paddingBottom:"40px"}}>
                        <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                        <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].twitterUrl}</div>
                      </div>
                    </div>
                    :status=="2"?
                        <div style={{background: "#d4f6ff",color:"#333333"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                            <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].ipName}</div>
                          </div>
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                            <TextArea readOnly
                                      className="fs_16 pl-5 mt-3"
                                      value={detailData?.data?.ipInfo[parseInt(status)-1]?.ipDetails} rows={4}
                                      style={{
                                        borderRadius:"15px",
                                        backgroundColor: "white",
                                        resize: "none",
                                      }}
                            />
                          </div>
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].externalUrl}</div>
                          </div>
                          <div className="pt-2"  style={{paddingBottom:"40px"}}>
                            <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].twitterUrl}</div>
                          </div>
                        </div>
                        :
                        <div style={{background: "#d4f6ff",color:"#333333"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                            <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data?.ipInfo[parseInt(status)-1].ipName}</div>
                          </div>
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                            <TextArea readOnly
                                      className="fs_16 pl-5 mt-3"
                                      value={detailData?.data?.ipInfo[parseInt(status)-1].ipDetails} rows={4}
                                      style={{
                                        borderRadius:"15px",
                                        backgroundColor: "white",
                                        resize: "none",
                                      }}
                            />
                          </div>
                          <div className="py-2">
                            <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data.ipInfo[parseInt(status)-1].externalUrl}</div>
                          </div>
                          <div className="pt-2" style={{paddingBottom:"40px"}}>
                            <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"32px"}}>{detailData?.data.ipInfo[parseInt(status)-1].twitterUrl}</div>
                          </div>
                        </div>
                }
              </div>
              <div className="rounded-xl mt-10 pb-10">
                <div className="w-full text-left fs_h3 pt-5">{t("page.IPAPPROVAL_license-information")}</div>

                <div className="flex w-full mt-5" style={{height: "auto"}}>
                  <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div
                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPAPPROVAL_license-for-nft-sure")}<span className="ml-2 text-red-500">※</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div
                        className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {/* NFTライセンス情報 */}
                        {detailData?.data.nftRequestFalg == "1" ? t("page.IPAPPROVAL_yes") : t("page.IPAPPROVAL_no")}
                      </div>
                    </div>
                  </div>
                </div>

                {detailData?.data.nftRequestFalg == "1" &&
                    <>
                      <div className="pt-10" style={{userSelect:"text"}}>
                        <Button
                            onClick={() => onChangeTo("JP", 2)}
                            className="fs_18_bold"
                            style={{
                              backgroundColor: clsx(status1 == "1" ? "#d4f6ff" : "white"),
                              borderColor: clsx(status1 == "1" ? "#d4f6ff" : "#c7cdce"),
                              color: "#333333",
                              borderRadius: "10px 10px 0 0",
                              height: "40px",
                              width: "95px",
                              fontWeight: "800",
                              marginRight: "10px",
                              userSelect:"text"
                            }}>
                          日本語
                        </Button>
                        <Button
                            onClick={() => onChangeTo("EN", 2)}
                            className="fs_18_bold"
                            style={{
                              backgroundColor: clsx(status1 == "2" ? "#d4f6ff" : "white"),
                              borderColor: clsx(status1 == "2" ? "#d4f6ff" : "#c7cdce"),
                              color: "#333333",
                              borderRadius: "10px 10px 0 0",
                              height: "40px",
                              width: "95px",
                              marginRight: "10px",
                              fontWeight: "800",
                              userSelect:"text"
                            }}>
                          English
                        </Button>
                        <Button
                            onClick={() => onChangeTo("ZH", 2)}
                            className="fs_18_bold"
                            style={{
                              backgroundColor: clsx(status1 == "3" ? "#d4f6ff" : "white"),
                              borderColor: clsx(status1 == "3" ? "#d4f6ff" : "#c7cdce"),
                              color: "#333333",
                              borderRadius: "10px 10px 0 0",
                              marginRight: "570px",
                              height: "40px",
                              width: "95px",
                              fontWeight: "800",
                              userSelect:"text"
                            }}>
                          中文
                        </Button>
                      </div>
                      <div className="flex w-full" style={{height: "auto"}}>
                        <div
                            className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                          <>
                            <div
                                className="w-full  border-jpnft-border px-5 h-28 flex justify-start items-center">
                              <div className="text-base text-jpnft-tbFtColor font-semibold">
                                {t("page.IPCONFIRM_important-information-for-nft-creation")}<span
                                  className="ml-2 text-red-500">※</span>
                              </div>
                            </div>
                          </>
                        </div>
                        <div
                            className="flex flex-col h-full w-2/5 rounded-br-3xl rounded-tr-3xl text-left border border-jpnft-border border-t border-b border-l">
                          <>
                            <div
                                className="w-full  border-jpnft-border px-5 h-28 flex justify-start items-center">
                              <div
                                  className="w-full text-base text-jpnft-tbFtColor font-semibold text-left break-words">
                                {/* NFT作成に関する特記事項 */}
                                <TextArea readOnly
                                          className="fs_16_bold"
                                          value={detailData?.data.ipInfo[parseInt(status1)-1].ipContent} rows={4}
                                          style={{
                                            borderColor: "white",
                                            resize: "none",
                                            padding: "4px 0px"
                                          }}/>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      {/* 利用権限 */}
                      <div className="flex w-full mt-5" style={{height: "auto"}}>
                        <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                          <div
                              className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {t("page.IPCONFIRM_usage-rights")}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                          <div
                              className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                              {detailData?.data.ipInfo[parseInt(status1)-1].ipUsage === "2" ? i18n.language==="ja"?"申請性":i18n.language==="en"?"Apply for License":"申请性" : i18n.language==="ja"?"許諾性":i18n.language==="en"?"Licensable":"许可性"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </div>
              <div className="rounded-xl mt-10 pb-10">
                <div className="w-full text-left fs_h3 pt-5">{t("page.IPAPPROVAL_registration-of-nft")}</div>
                <div className="flex w-full mt-5" style={{height: "auto"}}>
                  <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div
                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {t("page.IPAPPROVAL_registration-of-nft-info")}<span className="ml-2 text-red-500">※</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                    <div
                        className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                      <div className="text-base text-jpnft-tbFtColor font-semibold">
                        {/* NFTライセンス情報 */}
                        {detailData?.data?.nftExternalFalg == "1" ? t("page.IPAPPROVAL_yes") : t("page.IPAPPROVAL_no")}
                      </div>
                    </div>
                  </div>
                </div>

                {detailData?.data.nftExternalFalg == "1" &&
                    <>
                      <div className="pt-10">
                      </div>
                      <div className="flex w-full" style={{height: "auto"}}>
                        <div
                            className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-bl-3xl rounded-tl-3xl text-left border border-jpnft-border  border-b border-l">
                          {detailData?.data.nftExternalFalg == "1" &&
                              <>
                                <div
                                    className="w-full border-b  border-jpnft-border px-5 h-20 flex justify-start items-center">
                                  <div className="text-base text-jpnft-tbFtColor font-semibold">
                                    {t("page.IPAPPROVAL_nft-collection-image")}<span
                                      className="ml-2 text-red-500">※</span>
                                  </div>
                                </div>
                                <div
                                    className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                  <div className="text-base text-jpnft-tbFtColor font-semibold">
                                    {t("page.IPAPPROVAL_nft-collection-info")}<span
                                      className="ml-2 text-red-500">※</span>
                                  </div>
                                </div>
                              </>}
                        </div>
                        <div
                            className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border  border-b border-l">
                          <>
                            <div
                                className="w-full border-b  border-jpnft-border px-5 h-20 flex justify-start items-center" style={{wordBreak: "break-all"}}>
                              <div className="text-base text-jpnft-tbFtColor font-semibold">
                                <div className="rounded-md">
                                  <div className="flex flex-col">
                                    <a style={{
                                      textDecoration: "underline",
                                      color: "#13c1f1"
                                    }}
                                       className="fs_16_bold"
                                       onClick={() => downloadFile(detailData?.data.files[parseInt(status2)-1]?.reqFileId.toString(), detailData?.data.files[parseInt(status2)-1]?.fileName)}> {detailData?.data.files[parseInt(status2)-1]?.fileName}</a>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div
                                className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center"  style={{wordBreak: "break-all"}}>
                              <div className="text-base text-jpnft-tbFtColor font-semibold">
                                <div className="rounded-md">
                                  <div className="flex flex-col">
                                    <a style={{
                                      textDecoration: "underline",
                                      color: "#13c1f1"
                                    }}
                                       className="fs_16_bold"
                                       onClick={() => downloadFile(detailData?.data.ipFiles[parseInt(status2)-1]?.reqFileId.toString(), detailData?.data.ipFiles[parseInt(status2)-1]?.fileName)}> {detailData?.data.ipFiles[parseInt(status2)-1]?.fileName}</a>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
            {detailData?.data.status === 1 && (
              <Form.Item>
                <div className="flex my-14 justify-center items-center space-x-5">
                  <button
                    className="text-white w-40 h-8 rounded-3xl"
                    style={{
                      background: "white",
                      borderColor: "#7683D9",
                      boxShadow: "inset 0px 0px 0px 7px",
                      color: "#7683D9",
                    }}
                    onClick={() => reject()}
                  >
                    {t("page.IPAPPROVAL_declined")}
                  </button>
                  <button className="bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo w-40 h-8 rounded-3xl text-white">
                    {t("page.IPAPPROVAL_approval")}
                  </button>
                </div>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default IPApproval;
