import { Get, Post, Sessions } from "@/utils";

/**
 * AU01ランダム数字
 *
 * @returns {AU01}
 */
export const getRandomNum = async (): Promise<any | null> => {
  const res = await Get("/account/sign_code");
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

export const loginApi = async (params: {
  randomCodeToken: string;
  signatureData: string;
  walletAddress: string;
}): Promise<any> => {
  const res = await Post("/account/login", {
    body: JSON.stringify(params),
    headers: {
      "content-type": "application/json",
    },
  });
  return res.data;
};

export const AccountUpdate = async (params: {
  userName: string;
  avatar: string;
  userDetails: string;
  mail: string;
  twitterUrl: string;
  linktreeUrl: string;
  openseaUrl: string;
}): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Post("/account/update", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok && res.data) {
    return res.data;
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

export const sendInquiry = async (params: {
  name: string;
  email: string;
  tel: string;
  content: string;
}): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  const res = await Post("/inquiry/send", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

export const sendMail = async (mail: string): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get(
    "/account/email/verification/code",
    { email: mail },
    headers
  );
  console.log(res,"res")

  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

export const checkMail = async (params: {
  email: string;
  verificationCode: string;
}): Promise<any | null> => {
  let headers = { "content-type": "application/json" } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  let formData = new FormData();
  formData.append("email", params.email);
  formData.append("verificationCode", params.verificationCode);

  const res = await Post("/account/email/verification", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok) {
    return true;
  } else {
     const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
     return list;
  }
};
