import { Link, useNavigate } from "react-router-dom";
import { RoutePaths } from "@/router";
import { Actions } from "@/store/reducers/account";
import { useAccountState, useAppDispatch } from "@/store";
import { useEffect } from "react";
import { Sessions } from "@/utils";
import { useTranslation } from "react-i18next";

const AdminMyPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountState = useAccountState();

  function logout() {
    dispatch(Actions.logout());
    navigate(RoutePaths.HOME);
  }

  const userInfo = async () => {
    //const res = await getUserType(account.name);
    console.log(accountState.userType);
    if (accountState.userType == 2) {
      navigate(RoutePaths.PROFILE);
    } else if (accountState.userType == 3) {
      navigate(RoutePaths.PROFILE_IPHOLDER);
    } else {
    }
  };

  useEffect(() => {}, []);

  return (
      <div className="minHeight">
        <div className="main-container">
          <div className="px-40" style={{userSelect:"text"}}>
            <div className="text-black text-3xl	font-semibold py-7">
              {t("page.MYPAGE_my-page")}
            </div>
            <div className="text-center">
              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.IP_APPLICATION_LIST)}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_ip-application-list")}
                </button>
              </div>

              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.IP_APPROVAL_LIST)}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_ip-approve-list")}
                </button>
              </div>
              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.IP_LIST,{ state: accountState.userId })}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_ip-list")}
                </button>
              </div>
              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.NFT_APPLICATION_LIST)}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_nft-application-list")}
                </button>
              </div>

              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.NFT_APPROVE_LIST)}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_nft-approve-list")}
                </button>
              </div>
              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.NFT_CREATION_LIST,{ state: accountState.userId })}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_creat-nft-list")}
                </button>
              </div>
              <div className="py-4">
                <button
                    onClick={() => navigate(RoutePaths.NFT_BOUGHT_LIST,{ state: accountState.userId })}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_buy-nft-list")}
                </button>
              </div>
              <div className="py-4">
                <button
                    onClick={() => userInfo()}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_user-information")}
                </button>
              </div>
              <div className="py-4" style={{userSelect:"text"}}>
                <button
                    onClick={() => logout()}
                    className="w-full rounded-full border-solid border-2 border-black p-3 text-black transition duration-200 cursor-pointer hover:bg-black hover:text-white font-semibold"
                >
                  {t("page.MYPAGE_logout")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default AdminMyPage;
