import { Delete, Get, Post, Sessions } from "@/utils";

/**
 * 多言語
 */
export type NoticeInfo = {
  langvageId: number;
  externalUrl: string;
  noticeTitle: string;
};

/**
 * お知らせ一覧
 */
export type NoticeObj = {
  noticeId: null | number;
  showDate: string;
  noticeInfo: NoticeInfo[];
};

export type NoticeObj2 = {
  noticeId: null | number;
  showDate: moment.Moment;
  noticeInfo: NoticeInfo[];
};


/**
 * お知らせ一覧（普通ユーザー） データ
 */
export type NoticeData = {
  code: number;
  msg: string;
  data: NoticeObj[];
};

/**
 * お知らせ一覧（管理者） データ
 */
export type NoticeManageData = {
  data: {
    pageNum: number;
    pageSize: number;
    size: number;
    startRow: number;
    endRow: number;
    total: number;
    pages: number;
    prePage: number;
    nextPage: number;
    firstPage: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    lastPage: boolean;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    list: NoticeObj[];
  };
};

/**
 * お知らせ詳細
 */
export type NoticeDetails = {
  data: NoticeObj;
};

/**
 *お知らせ一覧（普通ユーザー）
 *
 */
export const notice_list = async (): Promise<NoticeData | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/notice/list", {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as NoticeData;
  } else {
    return null;
  }
};

/**
 *お知らせ一覧（管理者）
 *
 */
export const notice_manage_list = async (page: number): Promise<NoticeManageData | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/noticeManage/list", { page: page }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as NoticeManageData;
  } else {
    return null;
  }
};

/**
 * お知らせ詳細
 *
 * @returns {AU01}
 */
export const notice_details = async (noticeId: number): Promise<NoticeDetails | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/noticeManage/notice/" + noticeId, {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as NoticeDetails;
  } else {
    return null;
  }
};

/**
 * お知らせ削除
 * @param params
 */
export const notice_delete = async (noticeId: number): Promise<boolean> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Delete("/noticeManage/notice/" + noticeId, {
    headers: headers,
  });
  if (res.ok) {
    return true;
  } else {
    return false;
  }
};

/**
 * お知らせ登録または更新
 * @param params
 */
export const notice_registerOrUpdate = async (params: NoticeObj2): Promise<boolean> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Post("/noticeManage/update", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok) {
    return true;
  } else {
    return false;
  }
};
