import {BrowserRouter, Route, Routes } from "react-router-dom";
import {Suspense} from "react";
import App from "../App";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import NotFound from "../pages/Error/404";
import Test from "../pages/test";
import MyPage from "@/pages/Mypage/MyPage";
import NftDetail from "@/pages/NftDetail/NftDetail";
import Inquiry from "@/pages/ContactUs/Inquiry";
import Profile from "@/pages/UserInfo/Profile";
import IPRequestList from "@/pages/IP/IPRequestList";
import IPList from "@/pages/IP/IPList";
import ProfileIpholder from "@/pages/UserInfo/ProfileIpholder";
import IPForm from "@/pages/IP/IPForm";
import IPApproval from '@/pages/IP/IPApproval';
import IPFormConfirm from "@/pages/IP/IPFormConfirm";
import IPDetail from "@/pages/IP/IPDetail";
import NftApplicationList from "@/pages/NFT/NftApplicationList";
import NftApplication from "@/pages/NFT/NftApplication";
import NftApprove from "@/pages/NFT/NftApprove";
import NftApproveList from "@/pages/NFT/NftApproveList";
import NftApproveTwo from "@/pages/NFT/NftApproveTwo";
import NftCreateDetails from "@/pages/NFT/NftCreateDetails";
import NftApproveSecond from "@/pages/NFT/NftApproveSecond";
import NftApplication2 from "@/pages/NFT/NftApplication2";
import NftApplicationPay from "@/pages/NFT/NftApplicationPay";
import NftList from "@/pages/NFT/NftList";
import NftCreationList from "@/pages/NFT/NftCreationList";
import NftBoughtList from "@/pages/NFT/NftBoughtList";
import NftDetailInfo from "@/pages/NFT/NftDetaiInfo";
import NftApplicationLast from "@/pages/NFT/NftApplicationLast";
import NftExternalList from "@/pages/NFT/NftExternalList";
import AdminMyPage from "@/pages/Mypage/AdminMyPage";
import LineBarChart from "@/pages/Charts/LineBarChart";
import TreeChart from "@/components/TreeChart";
import Menu from "@/pages/Mypage/Menu";
import React from "react";
import { Spin } from "antd";
import AboutJpnft from "@/pages/Layout/AboutJpnft";
import ToEveryOne from "@/pages/Layout/ToEveryOne";
import Point from "@/pages/Layout/Point";
import PrivacyPolicy from "@/pages/Layout/PrivacyPolicy";
import CompanyProfile from "@/pages/Layout/CompanyProfile";
import Notice from "@/pages/Notice/Notice";

export enum RoutePaths {
    HOME = "/",
    LOGIN = "/login",
    REGISTER = "/register",
    PROFILE = "/profile",
    PROFILE_IPHOLDER = "/profileIpholder",
    MY_PAGE = "/mypage",
    ADMIN_MY_PAGE = "/adminMypage",
    NOT_FOUND = "/*",
    TEST = "/test",
    NFT_DETAIL = "/nftDetail/:ipNftId",
    INQUIRY = "/inquiry",
    IP_APPLICATION_LIST = "/IPApplication",
    IP_APPROVAL_LIST = "/IPApprovalList",
    NFT_APPLICATION_LIST = "/nftApplicationList",
    NFT_APPLICATION = "/nftApplication",
    NFT_APPLICATION2 = "/nftApplication2/:irRequestId",
    NFT_APPLICATION_PAY = "/nftApplicationPay/:irRequestId",
    NFT_APPROVE = "/nftApprove/:irRequestId",
    NFT_APPROVE_LIST = "/nftApproveList",
    NFT_CREATE_DETAILS = "/nftCreateDetails/:irRequestId",
    MY_IP = "myIp",
    My_NFT = "myNft",
    IP_LIST = "/IPList",
    IP_APPROVAL = "/IPApproval/:ipRequestId",
    IP_FORM = "/IPForm",
    IP_FORM_CONFIRM = "/ipFormConfirm",
    IP_DETAIL = "/ip/:ipBlockchainId",
    NFT_APPROVE_TWO="/nftApproveTwo/:irRequestId",
    NFT_APPROVE_SECOND="/nftApproveSecond/:irRequestId",
    NFT_LIST = "/nftList/:ipId",
    NFT_EXTERNAL_LIST = "/nftExternalList",
    NFT_CREATION_LIST = "/nftCreationList",
    NFT_BOUGHT_LIST = "/nftBoughtList",
    NFT_DETAIL_INFO = "/nft/:ipBlockchainId/:ipNftId",
    NFT_APPLICATION_LAST = "/nftApplicationLast/:irRequestId",
    LINEBAR_CHART="/lineBarChart",
    TREE_CHART="/treeChart",
    MENU="/menu",
    DOCUMENT = "/document/:pdfName",
    ABOUT_JPNFT="/aboutJpnft",
    TO_EVERY_ONE="/toEveryOne",
    POINT="/point",
    PRIVACY_POLICY="/privacyPolicy",
    COMPANY_PROFILE="/companyProfile",
    NOTICE="/notice"
}

const Loading = () => (
    <div className="w-full h-screen flex flex-col justify-center items-center">
        <Spin size="large" tip="Loading ..." />
    </div>
);


const PDFDisplayRoute = React.lazy(() => import("@/pages/pdf/PDFDisplay"));
const renderRouter = () => {
    return (<BrowserRouter>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home/>}></Route>
                    <Route path={RoutePaths.LOGIN} element={<Login/>}></Route>
                    <Route path={RoutePaths.REGISTER} element={<Register/>}></Route>
                    <Route path={RoutePaths.PROFILE} element={<Profile/>}></Route>
                    <Route path={RoutePaths.MY_PAGE} element={<MyPage/>}></Route>
                    <Route path={RoutePaths.NFT_DETAIL} element={<NftDetail/>}></Route>
                    <Route path={RoutePaths.INQUIRY} element={<Inquiry/>}></Route>
                    <Route path={RoutePaths.IP_APPLICATION_LIST} element={<IPRequestList/>}></Route>
                    <Route path={RoutePaths.IP_APPROVAL_LIST} element={<IPRequestList/>}></Route>
                    <Route path={RoutePaths.NFT_APPLICATION_LIST} element={<NftApplicationList/>}></Route>
                    <Route path={RoutePaths.NFT_APPLICATION} element={<NftApplication/>}></Route>
                    <Route path={RoutePaths.NFT_APPLICATION2} element={<NftApplication2/>}></Route>
                    <Route path={RoutePaths.NFT_APPLICATION_LAST} element={<NftApplicationLast/>}></Route>
                    <Route path={RoutePaths.NFT_APPROVE} element={<NftApprove/>}></Route>
                    <Route path={RoutePaths.NFT_APPROVE_LIST} element={<NftApproveList/>}></Route>
                    <Route path={RoutePaths.NFT_CREATE_DETAILS} element={<NftCreateDetails/>}></Route>
                    <Route path={RoutePaths.IP_LIST} element={<IPList/>}></Route>
                    <Route path={RoutePaths.IP_APPROVAL} element={<IPApproval/>}></Route>
                    <Route path={RoutePaths.IP_FORM} element={<IPForm/>}></Route>
                    <Route path={RoutePaths.IP_FORM_CONFIRM} element={<IPFormConfirm/>}></Route>
                    <Route path={RoutePaths.PROFILE_IPHOLDER} element={<ProfileIpholder/>}></Route>
                    <Route path={RoutePaths.IP_DETAIL} element={<IPDetail/>}></Route>
                    <Route path={RoutePaths.NFT_APPROVE_TWO} element={<NftApproveTwo/>}></Route>
                    <Route path={RoutePaths.NFT_APPROVE_SECOND} element={<NftApproveSecond/>}></Route>
                    <Route path={RoutePaths.NFT_APPLICATION_PAY} element={<NftApplicationPay/>}></Route>
                    <Route path={RoutePaths.NFT_LIST} element={<NftList/>}></Route>
                    <Route path={RoutePaths.NFT_CREATION_LIST} element={<NftCreationList/>}></Route>
                    <Route path={RoutePaths.NFT_BOUGHT_LIST} element={<NftBoughtList/>}></Route>
                    <Route path={RoutePaths.NFT_DETAIL_INFO} element={<NftDetailInfo/>}></Route>
                    <Route path={RoutePaths.NFT_EXTERNAL_LIST} element={<NftExternalList/>}></Route>
                    <Route path={RoutePaths.ADMIN_MY_PAGE} element={<AdminMyPage/>}></Route>
                    <Route path={RoutePaths.LINEBAR_CHART} element={<LineBarChart/>}></Route>
                    <Route path={RoutePaths.TREE_CHART} element={<TreeChart/>}></Route>
                    <Route path={RoutePaths.MENU} element={<Menu/>}></Route>
                    <Route path={RoutePaths.ABOUT_JPNFT} element={<AboutJpnft/>}></Route>
                    <Route path={RoutePaths.TO_EVERY_ONE} element={<ToEveryOne/>}></Route>
                    <Route path={RoutePaths.POINT} element={<Point/>}></Route>
                    <Route path={RoutePaths.PRIVACY_POLICY} element={<PrivacyPolicy/>}></Route>
                    <Route path={RoutePaths.COMPANY_PROFILE} element={<CompanyProfile/>}></Route>
                    <Route path={RoutePaths.NOTICE} element={<Notice/>}></Route>
                    <Route
                        path={RoutePaths.DOCUMENT}
                        element={<PDFDisplayRoute />}
                    ></Route>
                </Route>
                <Route path={RoutePaths.TEST} element={<Test/>}>
                </Route>
                <Route path={RoutePaths.NOT_FOUND} element={<NotFound/>}/>
            </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default renderRouter