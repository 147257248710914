import { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LineBarChart = () => {
  const chartRef: any = useRef();
  useEffect(() => {
    type EChartsOption = echarts.EChartsOption;
    var chartDom = document.getElementById("lineBarChartDiv")!;
    var myChart = echarts.init(chartDom);
    var chartDom2 = document.getElementById("lineBarChartDiv2")!;
    var myChart2 = echarts.init(chartDom2);

    const option: EChartsOption = {
      color: ["#92CC76", "#FAC758", "#B03A5B"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: false, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ["Evaporation", "Precipitation", "Temperature"],
      },
      xAxis: [
        {
          type: "category",
          axisLabel: {
            rotate: 45,
          },
          data: [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12",
          ],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Precipitation",
          min: 0,
          max: 100,
          interval: 20,
          axisLabel: {
            formatter: "{value} ml",
          },
        },
        {
          type: "value",
          name: "Temperature",
          min: 0,
          max: 28,
          interval: 4,
          axisLabel: {
            formatter: "{value} °C",
          },
        },
      ],
      series: [
        {
          name: "Evaporation",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return (value as number) + " ml";
            },
          },
          data: [
            34.0, 45.9, 67.0, 43.2, 55.6, 66.7, 40, 60.2, 52.6, 60.0, 45.4,
            24.3,
          ],
        },
        {
          name: "Precipitation",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return (value as number) + " ml";
            },
          },
          data: [
            25.6, 56.9, 58.0, 67.4, 59.7, 70.7, 43.6, 51.2, 48.7, 78.8, 63.0,
            36.3,
          ],
        },
        {
          name: "Temperature",
          type: "line",
          yAxisIndex: 1,
          smooth: true,
          label: {
            show: true,
            //   fontSize:12,
            //   color:'#070606'
            formatter: (obj: any) => {
              return obj.value + " °C";
            },
          },
          tooltip: {
            valueFormatter: function (value) {
              return (value as number) + " °C";
            },
          },
          data: [
            2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
          ],
        },
      ],
    };
    option && myChart.setOption(option);




    var chartDom2 = document.getElementById("lineBarChartDiv2")!;
    var myChart2 = echarts.init(chartDom2);
    var option2: EChartsOption;
    const waterMarkText = "RECIKA";
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    canvas.width  = 900;
    canvas.height = 600;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.08;
    ctx.font = "20px Microsoft Yahei";
    ctx.translate(50, 50);
    ctx.rotate(-Math.PI / 4);
    ctx.fillText(waterMarkText, 0, 0);

    //data
    const builderJson = {
      all: 10887,
      charts: {
        map: 3237,
        lines: 2164,
        bar: 7561,
        line: 7778,
        pie: 7355,
        scatter: 2405,
        candlestick: 1842,
        radar: 2090,
        heatmap: 1762,
        treemap: 1593,
        graph: 2060,
        boxplot: 1537,
        parallel: 1908,
        gauge: 2107,
        funnel: 1692,
        sankey: 1568,
      } as Record<string, number>
    };

    const downloadJson: Record<string, number> = {
        'echarts.min.js': 17365,
        'echarts.simple.min.js': 4079,
        'echarts.common.min.js': 6929,
        'echarts.js': 14890
      };

       option2 = {
        backgroundColor: {
          type: 'pattern',
          image: canvas,
          repeat: 'repeat'
        },
        tooltip: {},
        title: [
          {
            text: '在线构建',
            subtext: '总计 ' + builderJson.all,
            left: '15%',
            textAlign: 'center'
          },
          {
            text: '各版本下载',
            subtext:
              '总计 ' +
              Object.keys(downloadJson).reduce(function (all, key) {
                return all + downloadJson[key];
              }, 0),
            left: '75%',
            textAlign: 'center'
          },
        ],
        grid: [
          {
            top: 50,
            width: '100%',
            bottom: '5%',
            left: 10,
            containLabel: true
          }
        ],
        xAxis: [
          {
            type: 'value',
            max: builderJson.all,
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            data: Object.keys(builderJson.charts),
            axisLabel: {
              interval: 0,
              rotate: 30
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            type: 'bar',
            stack: 'chart',
            z: 3,
            label: {
              position: 'right',
              show: true
            },
            data: Object.keys(builderJson.charts).map(function (key) {
              return builderJson.charts[key];
            })
          },
          {
            type: 'bar',
            stack: 'chart',
            silent: true,
            itemStyle: {
              color: '#eee'
            },
            data: Object.keys(builderJson.charts).map(function (key) {
              return builderJson.all - builderJson.charts[key];
            })
          },
          
         
          {
            type: 'pie',
            radius: [0, '30%'],
            label: {
              show: true,
              rotate:45
            },
            center: ['75%', '25%'],
            data: Object.keys(downloadJson).map(function (key) {
              return {
                name: key.replace('.js', ''),
                value: downloadJson[key]
              };
            })
          }
        ]
      };




  
    option2 && myChart2.setOption(option2);
  }, []);

  return (
    <div className="minHeight">
      <div className="w-full mt-5">
        <div className="wull h-full justify-center items-center flex px-5 mb-60">
          <div style={{ width: 900, height: 600 }}>
            <div className="w-full flex justify-start px-10">
              <span className="font-bold text-xl text-black">降水と温度</span>
            </div>
            <div id="lineBarChartDiv" className="w-full h-full"></div>
            <div className="mt-2">
              <button className="w-48 h-12 bg-jpnft-tbFtColor rounded-3xl font-bold text-base">
                エクスポート
              </button>
            </div>
          </div>

          <div style={{ width: 900, height: 600 }}>
            <div className="w-full flex justify-start px-10">
              <span className="font-bold text-xl text-black">在线构建及各版本下载</span>
            </div>
            <div id="lineBarChartDiv2" className="w-full h-full"></div>
            <div className="mt-2">
              <button className="w-48 h-12 bg-jpnft-tbFtColor rounded-3xl font-bold text-base">
                エクスポート
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineBarChart;
