import * as echarts from "echarts";
import {useEffect} from "react";
import {getIpholderIpNftTree} from "@/services";
import i18n from "@/locales";
import {useAccountState} from "@/store";

const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
const TreeChart = () => {
    const accountState = useAccountState();

    type EChartsOption = echarts.EChartsOption;
    const chartInit = async () => {
        debugger
        if (accountState.userId){
            const res: any = await getIpholderIpNftTree(accountState.userId);
            if (res && res.data) {
                console.log(res.data)
                var chartDom = document.getElementById("treeCharts")!;
                var myChart = echarts.init(chartDom);
                const option: EChartsOption = {
                    tooltip: {
                        trigger: "item",
                        triggerOn: "mousemove",
                    },
                    series: [
                        {
                            type: "tree",
                            data: [res.data],
                            left: "2%",
                            right: "2%",
                            top: "8%",
                            bottom: "20%",
                            initialTreeDepth: undefined,
                            symbol: "emptyCircle",
                            orient: "vertical",
                            // node click zoom
                            expandAndCollapse: false,

                            label: {
                                position: "top",
                                rotate: -90,
                                verticalAlign: "middle",
                                align: "right",
                                fontSize: 9,
                                formatter: (params: any) => {
                                    let index: number = languageId ? languageId : 1;
                                    return params.data.names[index];
                                }
                            },

                            tooltip: {
                                formatter: (params: any) => {
                                    let index: number = languageId ? languageId : 1;
                                    return params.data.names[index];
                                },
                            },

                            leaves: {
                                label: {
                                    position: "bottom",
                                    rotate: -90,
                                    verticalAlign: "middle",
                                    align: "left",
                                },
                            },

                            animationDurationUpdate: 750,
                        },
                    ],
                };
                option && myChart.setOption(option);
                myChart.on("click", treeNodeClick)
            }
        }
    }
    const treeNodeClick = (node: any) => {
        console.log(new Date())
        if (node.data.itemUrl) {
            let url = node.data.itemUrl
            window.open(url,"A")
        }
        // console.log(node.event.target.culling, node.data)
        // if (node.event.target.culling) {// is parent node
        //     if (node.data.itemUrl) {
        //         window.open(node.data.itemUrl)
        //     }
        // } else {
        //     if (node.data.itemUrl) {
        //         window.open(node.data.itemUrl)
        //     }
        // }
    }
    useEffect(() => {
        chartInit();
    }, []);

    return (
        <div className="w-full min-h-screen">
            <div id="treeCharts" className="w-full h-96"></div>
        </div>
    );
};

export default TreeChart;
