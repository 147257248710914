import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {decrement, increment} from '@/store/reducers/counterSlice'

export default function Test() {
    debugger
    const count = useSelector((state: any) => state.counter.value);
    const dispatch = useDispatch()

    return (
        <div>
            <div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment {count}
                </button>
                <h1 >123 {count}</h1>
                <button
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement())}
                >
                    Decrement
                </button>
            </div>
        </div>
    )
}