import {Get, Post, Sessions} from "@/utils";

/**
 * upload photo
 * @param params
 */
export const uploadAvatar = async (params: {
    photo: any
}): Promise<any> => {
    let headers = {} as any;

    if (Sessions.read("_jpnft")) {
        headers.Authorization = Sessions.read("_jpnft");
    }

    const form = new FormData();
    form.append("photo", params.photo);

    const res = await Post("/photo/preview", {
        body: form,
        headers: headers
    });
    return res.data;
};


export const uploadLogo = async (params: {
    photo: any
}): Promise<any> => {
    let headers = {} as any;

    if (Sessions.read("_jpnft")) {
        headers.Authorization = Sessions.read("_jpnft");
    }

    const form = new FormData();
    form.append("photo", params.photo);

    const res = await Post("/photo/logo", {
        body: form,
        headers: headers
    });
    debugger
    return res.data;
};
export const uploadBanner = async (params: {
    photo: any
}): Promise<any> => {
    let headers = {} as any;

    if (Sessions.read("_jpnft")) {
        headers.Authorization = Sessions.read("_jpnft");
    }

    const form = new FormData();
    form.append("photo", params.photo);

    const res = await Post("/photo/banner", {
        body: form,
        headers: headers
    });
    return res.data;
};
