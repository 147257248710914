import { useEffect, useRef, useState } from "react";
import { Button, message, notification } from "antd";
import { RoutePaths } from "@/router";
import { Link, useNavigate } from "react-router-dom";
import { ip_holder_name, ip_royalty_request } from "@/services";
import { useAccountState } from "@/store";
import {useTranslation} from "react-i18next";
import i18n from "@/locales";


const IPRoyaltyForm = (func: any) => {
    const { t } = useTranslation();
    const account = useAccountState();
    const navigate = useNavigate();
    const [fileName, setFileName] = useState<string>("");
    const [file, setFile] = useState<File>();
    const [ipHolderName, setIpHolderName] = useState<string>("");
    const fileReF = useRef<HTMLInputElement | null>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | null>("");
    const accountState = useAccountState();

    function uploadFile() {
        if (fileReF.current) {
            fileReF.current.click();
        }
    }

    const uploadImg = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("user2"));
                return;
            }
            setFileName(fileName);
            setFile(file);
            notification.success({
                message: t("rightreqfir2"),
            })
        }
    };

    const submitIpRForm = async () => {
        if (account.isLogin) {
            if (file) {
                const params = {
                    ipBlockchainId: func.detailData.ipBlockchainId,
                    kariRequestFile: file
                }
                const res = await ip_royalty_request(params);
                if (res) {
                    console.log(res)
                    message.success(t("ipreq6"));
                    navigate(RoutePaths.NFT_APPLICATION_LIST);
                }else{
                    message.error(t("ipreq7"));
                }
            } else {
                message.error(t("rightreqfir3"));
            }
        } else {
            message.error(t("rightreqfir4"));
            navigate(RoutePaths.HOME);
        }
    }

    async function getIpHolderName() {
        const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        const params = { 'userId': func.detailData.ownerId,'languageId':languageId }
        const res = await ip_holder_name(params);
        if (res.data) {
            setIpHolderName(res.data.ipHolderName);
        }else{
            message.error(t(Object.keys(res.msg)[0]));
        }
    }

    useEffect(() => {
        getIpHolderName();
        if (accountState.avatar) {
            setAvatarUrl(accountState.avatarUrl);
        } else {
            setAvatarUrl("/sample.png");
        }
    }, []);
    return (
        <>
            <input ref={fileReF} accept="*" type="file" onChange={(event) => uploadImg(event)}
                style={{ display: "none" }} />
            <div className="flex flex-col w-full justify-center items-center">
                <div className="w-5/6 py-5" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="px-5">
                        <h1 className="content-top-center">{t("ipRoyaltyTitle")}</h1>
                    </div>
                    <div className="px-10">
                        <h1 className=" text-lg font-bold">{t("ip-holder")}</h1>
                        <div className=" text-lg">{ipHolderName}</div>
                    </div>
                    <div className="px-10 mt-5">
                        <h1 className=" text-lg font-bold">{t("ipRoyaltyName")}</h1>
                        <div className=" text-lg">{func.detailData.ipName}</div>
                    </div>
                    <div className="px-10 mt-5">
                        <h1 className=" text-lg font-bold">{t("ipRoyaltyDetails")}</h1>
                        <div className=" text-lg">{func.detailData.ipDetails}</div>
                    </div>
                </div>
                <div className="w-5/6 mt-10 py-5" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="px-5">
                        <h1 className="content-top-center">{t("applyInfo")}</h1>
                    </div>
                    <div className="flex flex-row px-10">
                        <div className="w-52 h-56" style={{ background: "#ececec", border: "1px #333333 solid" }}>
                            <img src={avatarUrl!} className="h-full cover" alt="" />
                        </div>
                        <div className="ml-5">
                            <div>
                                <div className=" text-lg font-bold">{t("inquiry3")}</div>
                                <div className=" text-lg">{accountState.userName}</div>
                            </div>
                            <div className="mt-5">
                                <div className=" text-lg font-bold">{t("royaltyDetails")}</div>
                                <div className="text-lg">{accountState.userDetails}</div>
                            </div>
                            <div className="mt-5">
                                <Link to={RoutePaths.PROFILE} className=" text-lg">{t("ipRoyaltyFile")}</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-5/6 mt-10 py-5" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="px-5">
                        <h1 className="content-top-center">{t("application-1-information")}</h1>
                    </div>
                    <div className="flex flex-row px-10">
                        <Button onClick={() => uploadFile()}> アップロード</Button>
                        <div>
                            {fileName}
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <Button onClick={() => submitIpRForm()} style={{
                        background: "#303030",
                        borderColor: "#333333",
                        padding: "0px 40px",
                        color: "rgba(255, 255, 255)",
                    }}
                        size={"large"}> {t("ipRoyaltyApply")}</Button>
                </div>
            </div>
        </>
    );
};

export default IPRoyaltyForm;