import { NetWorks } from "@/constants";
import { Actions, IAccountState } from "@/store/reducers/account";
import { message, notification } from "antd";
import { BigNumber, Contract, ethers, PopulatedTransaction } from "ethers";
import { TFunction } from "react-i18next";
import ERC721 from "@/contracts/JpnftPictures.json";
import Exchange from "@/contracts/WyvernExchange.json";
import ProxyRegister from "@/contracts/WyvernProxyRegistry.json";
import store from "@/store";
import {
  contract_addresses,
  contract_erc721Proxy,
  ip_holder_info,
  ip_holder_proxyAddress,
  nft_royalty,
  user_base_info,
  nft_details,
  nft_ipholder,
} from "@/services";
import { randomBytes } from "ethers/lib/utils";
import {
  OrderInfo,
  order_buy,
  order_contract_error,
  order_info,
  order_lock,
  order_sell,
  order_unlock,
} from "@/services/order";

declare let window: any;
const dappLink = "https://metamask.app.link/dapp/" + window.location.host + "/";
export const nullAddress = "0x0000000000000000000000000000000000000000";
export const nullHash =
  "0000000000000000000000000000000000000000000000000000000000000000";
export const maxUint256Hex =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const hexPaddingForAddress = "000000000000000000000000";

/**
 * Metamask Check
 * @returns boolean
 */
export const checkMetamask = () => {
  if (typeof window.ethereum === "undefined") {
    window.open(dappLink);
    message.warning("Metamask isn't installed!");
    return false;
  }
  return true;
};

/**
 * get JsonRpcSigner
 * @returns ethers.providers.JsonRpcSigner
 */
export const getSigner =
  async (): Promise<null | ethers.providers.JsonRpcSigner> => {
    try {
      if (!checkMetamask()) {
        return null;
      }
      await window.ethereum.request({ method: "eth_requestAccounts" });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      return signer;
    } catch (e) {
      message.error("Request provider failed!");
      return null;
    }
  };

export const checkChain = (chainId: number) => {
  const legalNetwork = Object.keys(NetWorks).find(
    (k) => parseInt(NetWorks[k]) === chainId
  );
  if (!legalNetwork) {
    return false;
  }
  return true;
};

export const numberToHex = (v: number) => {
  let val: number;
  if (typeof v == "string") {
    val = parseInt(v);
  } else {
    val = v;
  }
  // let tokenHex = ethers.utils.hexValue(val);
  const tokenHex = val.toString(16);
  const hexTemp =
    "0000000000000000000000000000000000000000000000000000000000000000" +
    tokenHex;
  return hexTemp.slice(-64);
};

const _makeOrder = (
  isBuy: boolean,
  exchange: string,
  buyer: string,
  seller: string,
  feeRecipient: string,
  tokenContract: string,
  basePrice: BigNumber,
  feeRatio: any,
  callData: string,
  replacePattern: string,
  orderType: string,
  expirationTime: number,
  paymentToken: string,
  salt: BigNumber
): { [key: string]: string | boolean | number | BigNumber } => {
  const sellKind = 0;
  const sellerTrade = orderType !== "list";
  return {
    exchange: exchange,
    maker: isBuy ? buyer : seller,
    taker: isBuy ? seller : sellerTrade ? buyer : nullAddress,
    makerRelayerFee: sellerTrade ? 0 : feeRatio,
    takerRelayerFee: sellerTrade ? feeRatio : 0,
    makerProtocolFee: 0,
    takerProtocolFee: 0,
    feeRecipient: feeRecipient,
    feeMethod: 1,
    side: isBuy ? 0 : 1,
    saleKind: sellKind,
    target: tokenContract,
    howToCall: 0,
    calldata: callData,
    replacementPattern: replacePattern,
    staticTarget: "0x0000000000000000000000000000000000000000",
    staticExtradata: "0x00",
    paymentToken: paymentToken,
    basePrice: basePrice,
    extra: 0,
    listingTime: 0,
    expirationTime: expirationTime,
    salt: salt,
  };
};

export const _hashOrder = (order: { [key: string]: any }): string => {
  const ehash = ethers.utils.solidityKeccak256(
    [
      "address",
      "address",
      "address",
      "uint",
      "uint",
      "uint",
      "uint",
      "address",
      "uint8",
      "uint8",
      "uint8",
      "address",
      "uint8",
      "bytes",
      "bytes",
      "address",
      "bytes",
      "address",
      "uint",
      "uint",
      "uint",
      "uint",
      "uint",
    ],
    [
      order.exchange,
      order.maker,
      order.taker,
      ethers.BigNumber.from(order.makerRelayerFee),
      ethers.BigNumber.from(order.takerRelayerFee),
      ethers.BigNumber.from(order.takerProtocolFee),
      ethers.BigNumber.from(order.takerProtocolFee),
      order.feeRecipient,
      order.feeMethod,
      order.side,
      order.saleKind,
      order.target,
      order.howToCall,
      order.calldata,
      order.replacementPattern,
      order.staticTarget,
      order.staticExtradata,
      order.paymentToken,
      ethers.BigNumber.from(order.basePrice),
      ethers.BigNumber.from(order.extra),
      ethers.BigNumber.from(order.listingTime),
      ethers.BigNumber.from(order.expirationTime),
      order.salt,
    ]
  );
  return ehash;
};

/**
 * 販売する
 */
export const nftSell = async (
  nftId: number,
  sellPrice: string,
  t: TFunction<"translation", undefined>,
  account: IAccountState,
  salt: any,
  orderHash: any,
  languageId: number
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let signer = provider.getSigner();
  if (!signer) {
    return;
  }
  let ethAddress = await signer.getAddress();
  let networkId = await signer.getChainId();
  const isOk = checkChain(networkId);
  if (!isOk) {
    message.error(t("please_switch_chain", { network: account.network }));
    return;
  }
  if (ethAddress !== account.address) {
    message.error(t("please_relogin"));
    return;
  }
  store.dispatch(Actions.loading());
  console.log("[ethAddress]:" + ethAddress);

  // NFT基本情報 
  const nftInfo : any = await nft_details(nftId.toString());
  if (nftInfo && nftInfo.data) {
    console.log("[nftInfo]: ok");
  }else{
    message.error(t("can not find this nft info."));
    return;
  }


  const nftipholderbase : any = await nft_ipholder(nftId.toString());
  if (nftipholderbase && nftipholderbase.data) {
    console.log("[nftipholderbase]: ok");
  }else{
    message.error(t("can not find this nft's IPHOLDER info."));
    return;
  }

  // NFT情報のコレクションからerc721を取得
  const erc721Res = await contract_erc721Proxy(nftId);
  if (
    erc721Res?.data.collectionAddress === null ||
    erc721Res?.data.collectionAddress === undefined
  ) {
    message.error("erc721Proxy is null or undefined");
    return;
  }
  console.log("[erc721Res]:", erc721Res.data.collectionAddress);
  // ERC721
  const ERC721Contract = new Contract(
    erc721Res.data.collectionAddress,
    ERC721.abi,
    signer
  );

  try {
    //step1 check proxy address
    let sullerInfo = await user_base_info(account.userId!);
    if (!sullerInfo) {
      message.error("user is not exists");
      return;
    }

    let proxyAddress = sullerInfo.data.proxyAddress;
    const addressesRes = await contract_addresses(
      parseInt(NetWorks[account.network!], 16)
    );


    const proxyRegisterAddress = addressesRes?.data["2"];
    const exchangeAddress = addressesRes?.data["3"];

    if (!proxyAddress || proxyAddress === "") {
      // message.error("proxyAddressなしのだめ、JPNFT管理員に連絡してください");
      // Create proxy address    

      console.log("[proxyRegisterAddress]:", proxyRegisterAddress);
      const proxyRegisterContract = new Contract(
        proxyRegisterAddress,
        ProxyRegister.abi,
        signer
      );

      proxyAddress = await proxyRegisterContract.proxies(ethAddress);
      if (proxyAddress === "0x0000000000000000000000000000000000000000") {
        console.log("register proxy address: ", ethAddress);

        // jpnft 一旦削除
        // let proxyUserRegister = await proxyRegisterContract.connect(signer);
        // let proxyUserRegisterTx = await proxyUserRegister.registerProxy();
        // await proxyUserRegisterTx.wait();
        // proxyAddress = await proxyRegisterContract.proxies(ethAddress);

        // ango からコピー
        let proxyUserRegisterTx = await proxyRegisterContract.registerProxy();
        await proxyUserRegisterTx.wait();
        proxyAddress = await proxyRegisterContract.proxies(ethAddress);
      }

      // Persist proxy address
      // tusertableを更新する
      const proxyAddressRes = ip_holder_proxyAddress({
        userId: account.userId,
        proxyAddress: proxyAddress,
      });
      if (!proxyAddressRes) {
        message.error(t("proxy_register_failed"));
        return;
      }
    }

    // Approval
    const approvalRes = await ERC721Contract[
      "isApprovedForAll(address,address)"
    ](ethAddress, proxyAddress);
    if (!approvalRes) {
      await ERC721Contract["setApprovalForAll(address,bool)"](
        proxyAddress,
        true
      );
    }
    //step2 make sell order
    try {
      // Exchange
      console.log("[exchangeAddress]:", exchangeAddress);
      const exchangeContract = new Contract(
        exchangeAddress,
        Exchange.abi,
        signer
      );

      // スマートコントラクトのtokenId？
      let tokenIdVar = Number(nftInfo.data.tokenId);
      let tokenIdHex = numberToHex(tokenIdVar);
      let price = ethers.utils.parseEther(sellPrice);
      console.info(`[sell] price ${price.toBigInt()}`);
      const feeRatioRes = await nft_royalty(nftId);
      const feeRatio = parseInt(feeRatioRes?.data.toString()!)*100;
      console.log("[sell] feeRatio:", feeRatio);
      let userAddressWithoutPrefix = ethAddress.slice(-40);
      const methodId = "0xb88d4fde"; // safeTransferFrom(address,address,uint256,bytes)
      let sellData =
        methodId +
        hexPaddingForAddress +
        userAddressWithoutPrefix +
        nullHash +
        tokenIdHex +
        "0000000000000000000000000000000000000000000000000000000000000080" +
        "0000000000000000000000000000000000000000000000000000000000000001" +
        nullHash;

      const sellDataMask =
        "0x00000000" +
        nullHash +
        "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" +
        nullHash +
        nullHash +
        nullHash +
        nullHash;

      salt.current = BigNumber.from(randomBytes(32));
      const sell = _makeOrder(
        false,
        exchangeContract.address,
        nullAddress,
        ethAddress,
        nftipholderbase.data.ethAddress,
        ERC721Contract.address,
        price,
        feeRatio,
        sellData,
        sellDataMask,
        "list",
        0,
        nullAddress,
        salt.current
      );

      console.log("[sell] sell info:", sell);
      orderHash.current = _hashOrder(sell);

      // add 0125 s
      // Exchange
      // let hashOrderResult = await exchangeContract.hashOrder_(
      //   [
      //     sell.exchange,
      //     sell.maker,
      //     sell.taker,
      //     sell.feeRecipient,
      //     sell.target,
      //     sell.staticTarget,
      //     sell.paymentToken,
      //   ],
      //   [
      //     sell.makerRelayerFee,
      //     sell.takerRelayerFee,
      //     sell.makerProtocolFee,
      //     sell.takerProtocolFee,
      //     sell.basePrice,
      //     sell.extra,
      //     sell.listingTime,
      //     sell.expirationTime,
      //     sell.salt,
      //   ],
      //   sell.feeMethod,
      //   sell.side,
      //   sell.saleKind,
      //   sell.howToCall,
      //   sell.calldata,
      //   sell.replacementPattern,
      //   sell.staticExtradata
      // );
      // console.log("order hash from contract api:", hashOrderResult);
      // add 0125 e


    } catch (err: any) {
      message.error("make order failed!!!");
    }
    if (!orderHash.current) {
      message.error("Make order hash failed!!!");
      return;
    }
    console.log("[orderHash]", orderHash.current);
    //step3 post order information

    message.info("販売のトランザクションが署名中です。お待ちください...");
    let signArraySell = ethers.utils.arrayify(orderHash.current);
    let signatureSell = await signer!.signMessage(signArraySell);

    console.info("[Sell] sell signature : ", signatureSell);
    const param = {
      nftId: nftId.toString(),
      orderType: 1,
      price: sellPrice.toString(),
      listingPeriod: "48",
      signature: signatureSell,
      salt: salt.current.toString(),
      networkId: networkId,
      userId: account.userId!,
    };
    try {
      let orderSellRes = await order_sell(param);
      if (orderSellRes) {
        notification.success({
          message: "販売のトランザクションが作成完了しています。",
        });
        return orderSellRes;
      } else {
        message.error("order sell request failed !!!");
      }
    } catch (err: any) {
      console.log(err);
    }
    //step4 refresh to fetch new status
    // navigate(RoutePaths.HOME);
  } catch (err: any) {
    message.error(err);
  } finally {
    store.dispatch(Actions.done());
  }
};

/**
 * 購入する
 */
export const nftBuy = async (
  nftId: number,
  sellPrice: number,
  t: TFunction<"translation", undefined>,
  account: IAccountState,
  languageId: number
) => {
  try {
    // 1. check account info
    if (!nftId) {
      console.log("[warn] nftId情報が取得失敗していました。");
      message.warning("[warn] nftId情報が取得失敗していました。");
      return;
    }

    if (!account.network || !account.chain) {
      console.log("Initialaze data load failed, please refresh current page again!");
      message.warning(
        "Initialaze data load failed, please refresh current page again!"
      );
      return;
    }

    // NFT基本情報 
    const nftInfo : any = await nft_details(nftId.toString());
    if (nftInfo && nftInfo.data) {
      console.log("[nftInfo]: ok");
    }else{
      console.log("can not find this nft info.");
      message.error(t("can not find this nft info."));
      return;
    }
  
  
    const nftipholderbase : any = await nft_ipholder(nftId.toString());
    if (nftipholderbase && nftipholderbase.data) {
      console.log("[nftipholderbase]: ok");
    }else{
      console.log("can not find this nft's IPHOLDER info.");
      message.error(t("can not find this nft's IPHOLDER info."));
      return;
    }

    store.dispatch(Actions.loading());
    // 2. check balance
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    if (!signer) {
      return;
    }
    const balance = await signer.getBalance();
    if (parseFloat(ethers.utils.formatEther(balance)) <= sellPrice) {
      console.log("Balance of ETH is insufficient!");
      notification.warning({
        message: "Balance of ETH is insufficient!",
      });
      return;
    }

    const buyerAddress = await signer.getAddress();
    const networkId = await signer.getChainId();

    // NFT情報のコレクションからerc721を取得
    const erc721Res = await contract_erc721Proxy(nftId);
    if (
      erc721Res?.data.collectionAddress === null ||
      erc721Res?.data.collectionAddress === undefined
    ) {
      console.log("erc721Proxy is null or undefined");
      message.error("erc721Proxy is null or undefined");
      return;
    }

    console.log("[erc721Res]:", erc721Res.data.collectionAddress);
    // ERC721
    const ERC721Contract = await new Contract(
      erc721Res.data.collectionAddress,
      ERC721.abi,
      signer
    );

    // スマートコントラクトアドレス
    const addressesRes = await contract_addresses(
      parseInt(NetWorks[account.network!], 16)
    );
    // Exchange
    const exchangeAddress = addressesRes?.data["3"];

    const exchangeContract = await new Contract(
      exchangeAddress,
      Exchange.abi,
      signer
    );

    // lock order
    const orderLockRes = await order_lock({
      nftId: nftId.toString(),
      networkId: account.chain,
    });
    if (!orderLockRes) {
      console.log("Order locked failed!!!");
      notification.error({
        message: "Order locked failed!!!",
      });
      return;
    }
    if (!orderLockRes.data){
      console.log("Order locked failed!!!");
    } else {
      console.info(`[Lock Order] Order has been locked succeed!`);
    }
    const fetchOrderInfo = async (
      orderId: number,
      nftId: number,
      networkId: number
    ): Promise<OrderInfo | null> => {
      if (networkId) {
        let res = await order_info({
          orderId: orderId,
          nftId: nftId.toString(),
          networkId: networkId,
        });
        return res;
      }
      return null;
    };
    const orderLocked = await fetchOrderInfo(
      orderLockRes.data,
      nftId,
      parseInt(NetWorks[account.network!], 16)
    );
    if (orderLocked == null) {
      console.log("can not find nft sale infomation");
      message.error("can not find nft sale infomation");
      return;
    }

    // Buy
    const price = ethers.utils.parseEther(orderLocked.data.price.toString());
    console.info(`[Buy] price ${price.toBigInt()}`);
    const feeRatioRes = await nft_royalty(nftId);
    const feeRatio = parseInt(feeRatioRes?.data.toString()!)*100;
    console.info(`[Buy] feeRatio ${feeRatio}`);
    
    // const fee = price.mul(feeRatio).div(100);
    // console.info(`[Buy] fee ${fee.toBigInt()}`);
    
    const sullerInfo = await user_base_info(orderLocked.data.makerId!);
    if (!sullerInfo) {
      console.log("ipholderInfo is null");
      message.error("ipholderInfo is null");
      return;
    }
    const sellerAddressWithoutPrefix = sullerInfo.data.ethAddress.slice(-40);
    const buyerAddressWithoutPrefix = buyerAddress.slice(-40);
    console.info(
      `[Buy] seller: ${sellerAddressWithoutPrefix} buyer: ${buyerAddressWithoutPrefix} fee account: ${sullerInfo.data.ethAddress}`
    );

    const methodId = "0xb88d4fde"; //safeTransferFrom(address,address,uint256,bytes)
    const tokenNoHex = numberToHex(Number(nftInfo.data.tokenId));
    const buyData =
      methodId +
      nullHash +
      hexPaddingForAddress +
      buyerAddressWithoutPrefix +
      tokenNoHex +
      "0000000000000000000000000000000000000000000000000000000000000080" +
      "0000000000000000000000000000000000000000000000000000000000000001" +
      nullHash;

    const sellData =
      methodId +
      hexPaddingForAddress +
      sellerAddressWithoutPrefix +
      nullHash +
      tokenNoHex +
      "0000000000000000000000000000000000000000000000000000000000000080" +
      "0000000000000000000000000000000000000000000000000000000000000001" +
      nullHash;

    const buyDataMask =
      "0x00000000" +
      "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" +
      nullHash +
      nullHash +
      nullHash +
      nullHash +
      nullHash;

    const sellDataMask =
      "0x00000000" +
      nullHash +
      "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff" +
      nullHash +
      nullHash +
      nullHash +
      nullHash;

    const saltValue = BigNumber.from(orderLocked.data.sellSalt);
    const buy = _makeOrder(
      true,
      exchangeContract.address,
      buyerAddress,
      sullerInfo.data.ethAddress,
      nullAddress,
      ERC721Contract.address,
      price,
      feeRatio,
      buyData,
      buyDataMask,
      "list",
      0,
      nullAddress,
      saltValue
    );
    const sell = _makeOrder(
      false,
      exchangeContract.address,
      nullAddress,
      sullerInfo.data.ethAddress,
      nftipholderbase.data.ethAddress,
      ERC721Contract.address,
      price,
      feeRatio,
      sellData,
      sellDataMask,
      "list",
      0,
      nullAddress,
      saltValue
    );

    console.log("[Buy] sell info:", sell);
    console.log("[Buy] buy info:", buy);

    const orderBuyHash = _hashOrder(buy);
    const orderSellHash = _hashOrder(sell);

    console.info("[Buy] buy order hash : ", orderBuyHash);
    console.info("[Buy] sell order hash : ", orderSellHash);

    const signArrayBuy = ethers.utils.arrayify(orderBuyHash);
    console.info("[Buy] signArrayBuy: ", signArrayBuy);

    const signatureBuy = await signer.signMessage(signArrayBuy);
    console.info("[Buy] signatureBuy: ", signatureBuy);

    const signatureSell = orderLocked.data.makerSig;
    console.info("[Buy] signatureSell: ", signatureSell);

    const sigSplitBuy = ethers.utils.splitSignature(signatureBuy);
    const sigSplitSell = ethers.utils.splitSignature(signatureSell);
    console.info("[Buy] sigSplitBuy:", sigSplitBuy);
    console.info("[Buy] sigSplitSell:", sigSplitSell);

    const txMatchPopData: PopulatedTransaction =
      await exchangeContract.populateTransaction.atomicMatch_(
        [
          buy.exchange,
          buy.maker,
          buy.taker,
          buy.feeRecipient,
          buy.target,
          buy.staticTarget,
          buy.paymentToken,
          sell.exchange,
          sell.maker,
          sell.taker,
          sell.feeRecipient,
          sell.target,
          sell.staticTarget,
          sell.paymentToken,
        ],
        [
          buy.makerRelayerFee,
          buy.takerRelayerFee,
          buy.makerProtocolFee,
          buy.takerProtocolFee,
          buy.basePrice,
          buy.extra,
          buy.listingTime,
          buy.expirationTime,
          buy.salt,
          sell.makerRelayerFee,
          sell.takerRelayerFee,
          sell.makerProtocolFee,
          sell.takerProtocolFee,
          sell.basePrice,
          sell.extra,
          sell.listingTime,
          sell.expirationTime,
          sell.salt,
        ],
        [
          buy.feeMethod,
          buy.side,
          buy.saleKind,
          buy.howToCall,
          sell.feeMethod,
          sell.side,
          sell.saleKind,
          sell.howToCall,
        ],
        buy.calldata,
        sell.calldata,
        buy.replacementPattern,
        sell.replacementPattern,
        buy.staticExtradata,
        sell.staticExtradata,
        [sigSplitBuy.v, sigSplitSell.v],
        [
          sigSplitBuy.r,
          sigSplitBuy.s,
          sigSplitSell.r,
          sigSplitSell.s,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
        { value: price }
      );
    console.log("[populate tx match]", txMatchPopData);
    try {
      const txMatch = await exchangeContract.atomicMatch_(
        [
          buy.exchange,
          buy.maker,
          buy.taker,
          buy.feeRecipient,
          buy.target,
          buy.staticTarget,
          buy.paymentToken,
          sell.exchange,
          sell.maker,
          sell.taker,
          sell.feeRecipient,
          sell.target,
          sell.staticTarget,
          sell.paymentToken,
        ],
        [
          buy.makerRelayerFee,
          buy.takerRelayerFee,
          buy.makerProtocolFee,
          buy.takerProtocolFee,
          buy.basePrice,
          buy.extra,
          buy.listingTime,
          buy.expirationTime,
          buy.salt,
          sell.makerRelayerFee,
          sell.takerRelayerFee,
          sell.makerProtocolFee,
          sell.takerProtocolFee,
          sell.basePrice,
          sell.extra,
          sell.listingTime,
          sell.expirationTime,
          sell.salt,
        ],
        [
          buy.feeMethod,
          buy.side,
          buy.saleKind,
          buy.howToCall,
          sell.feeMethod,
          sell.side,
          sell.saleKind,
          sell.howToCall,
        ],
        buy.calldata,
        sell.calldata,
        buy.replacementPattern,
        sell.replacementPattern,
        buy.staticExtradata,
        sell.staticExtradata,
        [sigSplitBuy.v, sigSplitSell.v],
        [
          sigSplitBuy.r,
          sigSplitBuy.s,
          sigSplitSell.r,
          sigSplitSell.s,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
        { value: price }
      );

      const param = {
        orderId: orderLocked.data.orderId,
        userId: account.userId!,
        signature: signatureBuy,
        salt: orderLocked.data.sellSalt,
        networkId: networkId,
        txHash: txMatch.hash,
      };
      const orderBuyRes = await order_buy(param);
      if (!orderBuyRes) {
        message.error("order buy request failed!!!");
        return;
      }

      // mine block
      txMatch.wait();
      console.log("tx match", txMatch);

      store.dispatch(Actions.refresh());
      notification.success({
        message: "Congratulations, you have successfully purchased.",
      });
    } catch (e) {
      console.error(e);
      const params = {
        userId: account.userId!,
        orderId: orderLocked.data.orderId!,
      };

      const res = await order_unlock(params);
      if (res) {
        console.log("オーダー情報をアンロックしました");
      } else {
        console.error("オーダー情報をアンロックに失敗しました！");
      }
      const erc721Res = await contract_erc721Proxy(nftId);
      if (
        erc721Res?.data.collectionAddress === null ||
        erc721Res?.data.collectionAddress === undefined
      ) {
        console.log("erc721Proxy is null or undefined");
        message.error("erc721Proxy is null or undefined");
        return;
      }
      order_contract_error({
        contractAddress: erc721Res?.data.collectionAddress,
        orderId: orderLocked.data.orderId, //  注文ID
        caller: buyerAddress, //  呼び出し元アドレス
        parameter: txMatchPopData.data ?? "", //  パラメータ
        networkId: networkId, //	ネットワークID
        userId: account.userId!,
      });
      console.log("Failed last");
      notification.error({
        message: `Failed! ${(e as Error).message}`,
      });
      return;
    }
  } catch (e) {
    message.info("Wallet connection was canceled!");
    console.log("error:", e);
  } finally {
    store.dispatch(Actions.done());
  }
};
export const metaMaskSigner = async (hash: string) => {
  let signer = await getSigner();
  if (signer) {
    return await signer.signMessage(hash);
  }
};
