import { Get, Sessions } from "@/utils";

export type GlobalInfoType = {
  feeRatio: number; // 手数料率
  feeAccount: string; // 手数料徴収アドレス
  priceUSD: string; // ETHUSD価格
  priceJPY: string; // ETHJPY価格
  address721: string; // ERC721アドレス
  addressExchange: string; // 取引アドレス
  addressRegistry: string; // プロキシアドレス
  addressTokenTransfer: string; // トークン移転アドレス
  addressWETH: string; // WETH変換アドレス
};

export type AccountInfoType = {
  name: string;
  address: string;
  email: string;
  phone: string;
  ethAddress: string;
  proxyAddress: string;
  city: string; // 市
  state: string; // 州
  country: string; //  国
  postCode: string; // 郵便番号
};


/**
 * globalInfo
 * @returns {GlobalInfoType}
 */
export const get_globalInfo = async (
  networkId: number // ネットワークID
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/globalInfo",  { networkId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as GlobalInfoType;
  } else {
    return JSON.parse(res.error);
  }
};


/**
 * アカウント情報取得
 * @returns {GlobalInfoType}
 */
 export const get_accountInfo = async (
  params: { ethAddress: string; networkId: number },
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/user/account", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as AccountInfoType;
  } else {
    return JSON.parse(res.error);
  }
};


/**
 * ユーザ基本情報
 *
 * @returns {UserInfo}
 */
 export const user_base_info = async (
  userId: number | undefined
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/account/baseInfo/" + userId, {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
     return list;
  }
};



