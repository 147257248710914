import {Get, Post, Sessions} from "@/utils";
import { type } from "os";

type NftRecommendItemType = {
  recommendId: number;
  ipNftId: number;
  recommendIndex: number;
  ipId: number;
  collectionId: number;
  nftDetails: string;
  nftImage: string;
};
export type NftRecommendListType = {
  data: {
    endRow: number;
    firstPage: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    lastPage: boolean;
    pageNum: number;
    pageSize: number;
    pages: number;
    prePage: number;
    size: number;
    startRow: number;
    total: number;
    list: NftRecommendItemType[];
  };
};

export const nft_recommend_list = async (
  page: number
): Promise<NftRecommendListType | null | any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/nft/nftRecommendList", { page: page }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

type Royalty = {
  data: number;
};
export const nft_royalty = async (
    nftId: number
): Promise<Royalty | null | any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/nft/royalty", { nftId: nftId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as Royalty;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};
type TIpNftInfoType ={
  nftName:string;
  nftImage:string;
  externalUrl:string;
  externalWebsite:string;
  nftDetails:string;
  ipNftId:number;
  langvageId:number;
  createdTime:string;
}
type IpholderInfoType ={
  ipholderName:string;
}
type IpInfo ={
  ipName:string;
}
export type NftDetails = {
  data: {
    ipNftId: number;
    ipId: number;
    collectionId: number;
    creatorId: number;
    ownerId: number;
    nftImage: string;
    metadataUrl: string;
    saleStatus: number;
    externalUrl: string;
    certificationStatus: number;
    oyalty: number;
    delFlg: number;
    creator: string;
    //作成者
    creatorName: string;
    //所有者
    ownerName: string;
    //IP所有者
    ipOwnerName: string;
    created: string;
    updater: string;
    tokenId: number;
    tokenType: string;
    nftName: string;
    nftDetails: string;
    royalty: number;
    royalty2: number;
    royaltyN: number;
    price: number;
    ethAddress: string;
    tipNftInfos: TIpNftInfoType[];
    //IP所有者（ipholder）
    ipholderInfos: IpholderInfoType[];
    //NFT所有者（ipholder）
    ownerIpholderInfos: IpholderInfoType[];
    //作成者（ipholder）
    creatorIpholderInfos: IpholderInfoType[];
    tipInfos: IpInfo[];
    ipTransactionUrl: string;
    ipOwnerAddress: string;
    userName: string;
    contractAddress: string;
    createdTime:string;
  };
};

export const nft_details = async (
  nftId: string | undefined
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/nft/details", { nftId: nftId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as NftDetails;
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * 更新t_ir_chat
 * */
export const nft_update_chat = async (params: any): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Post("/nft/updateChat", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok) {
    return res;
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
     return list;
  }
};

export type NftMessage = {
  data: {
    chatInfo: string;
  };
};
/**
 * 查询t_ir_chat
 * */
export const nft_get_chat = async (
    irRequestId: number | undefined
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/nft/getChat", { irRequestId: irRequestId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
     return list;
  }
}

/**
 * NFT一覧
 *
 * @returns {AU01}
 */
export const nft_list = async (
    saleStatus: string | undefined,
    keyWord: string | undefined,
    userId: string | undefined,
    isBought: boolean | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {
    saleStatus: saleStatus,
    keyWord: keyWord,
    userId: userId,
    isBought: isBought
  };
  const res = await Get("/nft/list", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * NFT买入一覧
 *
 * @returns {AU01}
 */
export const nft_bought_list = async (
    saleStatus: string | undefined,
    keyWord: string | undefined,
    userId: string | undefined,
    isBought: boolean | undefined,
    languageId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {};
  if (userId === "") {
    params = {
      saleStatus: saleStatus,
      keyWord: keyWord,
      isBought: isBought,
      languageId: languageId,
    };
  } else {
    params = {
      saleStatus: saleStatus,
      keyWord: keyWord,
      userId: userId,
      isBought: isBought,
      languageId: languageId,
    };
  }
  const res = await Get("/nft/bought/list", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * 外部NFT一覧
 *
 * @returns {AU01}
 */
export const nft_external_list = async (
    keyWord: string | undefined,
    ipId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {
    ipId: ipId,
    keyWord: keyWord
  };
  const res = await Get("/nft/external", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * 作成NFT一覧
 *
 * @returns {AU01}
 */
export const nft_listGroup = async (
    keyWord: string | undefined,
    userId: string | undefined,
    languageId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params: any = {};
  if (userId === "") {
    params = {
      keyWord: keyWord,
      languageId: languageId,
    };
  } else {
    params = {
      keyWord: keyWord,
      languageId: languageId,
      userId: userId,
    };
  }
  const res = await Get("/nft/listGroup", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key:item,
      value:''
    }) );
    return list;
  }
};

export const nft_ipholder = async (
  nftId: string | undefined
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/nft/ipholder", { nftId: nftId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as NftDetails;
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key:item,
      value:''
    }) );
    return list;
  }
};

/**
 * NFT cancel sell
 *
 */
export const nft_cancel_sell = async (ipNftId: number): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {
    ipNftId: ipNftId,
  };
  const res = await Get("/nft/cancelSell", params, headers);
  if (res.ok) {
    return true;
  } else {
    return false;
  }
};

/**
 * 最新NFT购入一覧
 *
 * @returns {AU01}
 */
export const bought_nft_list = async (
    userId: string | undefined,
    languageId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {};
  if (userId === "") {
    params = {
      languageId: languageId,
    };
  } else {
    params = {
      userId: userId,
      languageId: languageId,
    };
  }
  const res = await Get("/nft/boughtNftList", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * 他の商品NFT一覧
 *
 * @returns {AU01}
 */
export const ip_nft_list = async (
    saleStatus: string | undefined,
    keyWord: string | undefined,
    ipId: string | undefined,
    languageId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {};
  params = {
    saleStatus: saleStatus,
    keyWord: keyWord,
    ipId: ipId,
    languageId: languageId,
  };
  const res = await Get("/nft/ipNftList", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};
