import {Button, message, notification} from "antd";
import {getRandomNum, ip_file_download, ip_request} from "@/services";
import {RoutePaths} from "@/router";
import {useLocation, useNavigate} from "react-router-dom";
import {metaMaskSigner} from "@/utils/web3";
import IpHolderInfo from "@/components/IpHolderInfo";
import {getHash} from "@/utils/hash";
import {ipBannerUrl, ipLogoUrl} from "@/constants";
import TextArea from "antd/lib/input/TextArea";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Menu from "@/pages/Mypage/Menu";
import {useEffect, useState} from "react";
import i18n from "@/locales";

const IPFormConfirm = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const data: any = location.state;
    let [status, setStatus] = useState<string>('1');
    let [status1, setStatus1] = useState<string>('1');
    let [status2, setStatus2] = useState<string>('1');

    const [showMenu,setShowMenu] = useState<boolean>(data.showMenu);
    const onFinish = async () => {
        const res01 = await getRandomNum();
        const hashParam = {
            ipName: data?.ipName,
            ipTag: data?.ipTag,
            systemDateTime: new Date().toISOString(),
            randomNum: res01.data.randomCode,
        };
        // @ts-ignore
        const hash = getHash(hashParam);
        const sign = await metaMaskSigner(hash);
        let param: any = {};
        param.form = data;
        param.form["ipHash"] = hash;
        param.form["hashSystem"] = hashParam.systemDateTime;
        param.form["hashSalt"] = hashParam.randomNum;
        param.form["requestSig"] = sign;

        const res = await ip_request(param);
        if (res) {
            notification.success({
                message: t("msg.IPREQ_6"),
            });
            navigate(RoutePaths.IP_APPLICATION_LIST,{state:showMenu});
        } else {
            notification.error({
                message: t("msg.IPREQ_7"),
            });
        }
    };

    const edit = async () => {
        const paramData = {
            ipLogo:  data?.ipLogo,
            ipBanner:  data?.ipBanner,
            ipInfo:data?.ipInfo,
            ipFiles:data?.ipFiles,
            files:data?.files,
            nftRequestFalg:  data?.nftRequestFalg,
            nftExternalFalg:  data?.nftExternalFalg,
            ipTag:  data?.ipTag,
            showMenu:showMenu,
        };
        navigate(RoutePaths.IP_FORM, {state: paramData});
    };

    const downloadFile = async (fileId: string, fileName: string) => {
        if (!fileId) {
            return;
        }
        const fName: string = fileName;
        const result = await ip_file_download(fileId);
        if (result && result.code > 0) {
            let msg = Object.keys(result.msg)[0];
            message.error(msg);
            return;
        }
        if (result) {
            var blob = new Blob([result]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = fName;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
        }
    }
    const onChangeTo  = async (language:string,status:number) => {
        if(status==1){
            if(language=="JP"){
                setStatus("1");
            }else if(language=="EN"){
                setStatus("2");
            }else{
                setStatus("3");
            }
        }else if(status==2){
            if(language=="JP"){
                setStatus1("1");
            }else if(language=="EN"){
                setStatus1("2");
            }else{
                setStatus1("3");
            }
        }else{
            if(language=="JP"){
                setStatus2("1");
            }else if(language=="EN"){
                setStatus2("2");
            }else{
                setStatus2("3");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="minHeight">
            <div className={clsx(showMenu ? "main-container flex" : "main-container")}>
                {showMenu &&
                    <Menu/>
                }
                <div className={clsx(showMenu ? "w-4/5 text-center pl-10 pr-30 " : "px-40")}>
                    <div className="fs_h2 text-3xl py-7" style={{userSelect:"text"}}>{t("page.IPCONFIRM_confirm-ip-information")}</div>
                    <div className="w-full mt-5">
                        <div className="rounded-xl">
                            <div className="w-full text-left fs_h3 pt-5" style={{userSelect:"text"}}>{t("page.IPCONFIRM_ip-information")}</div>
                            <div className="flex w-full mt-5" style={{height: "auto"}}>
                                <div className="flex flex-col h-full w-1/3 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l" style={{userSelect:"text"}}>
                                    <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {t("page.IPCONFIRM_ip-holder-picture")}<span className="ml-2 text-red-500">※</span>
                                        </div>
                                    </div>
                                    <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {t("page.IPCONFIRM_ip-banner")}<span className="ml-2 text-red-500">※</span>
                                        </div>
                                    </div>
                                    <div className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {t("page.IPCONFIRM_type-of-ip")}
                                            <span className="ml-2 text-red-500">※</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white flex flex-col h-full w-2/3 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                                    <div
                                        className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {/* 作品の代表画像 */}
                                            <div className="flex w-full h-full items-center">
                                                <img
                                                    src={ipLogoUrl + "/" + data?.ipLogo}
                                                    alt=""
                                                    style={{width: "150px", height: "160px"}}
                                                    className="rounded-lg"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="w-full border-b border-jpnft-border px-5 h-56 w-96 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {/* 作品のバナー画像 */}
                                            <div className="flex w-full h-full items-center">
                                                <img
                                                    src={ipBannerUrl + "/" + data?.ipBanner}
                                                    alt=""
                                                    style={{height: "160px"}}
                                                    className="w-full max-h-full rounded-lg"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center"  style={{userSelect:"text"}}>
                                        <div className="text-base text-jpnft-tbFtColor font-semibold" style={{fontSize:"14px"}}>
                                            {/* 著作物の種類 */}
                                            {data?.ipTag.includes("1") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_cartoons")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("2") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_anime")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("3") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2"
                                                    style={{

                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_pictures")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("4") &&
                                                <div
                                                    className="text-center rounded-3xl w-30 h-8 mx-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_characters")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("5") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_games")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("6") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_illustrations")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("7") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2 mt-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_music")}
                                                </div>
                                            }
                                            {data?.ipTag.includes("8") &&
                                                <div
                                                    className="text-center rounded-3xl w-20 h-8 mx-2 mt-2"
                                                    style={{
                                                        backgroundColor: "#ff8a00",
                                                        color: "white",
                                                        lineHeight:"32px",
                                                        display:"inline-block",
                                                        borderRadius: "5px",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {t("page.HOME_other")}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-10" style={{userSelect:"text"}}>
                                <Button
                                    onClick={() => onChangeTo("JP",1)}
                                    className="fs_18_bold"
                                    style={{
                                        backgroundColor: clsx(status=="1" ? "#d4f6ff" : "white"),
                                        borderColor: clsx(status=="1" ? "#d4f6ff" : "#c7cdce"),
                                        color: "#333333",
                                        borderRadius:"10px 10px 0 0",
                                        height:"40px",
                                        width:"95px",
                                        fontWeight:"800",
                                        marginRight:"10px",
                                        userSelect:"text"
                                    }}>
                                    日本語
                                </Button>
                                <Button
                                    onClick={() => onChangeTo("EN",1)}
                                    className="fs_18_bold"
                                    style={{
                                        backgroundColor: clsx(status=="2" ? "#d4f6ff" : "white"),
                                        borderColor: clsx(status=="2" ? "#d4f6ff" : "#c7cdce"),
                                        color: "#333333",
                                        borderRadius:"10px 10px 0 0",
                                        height:"40px",
                                        width:"95px",
                                        marginRight:"10px",
                                        fontWeight:"800",
                                        userSelect:"text"
                                    }}>
                                    English
                                </Button>
                                <Button
                                    onClick={() => onChangeTo("ZH",1)}
                                    className="fs_18_bold"
                                    style={{
                                        backgroundColor: clsx(status=="3" ? "#d4f6ff" : "white"),
                                        borderColor: clsx(status=="3" ? "#d4f6ff" : "#c7cdce"),
                                        color: "#333333",
                                        borderRadius:"10px 10px 0 0",
                                        marginRight: "570px",
                                        height:"40px",
                                        width:"95px",
                                        fontWeight:"800",
                                        userSelect:"text"
                                    }}>
                                    中文
                                </Button>
                            </div>
                            { status=="1"?
                                <div style={{background: "#d4f6ff",color:"#333333",userSelect:"text"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                                    <div className="py-2">
                                        <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                                        <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].ipName}</div>
                                    </div>
                                    <div className="py-2">
                                        <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                                        <TextArea readOnly
                                                  className="fs_16 pl-5 mt-3"
                                                  value={data?.ipInfo[parseInt(status)-1].ipDetails} rows={4}
                                                  style={{
                                                      borderRadius:"15px",
                                                      backgroundColor: "white",
                                                      resize: "none",
                                                  }}
                                        />
                                    </div>
                                    <div className="py-2">
                                        <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                                        <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].externalUrl}</div>
                                    </div>
                                    <div className="pt-2" style={{paddingBottom:"40px"}}>
                                        <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                                        <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].twitterUrl}</div>
                                    </div>
                                </div>
                                :status=="2"?
                                    <div style={{background: "#d4f6ff",color:"#333333",userSelect:"text"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                                            <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].ipName}</div>
                                        </div>
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                                            <TextArea readOnly
                                                      className="fs_16 pl-5 mt-3"
                                                      value={data?.ipInfo[parseInt(status)-1]?.ipDetails} rows={4}
                                                      style={{
                                                          borderRadius:"15px",
                                                          backgroundColor: "white",
                                                          resize: "none",
                                                      }}
                                            />
                                        </div>
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].externalUrl}</div>
                                        </div>
                                        <div className="pt-2" style={{paddingBottom:"40px"}}>
                                            <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].twitterUrl}</div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{background: "#d4f6ff",color:"#333333",userSelect:"text"}} className="text-left rounded-r-xl rounded-bl-xl px-10 pt-10 mb-10">
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_name")}<span style={{color: "red"}}>※</span></div>
                                            <div className="h-auto pl-5 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].ipName}</div>
                                        </div>
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_summary")}<span style={{color: "red"}}>※</span></div>
                                            <TextArea readOnly
                                                      className="fs_16 pl-5 mt-3"
                                                      value={data?.ipInfo[parseInt(status)-1].ipDetails} rows={4}
                                                      style={{
                                                          borderRadius:"15px",
                                                          backgroundColor: "white",
                                                          resize: "none",
                                                      }}
                                            />
                                        </div>
                                        <div className="py-2">
                                            <div className="fs_16_bold">{t("page.IPREQUEST_website")}</div>
                                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].externalUrl}</div>
                                        </div>
                                        <div className="pt-2" style={{paddingBottom:"40px"}}>
                                            <div className="fs_16_bold">{t("page.IPREQUEST_twitter")}</div>
                                            <div className="h-auto pl-3 mt-3" style={{border:"3px solid #EFEFEF",borderRadius:"15px",backgroundColor: "white",minHeight:"36px"}}>{data?.ipInfo[parseInt(status)-1].twitterUrl}</div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="rounded-xl mt-10 pb-10" style={{userSelect:"text"}}>
                            <div className="w-full text-left fs_h3 pt-5">{t("page.IPAPPROVAL_license-information")}</div>

                            <div className="flex w-full mt-5" style={{height: "auto"}}>
                                <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                                    <div
                                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {t("page.IPAPPROVAL_license-for-nft-sure")}<span className="ml-2 text-red-500">※</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                                    <div
                                        className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {/* NFTライセンス情報 */}
                                            {data?.nftRequestFalg == "1" ? t("page.IPAPPROVAL_yes") : t("page.IPAPPROVAL_no")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {data?.nftRequestFalg == "1" &&
                                <>
                                    <div className="pt-10" style={{userSelect:"text"}}>
                                        <Button
                                            onClick={() => onChangeTo("JP", 2)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status1 == "1" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "1" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                height: "40px",
                                                width: "95px",
                                                fontWeight: "800",
                                                marginRight: "10px",
                                                userSelect:"text"
                                            }}>
                                            日本語
                                        </Button>
                                        <Button
                                            onClick={() => onChangeTo("EN", 2)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status1 == "2" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "2" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                height: "40px",
                                                width: "95px",
                                                marginRight: "10px",
                                                fontWeight: "800",
                                                userSelect:"text"
                                            }}>
                                            English
                                        </Button>
                                        <Button
                                            onClick={() => onChangeTo("ZH", 2)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status1 == "3" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "3" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                marginRight: "570px",
                                                height: "40px",
                                                width: "95px",
                                                fontWeight: "800",
                                                userSelect:"text"
                                            }}>
                                            中文
                                        </Button>
                                    </div>
                                    <div className="flex w-full" style={{height: "auto"}}>
                                        <div
                                            className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            <>
                                                <div
                                                    className="w-full  border-jpnft-border px-5 h-28 flex justify-start items-center">
                                                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {t("page.IPCONFIRM_important-information-for-nft-creation")}<span
                                                        className="ml-2 text-red-500">※</span>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                        <div
                                            className="flex flex-col h-full w-2/5 rounded-br-3xl rounded-tr-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            <>
                                                <div
                                                    className="w-full  border-jpnft-border px-5 h-28 flex justify-start items-center">
                                                    <div
                                                        className="w-full text-base text-jpnft-tbFtColor font-semibold text-left break-words">
                                                        {/* NFT作成に関する特記事項 */}
                                                        <TextArea readOnly
                                                                  className="fs_16_bold"
                                                                  value={data?.ipInfo[parseInt(status1)-1].ipContent} rows={4}
                                                                  style={{
                                                                      borderColor: "white",
                                                                      resize: "none",
                                                                      padding: "4px 0px"
                                                                  }}/>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                    <div className="flex w-full mt-5" style={{height: "auto"}}>
                                        <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            <div
                                                className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                    {t("page.IPCONFIRM_usage-rights")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            <div
                                                className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                    {/* 利用権限*/}
                                                    {data?.ipInfo[parseInt(status1)-1].ipUsage === "2" ? i18n.language==="ja"?"申請性":i18n.language==="en"?"Apply for License":"申请性" : i18n.language==="ja"?"許諾性":i18n.language==="en"?"Licensable":"许可性"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="rounded-xl mt-10 pb-10" style={{userSelect:"text"}}>
                            <div className="w-full text-left fs_h3 pt-5">{t("page.IPAPPROVAL_registration-of-nft")}</div>
                            <div className="flex w-full mt-5" style={{height: "auto"}}>
                                <div className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-tl-3xl rounded-bl-3xl text-left border border-jpnft-border border-t border-b border-l">
                                    <div
                                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {t("page.IPAPPROVAL_registration-of-nft-info")}<span className="ml-2 text-red-500">※</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                                    <div
                                        className="w-full　border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                            {/* NFTライセンス情報 */}
                                            {data?.nftExternalFalg == "1" ? t("page.IPAPPROVAL_yes") : t("page.IPAPPROVAL_no")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {data?.nftExternalFalg == "1" &&
                                <>
                                    <div className="pt-10">
                                    </div>
                                    <div className="flex w-full" style={{height: "auto"}}>
                                        <div
                                            className="flex flex-col h-full w-3/5 bg-jpnft-tbHeaderBg rounded-bl-3xl rounded-tl-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            {data?.nftExternalFalg == "1" &&
                                                <>
                                                    <div
                                                        className="w-full border-b  border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                            {t("page.IPAPPROVAL_nft-collection-image")}<span
                                                            className="ml-2 text-red-500">※</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                        <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                            {t("page.IPAPPROVAL_nft-collection-info")}<span
                                                            className="ml-2 text-red-500">※</span>
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                        <div
                                            className="flex flex-col h-full w-2/5 rounded-tr-3xl  rounded-br-3xl text-left border border-jpnft-border border-t border-b border-l">
                                            <>
                                                <div
                                                    className="w-full border-b  border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                        <div className="rounded-md">
                                                            <div className="flex flex-col">
                                                                <a style={{
                                                                    textDecoration: "underline",
                                                                    color: "#13c1f1"
                                                                }}
                                                                   className="fs_16_bold"
                                                                   onClick={() => downloadFile(data.files?.reqFileId, data.files?.fileName)}>{data.files[0]?.fileName}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                        <div className="rounded-md">
                                                            <div className="flex flex-col">
                                                                <a style={{
                                                                    textDecoration: "underline",
                                                                    color: "#13c1f1"
                                                                }}
                                                                   className="fs_16_bold"
                                                                   onClick={() => downloadFile(data.ipFiles?.reqFileId, data.ipFiles?.fileName)}> {data.ipFiles[0]?.fileName}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="flex w-full relative my-14" style={{userSelect:"text"}}>
                        <IpHolderInfo/>
                        <div className="flex absolute top-0 right-0" style={{userSelect:"text"}}>
                            <Button
                                onClick={() =>
                                    window.open("mailto:?subject=&cc=&subject=主题&body=内容")
                                }
                                style={{
                                    background: "#13c1f1",
                                    width: "150px",
                                    borderRadius: "20px",
                                    color: "white",
                                    fontSize: "16px",
                                    height: "35px",
                                    userSelect:"text"
                                }}
                            >
                                {t("page.IPAPPROVAL_edit")}
                            </Button>
                        </div>
                    </div>
                    {data?.fromPage === "ipForm" ? (
                        <div
                            className="flex w-full justify-center items-center space-x-5 text-center rounded-3xl mb-14 pt-10" style={{userSelect:"text"}}>
                            <button
                                onClick={() => {
                                    edit();
                                }}
                                className="font-bold fs_16 w-56 h-12 rounded-3xl border-8 border-jpnft-buttonTo"
                                style={{color: "#0CC1F8"}}
                            >
                                {t("page.IPCONFIRM_edit")}
                            </button>
                            <button
                                onClick={() => onFinish()}
                                className="text-white font-bold bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-56 h-12 rounded-3xl"
                            >
                                {t("page.NFTREQUESTSEC_apply")}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default IPFormConfirm;
