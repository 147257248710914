import React, { useEffect, useState, useRef } from "react";
import { FormInstance } from "antd/es/form";
import {Form, Input, message, notification, Tooltip} from "antd";
import { ip_detail, ip_holder_name } from "@/services";
import { useAccountState } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "@/router";
import { useTranslation } from "react-i18next";
import { nftBuy } from "@/utils";
import { showErrorMsg } from "@/utils/error";
import { CheckCircle } from "@/components/Icons";
import { normal } from "@/constants";
import i18n from "@/locales";
import copy from "clipboard-copy";
import clsx from "clsx";
import nftmark from "@/assets/nftmark.png";
import hatena from "@/assets/hatena.png";
import isMobile from "ismobilejs";

const { TextArea } = Input;
type TipNftInfosType = {
  externalUrl: string;
  externalWebsite: string;
  ipNftId: number;
  langvageId: number;
  nftDetails: string;
  nftImage: string;
  nftName: string;
  creator: string;
};
type nftType = {
  ipNftId: number;
  ipId: number;
  collectionId: number;
  creatorId: number;
  creator: string;
  ownerId: number;
  nftImage: string;
  metadataUrl: string;
  saleStatus: number;
  externalUrl: string;
  certificationStatus: number;
  royalty: number;
  royalty2: number;
  royaltyN: number;
  price: number;
  nftName: string;
  tokenId: number;
  tokenType: number;
  nftDetails: string;
  tipNftInfos: TipNftInfosType[];
};
type externalNftType = {
  ipNftId: number;
  ipId: number;
  nftName: string;
  nftImage: string;
  inOutType: number;
  certificationStatus: number;
  creator: string;
  tipNftInfos: TipNftInfosType[];
};

type IpInfoType = {
  ipId: number;
  langvageId: number;
  ipName: string;
  externalUrl: string;
  twitterUrl: string;
  ipContent: string;
  ipDetails: string;
};

type IpHolderType = {
  ipholderName: string;
};
const IPDetail = (func: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param: any = useParams();
  const formRef = React.createRef<FormInstance>();
  const [form] = Form.useForm();
  const [formDisableFlag, setFormDisableFlag] = useState<boolean>(false);
  const [ipHolderName, setIpHolderName] = useState<string>("");
  const accountState = useAccountState();
  const [data, setData] = useState<any>();
  const [nftList, setNftList] = useState<any>([]);
  const [externalNftList, setExternalNftList] = useState<any>([]);
  const [externalNftSize, setExternalNftSize] = useState<number>(2);
  const [nftSize, setNftSize] = useState<number>(2);
  const [ipInfos, setIpInfos] = useState<any>([]);
  const [ipHolders, setIpHolders] = useState<any>([]);
  const pageView = useRef(null);
  const languageId =
    i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
  const mobileFlg:any = isMobile(window.navigator).any ? true : false;

  async function getIpHolderName() {
    const params = { userId: data.ownerId, languageId: languageId };
    const res = await ip_holder_name(params);
    if (res.data) {
      setIpHolderName(res.data.ipHolderName);
    }
  }

  const fetchAllNftByIp = async () => {
    setNftList(data.nftList.slice(0, 4 * nftSize));
    setNftSize(nftSize + 1);
  };
  const fetchAllExternalNftByIp = async () => {
    setExternalNftList(data.externalNftList.slice(0, 4 * externalNftSize));
    setExternalNftSize(externalNftSize + 1);
  };
  const pageInit = async () => {
    const ipBlockchainId = param.ipBlockchainId;
    let languageId = null;
    if (i18n.language) {
      languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    } else {
      languageId = 1;
    }
    const res: any = await ip_detail(ipBlockchainId, languageId);
    if (res && res.data) {
      setData(res.data);
      setNftList(res.data.nftList? res.data.nftList.slice(0, 4) : null);
      setExternalNftList(
        res.data.externalNftList ? res.data.externalNftList.slice(0, 4) : null
      );
      setIpInfos(res.data.ipInfos);
      setIpHolders(res.data.ipHolders);
      if (func.modelType === "detail") {
        getIpHolderName();
        setFormDisableFlag(false);
        form.setFieldsValue({
          // ipDetails: data.ipDetails,
          // ipType: String(data.ipType),
          // ipContent: data.ipContent,
          // ipUsage: String(data.ipUsage),
        });
      } else {
        console.log(res.data, "modelType");
        setFormDisableFlag(true);
        //查询ipholder
        form.setFieldsValue({
          ipDetails: res.data.ipDetails,
          ipContent: res.data.ipContent,
          ntfUrl: normal + "/",
        });
      }
    } else {
      showErrorMsg(res, t);
    }
  };

  useEffect(() => {
    //滚动条到顶部
    window.scrollTo(0, 0);
    pageInit();
  }, []);

  const copyBlockchainInfo = async (e: any) => {
    e.stopPropagation();
    copy(data.ipBlockchainId!).then(() => {
      notification.success({ message: t("msg.COPYSUCCEED_1") });
    });
  };

  const onFinish = async (values: any) => {
    // if (!accountState.isLogin) {
    //   message.error("ログインしてください");
    //   return;
    // }
    // let param: any = {};
    // param.form = values;
    // param.form["ipName"]=func.detailData.ipName;
    // const res = await ip_request(param);
    // if (res) {
    //     form.resetFields();
    //     notification.success({
    //         message: "申請いたしました",
    //     })
    // func.ipCloseModel();
    // }
  };

  const buy = async (ipNftId: number, price: number) => {
    const languageId =
      i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    await nftBuy(ipNftId, price, t, accountState, languageId);
    navigate(RoutePaths.HOME);
  };

  function copyApplication() {
    const paramData = {
      ipImageUrl: data?.ipImageUrl,
      ipName: data?.ipName,
      ipHolderName: data?.ipHolderName,
      ipContent: data?.ipContent,
      ipBlockchainId: data?.ipBlockchainId,
      ipHolders: data?.ipHolders,
      ipInfos: data?.ipInfos,
    };
    if (!accountState.isLogin) {
      message.error(t("msg.RIGHTREQFIR_4"));
      return;
    }
    navigate(RoutePaths.NFT_APPLICATION, { state: paramData });
  }

  //计算字符宽度,字符占2个,文字占3个，合计长度33
  const charLength = (value:any) => {
    let len = 0;
    if(value!=null){
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
          len += 3;
        } else {
          len += 2;
        }
        if(len>33){
          value = value.substring(0,i-1)+"..."
          return value;
        }
      }
    }
    return value;
  }

  //计算字符宽度,字符占1个,文字占2个，合计长度25
  const charLength2 = (value:any) => {
    let len = 0;
    if(value!=null){
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len += 1;
        }
        if(len>25){
          value = value.substring(0,i-1)+"..."
          return value;
        }
      }
    }
    return value;
  }
  //计算字符总长，是否显示Tooltip
  const moreLength = (value:any) => {
    let len = 0;
    if(value!=null){
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
          len += 3;
        } else {
          len += 2;
        }
      }
      if(len>33){
        return true;
      }
    }
    return false;
  }

  return (
    <div className="minHeight">
      <div className="main-container">
        {!mobileFlg &&
          <div style={{ borderRadius: "15px" }} className="w-full">
          <div className="grid w-full items-center">
            <img
              src={data?.ipBannerUrl}
              className="cover"
              alt=""
              style={{ width: "1280px", height: "400px" }}
            />
          </div>
        </div>
        }
        {data && (
          <div className={clsx(!mobileFlg?"px-32":"","py-8")} >
            <Form
              ref={formRef}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <div className={clsx(!mobileFlg?"grid-flow-col pt-10 gap-5":"","grid")}>
                <div className="row-span-6">
                  <Form.Item>
                    <div style={{ borderRadius: "8px" }}>
                      <div className="grid h-full items-center justify-center">
                        <img
                          src={data?.ipImageUrl}
                          style={{ borderRadius: "8px" }}
                          className={clsx(mobileFlg?"w-full":"w-80 max-w-4xl", "cover")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Form.Item>
                  {data.blockchainShowFlag === 1 && mobileFlg === false &&
                      <>
                        <div
                            className="text-left flex"
                            style={{
                              fontSize: "23px",
                              fontWeight: "700",
                              color: "#333333",
                            }}
                        >
                          <div style={{display:"inline-block",userSelect:"text"}}>{t("page.IPDETAIL_blockchain-info")}</div>
                          <button className="pl-2 flex" style={{display:"inline-block"}} onClick={() => window.open("https://jpnft.gitbook.io/jpnftwebusaitogaido/")}>
                            <img style={{width:"25px",height:"25px"}} src={hatena}/>
                          </button>
                        </div>

                        <Form.Item className="text-left fs_18_bold" label-w>
                          <div className="mb-4">
                                                  <span
                                                      style={{
                                                        textDecoration: "underline",
                                                        color: "#70daf7",
                                                        fontSize: "16px",
                                                        cursor: "pointer",
                                                        marginRight: "25px",
                                                        userSelect:"text"
                                                      }}
                                                      onClick={() => window.open(data?.ipTransactionUrl)}
                                                  >
                                                      {data?.ipBlockchainId}

                                                  </span>
                            <div style={{userSelect:"text",display:"inline-block"}}>
                              <button
                                  onClick={copyBlockchainInfo}
                                  className="text-white bg-jpnft-buttonTo from-yellow-300 to-jpnft-buttonTo fs_16_white w-20 h-8 rounded-xl"
                              >
                                {t("page.IPDETAIL_copy")}
                              </button>
                            </div>
                          </div>
                        </Form.Item>
                      </>
                  }
                </div>
                <div
                  className={mobileFlg?"w-full":"col-span-5"}
                  style={{ paddingLeft: clsx(languageId == 2 ? "25px" : ""),userSelect:"text" }}
                >
                  {mobileFlg === false ? <Form.Item>
                    <label
                      style={{
                        float: "left",
                        fontSize: "33px",
                        fontWeight: "800",
                        color: "#333",
                      }}
                    >
                      <div>
                        {i18n.language === "ja"
                            ? ipInfos[0]?.ipName
                            : i18n.language === "en"
                                ? ipInfos[1]?.ipName
                                : ipInfos[2]?.ipName}
                      </div>
                    </label>
                  </Form.Item>:
                  <Form.Item>
                    <label
                      style={{
                        float: "left",
                        fontSize: "28px",
                        fontWeight: "800",
                        color: "#333",
                      }}
                    >
                      <div className="w-full">
                        {i18n.language === "ja"
                          ? ipInfos[0]?.ipName
                          : i18n.language === "en"
                            ? ipInfos[1]?.ipName
                            : ipInfos[2]?.ipName}
                      </div>
                    </label>
                  </Form.Item>
                  }
                  <div
                    className="text-left text-xs"
                    style={{ marginTop: "-20px", marginBottom: "20px", marginLeft: "-4px" }}
                  >
                    {data.tagId.includes("1") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#8bbf49",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_cartoons")}
                      </div>
                    )}
                    {data.tagId.includes("2") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#ff8a00",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_anime")}
                      </div>
                    )}
                    {data.tagId.includes("3") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#ad3fe0",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_pictures")}
                      </div>
                    )}
                    {data.tagId.includes("4") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#ef68b4",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_characters")}
                      </div>
                    )}
                    {data.tagId.includes("5") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#5370ac",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_games")}
                      </div>
                    )}
                    {data.tagId.includes("6") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#ffd11b",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_illustrations")}
                      </div>
                    )}
                    {data.tagId.includes("7") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#ff5400",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_music")}
                      </div>
                    )}
                    {data.tagId.includes("8") && (
                      <div
                        className="text-center w-20 h-8 mx-1 mt-2"
                        style={{
                          backgroundColor: "#65ccdf",
                          color: "white",
                          lineHeight:"32px",
                          display:"inline-block",
                          borderRadius: "5px",
                        }}
                      >
                        {t("page.HOME_other")}
                      </div>
                    )}
                  </div>
                  <Form.Item>
                    <label
                      style={{ float: "left", fontSize: "20px", color: "#333",fontWeight:"700" }}
                    >
                      {i18n.language === "ja"
                        ? ipHolders[0]?.ipholderName
                        : i18n.language === "en"
                        ? ipHolders[1]?.ipholderName
                        : ipHolders[2]?.ipholderName}
                    </label>
                  </Form.Item>
                  {!mobileFlg ?
                  <Form.Item style={{ marginTop: "-15px",marginBottom:'10px'}}>
                    <TextArea
                      readOnly
                      rows={10}
                      className="text-black"
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        resize: "none",
                        // marginLeft: "-4%",
                        padding: 0,
                        fontSize: "16px",
                        color: "#333"
                      }}
                      value={
                        i18n.language === "ja"
                          ? ipInfos[0]?.ipDetails
                          : i18n.language === "en"
                          ? ipInfos[1]?.ipDetails
                          : ipInfos[2]?.ipDetails
                      }
                    />
                  </Form.Item>
                     :
                      <div style={{
                        textAlign: "left",
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        resize: "none",
                        // marginLeft: "-4%",
                        padding: 0,
                        fontSize: "16px",
                        color: "#333",
                        marginTop: "-15px",
                        marginBottom:'10px'
                      }}>
                        {
                          i18n.language === "ja"
                              ? ipInfos[0]?.ipDetails
                              : i18n.language === "en"
                                  ? ipInfos[1]?.ipDetails
                                  : ipInfos[2]?.ipDetails
                        }
                      </div>
                  }
                  <div className={clsx(mobileFlg && "my-8 text-sm","flex w-full justify-between")}>
                    {!mobileFlg &&
                    <div className="flex">
                      {ipInfos[0]?.externalUrl && (
                        <>
                          <button>
                            <svg
                                onClick={() =>
                                    window.open(
                                        i18n.language === "ja"
                                            ? ipInfos[0]?.externalUrl
                                            : i18n.language === "en"
                                                ? ipInfos[1]?.externalUrl
                                                : ipInfos[2]?.externalUrl
                                    )
                                }
                                className="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="3652"
                                id="mx_n_1674031814574"
                                width="32"
                                height="32"
                            >
                              <path
                                  d="M640.992 928.288 384.992 928.288C384.992 928.288 384.992 741.792 384.992 640.288 384.992 623.296 391.744 607.04 403.744 595.04 415.744 583.04 432.032 576.288 448.992 576.288 488.096 576.288 537.888 576.288 576.992 576.288 593.952 576.288 610.24 583.04 622.24 595.04 634.24 607.04 640.992 623.296 640.992 640.288 640.992 741.792 640.992 928.288 640.992 928.288ZM971.264 477.792 559.232 82.464C533.632 57.888 492.192 57.824 466.496 82.464L54.464 477.792C16.224 514.496 27.904 544.288 81.184 544.288L163.296 544.288 191.2 928.64C193.952 963.648 224.96 992.288 260.448 992.288L765.568 992.288C800.928 992.288 832.032 963.808 834.816 928.64L862.688 544.288 944.544 544.288C997.568 544.288 971.264 477.792 971.264 477.792Z"
                                  p-id="3653"
                                  fill="#707070"
                              ></path>
                            </svg>
                          </button>
                        </>
                      )}
                      <div style={{ paddingRight: "20px" }}></div>
                      {ipInfos[0]?.twitterUrl && (
                        <button>
                          <svg
                              onClick={() =>
                                  window.open(
                                      i18n.language === "ja"
                                          ? ipInfos[0]?.twitterUrl
                                          : i18n.language === "en"
                                              ? ipInfos[1]?.twitterUrl
                                              : ipInfos[2]?.twitterUrl
                                  )
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 -10 64 64"
                              width="40" 
                              height="40">
                                <path d="M6.91992,6l14.2168,20.72656l-14.9082,17.27344h3.17773l13.13867,-15.22266l10.44141,15.22266h10.01367l-14.87695,-21.6875l14.08008,-16.3125h-3.17578l-12.31055,14.26172l-9.7832,-14.26172z"
                                  fill="#707070"></path>
                              </svg>
                        </button>
                      )}
                    </div>
                    }
                    {!mobileFlg?
                    <div
                        style={{
                          fontSize: "16px",
                          color: "#333",
                        }}
                        className="flex space-x-5"
                    >
                      <div className="flex">
                        {t("page.IPDETAIL_created")}:
                        <div>{data.createdDate}</div>
                      </div>
                      <div className="flex">
                        {t("page.IPDETAIL_updated")}:
                        <div>{data.updatedDate}</div>
                      </div>
                    </div>
                    :
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#333",
                        width: "100%",
                        display:"grid",
                        justifyItems: "end"
                      }}
                      className="text-right"
                    >
                      <div className="">
                        <div> {t("page.IPDETAIL_created")}: {data.createdDate}</div>
                      </div>
                      <div className="">
                        <div>{t("page.IPDETAIL_updated")}: {data.updatedDate}</div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
              <div className="w-full pb-5">
                {data && data?.blockchainShowFlag === 1 && mobileFlg && (
                  <div className="w-full flex flex-col mb-10">
                    <div className="w-full flex justify-start items-center">
                      <div className="font-bold" style={{ userSelect: "text", color: "#333333",fontSize:"23px" }}>
                        {t("page.IPDETAIL_blockchain-info")}
                      </div>
                      <button
                        className="pl-2 flex"
                        style={{ display: "inline-block" }}
                        onClick={() => window.open("https://jpnft.gitbook.io/jpnftwebusaitogaido/")}>
                        <img style={{ width: "20px", height: "20px" }} src={hatena} alt="" />
                      </button>
                    </div>
                    <div className="w-full flex justify-start items-center">
                      <div className="w-3/5 flex justify-start items-center font-bold">
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "#70daf7",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginRight: "10px",
                            userSelect: "text",
                          }}
                          onClick={() => window.open(data?.ipTransactionUrl)}>
                          {data?.ipBlockchainId}
                        </span>
                        <button
                          onClick={copyBlockchainInfo}
                          className="text-white bg-jpnft-buttonTo from-yellow-300 to-jpnft-buttonTo text-sm w-20 h-8 rounded-xl">
                          {t("page.IPDETAIL_copy")}
                        </button>
                      </div>
                      <div className="w-2/5 flex justify-end items-center space-x-3">
                        {ipInfos[0]?.externalUrl && (
                          <>
                            <button>
                              <svg
                                onClick={() =>
                                  window.open(
                                    i18n.language === "ja"
                                      ? ipInfos[0]?.externalUrl
                                      : i18n.language === "en"
                                      ? ipInfos[1]?.externalUrl
                                      : ipInfos[2]?.externalUrl
                                  )
                                }
                                className="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="3652"
                                id="mx_n_1674031814574"
                                width="32"
                                height="32">
                                <path
                                  d="M640.992 928.288 384.992 928.288C384.992 928.288 384.992 741.792 384.992 640.288 384.992 623.296 391.744 607.04 403.744 595.04 415.744 583.04 432.032 576.288 448.992 576.288 488.096 576.288 537.888 576.288 576.992 576.288 593.952 576.288 610.24 583.04 622.24 595.04 634.24 607.04 640.992 623.296 640.992 640.288 640.992 741.792 640.992 928.288 640.992 928.288ZM971.264 477.792 559.232 82.464C533.632 57.888 492.192 57.824 466.496 82.464L54.464 477.792C16.224 514.496 27.904 544.288 81.184 544.288L163.296 544.288 191.2 928.64C193.952 963.648 224.96 992.288 260.448 992.288L765.568 992.288C800.928 992.288 832.032 963.808 834.816 928.64L862.688 544.288 944.544 544.288C997.568 544.288 971.264 477.792 971.264 477.792Z"
                                  p-id="3653"
                                  fill="#707070"></path>
                              </svg>
                            </button>
                          </>
                        )}
                        {ipInfos[0]?.twitterUrl && (
                          <button>
                            <svg
                              onClick={() =>
                                window.open(
                                  i18n.language === "ja"
                                    ? ipInfos[0]?.twitterUrl
                                    : i18n.language === "en"
                                    ? ipInfos[1]?.twitterUrl
                                    : ipInfos[2]?.twitterUrl
                                )
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 -10 64 64"
                              width="40" 
                              height="40">
                                <path d="M6.91992,6l14.2168,20.72656l-14.9082,17.27344h3.17773l13.13867,-15.22266l10.44141,15.22266h10.01367l-14.87695,-21.6875l14.08008,-16.3125h-3.17578l-12.31055,14.26172l-9.7832,-14.26172z"
                                  fill="#707070"></path>
                              </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {ipInfos[languageId - 1]?.ipContent && (
                <div style={{userSelect:"text"}}>
                  <div
                    className="text-left"
                    style={{
                      fontSize: "23px",
                      fontWeight: "700",
                      color: "#333333",
                      marginBottom: "10px",
                      marginTop: "-20px",
                    }}
                  >
                    {t("page.IPDETAIL_important-info")}
                  </div>
                  <Form.Item>
                    <div
                      className="w-full h-auto text-left"
                      dangerouslySetInnerHTML={{
                        __html:
                          i18n.language === "ja"
                            ? ipInfos[0]?.ipContent
                            : i18n.language === "en"
                            ? ipInfos[1]?.ipContent
                            : ipInfos[2]?.ipContent,
                      }}
                    ></div>
                  </Form.Item>
                </div>
              )}
              {data.nftRequestFalg === 1 && (
                <>{mobileFlg ? <div className="text-center text-white rounded-3xl flex justify-center items-center w-full flex-col"
                style={{ paddingTop: "10px", paddingBottom: "50px",userSelect:"text" }}>
                    <button
                      disabled
                      className="text-white fs_16_white w-40 h-8 rounded-3xl disabled:bg-jpnft-disable"
                      style={{ padding: "0px 20px",fontWeight:"700" ,width:clsx(languageId==2?"auto":"160px")}}>
                      {t("page.IPDETAIL_apply-to-create-nfts")}
                    </button>
                    <p className="w-full text-red-500 text-base mt-3"> {t("page.IPREQUEST_mobile_not_request")}</p>
                  </div>: <div
                    className="text-center text-white rounded-3xl"
                    style={{ paddingTop: "10px", paddingBottom: "50px",userSelect:"text" }}>
                    <button
                      onClick={copyApplication}
                      className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-40 h-8 rounded-3xl"
                      style={{ padding: "0px 20px",fontWeight:"700" ,width:clsx(languageId==2?"auto":"160px")}}>
                      {t("page.IPDETAIL_apply-to-create-nfts")}
                    </button>
                  </div>
                 }
                </>
              )}
              {(externalNftList.length !== 0 || nftList.length !== 0) && (
                <div className="fs_h2" style={{ float: "left",userSelect:"text" }}>
                  {t("page.IPDETAIL_verified-nfts")}
                </div>
              )}

              <div className="pt-16">
                {externalNftList.length != 0 && (
                  <div className="fs_h3 text-left" style={{userSelect:"text"}}>
                    {t("page.IPDETAIL_certified-collections")}
                  </div>
                )}
                <div>
                  {externalNftList?.map(function (
                    item: {
                      nftImage: string | undefined;
                      ipNftId: number | undefined;
                      nftName: string | undefined;
                      inOutType: number;
                      certificationStatus: number;
                      tipNftInfos: TipNftInfosType[];
                    },
                    index: React.Key | null | undefined
                  ) {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          width: mobileFlg ? "50%":"25%",
                          verticalAlign: "top",
                          float: "left",
                          height:"310px",
                        }}
                        className="pt-5"
                      >
                        <div style={{ borderRadius: "8px" }}>
                          <button>
                            <img
                                onClick={() => {
                                  if (item.inOutType === 2) {
                                    const url =
                                        i18n.language === "ja"
                                            ? item.tipNftInfos[0]?.externalUrl
                                            : i18n.language === "en"
                                                ? item.tipNftInfos[1]?.externalUrl
                                                : item.tipNftInfos[2]?.externalUrl;
                                    window.open(url);
                                  } else {
                                    navigate(
                                        `/nft/${data.ipBlockchainId}/${item.ipNftId}`,
                                        {
                                          state: {
                                            data: item,
                                            ipBlockchainId: data?.ipBlockchainId,
                                            ownerId: data?.ownerId,
                                          },
                                        }
                                    );
                                  }
                                }}
                                src={
                                  i18n.language === "ja"
                                      ? item.tipNftInfos[0]?.nftImage
                                      : i18n.language === "en"
                                          ? item.tipNftInfos[1]?.nftImage
                                          : item.tipNftInfos[2]?.nftImage
                                }
                                style={{borderRadius: "8px"}}
                                className="w-52 cover border border-jpnft-border"
                            />
                          </button>



                          <div className="ipdetail-nftname">
                          <div className="w-52 mt-5">
                            <div
                              className="text-lg text-black h-auto max-h-16"
                              style={{
                                wordBreak: "break-all",
                                // minHeight: "35px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  width: item.certificationStatus == 2 ? "90%" : "100%",
                                  fontSize:"16px",
                                  userSelect:"text"
                                }}
                              >
                                <Tooltip placement="topLeft" title={moreLength(item.tipNftInfos[languageId-1]?.nftName) && item.tipNftInfos[languageId-1]?.nftName}
                                style={{position:"absolute"}}
                                >
                                  {
                                    charLength(item.tipNftInfos[languageId-1]?.nftName)
                                  }
                                </Tooltip>
                              </div>

                              {item.certificationStatus == 2 && (
                              <div
                                style={{
                                  display: "inline-block",
                                  position: "absolute",
                                  paddingTop: "5px",
                                  width: "10%",
                                }}
                                className="flex"
                                >
                                <img className="w-4 h-4" src={nftmark} />
                              </div>
                              )}

                            </div>
                          </div>
                          </div>


                          <div className="ipdetail-nftname">
                          <div
                            className="h-6 w-52 text-base"
                            style={{ color: "#999999",userSelect:"text" }}
                          >
                            <Tooltip placement="topLeft" title={moreLength(item.tipNftInfos[languageId-1]?.externalWebsite) && item.tipNftInfos[languageId-1]?.externalWebsite}
                                     style={{position:"absolute"}}
                            >
                              {
                                charLength2(item.tipNftInfos[languageId-1]?.externalWebsite)
                              }
                            </Tooltip>
                          </div>
                          </div>

                        </div>
                      </div>
                    );
                  })}
                </div>
                {externalNftList.length != 0 &&
                  externalNftList.length < data.externalNftList.length && (
                    <div
                      className="text-center text-white rounded-3xl"
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        float: "left",
                        width: "100%",
                        userSelect:"text"
                      }}
                    >
                      <button
                        onClick={() => fetchAllExternalNftByIp()}
                        className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-40 h-8 rounded-3xl"
                      >
                        {t("page.IPDETAIL_more")}
                      </button>
                    </div>
                  )}
                <div style={{ float: "left", width: "100%" }}>
                  {nftList.length != 0 && (
                    <div className="fs_h3 text-left mt-10" style={{userSelect:"text"}}>
                      {t("page.IPDETAIL_buy-now")}
                    </div>
                  )}
                  {nftList?.map(function (
                    item: nftType,
                    index: React.Key | null | undefined
                  ) {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: "10px",
                          display: "inline-block",
                          width: !mobileFlg ? "25%" : "50%",
                          height:"420px",
                          float: "left",
                        }}
                      >
                        <div style={{ borderRadius: "8px" }}>
                          <button>
                            <img
                              src={
                                i18n.language === "ja"
                                  ? item.tipNftInfos[0]?.nftImage
                                  : i18n.language === "en"
                                  ? item.tipNftInfos[1]?.nftImage
                                  : item.tipNftInfos[2]?.nftImage
                              }
                              className=" w-52 cover border border-jpnft-border"
                              style={{ borderRadius: "8px" }}
                              onClick={() => {
                                navigate(
                                  `/nft/${data.ipBlockchainId}/${item.ipNftId}`,
                                  {
                                    state: {
                                      data: item,
                                      ipBlockchainId: data?.ipBlockchainId,
                                      ownerId: data?.ownerId,
                                    },
                                  }
                                );
                              }}
                            />
                          </button>
                          <div
                            className="h-auto w-full"
                            style={{ paddingTop: "20px" }}
                          >
                            <div className="text-base text-black" style={{width:"90%",display:"inline-block",minHeight:"32px",userSelect:"text",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
                              <Tooltip placement="topLeft" title={moreLength(item.tipNftInfos[languageId-1]?.nftName) && item.tipNftInfos[languageId-1]?.nftName}
                                       style={{position:"absolute",userSelect:"text"}}
                              >
                                {
                                  charLength(item.tipNftInfos[languageId-1]?.nftName)
                                }
                              </Tooltip>
                            </div>
                            {item.certificationStatus === 2 && (
                                <div className="flex h-auto mt-1" style={{display:"inline-block",lineHeight:"32px",position:"absolute"}}>
                                  <img className="w-4 h-4" src={nftmark} />
                                  {/*<CheckCircle />*/}
                                </div>
                            )}
                          </div>
                          <div className="text-base" style={{color: "#999999",userSelect:"text"}}>
                            <Tooltip placement="topLeft" title={moreLength(item.tipNftInfos[languageId - 1]?.creator) && item.tipNftInfos[languageId - 1]?.creator} style={{position: "absolute"}}>
                              {charLength(item.tipNftInfos[languageId - 1]?.creator)}
                            </Tooltip>
                          </div>
                          {/* <div
                                      className="h-6 fs_16"
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {item.userName}
                                    </div> */}
                          <div className="fs_18_bold h-6" style={{userSelect:"text"}}>
                            {item.price}
                            <span
                              className="fs_16"
                              style={{ fontWeight: "400" }}
                            >
                              ETH
                            </span>
                          </div>
                          <button
                            className="h-12 w-28 font-bold rounded-3xl mt-5 fs_16_white"
                            onClick={() =>
                              navigate(
                                `/nft/${data.ipBlockchainId}/${item?.ipNftId}`
                              )
                            }
                            style={{ backgroundColor: "#13c1f1",userSelect:"text" }}
                          >
                            {t("page.IPDETAIL_purchase")}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {nftList.length != 0 &&
                    nftList.length < data.nftList.length && (
                        <div
                            className="text-center text-white rounded-3xl"
                            style={{
                              paddingBottom: "50px",
                              display: "inline-block",
                              float: "left",
                              width: "100%",
                              userSelect:"text"
                            }}
                        >
                          <button
                              onClick={() => fetchAllNftByIp()}
                              className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-40 h-8 rounded-3xl"
                          >
                            {t("page.IPDETAIL_more")}
                          </button>
                        </div>
                    )}
              </div>
            </Form>

            {/*{(externalNftList.length !== 0 || nftList.length !== 0) && (*/}
            {/*    <div className="text-base text-black whitespace-pre-wrap my-10 pb-32">*/}
            {/*        {t("page.IPDETAIL_description")}*/}
            {/*    </div>*/}
            {/*)}*/}
          </div>
        )}
      </div>
    </div>
  );
};

export default IPDetail;
