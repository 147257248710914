import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './en.json'
import ja from './ja.json'
import zh from './zh.json'

const resources = {
    en: {
        translation:en
    },
    ja: {
        translation: ja
    },
    zh: {
        translation: zh
    }
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: 'ja',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n