import { Get, Post, Sessions } from "@/utils";

export type HolderItemType = {
  userId: string;
  ethAddress: string;
  ipHolderName: string;
  avatar: string;
};
export type IpHolderListType = {
  data: {
    endRow: number;
    firstPage: number;
    isFirstPage: boolean;
    isLastPage: boolean;
    lastPage: boolean;
    pageNum: number;
    pageSize: number;
    pages: number;
    prePage: number;
    size: number;
    startRow: number;
    total: number;
    list: HolderItemType[];
  };
};

type IpHolderInfo = {
  data: {
    userId: number;
    ethAddress: string;
    proxyAddress: string;
    ipholderName: string;
    postCode: string;
    address: string;
    tel: string;
    email: string;
    avatar: string;
    userType: number;
    userName: string;
  };
};

/**
 * IP Holder一覧
 *
 * @returns {IpHolderListType}
 */

export const ip_holder_list = async (
  page: number
): Promise<IpHolderListType | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ipholder/list", { page: page }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * IP HolderName取得
 *
 * @returns {any}
 */
export const ip_holder_name = async (params: any): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ipholder/name", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

/**
 * IP HolderName取得
 *
 * @returns {any}
 */
export const ip_holder_name2 = async (params: any): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ipholder/register", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
    return list;
  }
};

/**
 * IPHolder情報
 *
 * @returns {HolderInfo}
 */
export const ip_holder_info = async (
  userId: number | undefined,
  languageId: number
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ipholder/info", { userId: userId,languageId:languageId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
     return list;
  }
};

/**
 * IPHolder情報
 *
 * @returns {HolderInfo}
 */
export const ip_holder_list_info = async (
  userId: number | undefined,
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ipholder/listInfo", { userId: userId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map( (item,indx) => ({key:item, value:''}) );
     return list;
  }
};

/**
 *  set proxyAddress
 * @param params
 * @returns
 */
export const ip_holder_proxyAddress = async (
  params: any
): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let formData = new FormData();
  formData.append("userId", params.userId);
  formData.append("proxyAddress", params.proxyAddress);

  const res = await Post("/ipholder/proxyAddress", {
    body: formData,
    headers: headers,
  });
  if (res.ok && res.data) {
    return res;
  } else {
    return JSON.parse(res.error);
  }
};