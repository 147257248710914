import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Sessions} from "@/utils";

export interface IAccountState {
    twitterUrl: any;
    linktreeUrl: any;
    openseaUrl: any;
    userId: number | null;
    userName: string | null;
    avatar: string | null;
    avatarUrl: string | null;
    isLogin: boolean;
    isMetamask: boolean;
    signed: boolean;
    email: string;
    address: string | null;
    jwt: string | null;
    network: string | null; // supported network
    loading: boolean;
    refresh: boolean; // refresh singnal
    chain: number | null;
    role: number | null;
    loginType: number | null;
    recikaEthAddress: string | null;
    userDetails: string | null;
    userType: number | null;
    jpnft: boolean;
    locale:string;
}

const initialAccountState = {
    userId: null,
    userName: null,
    avatar: null,
    avatarUrl: null,
    isLogin: false,
    isMetamask: false,
    signed: false,
    email: "",
    address: null,
    jwt: null,
    network: "Mainnet",
    loading: false,
    refresh: false,
    chain: null,
    role: null,
    loginType: null,
    recikaEthAddress: null,
    userDetails: null,
    userType: null,
    jpnft: false,
    locale:'ja'
} as IAccountState;

export const SessionKey = "jpnft";

export const accountSlice = createSlice({
    name: "account",
    initialState:
        Sessions.decode<IAccountState>(Sessions.read<string>(SessionKey)) ??
        initialAccountState,
    reducers: {
        login: (state, action: PayloadAction<Partial<IAccountState>>) => {
            Object.assign(state, {
                ...state,
                ...action.payload,
                signed: true,
                loading: false,
                isLogin: true,
            });
            Sessions.write(SessionKey, Sessions.encode(state));
        },
        logout: (state) => {
            Sessions.remove(SessionKey);
            Sessions.remove("_jpnft");
            Object.assign(state, {
                ...initialAccountState,
                chain: state.chain,
                network: state.network,
                signed: false,
                isLogin: false,
            });
        },
        update: (state, action: PayloadAction<Partial<IAccountState>>) => {
            Object.assign(state, {
                ...state,
                ...action.payload,
            });
        },
        refresh: (state) => {
            Object.assign(state, {
                ...state,
                refresh: !state.refresh,
            });
        },
        loading: (state) => {
            Object.assign(state, {
                ...state,
                loading: true,
            });
        },
        done: (state) => {
            Object.assign(state, {
                ...state,
                loading: false,
            });
        },
    },
});

export const Actions = accountSlice.actions;
export default accountSlice.reducer;
