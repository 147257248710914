import {useEffect, useState} from "react";
import TreeChart from "@/components/TreeChart";
import {getIpholderStatInfo} from "@/services";
import {useTranslation} from "react-i18next";
import {useAccountState} from "@/store";

type DataType = {
    ipCount: number;
    nftCount: number;
    nftOwnerCount: number;
    nftTradeTotal: string;
    nftRoyaltyTotal: string;
};

const Analytics = (props: any) => {
    const {t} = useTranslation();
    const accountState = useAccountState();

    const [data, setData] = useState<DataType>({
        ipCount: 0,
        nftCount: 0,
        nftOwnerCount: 0,
        nftTradeTotal: "",
        nftRoyaltyTotal: "",
    });

    const dataInit = async () => {
        console.log(accountState)
        if (accountState.userId) {
            const res: any = await getIpholderStatInfo(accountState.userId);
            if (res && res.data) {
                setData(res.data)
            }
        }
    }
    useEffect(() => {
        dataInit();
    }, []);

    return <div className="w-full h-full text-left">
        <div className="flex w-full  bg-white fs_18_bold border" style={{userSelect:"text"}}>
            <div className="flex-initial w-1/5 p-5 border">
                <div className="text-sm">
                    {t("page.ANALYTICS_registered-ip")}
                </div>
                <div>{data.ipCount}</div>
            </div>
            <div className="flex-initial w-1/5 p-5 border">
                <div className="text-sm">
                    {t("page.ANALYTICS_minted-nft")}
                </div>
                <div>{data.nftCount}</div>
            </div>
            <div className="flex-initial w-1/5 p-5 border">
                <div className="text-sm">
                    {t("page.ANALYTICS_nft-holders")}
                </div>
                <div>{data.nftOwnerCount}</div>
            </div>
            <div className="flex-initial w-1/5 p-5 border">
                <div className="text-sm">
                    {t("page.ANALYTICS_total-transaction-volume")}
                </div>
                <div>{data.nftTradeTotal}</div>
            </div>
            <div className="flex-initial w-1/5 p-5 border">
                <div className="text-sm">
                    {t("page.ANALYTICS_total-royalty-payment")}
                </div>
                <div>{data.nftRoyaltyTotal}</div>
            </div>
        </div>
        <div className="bg-white mt-5"  style={{userSelect:"text"}}>
            <TreeChart/>
        </div>
    </div>
}
export default Analytics;