import React, {useEffect, useRef, useState} from "react";
import {FormInstance} from "antd/es/form";
import {Button, Form, Input, message, notification, Radio, Switch,Slider} from "antd";
import {
    ip_complete,
    ip_file_download,
    ip_holder_name,
    ip_holder_name2,
    ip_reject,
    uploadBanner,
    uploadLogo
} from "@/services";
import {RoutePaths} from "@/router";
import {useLocation, useNavigate} from "react-router-dom";
import {useAccountState} from "@/store";
import {metaMaskSigner} from "@/utils/web3";
import IpHolderInfo from "@/components/IpHolderInfo";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {ipBannerUrl,ipLogoUrl} from "@/constants";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";


const {TextArea} = Input;

const IPForm = (func: any,props:any) => {
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    const {t} = useTranslation();
    const fileReF = useRef<HTMLInputElement | null>(null);
    const ipFileReF = useRef<HTMLInputElement | null>(null);
    const imgReF = useRef<HTMLInputElement | null>(null);
    const bannerReF = useRef<HTMLInputElement | null>(null);
    const formRef = React.createRef<FormInstance>();
    const navigate = useNavigate();
    const {showTitle} = props;
    const [fileName, setFileName] = useState<string>("");
    const [ipFileName, setIpFileName] = useState<string>("");
    const [fileName1, setFileName1] = useState<string>("");
    const [ipFileName1, setIpFileName1] = useState<string>("");
    const [fileName2, setFileName2] = useState<string>("");
    const [ipFileName2, setIpFileName2] = useState<string>("");
    const [logoFileName, setLogoFileName] = useState<string>("");
    const [bannerFileName, setBannerFileName] = useState<string>("");
    const [flagValue, setFlagValue] = useState<string>("2");
    const [checkStatus, setCheckStatus] = useState<boolean>(true);
    const [externalFlagValue, setExternalFlagValue] = useState<string>("2");
    const [externalStatus, setExternalStatus] = useState<boolean>(true);
    const [file, setFile] = useState<File[]>();
    const [file1, setFile1] = useState<File[]>();
    const [file2, setFile2] = useState<File[]>();
    const [ipFile, setIpFile] = useState<File[]>();
    const [ipFile1, setIpFile1] = useState<File[]>();
    const [ipFile2, setIpFile2] = useState<File[]>();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [imgUrlBanner, setImgUrlBanner] = useState<string>("");
    const location = useLocation();
    const [showMenu,setShowMenu] = useState<boolean>(true);

    let [status, setStatus] = useState<string>('1');
    let [status1, setStatus1] = useState<string>('1');
    let [status2, setStatus2] = useState<string>('1');

    const [classes, setClasses] = useState<string>('');
    //1 入口申請　２　入口承認
    const direction = location.pathname === RoutePaths.IP_APPLICATION_LIST ? 1 : 2

    const [form] = Form.useForm();

    const [formDisableFlag, setFormDisableFlag] = useState<boolean>(false);
    const [contentView, setContentView] = useState<string>("2");
    const [fileIsView, setFileIsView] = useState<string>("2");
    const [detailData, setDetailData] = useState<any>();
    const accountState = useAccountState();
    const [ipHolderName, setIpHolderName] = useState<string>("");

    useEffect(() => {
        window.scrollTo(0, 0);
        if (func.modelType === "detail") {
            setFormDisableFlag(true)
            setDetailData(func.detailData)
            const fName = func.detailData && func.detailData.files && func.detailData.files.length ? func.detailData.files[0].fileName : "";
            setFileName(fName);
            setImgUrl(ipLogoUrl  + "/" + `${func.detailData.ipLogo}`);
            setImgUrlBanner(ipBannerUrl  + "/" + `${func.detailData.ipBanner}`);
            form.setFieldsValue({
                toIpAddress: accountState.address,
                ipName: func.detailData.ipName,
                ipDetails: func.detailData.ipDetails,
                ipType: String(func.detailData.ipType),
                ipContent: func.detailData.ipContent,
                twitterUrl: func.detailData.twitterUrl,
                externalUrl: func.detailData.externalUrl,
                ipUsage: String(func.detailData.ipUsage),
                nftRequestFalg: String(func.detailData.nftRequestFalg),
                nftExternalFalg: String(func.detailData.nftExternalFalg),
                ipAddress: func.detailData && func.detailData.ipAddress ? func.detailData.ipAddress : ""
            })
            getIpHolderName();
        } else {
            getIpHolderName2();
            console.log(form, "kaish2i", location.state)
            setFormDisableFlag(false)
            form.resetFields();
            if (location.state) {
                if(location.state.nftRequestFalg=="1"){
                    setCheckStatus(false)
                    setFlagValue("1")
                }
                if(location.state.nftExternalFalg=="1"){
                    setExternalStatus(false)
                    setExternalFlagValue("1")
                }
                setFile(location.state.files[0]?.file);
                setFileName(location.state.files[0]?.fileName);
                setFile1(location.state.files[1]?.file);
                setIpFileName1(location.state.files[1]?.fileName);
                setFile2(location.state.files[2]?.file);
                setFileName2(location.state.files[2]?.fileName);
                setIpFileName(location.state.ipFiles[0]?.fileName);
                setIpFile(location.state.ipFiles[0]?.ipFile);
                setClasses(location.state.ipTag);
                setImgUrl(ipLogoUrl  + "/" +`${location.state.ipLogo}`);
                setImgUrlBanner(ipBannerUrl  + "/" + `${location.state.ipBanner}`);
                setLogoFileName(location.state.ipLogo);
                setBannerFileName(location.state.ipBanner);
                setShowMenu(location.state.showMenu);
                form.setFieldsValue({
                    ipLogo: location.state.ipLogo,
                    ipBanner: location.state.ipBanner,
                    ipName: location.state.ipInfo[0]?.ipName,
                    ipDetails: location.state.ipInfo[0]?.ipDetails,
                    externalUrl: location.state.ipInfo[0]?.externalUrl,
                    twitterUrl: location.state.ipInfo[0]?.twitterUrl,
                    ipContent: location.state.ipInfo[0]?.ipContent,
                    ipUsage: location.state.ipInfo[0]?.ipUsage,
                    ipName1: location.state.ipInfo[1]?.ipName,
                    ipDetails1: location.state.ipInfo[1]?.ipDetails,
                    externalUrl1: location.state.ipInfo[1]?.externalUrl,
                    twitterUrl1: location.state.ipInfo[1]?.twitterUrl,
                    ipContent1: location.state.ipInfo[1]?.ipContent,
                    ipUsage1: location.state.ipInfo[1]?.ipUsage,
                    ipName2: location.state.ipInfo[2]?.ipName,
                    ipDetails2: location.state.ipInfo[2]?.ipDetails,
                    externalUrl2: location.state.ipInfo[2]?.externalUrl,
                    twitterUrl2: location.state.ipInfo[2]?.twitterUrl,
                    ipContent2: location.state.ipInfo[2]?.ipContent,
                    ipUsage2: location.state.ipInfo[2]?.ipUsage,
                    nftRequestFalg: flagValue,
                    nftExternalFalg: externalFlagValue,
                })
            }

        }
    }, [])

    const uploadImg = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("msg.USER_2"));
                return;
            }
            if(status2=="1"){
                setFileName(fileName);
                setFile(file);
            }else if(status2=="2"){
                setFileName1(fileName);
                setFile1(file);
            }else{
                setFileName2(fileName);
                setFile2(file);
            }

            notification.success({
                message: t("msg.IPREQ_5"),
            })
        }
    }

    const uploadFile = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("msg.USER_2"));
                return;
            }
            if(status2=="1"){
                setIpFileName(fileName);
                setIpFile(file);
            }else if(status2=="2"){
                setIpFileName1(fileName);
                setIpFile1(file);
            }else{
                setIpFileName2(fileName);
                setIpFile2(file);
            }
            notification.success({
                message: t("msg.IPREQ_5"),
            })
        }
    }
    const uploadImg2 = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            console.log(fileName.substring(fileName.lastIndexOf(".")));
            let fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("msg.USER_2"));
            }

            if (fileType === "jpg" || fileType === "bmp" || fileType === "png" || fileType === "jpeg") {

                const res = await uploadLogo({photo: file});
                if (res) {
                    console.log(res)
                    notification.warning({
                        message: t("msg.USER_1"),
                    })
                    if (res.data) {
                        setLogoFileName(res.data.photoName);
                        setImgUrl(res.data.previewUrl);
                        console.log(res.data.previewUrl);
                    } else {
                        message.error(t(Object.keys(res.msg)[0]));
                    }
                } else {
                    message.error(t(Object.keys(res.msg)[0]));
                }
                e.target.value = '';
            } else {
                notification.warning({
                    message: t("msg.USER_3"),
                })
            }

        }
    }

    const downloadFile = async () => {
        const reqFileId = detailData && detailData.files && detailData.files[0] ? detailData.files[0].reqFileId
            : ""
        if (!reqFileId) {
            return;
        }
        const fName: string = fileName;
        const result = await ip_file_download(reqFileId);
        if (result && result.code > 0) {
            let msg = Object.keys(result.msg)[0];
            message.error(msg);
            return;
        }
        if (result) {
            var blob = new Blob([result]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = fName;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
        }
    }

    const onUpload = () => {
        if (fileReF.current) {
            fileReF.current.click();
        }
    }

    const onUploadFile = () => {
        if (ipFileReF.current) {
            ipFileReF.current.click();
        }
    }

    const uploadImg3 = async (e: any) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name
            console.log(fileName.substring(fileName.lastIndexOf(".")));
            let fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(t("msg.USER_2"));
            }

            if (fileType === "jpg" || fileType === "bmp" || fileType === "png" || fileType === "jpeg") {

                const res = await uploadBanner({photo: file});
                if (res) {
                    console.log(res)
                    notification.warning({
                        message: t("msg.USER_1"),
                    })
                    if (res.data) {
                        setBannerFileName(res.data.photoName);
                        setImgUrlBanner(res.data.previewUrl);
                        console.log(res.data.previewUrl);
                    } else {
                        message.error(t(Object.keys(res.msg)[0]));
                    }
                } else {
                    message.error(t(Object.keys(res.msg)[0]));
                }
                e.target.value = '';
            } else {
                notification.warning({
                    message: t("msg.USER_3"),
                })
            }

        }
    }


    const onUploadImg = () => {
        if (imgReF.current) {
            imgReF.current.click();
        }
    }
    const onUploadImg2 = () => {
        if (bannerReF.current) {
            bannerReF.current.click();
        }
    }

    //计算字符长度
    const charLength = ( value:any,maxLength:any,fieldName:any ) => {
        let len = 0;
        const k = 0x80;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 2;
                } else {
                    len++;
                }
            }
        }
        debugger
        if (len > maxLength) {
            if (fieldName == 1) {
                notification.warning({
                    message: t("msg.IPREQ_15")
                })
            } else if (fieldName == 2) {
                notification.warning({
                    message: t("msg.IPREQ_16")
                })
            } else {
                notification.warning({
                    message: t("msg.IPREQ_17")
                })
            }
            return true;
        } else {
            return false;
        }
    }

    const onFinish = async () => {
        if(charLength(form.getFieldValue(['ipName']),128,1)){
            return false;
        }
        if(charLength(form.getFieldValue(['ipName1']),128,1)){
            return false;
        }
        if(charLength(form.getFieldValue(['ipName2']),128,1)){
            return false;
        }
        if(charLength(form.getFieldValue(['externalUrl']),256,2)){
            return false;
        }
        if(charLength(form.getFieldValue(['externalUrl1']),256,2)){
            return false;
        }
        if(charLength(form.getFieldValue(['externalUrl2']),256,2)){
            return false;
        }
        if(charLength(form.getFieldValue(['twitterUrl']),256,3)){
            return false;
        }
        if(charLength(form.getFieldValue(['twitterUrl1']),256,3)){
            return false;
        }
        if(charLength(form.getFieldValue(['twitterUrl2']),256,3)){
            return false;
        }
        const ipInfo = [{
            ipName: form.getFieldValue(['ipName']),
            ipDetails: form.getFieldValue(['ipDetails']),
            externalUrl: form.getFieldValue(['externalUrl']),
            twitterUrl: form.getFieldValue(['twitterUrl']),
            ipContent: form.getFieldValue(['ipContent']),
            ipUsage: form.getFieldValue(['ipUsage'])==null?1:form.getFieldValue(['ipUsage']),
            langvageId: 1,
        },{
            ipName: form.getFieldValue(['ipName1']),
            ipDetails: form.getFieldValue(['ipDetails1']),
            externalUrl: form.getFieldValue(['externalUrl1']),
            twitterUrl: form.getFieldValue(['twitterUrl1']),
            ipContent: form.getFieldValue(['ipContent1']),
            ipUsage: form.getFieldValue(['ipUsage1'])==null?1:form.getFieldValue(['ipUsage1']),
            langvageId: 2,
        },{
            ipName: form.getFieldValue(['ipName2']),
            ipDetails: form.getFieldValue(['ipDetails2']),
            externalUrl: form.getFieldValue(['externalUrl2']),
            twitterUrl: form.getFieldValue(['twitterUrl2']),
            ipContent: form.getFieldValue(['ipContent2']),
            ipUsage: form.getFieldValue(['ipUsage2'])==null?1:form.getFieldValue(['ipUsage2']),
            langvageId: 3,
        }];
        const ipFiles = [{
            fileName: ipFileName,
            ipFile: ipFile,
            langvageId: 1,
        },{
            ipFile: ipFile1,
            fileName: ipFileName1,
            langvageId: 2,
        },{
            ipFile: ipFile2,
            fileName: ipFileName2,
            langvageId: 3,
        }];
        const files = [{
            fileName: fileName,
            file: file,
            langvageId: 1,
        },{
            file: file1,
            fileName: fileName1,
            langvageId: 2,
        },{
            file: file2,
            fileName: fileName2,
            langvageId: 3,
        }];
        const paramData = {
            ipLogo: logoFileName,
            ipBanner: bannerFileName,
            nftRequestFalg: flagValue,
            nftExternalFalg: externalFlagValue,
            ipTag: classes,
            ipInfo: ipInfo,
            ipFiles:ipFiles,
            files:files,
            fromPage: 'ipForm',
            showMenu:showMenu,
        }
        navigate(RoutePaths.IP_FORM_CONFIRM, {state: paramData});
    };

    const complete = async () => {
        let approvalSig: string | undefined = "";
        if (detailData?.data.ipHash) {
            approvalSig = await metaMaskSigner(detailData?.data.ipHash);
        } else {
            return;
        }
        let params = {
            ipRequestId: detailData.ipRequestId,
            approvalSig: approvalSig!
        };
        const res = await ip_complete(params);
        if (res) {
            form.resetFields();
            notification.success({
                message: t("msg.IPREQ_6"),
            })
            func.ipCloseModel();
        } else {
            showErrorMsg(res,t);
        }
    }

    const reject = async () => {
        let params = {
            ipRequestId: detailData.ipRequestId,
        }
        const res = await ip_reject(params);
        if (res) {
            form.resetFields();
            notification.success({
                message: t("msg.IPAPPR_6"),
            })
            func.ipCloseModel();
        } else {
            showErrorMsg(res,t);
        }
    }

    async function getIpHolderName() {
        const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        const params = {'userId': func.detailData.fromUserId,'languageId':languageId}
        const res = await ip_holder_name(params);
        if (res.data) {
            setIpHolderName(res.data.ipHolderName);
        } else {
            showErrorMsg(res,t);
        }
    }

    async function getIpHolderName2() {
        const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        const params = {'languageId':languageId}
        const res = await ip_holder_name2(params);
        if (res.data) {
            setIpHolderName(res.data.ipHolderName);
        } else {
            showErrorMsg(res,t);
        }
    }

    const deleteImg = () => {
        setImgUrl("");
    }

    const onChangeTo  = async (language:string,status:number) => {
        if(status==1){
            if(language=="JP"){
                setStatus("1");
            }else if(language=="EN"){
                setStatus("2");
            }else{
                setStatus("3");
            }
        }else if(status==2){
            if(language=="JP"){
                setStatus1("1");
            }else if(language=="EN"){
                setStatus1("2");
            }else{
                setStatus1("3");
            }
        }else{
            if(language=="JP"){
                setStatus2("1");
            }else if(language=="EN"){
                setStatus2("2");
            }else{
                setStatus2("3");
            }
        }
    };

    const deleteImgBanner = () => {
        setImgUrlBanner("");
    }

    const nftFlagValue = () => {
        if(flagValue=="2"){
            setFlagValue("1")
            setCheckStatus(false)
        }else{
            setFlagValue("2")
            setCheckStatus(true)
        }
        form.setFieldsValue({
            nftRequestFalg: flagValue,
        });
    }

    const nftExternalFlagValue = () => {
        if(externalFlagValue=="2"){
            setExternalFlagValue("1")
            setExternalStatus(false)
        }else{
            setExternalFlagValue("2")
            setExternalStatus(true)
        }
        form.setFieldsValue({
            nftExternalFalg: externalFlagValue,
        });
    }

    /*
    *
    * */
    const onhandleChangeStatus = (value: string) => {
        if (classes == '') {
            setClasses(value)
            // getData(page, direction, "0");
        } else {
            if (classes.includes(value)) {
                value = classes.replaceAll(value, "").replaceAll("_", "");
            } else {
                value = value + "_" + classes;
            }
            setClasses(value);
        }
    };
    return (
        <div className="minHeight">
            <input ref={fileReF} accept="image/*" type="file" onChange={(event) => uploadImg(event)}
                   className="hidden"/>
            <input ref={ipFileReF} accept="*" type="file" onChange={(event) => uploadFile(event)}
                   className="hidden"/>
            <div className={clsx(showMenu ? "main-container flex" : "main-container")}>
                {showMenu &&
                    <Menu/>
                }
                <div className={clsx(showMenu ? "w-4/5 text-center pl-10 pr-30 " : "px-40")}>
                    {!showTitle &&
                        <div style={{userSelect:"text"}}>
                            <div className="fs_h2 text-3xl py-7">
                                {t("page.IPREQUEST_ip-registration")}
                            </div>
                            <div className="pt-10 rounded-tr-xl  rounded-tl-xl">
                                <div className="fs_h3 text-2xl" style={{textAlign: "left", paddingBottom: "50px"}}>
                                    {t("page.IPREQUEST_ip-information")}
                                </div>
                            </div>
                        </div>
                    }

                    <Form ref={formRef} layout="vertical" form={form}>
                        <div>
                            <div className="rounded-br-xl  rounded-bl-xl">
                                <div className="grid grid-flow-col gap-3">
                                    <div className="row-span-3">
                                        <div style={{textAlign: "left",userSelect:"text"}} className="fs_16_bold">{t("page.IPREQUEST_ip-profile-picture")}<span
                                            style={{color: "red"}} className="pl-2">※</span></div>
                                        <div className="w-52 h-56 rounded-lg"
                                             style={{border: "1px grey solid",backgroundColor:"white"}}>
                                            <div
                                                style={{position: "absolute", paddingLeft: "175px"}}>
                                                <Button onClick={() => deleteImg()} style={{width:"30px"}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 45 45"
                                                        style={{marginLeft: "-23px",marginTop: "-5px", borderRadius: " 0 8px 0 16px"}}
                                                        >
                                                        <path
                                                            d="M0,0H45A20,20,0,0,1,65,20V65a0,0,0,0,1,0,0H20A20,20,0,0,1,0,45V0A0,0,0,0,1,0,0Z"
                                                            fill="#ccc"/>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="35"
                                                             height="35"
                                                             viewBox="-10 -10 40 40">
                                                            <path
                                                                d="M694.253,323.5l-10.96-10.96L673.04,322.789a2.5,2.5,0,0,1-3.535-3.535L679.758,309,669.5,298.747a2.5,2.5,0,0,1,3.535-3.535l10.253,10.253,10.96-10.96a2.5,2.5,0,0,1,3.536,3.535L686.828,309l10.96,10.96a2.5,2.5,0,1,1-3.536,3.536Z"
                                                                transform="translate(-668.772 -293.772)" fill="#fff"/>
                                                        </svg>
                                                    </svg>
                                                </Button>
                                            </div>
                                            <div className="grid w-52 h-56 items-center">
                                                {imgUrl ? <button className="" onClick={() => onUploadImg()}>
                                                        <img src={imgUrl} className="w-52 h-56 cover rounded-lg"/>
                                                    </button> :
                                                    <div>
                                                        <Button shape="round" size="large"
                                                                className="fs_16_white"
                                                                style={{
                                                                    minWidth: "125px",
                                                                    background: "#13c1f1",
                                                                    color: "#FFFFFF",
                                                                    fontWeight: "800",
                                                                    fontSize: "16px",
                                                                    userSelect:"text"
                                                                }}
                                                                onClick={() => onUploadImg()}>
                                                            {t("page.IPREQUEST_upload-picture")}
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-span-3" style={{paddingRight: "150px"}}>
                                        <div style={{textAlign: "left",userSelect:"text"}} className="fs_16_bold">{t("page.IPREQUEST_ip-banner")}<span
                                            style={{color: "red"}} className="pl-2">※</span></div>
                                        <div className="w-96 h-36 rounded-lg"
                                             style={{border: "1px grey solid", backgroundColor:"white"}}>
                                            <div
                                                style={{position: "absolute", paddingLeft: "351px"}}>
                                                <Button onClick={() => deleteImgBanner()} style={{width:"30px"}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 45 45"
                                                        style={{marginLeft: "-23px",marginTop: "-5px", borderRadius: " 0 8px 0 16px"}}>
                                                        <path
                                                            d="M0,0H45A20,20,0,0,1,65,20V65a0,0,0,0,1,0,0H20A20,20,0,0,1,0,45V0A0,0,0,0,1,0,0Z"
                                                            fill="#ccc"/>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="35"
                                                             height="35"
                                                             viewBox="-10 -10 40 40">
                                                            <path
                                                                d="M694.253,323.5l-10.96-10.96L673.04,322.789a2.5,2.5,0,0,1-3.535-3.535L679.758,309,669.5,298.747a2.5,2.5,0,0,1,3.535-3.535l10.253,10.253,10.96-10.96a2.5,2.5,0,0,1,3.536,3.535L686.828,309l10.96,10.96a2.5,2.5,0,1,1-3.536,3.536Z"
                                                                transform="translate(-668.772 -293.772)" fill="#fff"/>
                                                        </svg>
                                                    </svg>
                                                </Button>
                                            </div>
                                            <div className="grid w-96 h-36 items-center">
                                                {imgUrlBanner ? <button onClick={() => onUploadImg2()}>
                                                        <img src={imgUrlBanner} className="w-96 h-36 rounded-lg cover"/>
                                                    </button> :
                                                    <div>
                                                        <Button shape="round" size="large"
                                                                className="fs_16_white"
                                                                style={{
                                                                    minWidth: "125px",
                                                                    background: "#13c1f1",
                                                                    color: "#FFFFFF",
                                                                    fontWeight: "800",
                                                                    fontSize: "16px",
                                                                    userSelect:"text"
                                                                }}
                                                                onClick={() => onUploadImg2()}>
                                                            {t("page.IPREQUEST_upload-picture")}
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-span-3"></div>
                                </div>

                                <div className="pt-10" style={{userSelect:"text"}}>
                                    <div style={{color: "red", position: "absolute",paddingLeft: clsx(languageId==1?"100px":languageId==2?"115px":"60px"),fontSize:18 ,paddingTop:"2px"}}
                                         className="pl-2">※
                                    </div>
                                    <Form.Item name="ipTag" label={t("page.IPREQUEST_ipName-class")} style={{textAlign: "left"}}
                                               className="fs_18_bold">
                                        <div className="row" style={{backgroundColor:"transparent"}}>
                                            <Button
                                                onClick={() => onhandleChangeStatus("1")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #8bbf49",
                                                    backgroundColor: classes.includes("1") ? "#13c1f1" : "white",
                                                    color: classes.includes("1") ? "white" : "#8bbf49",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_cartoons")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("2")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #ff8a00",
                                                    backgroundColor: classes.includes("2") ? "#13c1f1" : "white",
                                                    color: classes.includes("2") ? "white" : "#ff8a00",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.HOME_anime")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("3")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #ad3fe0",
                                                    backgroundColor: classes.includes("3") ? "#13c1f1" : "white",
                                                    color: classes.includes("3") ? "white" : "#ad3fe0",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_pictures")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("4")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #ef68b4",
                                                    backgroundColor: classes.includes("4") ? "#13c1f1" : "white",
                                                    color: classes.includes("4") ? "white" : "#ef68b4",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_characters")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("5")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #5370ac",
                                                    backgroundColor: classes.includes("5") ? "#13c1f1" : "white",
                                                    color: classes.includes("5") ? "white" : "#5370ac",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_games")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("6")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #ffd11b",
                                                    backgroundColor: classes.includes("6") ? "#13c1f1" : "white",
                                                    color: classes.includes("6") ? "white" : "#ffd11b",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_illustrations")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("7")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #ff5400",
                                                    backgroundColor: classes.includes("7") ? "#13c1f1" : "white",
                                                    color: classes.includes("7") ? "white" : "#ff5400",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_music")}
                                            </Button>
                                            <Button
                                                onClick={() => onhandleChangeStatus("8")}
                                                className="rounded-3xl mr-2"
                                                style={{
                                                    borderRadius:"10px",
                                                    border:"3px solid #65ccdf",
                                                    backgroundColor: classes.includes("8") ? "#13c1f1" : "white",
                                                    color: classes.includes("8") ? "white" : "#65ccdf",
                                                    userSelect:"text"
                                                }}
                                            >
                                                {t("page.IPREQUEST_other")}
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </div>
                                <div style={{userSelect:"text"}}>
                                    <Button onClick={() => onChangeTo("JP",1)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status=="1" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status=="1" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius:"10px 10px 0 0",
                                                height:"40px",
                                                width:"95px",
                                                fontWeight:"800",
                                                marginRight:"10px",
                                                userSelect:"text"
                                            }}>
                                        日本語
                                    </Button>
                                    <Button onClick={() => onChangeTo("EN",1)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status=="2" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status=="2" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius:"10px 10px 0 0",
                                                height:"40px",
                                                width:"95px",
                                                marginRight:"10px",
                                                fontWeight:"800",
                                                userSelect:"text"
                                            }}>
                                        English
                                    </Button>
                                    <Button onClick={() => onChangeTo("ZH",1)}
                                            className="fs_18_bold"
                                            style={{
                                                backgroundColor: clsx(status=="3" ? "#d4f6ff" : "white"),
                                                borderColor: clsx(status=="3" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius:"10px 10px 0 0",
                                                marginRight: "570px",
                                                height:"40px",
                                                width:"95px",
                                                fontWeight:"800",
                                                userSelect:"text"
                                            }}>
                                        中文
                                    </Button>
                                </div>
                                { status=="1"?
                                    <div style={{background: "#d4f6ff",userSelect:"text"}} className="rounded-r-xl rounded-bl-xl px-10">
                                        <div style={{paddingTop: "40px"}}>
                                            <div style={{color: "red", position: "absolute", paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"135px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                 className="pl-2">※
                                            </div>
                                            <Form.Item name="ipName" label={t("page.IPREQUEST_name")} className="fs_18_bold">
                                                <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}/>
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <div style={{color: "red", position: "absolute", paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"138px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                 className="pl-2">※
                                            </div>
                                            <Form.Item name="ipDetails" label={t("page.IPREQUEST_summary")} className="fs_18_bold">
                                                <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                          rows={4}/>
                                            </Form.Item>
                                        </div>

                                        <Form.Item name="externalUrl" label={t("page.IPREQUEST_website")}
                                                   style={{textAlign: "left"}}
                                                   className="fs_18_bold">
                                            <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                   placeholder="https://XXXX"/>
                                        </Form.Item>
                                        <Form.Item name="twitterUrl" label={t("page.IPREQUEST_twitter")}
                                                   style={{textAlign: "left", paddingBottom: "40px"}}
                                                   className="fs_18_bold">
                                            <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                   placeholder="https://twitter.com/XXXX"/>
                                        </Form.Item>
                                    </div>
                                    :
                                    status=="2"?
                                    <div style={{background: "#d4f6ff",userSelect:"text"}} className="rounded-r-xl rounded-bl-xl px-10">
                                        <div style={{paddingTop: "40px"}}>
                                            <div style={{color: "red", position: "absolute",  paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"135px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                 className="pl-2">※
                                            </div>
                                            <Form.Item name="ipName1" label={t("page.IPREQUEST_name")} className="fs_18_bold">
                                                <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}/>
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <div style={{color: "red", position: "absolute",  paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"138px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                 className="pl-2">※
                                            </div>
                                            <Form.Item name="ipDetails1" label={t("page.IPREQUEST_summary")} className="fs_18_bold">
                                                <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                          rows={4}/>
                                            </Form.Item>
                                        </div>

                                        <Form.Item name="externalUrl1" label={t("page.IPREQUEST_website")}
                                                   style={{textAlign: "left"}}
                                                   className="fs_18_bold">
                                            <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                   placeholder="https://XXXX"/>
                                        </Form.Item>
                                        <Form.Item name="twitterUrl1" label={t("page.IPREQUEST_twitter")}
                                                   style={{textAlign: "left", paddingBottom: "40px"}}
                                                   className="fs_18_bold">
                                            <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                   placeholder="https://twitter.com/XXXX"/>
                                        </Form.Item>
                                    </div>

                                    :
                                    <div style={{background: "#d4f6ff",userSelect:"text"}} className="rounded-r-xl rounded-bl-xl px-10">
                                            <div style={{paddingTop: "40px"}}>
                                                <div style={{color: "red", position: "absolute",  paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"135px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                     className="pl-2">※
                                                </div>
                                                <Form.Item name="ipName2" label={t("page.IPREQUEST_name")} className="fs_18_bold">
                                                    <Input style={{borderRadius: "18px"}} disabled={formDisableFlag}/>
                                                </Form.Item>
                                            </div>

                                            <div>
                                                <div style={{color: "red", position: "absolute",  paddingLeft: clsx(i18n.language==="ja"?"60px":i18n.language==="en"?"138px":"60px"),fontSize:18,paddingTop:'2px'}}
                                                     className="pl-2">※
                                                </div>
                                                <Form.Item name="ipDetails2" label={t("page.IPREQUEST_summary")} className="fs_18_bold">
                                                    <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                              rows={4}/>
                                                </Form.Item>
                                            </div>

                                            <Form.Item name="externalUrl2" label={t("page.IPREQUEST_website")}
                                                       style={{textAlign: "left"}}
                                                       className="fs_18_bold">
                                                <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                       placeholder="https://XXXX"/>
                                            </Form.Item>
                                            <Form.Item name="twitterUrl2" label={t("page.IPREQUEST_twitter")}
                                                       style={{textAlign: "left", paddingBottom: "40px"}}
                                                       className="fs_18_bold">
                                                <Input style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                       placeholder="https://twitter.com/XXXX"/>
                                            </Form.Item>
                                        </div>
                                }



                                <div className="fs_h3 text-left" style={{userSelect:"text"}}>{t("page.IPAPPROVAL_license-information")}</div>
                                <div className="text-left pt-5" style={{userSelect:"text"}}>{t("page.IPCONFIRM_license-info")}<span style={{color: "red"}}>※</span></div>
                                <Form.Item name="nftRequestFalg" label="" style={{textAlign: "left",userSelect:"text"}}
                                           className="fs_16">
                                    {checkStatus?"":t("page.IPAPPROVAL_yes")}
                                    <Switch onChange={nftFlagValue} style={{backgroundColor: clsx(checkStatus?"#d4f6ff":"#1890ff"),width:"50px"}} checked={checkStatus}/>
                                    {checkStatus?t("page.IPAPPROVAL_no"):""}
                                </Form.Item>
                                <div style={{userSelect:"text"}}>
                                    <Button onClick={() => onChangeTo("JP",2)} disabled={checkStatus}
                                            style={{
                                                backgroundColor: clsx(status1 == "1" ? checkStatus ? "#eefbff" : "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "1" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                height: "40px",
                                                width: "95px",
                                                fontWeight: "800",
                                                marginRight: "10px",
                                                userSelect:"text"
                                            }}>
                                        日本語
                                    </Button>
                                    <Button onClick={() => onChangeTo("EN",2)} disabled={checkStatus}
                                            style={{
                                                backgroundColor: clsx(status1 == "2" ? checkStatus ? "#eefbff" : "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "2" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                height: "40px",
                                                width: "95px",
                                                marginRight: "10px",
                                                fontWeight: "800",
                                                userSelect:"text"
                                            }}>
                                        English
                                    </Button>
                                    <Button onClick={() => onChangeTo("ZH",2)} disabled={checkStatus}
                                            style={{
                                                backgroundColor: clsx(status1 == "3" ? checkStatus ? "#eefbff" : "#d4f6ff" : "white"),
                                                borderColor: clsx(status1 == "3" ? "#d4f6ff" : "#c7cdce"),
                                                color: "#333333",
                                                borderRadius: "10px 10px 0 0",
                                                marginRight: "570px",
                                                height: "40px",
                                                width: "95px",
                                                fontWeight: "800",
                                                userSelect:"text"
                                            }}>
                                        中文
                                    </Button>
                                </div>
                                {status1=="1"?
                                    <div style={{background:clsx( checkStatus ? "#eefbff" : "#d4f6ff"),userSelect:"text"}}
                                         aria-disabled={checkStatus}
                                         className="rounded-r-xl rounded-bl-xl px-10 pt-5 pb-5 ">
                                        {/*NFT作成に関する特記事項*/}
                                        <Form.Item name="ipContent"
                                                   label={t("page.IPREQUEST_remarks")}
                                                   className="fs_16_bold">
                                            <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                      readOnly={checkStatus}
                                                      placeholder="NFTライセンス付与に関する特記事項などをご記入ください"
                                                      rows={4}/>
                                        </Form.Item>
                                    </div>
                                    :
                                    status1=="2"?
                                    <div style={{background:clsx( checkStatus ? "#eefbff" : "#d4f6ff"),userSelect:"text"}}
                                         aria-disabled={checkStatus}
                                         className="rounded-r-xl rounded-bl-xl px-10 pt-5 pb-5 ">
                                        {/*NFT作成に関する特記事項*/}
                                        <Form.Item name="ipContent1"
                                                   label={t("page.IPREQUEST_remarks")}
                                                   className="fs_16_bold">
                                            <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                      readOnly={checkStatus}
                                                      placeholder="Fill out special notes on NFT licensing"
                                                      rows={4}/>
                                        </Form.Item>
                                    </div>
                                        :
                                        <div style={{background:clsx( checkStatus ? "#eefbff" : "#d4f6ff"),userSelect:"text"}}
                                             aria-disabled={checkStatus}
                                             className="rounded-r-xl rounded-bl-xl px-10 pt-5 pb-5 ">
                                            {/*NFT作成に関する特記事項*/}
                                            <Form.Item name="ipContent2"
                                                       label={t("page.IPREQUEST_remarks")}
                                                       className="fs_16_bold">
                                                <TextArea style={{borderRadius: "20px"}} disabled={formDisableFlag}
                                                          readOnly={checkStatus}
                                                          placeholder="请填写NFT许可证授予的特别事项等"
                                                          rows={4}/>
                                            </Form.Item>
                                        </div>
                                }

                            </div>
                            <Form.Item name="ipUsage"
                                       label={t("page.IPREQUEST_usage-rights")}
                                       style={{textAlign: "left",paddingTop:"10px",userSelect:"text"}}
                                       className="fs_16_bold">
                                <Radio.Group>
                                    <Radio
                                        value="1" defaultChecked>{t("page.IPREQUEST_licensable")}</Radio>
                                    <Radio
                                        value="2">{t("page.IPREQUEST_apply-for-license-")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div className="mt-10 py-10 rounded-xl" style={{userSelect:"text"}}>
                                <div className="fs_h3 text-left">{t("page.IPAPPROVAL_registration-of-nft")}</div>
                                <div className="text-left pt-5">{t("page.IPAPPROVAL_registration-of-nft-info")}<span
                                    style={{color: "red"}}>※</span></div>
                                <Form.Item name="nftExternalFalg" label="" style={{textAlign: "left"}}
                                           className="fs_16">
                                    {externalStatus ? "" : t("page.IPAPPROVAL_yes")}
                                    <Switch onChange={nftExternalFlagValue} style={{
                                        backgroundColor: clsx(externalStatus ? "#d4f6ff" : "#1890ff"),
                                        width: "50px"
                                    }} checked={externalStatus}/>
                                    {externalStatus ? t("page.IPAPPROVAL_no") : ""}
                                </Form.Item>

                                <div style={{background: clsx(externalStatus ? "#eefbff" : "#d4f6ff")}}
                                     className="rounded-xl px-10 pt-5 pb-2 ">

                                    <div className="text-left" style={{userSelect:"text"}}>
                                        <div className="fs_18 pt-5"
                                             style={{color: "grey"}}>{t("page.IPAPPROVAL_nft-collection-image")}<span
                                            className="fs_16" style={{color: "red"}}>※</span></div>
                                        <div className="fs_16 pt-5"
                                             style={{color: "grey"}}>{t("page.IPAPPROVAL_nft-collection-image-detial")}
                                        </div>
                                        <Button
                                            style={{
                                                borderRadius: "20px",
                                                color: "white",
                                                width: "200px",
                                                background: clsx(externalStatus ? "#a1e6f9" : "#13c1f1"),
                                                marginTop: "15px",
                                                userSelect:"text"
                                            }} disabled={formDisableFlag || externalStatus}
                                            onClick={() => onUpload()}>{t("page.NFTREQUESTFIR_choose-a-file")}</Button>
                                        {fileName && detailData && <a onClick={() => downloadFile()}>{fileName}</a>}
                                        {fileName && !detailData && <span>{fileName}</span>}

                                        <div className="fs_18 pt-10"
                                             style={{color: "grey"}}>{t("page.IPAPPROVAL_nft-collection-info")}<span
                                            className="fs_16" style={{color: "red"}}>※</span></div>
                                        <div className="fs_16 pt-5"
                                             style={{color: "grey"}}>{t("page.IPAPPROVAL_nft-collection-info-detial")}
                                        </div>
                                        <Button
                                            style={{
                                                borderRadius: "20px",
                                                color: "white",
                                                width: "200px",
                                                background: clsx(externalStatus ? "#a1e6f9" : "#13c1f1"),
                                                marginTop: "15px",
                                                userSelect:"text"
                                            }} disabled={formDisableFlag || externalStatus}
                                            onClick={() => onUploadFile()}>{t("page.NFTREQUESTFIR_choose-a-file")}</Button>
                                        {ipFileName && detailData && <a onClick={() => downloadFile()}>{ipFileName}</a>}
                                        {ipFileName && !detailData && <span>{ipFileName}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full relative pt-10" >
                            <IpHolderInfo/>
                            <div className="flex absolute top-0 right-0 pt-10" style={{userSelect:"text"}}>
                                <Button onClick={() =>
                                    window.open("mailto:?subject=&cc=&subject=主题&body=内容")
                                }
                                        style={{
                                            background: "#13c1f1",
                                            width: "150px",
                                            borderRadius: "20px",
                                            color: "white",
                                            fontSize: "16px",
                                            height: "35px",
                                            userSelect:"text"
                                        }}>
                                    {t("page.IPAPPROVAL_edit")}
                                </Button>
                            </div>
                        </div>

                        <input ref={fileReF} accept="*" type="file" onChange={(event) => uploadImg(event)}
                               style={{display: "none"}}
                        />
                        <input ref={imgReF} accept="image/*" type="file" onChange={(event) => uploadImg2(event)}
                               style={{display: "none"}}/>

                        <input ref={bannerReF} accept="image/*" type="file" onChange={(event) => uploadImg3(event)}
                               style={{display: "none"}}/>

                        {/*申请一览 申请*/}
                        {detailData && detailData.status === 1 && direction === 2 &&
                            <div style={{width: "100%", textAlign: "center", gap: "10%"}}>
                                <Button onClick={() => complete()} style={{
                                    background: "#303030",
                                    borderColor: "#333333",
                                    padding: "0px 40px",
                                    color: 'rgba(255, 255, 255)',
                                    userSelect:"text"
                                }} size={"large"}>作成</Button>
                                <Button style={{
                                    marginLeft: "10px",
                                    background: "#303030",
                                    borderColor: "#333333",
                                    padding: "0px 40px",
                                    color: 'rgba(255, 255, 255)',
                                    userSelect:"text"
                                }} size={"large"} onClick={() => reject()}>却下</Button>
                            </div>
                        }

                        {func.modelType !== "detail" &&
                            <div className="text-center text-white rounded-3xl"
                                 style={{paddingTop: "50px", paddingBottom: "50px",userSelect:"text"}}>
                                <button onClick={() => onFinish()}
                                    className="text-white bg-gradient-to-r from-yellow-300 to-jpnft-buttonTo fs_16_white w-auto h-8 rounded-3xl"
                                    style={{padding: "0px 40px",minWidth:"240px"}}>{t("page.IPREQUEST_confirm-ip-information")}</button>
                            </div>
                        }
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default IPForm;