import {Button, Form, Input, message, notification, Space} from 'antd';
import {sendInquiry} from "@/services";
import {RoutePaths} from "@/router";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { showErrorMsg } from '@/utils/error';

const {TextArea} = Input;

const Inquiry = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const onFinish = async (values: any) => {
        const res = await sendInquiry({
            name: values.username,
            email: values.mail,
            tel: values.tel,
            content: values.message
        });
        debugger
        if (res){
            notification.success({
                message: t("inquiry1"),
            });
            navigate(RoutePaths.HOME);
        }else{
            showErrorMsg(res,t);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <div className="minHeight">
                <div className="main-container">
                    <div className="px-80">
                        <div className="text-black text-3xl	font-semibold py-7">
                            {t("inquiry2")}
                        </div>
                        <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}
                              autoComplete="off">
                            <Form.Item name="username" label= {t("inquiry3")}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name="mail" label={t("email-address")}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name="tel" label={t("phone-number")}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name="message" label="メッセージ">
                                <TextArea rows={4} maxLength={500}/>
                            </Form.Item>
                            <Form.Item>
                                <Button className="w-full text-white"
                                        style={{background: "#303030", borderColor: "#333333"}} size={"large"}
                                        type="primary" htmlType="submit">
                                    {t("inquiry4")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Inquiry;