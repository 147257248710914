import React, { useEffect, useRef, useState } from "react";
import { ip_detail, ip_list, IpInfo } from "@/services";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { RoutePaths } from "@/router";
import { useTranslation } from "react-i18next";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import {Button, Tooltip} from "antd";
import Tag from "@/components/Tag";
import isMobile from "ismobilejs";

interface DataType {
    ownerId: number;
  ipId: number;
  ipBlockchainId: string;
  ipholderName: string;
  ipImageUrl: string;
  ipName: string;
  price: number;
  key: string;
  name: string;
  age: number;
  address: string;
  tagId: string;
  ipholders: IpHolderType[];
  ipInfos: IpInfo[];
}
type IpHolderType = {
  ipholderName: string;
};

const IPList = (props: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<DataType[]>([]);
  const fileReF = useRef<HTMLInputElement | null>(null);
  const [status, setStatus] = useState<string>("");
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

  const mobileFlg:any = isMobile(window.navigator).any ? true : false;
  console.log(mobileFlg,"Mobile")
  useEffect(() => {
    window.scrollTo(0, 0);
    getData("");
  }, []);

  const openDetail = async function (ipBlockchainId: string) {
    console.log(ipBlockchainId, "ip");
    navigate(`/ip/${ipBlockchainId}`);
  };

  async function getData(value: string) {
    // if (status.includes("1")) {
    //   setStatus("");
    // }
    if (state != null) {
      const res = await ip_list(value, fileReF.current?fileReF.current.value : "", state,languageId);
      if (res) {
        setTableData(res.data);
      } else {
        showErrorMsg(res, t);
      }
    } else {
      const res = await ip_list(value, fileReF.current?fileReF.current.value : "", "",languageId);
      if (res) {
        setTableData(res.data);
      } else {
        showErrorMsg(res, t);
      }
    }
  }

  const selectIp = () => {
    getData(status);
  };

  const onhandleChangeStatus = (value: string) => {
      debugger
      if(value==""){
          setStatus("");
          getData("")
      }else{
          if (status == '') {
              setStatus(value);
              getData(value);
          } else {
              if (status.includes(value)) {
                  value = status.replace(value+"_", "").replace("_"+value, "").replace(value,"");
              } else {
                  value = value + "_" + status;
              }
              if (value.includes("2") && value.includes("1") && value.includes("3") && value.includes("4") && value.includes("5") && value.includes("6") && value.includes("7") && value.includes("8")) {
                  setStatus("");
                  getData("");
              } else {
                  setStatus(value);
                  getData(value);
              }

          }
      }
  };

    //计算字符宽度,字符占2个,文字占3个，合计长度33
    const charLength = (value:any) => {
        let len = 0;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 3;
                } else {
                    len += 2;
                }
                if(len>33){
                    value = value.substring(0,i-1)+"..."
                    return value;
                }
            }
        }
        return value;
    }

    //计算字符总长，是否显示Tooltip
    const moreLength = (value:any) => {
        let len = 0;
        if(value!=null){
            for (let i = 0; i < value.length; i++) {
                if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
                    len += 19;
                } else {
                    len += 11;
                }
            }
            if(len>209){
                return true;
            }
        }
        return false;
    }

  return (
    <div className="minHeight text-left">
      <div className="main-container">
        <div className="">
          {/*<button className="fs_h2 pt-10">*/}
          {/*    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"*/}
          {/*         onClick={() => window.history.back()}*/}
          {/*         xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">*/}
          {/*        <path*/}
          {/*            d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"*/}
          {/*            p-id="2097"></path>*/}
          {/*    </svg>*/}
          {/*</button>*/}
          <div className="fs_h2 pt-5 text-center" style={{userSelect:"text"}}>IP</div>
            {!mobileFlg &&
            <>
              <div className="pt-10" style={{ display: "inline-block",userSelect:"text" }}>
                <div style={{ position: "absolute" }}>
                  <svg
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3500"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M383.3 699.8C217.4 699.8 82.5 564.9 82.5 399S217.4 98.2 383.3 98.2 684.1 233.1 684.1 399 549.2 699.8 383.3 699.8z m0-552.6c-138.8 0-251.7 112.9-251.7 251.7s112.9 251.7 251.7 251.7c138.8 0 251.8-112.9 251.8-251.7s-113-251.7-251.8-251.7z"
                      fill="#333333"
                      p-id="3501"
                    ></path>
                    <path
                      d="M558.272 600.948l33.412-35.975 349.791 324.87-33.412 35.976z"
                      fill="#333333"
                      p-id="3502"
                    ></path>
                  </svg>
                </div>
                <input
                  ref={fileReF}
                  style={{ border: "1px #DBDBDB solid", outline: "none",backgroundColor:"white",zIndex: "2",paddingLeft:"40px" }}
                  type="search"
                  className="rounded-tl-3xl rounded-bl-3xl h-8 w-80 text-black pl-6"
                  placeholder={t("page.IPLIST_keyword-search")}
                />
                <button
                  className="h-8 w-16 rounded-3xl"
                  onClick={() => selectIp()}
                  style={{ backgroundColor: "#13c1f1",zIndex: "1",marginLeft:"-10px" }}
                >
                  {t("page.IPLIST_search")}
                </button>
              </div>
              <div className="text-black pt-5" style={{userSelect:"text"}}>
              <button onClick={() => onhandleChangeStatus("1")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("1") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("1") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_cartoons")}
              </button>
              <button onClick={() => onhandleChangeStatus("2")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("2") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("2") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_anime")}
              </button>
              <button onClick={() => onhandleChangeStatus("3")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("3") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("3") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_pictures")}
              </button>
              <button onClick={() => onhandleChangeStatus("4")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("4") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("4") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_characters")}
              </button>
              <button onClick={() => onhandleChangeStatus("5")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("5") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("5") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_games")}
              </button>
              <button onClick={() => onhandleChangeStatus("6")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("6") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("6") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_illustrations")}
              </button>
              <button onClick={() => onhandleChangeStatus("7")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("7") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("7") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_music")}
              </button>
              <button onClick={() => onhandleChangeStatus("8")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status.includes("8") ? "#13c1f1" : "white"),
                          color: clsx(status.includes("8") ? "white" : "#333333")
                      }}>
                  {t("page.HOME_other")}
              </button>
              <button onClick={() => onhandleChangeStatus("")} className="rounded-3xl w-20 h-8 mx-2"
                      style={{
                          border:"1px solid #DBDBDB",
                          backgroundColor: clsx(status=="" ? "#13c1f1" : "white"),
                          color: clsx(status=="" ? "white" : "#333333")
                      }}>
                  {t("page.IPREQUESTLIST_all")}
              </button>
          </div>
            </>
            }

          <div className="w-full flex justify-center items-center mt-20 mb-10">
            {!mobileFlg && <div className="grid-cols-5 w-full grid  gap-1 gap-y-10 justify-items-center items-center">
              {tableData.map((item, index) => (
                  // recika ip display none
                <div
                  className={clsx("flex flex-col justify-center items-center")}
                  key={`ipList${index}`}
                >
                  <div
                    className="flex flex-col w-full h-80 justify-center items-center"
                    key={index}
                  >
                      <div className="w-11/12 h-4/6">
                          <button>
                              <img
                                  src={item.ipImageUrl}
                                  alt=""
                                  style={{maxHeight: "256px", objectFit: "cover"}}
                                  className="w-full ipRounded"
                                  onClick={() => {
                                      openDetail(item.ipBlockchainId);
                                  }}
                              />
                          </button>
                      </div>
                    <div className="w-full ml-5 mt-5 justify-center items-center">
                        <div className="text-lg text-black  max-h-16 w-full mt-2 text-center"
                             style={{
                                 width: "213px",
                                 display: "block",
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 userSelect:"text"
                             }}>
                            <Tooltip placement="topLeft"
                                     title={moreLength(item.ipInfos[languageId - 1]?.ipName) && item.ipInfos[languageId - 1]?.ipName}
                                     style={{position: "absolute"}}
                            >
                                {item.ipInfos[languageId - 1]?.ipName}
                            </Tooltip>
                        </div>
                        {/* <div className="w-full text-center h-20">
                            <Tag tagIdStr={item.tagId} />
                        </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>}
            {mobileFlg && <div className="grid-cols-1 w-full grid  gap-1 gap-y-10 justify-items-center items-center px-3">
              {tableData.map((item, index) => (
                  // recika ip display none
                <div
                  className={clsx("flex flex-col justify-center items-center")}
                  key={`ipList${index}`}
                >
                  <div
                    className="flex flex-col w-full h-auto justify-center items-center"
                    key={index}
                  >
                      <div className="w-full h-auto">
                          <button>
                              <img
                                  src={item.ipImageUrl}
                                  alt=""
                                  style={{ objectFit: "cover"}}
                                  className="w-full ipRounded"
                                  onClick={() => {
                                      openDetail(item.ipBlockchainId);
                                  }}
                              />
                          </button>
                      </div>
                    <div className="w-full mt-3 flex justify-start items-center">
                        <div className="text-lg text-black  max-h-16 w-auto max-w-full mt-2 text-center"
                             style={{
                                 width: "auto",
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 userSelect:"text"
                             }}>
                             {item.ipInfos[languageId - 1]?.ipName}
                        </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>}
          
          </div>
          {/* <div>
            {/* <div className="pt-10 w-full grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-60 2xl:gap-20 mb-40"> */}
          {/*<div className="pt-10 w-full grid grid-cols-5 gap-1 gap-y-10 justify-items-center items-center mb-40"></div>
            {tableData?.map(function (item, index) {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center"
                >
                  <div
                    className="flex w-full h-60 justify-center items-center"
                    key={index}
                    onClick={() => {
                      openDetail(item.ipBlockchainId);
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "15px",
                        width: "366px",
                        height: "400px",
                      }}
                    >
                      <img
                        src={item.ipImageUrl}
                        className="rounded-3xl h-full w-full cover"
                        onClick={() => {
                          openDetail(item.ipBlockchainId);
                        }}
                      />
                      <div
                        className="text-center"
                        style={{ wordBreak: "break-word" }}
                      >
                        <div className="fs_18_bold max-h-16 pt-2">
                          {" "}
                          {i18n.language === "ja"
                            ? item.ipInfos[0]?.ipName.length > 24
                              ? item.ipInfos[0]?.ipName.substring(0, 21) + "..."
                              : item.ipInfos[0]?.ipName
                            : i18n.language === "en"
                            ? item.ipInfos[1]?.ipName.length > 24
                              ? item.ipInfos[1]?.ipName.substring(0, 21) + "..."
                              : item.ipInfos[1]?.ipName
                            : item.ipInfos[2]?.ipName.length > 24
                            ? item.ipInfos[2]?.ipName.substring(0, 21) + "..."
                            : item.ipInfos[2]?.ipName}
                        </div>
                        <div className="h-12 fs_16 pt-2">
                          {" "}
                          {i18n.language === "ja"
                            ? item.ipholders[0]?.ipholderName.length > 24
                              ? item.ipholders[0]?.ipholderName.substring(
                                  0,
                                  21
                                ) + "..."
                              : item.ipholders[0]?.ipholderName
                            : i18n.language === "en"
                            ? item.ipholders[1]?.ipholderName.length > 40
                              ? item.ipholders[1]?.ipholderName.substring(
                                  0,
                                  37
                                ) + "..."
                              : item.ipholders[1]?.ipholderName
                            : item.ipholders[2]?.ipholderName.length > 26
                            ? item.ipholders[2]?.ipholderName.substring(0, 23) +
                              "..."
                            : item.ipholders[2]?.ipholderName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default IPList;
