import { Get, Post, Sessions } from "@/utils";
export type OrderId = {
  orderId: number; // 注文ID
};

export type OrderInfo = {
  data: {
    orderId: number;
    network: number;
    nftId: number;
    makerId: number;
    takerId: number;
    price: number;
    orderStatus: number;
    saleStartDate: string;
    saleEndDate: string;
    makerSig: string;
    sellSalt: string;
    lockingDate: string;
    created: string;
  };
};

/**
 * トークンの売る
 * @param params
 */

export const order_sell = async (params: any): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let formData = new FormData();
  formData.append(
    "orderRequest",
    new Blob([JSON.stringify(params)], { type: "application/json" })
  );

  let res = await Post("/order/sell", {
    body: formData,
    headers: headers,
  });
  if (res.ok && res.data) {
    return res.data;
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * 注文をロックする
 * @param params
 */

export const order_lock = async (params: {
  nftId: string; // nft
  networkId: number; // ネットワークID
}): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let res = await Get("/order/lock", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * 注文情報の取得
 * @param params
 */

export const order_info = async (params: {
  orderId?: number;
  nftId: string;
  networkId: number;
}): Promise<OrderInfo | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/order/info", params);
  if (res.ok && res.data) {
    return JSON.parse(res.data) as OrderInfo;
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * スマートコントラクトエラー
 * @param params
 */

export const order_contract_error = async (params: {
  contractAddress: string; // スマートコントラクトアドレス
  orderId: number; //  注文ID
  caller: string; //  呼び出し元アドレス
  parameter: string; //  パラメータ
  networkId: number; //	ネットワークID
  userId: number;
}): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const form = new FormData();
  form.append("orderId", params.orderId.toString());
  form.append("contractAddress", params.contractAddress);
  form.append("caller", params.caller);
  form.append("parameter", params.parameter);
  form.append("networkId", params.networkId.toString());
  form.append("userId", params.userId.toString());

  let res = await Post("/order/contractError", {
    body: form,
    headers: headers,
  });
  if (res.ok && res.data) {
    return res.data;
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * NFTの買う
 * @param params
 */

export const order_buy = async (params: any): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  let formData = new FormData();
  formData.append(
    "orderRequest",
    new Blob([JSON.stringify(params)], { type: "application/json" })
  );

  let res = await Post("/order/buy", {
    body: formData,
    headers: headers,
  });
  if (res.ok && res.data) {
    return res.data;
  } else {
    return JSON.parse(res.error);
  }
};

/**
 * 注文をアンロックする
 * @param params
 */

export const order_unlock = async (params: {
  userId: number;
  orderId: number;
}): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let res = await Get("/order/unlock", params, headers);
  if (res.ok && res.data) {
    return res.data;
  } else {
    return JSON.parse(res.error);
  }
};


/**
 * 注文情報listの取得
 * @param params
 */


export const order_list_info = async (
    nftId: string ,
    orderStatus: number
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {
    nftId: nftId,
    orderStatus:orderStatus
  };
  const res = await Get("/order/listInfo", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    debugger
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};


/**
 * 商品の取引履歴を取得する
 * @param nftId 
 * @param orderStatus 
 * @returns 
 */
export const all_order_info = async (
  nftId: string 
): Promise<any> => {
let headers = {
  "content-type": "application/json",
} as any;
let params = {
  nftId: nftId
};
const res = await Get("/order/allOrderInfo", params, headers);
if (res.ok && res.data) {
  return JSON.parse(res.data);
} else {
  debugger
  const list = Object.keys(res.error.msg).map((item, indx) => ({
    key: item,
    value: "",
  }));
  return list;
}
};
