import sha256 from 'crypto-js/sha256';

type HashInputDataType = {
  ipName: string;
  ipTag: string;
  systemDateTime: string;
  randomNum: number;
};
export const getHash = (param: HashInputDataType) => {
  const data = JSON.stringify(param);
  const hash = sha256(data).toString();
  return hash;
};
