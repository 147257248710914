import { NoticeObj, NoticeInfo, notice_list } from "@/services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "@/locales";
import moment from "moment";

const Notice = () => {
  const { t } = useTranslation();
  const [noticeList,setNoticeList] = useState<NoticeObj[]>();
  const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

  const fetchNoticeList = async ()=>{
   const res = await notice_list();
   if(res && res.data){
    setNoticeList(res.data);
   }
  }

  useEffect(() => {
    fetchNoticeList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="minHeight">
      <div className="main-container">
        <div className="px-5 md:px-60 mt-20 mb-10" style={{ userSelect: "text" }}>
          {/**title */}
          <div className="w-full flex justify-center items-center mb-2">
            <div className="font-bold text-2xl text-black md:text-4xl">{t("page.HOME_notice")}</div>
          </div>
          {/**notice */}
          <div className="w-full flex flex-col justify-center items-center mt-10 space-y-8">
            {noticeList && noticeList?.map((item,index)=>{
              const showDate = moment(item.showDate).utcOffset(9).format("yyyy-MM-DD HH:mm:ss");
              const objs:NoticeInfo[] = item.noticeInfo.filter(it=>{
                return it.langvageId === languageId;
              })
              return  <div key={`notice${index}`} className="w-full flex flex-col justify-start items-center">
              <div className="fs_16 md:text-lg font-bold w-full flex justify-start items-center">
                <div style={{ fontSize: "16px"}} className="text-jpnft-noticeGrey">{showDate.substring(0,10).replaceAll("-",".")}</div>
              </div>
              <div className="w-full flex justify-start items-center mt-2 cursor-pointer">
                <div className="notice-content text-black fs_16 md:text-lg" onClick={()=>{
                  window.open(objs[0].externalUrl);
                }}>
                 {objs[0].noticeTitle}
                </div>
              </div>
              <div className="w-full h-auto border-b border-jpnft-border mt-8"/>
            </div>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
