import { RoutePaths } from "@/router";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "@/assets/logo.png";
import certified from "@/assets/home/certified.png";
import jlod from "@/assets/home/jlod.svg";
import { Consumer } from "@/App";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function openContactUs() {
    window.open("https://forms.gle/GpLJLeHQDshiHrWe9");
  }

  return (
    <Consumer>
      {(ctx) => (
        <footer className="footer bg-jpnft-buttonTo">
          <div className="main-container">
            <div className="flex w-full flex-col space-y-5 md:space-y-0 md:flex-row md:justify-between">
              {ctx.width > 768 && (
                <div className="flex flex-col w-1/2 justify-start text-footer leading-5">
                  <div
                    className="fs_h3_white self-start cursor-pointer pr-36"
                    onClick={() => navigate(RoutePaths.HOME)}>
                    <img className="w-32" src={logo} />
                  </div>
                  <div
                    className="flex text-left fs_14_white pr-40 pt-6"
                    style={{ lineHeight: "24px", userSelect: "text" }}>
                    {t("page.HOME_jlod")}
                  </div>
                  <div className="flex pr-36 pt-2">
                    <img className="flex w-28" src={jlod} />
                    <img className="flex w-20 pl-2" src={certified} />
                  </div>
                </div>
              )}
              <div className="flex w-full md:w-auto flex-col relative text-left" style={{ userSelect: "text" }}>
                  <div className="fs_16_white text-white pb-4 font-semibold">{t("page.HOME_about-our-service")}</div>
                  {/*<Link to="/document/aboutJpnft">*/}
                  <button
                    className="fs_14_white text-white pb-1 text-left"
                    onClick={() => navigate(RoutePaths.ABOUT_JPNFT)}>
                    {t("page.HOME_about_jpnft")}
                  </button>
                  {/*</Link>*/}
                  <button
                    className="fs_14_white text-left pb-1"
                    onClick={() => window.open("https://jpnft.gitbook.io/jpnftwebusaitogaido/")}>
                    {t("page.HOME_user-guide")}
                  </button>
                  {/*<Link to="/document/toEveryone">*/}
                  <button
                    className="fs_14_white text-white text-left pb-1"
                    onClick={() => navigate(RoutePaths.TO_EVERY_ONE)}>
                    {t("page.HOME_how-to-register")}
                  </button>
                  {/*</Link>*/}
                  {/*<Link to="/document/point">*/}
                  {/*<button className="fs_14_white text-white pb-1 text-left" onClick={() => window.open(RoutePaths.POINT)}>{t("page.HOME_warnings")}</button>*/}
                  {/*</Link>*/}
                  <button
                    className="fs_14_white text-white text-left"
                    onClick={() => window.open("http://ipconnect.co.jp/")}>
                    {t("page.HOME_company-profile")}
                  </button>
              </div>
              <div className="flex flex-col relative text-left" style={{ userSelect: "text" }}>
                <div className="fs_16_white text-white pb-4 font-semibold">{t("page.HOME_others")}</div>
                <div className="fs_14_white text-white cursor-pointer" onClick={() => openContactUs()}>
                  {t("page.HOME_contact-us")}
                </div>
                <div className="fs_14_white text-white cursor-pointer" 
                    onClick={() => window.open("http://ipconnect.co.jp/#news")}>
                  {t("page.HOME_notice")}
                </div>
                <div className="fs_14_white text-white cursor-pointer" onClick={() => {
                  navigate(RoutePaths.PRIVACY_POLICY);
                }}>
                  {t("page.HOME_privacy-policy")}
                </div>
              </div>
            </div>
            <div
              className="text-sm text-center text-white"
              style={{ fontSize: "12px", marginTop: "-20px", userSelect: "text" }}>
              © 2024 IPconnect株式会社 All Rights Reserved
            </div>
          </div>
        </footer>
      )}
    </Consumer>
  );
};
export default Footer;
