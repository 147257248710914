import {Table, Typography} from "antd";
import type {TablePaginationConfig} from "antd/es/table";
import {useEffect, useState} from "react";
import {
    ip_royalty_details,
    ipRoyaltyReqList,
    collectionList,
    DataType,
} from "@/services";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import clsx from "clsx";
import {useAccountState} from "@/store";
import { useTranslation } from "react-i18next";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";

const {Title} = Typography;

const NftApproveList = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<DataType[]>([]);

    const [visible, setVisible] = useState(false);
    const [detailData, setDetailData] = useState<any>();
    const [irRequestId, setIrRequestId] = useState<number>();
    const [optionList, setOptionList] = useState([]);
    const [status, setStatus] = useState<string>("0");
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    //1:申請提出　２:申し込み受付
    const direction = 2;
    const accountState = useAccountState();
    const isIpholder = accountState.userType === 3 ? true : false;
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getData(page, direction, status);
    }, []);

    const openDetail = async function (value: any) {
        debugger;
        if (value.irRequestId) {
            setIrRequestId(value.irRequestId);
            const res = await ip_royalty_details(value.irRequestId);
            if (res && res.data) {
                // if (res.data.status === 12 && direction === 1) {
                //   // setTitle("IP使用権二次申請画面");
                // } else {
                //   // setTitle("使用権申請詳細");
                // }
                setDetailData(res.data);
            }
        }
        //查询下拉列表
        const res = await collectionList();
        if (res && res.data) {
            setOptionList(res.data);
        }
        setVisible(true);
    };

    async function getData(p: number, d: number, status: string) {
        let params: any = {};
        params["page"] = p;
        params["direction"] = d;
        params["isJpNft"] = accountState.userType === 1 ? true : false;
        if (status.includes("4")) {
            params["rejectFlg"] = "1";
        } else {
            params["rejectFlg"] = "0";
        }
        if (status.includes("5")) {
            params["cancelFlg"] = "1";
        } else {
            params["cancelFlg"] = "0";
        }
        params["status"] = status;
        const res = await ipRoyaltyReqList(params);
        if (res) {
            setTotal(res.data.total);
            setTableData(res.data.list);
            setPagination({
                current: res.data.pageNum,
                pageSize: res.data.pageSize,
            });
        }
    }

    const gotoWhere = (statusNum: number, irRequestId: number, rejectFlg: number, cancelFlg: number) => {

        const page = statusNum == 11 ? 1 : 12 ? 2 : 33 ? 4 : 3;
        if (rejectFlg == 1) {
            navigate(`/nftCreateDetails/${irRequestId}`, {
                state: {stepNum: page},
            });
        } else if (cancelFlg == 1) {
            navigate(`/nftCreateDetails/${irRequestId}`, {
                state: {stepNum: page},
            });
        } else {
            switch (statusNum) {
                //一次申請中
                case 11:
                    if (isIpholder) {
                        navigate("/nftApprove/" + irRequestId);
                    } else {
                        navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 1}});
                    }
                    break;
                //支払い確認済
                case 21:
                    if (isIpholder) {
                        navigate("/nftApproveTwo/" + irRequestId);
                    } else {
                        navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 2}});
                    }
                    break;
                //二次申請中
                case 31:
                    if (isIpholder) {
                        navigate("/nftApproveSecond/" + irRequestId);
                    } else {
                        navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 3}});
                    }
                    break;
                //NFT作成中
                case 32:
                    navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 3}});
                    break;
                case 33:
                    navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 4}});
                    break;
                default:
                    navigate(`/nftCreateDetails/${irRequestId}`, {state: {stepNum: 1}});
            }
        }
    };


    const onhandleChangeStatus = (value: string) => {
        if (value == "0") {
            setStatus("0")
            getData(page, direction, "0");
        } else {
            if (status == "0") {
                setStatus(value);
                getData(page, direction, value);
            } else {
                if (status.includes(value)) {
                    value = status.replace(value+"_", "").replace("_"+value, "").replace(value,"");
                    if (value == "") {
                        value = "0";
                    }
                } else {
                    value = value + "_" + status;
                }
                if (value.includes("11") && value.includes("12") && value.includes("21") && value.includes("31") && value.includes("32") && value.includes("33") && value.includes("4") && value.includes("5")) {
                    setStatus("0");
                    getData(page, direction, "0");
                } else {
                    setStatus(value);
                    if (value == "0") {
                        getData(page, direction, "0");
                    } else {
                        getData(page, direction, value);
                    }
                }
            }
        }
    };

    return (
        <div className="minHeight text-left">
            <div className="main-container flex">
                <Menu show={"5"} status={"2"}/>
                <div className="w-4/5 text-center pl-10 pr-30" style={{userSelect:"text"}}>
                    {/*<div className="text-center text-black text-4xl	font-semibold py-7">*/}
                    {/*    {t("page.NFTAPPROVALLIST_nft-creation-approval-list")}*/}
                    {/*</div>*/}
                    <button className="fs_h2 pb-5 pt-5" style={{float: "left"}}>
                        <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                             onClick={() => navigate(RoutePaths.MY_PAGE, {state: {show: "5", status: "2"}})}
                             xmlns="http://www.w3.org/2000/svg" p-id="2096" width="32" height="32">
                            <path
                                d="M747.245 81.064c-28.497-29.315-74.739-29.315-103.307 0l-367.236 378.011c-28.483 29.367-28.483 76.982 0 106.291l367.236 377.997c28.562 29.367 74.806 29.367 103.307 0 28.546-29.325 28.546-76.929 0-106.304l-315.6-324.841 315.599-324.803c28.545-29.367 28.544-76.973 0-106.356l0 0z"
                                p-id="2097"></path>
                        </svg>
                    </button>
                    <div>
                        <div className="text-black px-2 row" style={{backgroundColor:"transparent"}}>
                            <button
                                onClick={() => onhandleChangeStatus("11")}
                                className={clsx(languageId==2 ?  "rounded-3xl w-72 mx-2 text-left":languageId==1?"rounded-3xl w-40 mx-2":"rounded-3xl w-56 mx-2")}
                                style={{
                                    backgroundColor: status.includes("11") ? "#13c1f1" : "white",
                                    color: status.includes("11") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_application-1-awaiting-approval")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("12")}
                                className={clsx(languageId==2 ?  "rounded-3xl mx-2 text-left":"rounded-3xl w-40 mx-2")}
                                style={{
                                    backgroundColor: status.includes("12") ? "#13c1f1" : "white",
                                    color: status.includes("12") ? "white" : "#333333",
                                    width:clsx(languageId==2 ? "281px":"")
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_service-fee-awaiting-payment")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("21")}
                                className={clsx(languageId==2 ?  "rounded-3xl w-72 mx-2 text-left":"rounded-3xl w-40 mx-2")}
                                style={{
                                    backgroundColor: status.includes("21") ? "#13c1f1" : "white",
                                    color: status.includes("21") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_service-fee-paid")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("31")}
                                className={clsx(languageId==2 ?  "rounded-3xl w-72 mx-2 text-left":languageId==1?"rounded-3xl w-40 mx-2":"rounded-3xl w-56 mx-2")}
                                style={{
                                    backgroundColor: status.includes("31") ? "#13c1f1" : "white",
                                    color: status.includes("31") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_application-2-awaiting-approval")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("32")}
                                className="rounded-3xl w-20 mx-2"
                                style={{
                                    backgroundColor: status.includes("32") ? "#13c1f1" : "white",
                                    color: status.includes("32") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_issuing")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("33")}
                                className="rounded-3xl w-20 mx-2"
                                style={{
                                    backgroundColor: status.includes("33") ? "#13c1f1" : "white",
                                    color: status.includes("33") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_registered")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("5")}
                                className="rounded-3xl w-20 mx-2"
                                style={{
                                    backgroundColor: status.includes("5") ? "#13c1f1" : "white",
                                    color: status.includes("5") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_cancelled")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("4")}
                                className="rounded-3xl w-20 mx-2"
                                style={{
                                    minHeight:"32px",
                                    backgroundColor: status.includes("4") ? "#13c1f1" : "white",
                                    color: status.includes("4") ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_declined")}
                            </button>
                            <button
                                onClick={() => onhandleChangeStatus("0")}
                                className="rounded-3xl w-20 mx-2"
                                style={{
                                    backgroundColor: status == "0" ? "#13c1f1" : "white",
                                    color: status == "0" ? "white" : "#333333",
                                }}
                            >
                                {t("page.NFTAPPROVALLIST_all")}
                            </button>
                        </div>
                    </div>
                    <div className="border border-jpnft-border rounded-3xl text-black mb-10">
                        <table className="w-full">
                            <thead>
                            <tr
                                style={{backgroundColor: "#13c1f1", borderColor: "#d3d3d3"}}
                                className="h-12 text-white border-b"
                            >
                                <td
                                    style={{width: "5%", backgroundColor: "#13c1f1"}}
                                    className="text-center rounded-tl-3xl"
                                >
                                    {t("page.NFTAPPROVALLIST_no")}
                                </td>
                                <td className="pl-2" style={{width:clsx(languageId==2?"15%":"1%"),minWidth:"80px" }}>
                                    {t("page.NFTAPPROVALLIST_status")}
                                </td>
                                <td className="pl-2" style={{width: "15%"}}>
                                    {t("page.NFTAPPROVALLIST_applicant-name")}
                                </td>
                                <td className="pl-2" style={{width: "20%"}}>
                                    {t("page.IPAPPROVALLIST_ip-name")}
                                </td>
                                <td className="pl-2" style={{width: "15%"}}>
                                    {t("page.NFTAPPROVALLIST_collection-name")}
                                </td>
                                <td className="pl-2" style={{width: "10%"}}>
                                    {t("page.NFTREQUESTLIST_application-date")}
                                </td>
                                <td className="pl-2 rounded-tr-3xl" style={{width: "10%"}}>
                                    {t("page.NFTREQUESTLIST_application-info")}
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((item, index) => {
                                return (
                                    <tr
                                        style={{borderColor: "#d3d3d3"}}
                                        className={clsx(
                                            "h-12",
                                            index === tableData.length - 1
                                                ? ""
                                                : "border-b border-jpnft-border"
                                        )}
                                        key={index}
                                    >
                                        {tableData.length == index + 1 ? (
                                            <td
                                                style={{backgroundColor: "#aaedff"}}
                                                className="text-center rounded-bl-3xl"
                                            >
                                                {item.irRequestId}
                                            </td>
                                        ) : (
                                            <td
                                                style={{backgroundColor: "#aaedff"}}
                                                className="text-center"
                                            >
                                                {item.irRequestId}
                                            </td>
                                        )}

                                        <td className="px-1 fs_16_white">
                                            {item.statusDisplay == "一次申請承認待ち" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#5db8cd",
                                                        borderRadius: "5px",
                                                        maxWidth:clsx(languageId==2?"160px":"78px")
                                                    }}
                                                    className={clsx(languageId==2 ?  "text-center px-1":"")}
                                                >
                                                    {t("page.NFTAPPROVALLIST_application-1-awaiting-approval")}
                                                </div>
                                            ) : item.statusDisplay == "手数料支払い待ち" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#f49820",
                                                        borderRadius: "5px",
                                                        maxWidth:clsx(languageId==2?"150px":"78px")
                                                    }}
                                                    className={clsx(languageId==2 ?  "text-center px-1":"")}
                                                >
                                                    {t("page.NFTAPPROVALLIST_service-fee-awaiting-payment")}
                                                </div>
                                            ) : item.statusDisplay == "手数料支払い済み" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#4bf113",
                                                        borderRadius: "5px",
                                                        maxWidth:clsx(languageId==2?"160px":"78px")
                                                    }}
                                                    className={clsx(languageId==2 ?  "text-center px-1":"")}
                                                >
                                                    {t("page.NFTAPPROVALLIST_service-fee-paid")}
                                                </div>
                                            ) : item.statusDisplay == "二次申請承認待ち" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#5d8cc0",
                                                        borderRadius: "5px",
                                                        maxWidth:clsx(languageId==2?"160px":languageId==1?"78px":"80px")
                                                    }}
                                                    className={clsx(languageId==2 ?  "text-center px-1":"")}
                                                >
                                                    {t("page.NFTAPPROVALLIST_application-2-awaiting-approval")}
                                                </div>
                                            ) : item.statusDisplay == "取下げ" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#b1b1b1",
                                                        borderRadius: "5px",
                                                    }}
                                                    className="w-20"
                                                >
                                                    {t("page.NFTAPPROVALLIST_cancelled")}
                                                </div>
                                            ) : item.statusDisplay == "発行中" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#5d64c0",
                                                        borderRadius: "5px",
                                                    }}
                                                    className="w-20"
                                                >
                                                    {t("page.NFTAPPROVALLIST_issuing")}
                                                </div>
                                            ) : item.statusDisplay == "発行済み" ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "#a45dc0",
                                                        borderRadius: "5px",
                                                    }}
                                                    className="w-20"
                                                >
                                                    {t("page.NFTAPPROVALLIST_registered")}
                                                </div>
                                            )  : (
                                                <div
                                                    style={{
                                                        backgroundColor: "#ff4a4a",
                                                        borderRadius: "5px",
                                                    }}
                                                    className="w-20"
                                                >
                                                    {t("page.NFTAPPROVALLIST_declined")}
                                                </div>
                                            )}
                                        </td>
                                        <td
                                            className="pl-2"
                                            style={{backgroundColor: "#eafbff",wordBreak: "break-all"}}
                                        >
                                            {item.userType==3?item?.ipholders[languageId-1]?.ipholderName:item.fromName}
                                        </td>
                                        <td className="pl-2">
                                            <button
                                                style={{
                                                    textDecoration: "underline",
                                                    color: "#70daf7",
                                                }}
                                                onClick={() => gotoWhere(item.status, item.irRequestId, item.rejectFlg, item.cancelFlg)}
                                            >
                                                {item?.ipInfos[languageId-1]?.ipName}
                                            </button>
                                        </td>
                                        <td
                                            className="pl-2"
                                            style={{backgroundColor: "#eafbff"}}
                                        >
                                            {item.collectionName}
                                        </td>
                                        <td className="pl-2">{item.created}</td>
                                        {tableData.length == index + 1 ? (
                                            <td
                                                className="pl-2 rounded-br-3xl"
                                                style={{backgroundColor: "#eafbff"}}
                                            >
                                                {item.issueDate}
                                            </td>
                                        ) : (
                                            <td
                                                className="pl-2"
                                                style={{backgroundColor: "#eafbff"}}
                                            >
                                                {item.issueDate}
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    { total>10 &&
                        <Table
                            className="newPage"
                            pagination={{
                                total: total, //数据总数量
                                pageSize: pagination.pageSize,
                                onChange(current) {
                                    setPage(current);
                                    getData(current, direction, status);
                                },
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default NftApproveList;
