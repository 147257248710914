import { message } from "antd";
import { TFunction, useTranslation } from "react-i18next";

type Err = {
  key: string;
  value: string;
};
export const showErrorMsg = (
  errors: Err[],
  t: TFunction<"translation", undefined>
) => {
  if (errors.length === 1) {
    message.error(t(errors[0].key));
  } else {
    errors.map((item, _index) => {
      message.error(t(item.key));
    });
  }
};
