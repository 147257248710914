import { useEffect, useRef, useState } from "react";
import { Divider, Input, message, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountState } from "@/store";
import { nftBuy, nftSell } from "@/utils";
import { useTranslation } from "react-i18next";
import { BigNumber } from "ethers/lib/ethers";
import { RoutePaths } from "@/router";
import { NftDetails, nft_details } from "@/services";
import { NetWorks, normal } from "@/constants";
import { OrderInfo, order_info } from "@/services/order";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";


const NftDetail = () => {
    const accountState = useAccountState();
    const [nftData, setNftData] = useState<NftDetails>();
    const [orderInfo, setOrderInfo] = useState<OrderInfo | null>();
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [sellPrice, setSellPrice] = useState('0.0');
    const [dollar, setDollar] = useState('0.0');
    const salt = useRef<BigNumber>(BigNumber.from(0));
    const orderHash = useRef<string>();
    const { ipNftId } = useParams();
    const canSell = accountState.jpnft && nftData?.data.saleStatus === 1;
    const { t } = useTranslation();
    const navigate = useNavigate();


    const fetchNftDetail = async () => {
        //ipNftId
        const res = await nft_details(ipNftId);
        if (res && res.data) {
            setNftData(res);
            if (res.data.saleStatus === 2) {
                fetchOrderInfo();
            }
        }else{
            showErrorMsg(res,t);
        }
    }

    const fetchOrderInfo = async () => {
        const chain = parseInt(NetWorks[accountState.network!], 16)
        const res = await order_info({ 'networkId': chain, 'nftId': ipNftId! });
        if (res && res.data) {
            setOrderInfo(res);
        }
    }

    const handleCancel = () => {
        setIsPriceModalOpen(false);
    };
    const dollarPrice = (v: number) => {
        return (parseFloat('1573') * v).toFixed(2) ?? "0.0";
    }
    const onHndleSellPriceChange = (v: string) => {
        const dollar = dollarPrice(parseFloat(v));
        setSellPrice(v);
        setDollar(dollar);
    }

    const sell = async () => {
        if (!accountState.network) {
            message.warning(
                "Initialaze data load failed, please refresh current page again!"
            );
            return;
        }
        if (!accountState.isLogin) {
            message.warning(t("check_login_sync"));
            return;
        }
        setIsPriceModalOpen(false);
        const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        await nftSell(parseInt(ipNftId!), sellPrice, t, accountState, salt, orderHash,languageId);
        navigate(RoutePaths.HOME);
    }
    const onHandelSell = () => {
        if (!accountState.isLogin) {
            message.warning(t("rightreqfir4"));
            return;
        }
        setIsPriceModalOpen(true);
    }
    const buy = async () => {
        setIsApproveModalOpen(false);
        const languageId =
        i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
        await nftBuy(parseInt(ipNftId!), orderInfo?.data.price!, t, accountState,languageId);
        navigate(RoutePaths.HOME);

    }

    const onHandelBuy = () => {
        if (!accountState.isLogin) {
            message.warning(t("rightreqfir4"));
            return;
        }
        setIsApproveModalOpen(true);
    }
    useEffect(() => {
        fetchNftDetail();
    }, [])
    return (
        <div className="minHeight mt-5">
            <div className="main-container">
                {nftData &&
                    <div className="w-full">
                        <div className="flex w-full justify-center items-center">
                            <img src={normal + "/" + nftData.data.nftImage} alt="" width={700} height={200} />
                        </div>
                        <div className="flex w-full justify-center items-center">
                            <div className="flex space-x-4 mt-10">
                                <div className=" text-gray-400 text-lg">title</div>
                                <div className=" text-jpnft-grey text-xl font-bold">{nftData.data.nftDetails}</div>
                            </div>

                        </div>
                        <Divider className=" border-2" />
                        <div className="flex flex-col w-full justify-center items-center">
                            <div className="flex justify-center   space-x-4">
                                <div className=" text-gray-400 text-lg">作者</div>
                                <div className=" text-jpnft-grey text-xl font-bold">{nftData.data.creator}</div>
                            </div>
                            <div className="flex flex-row w-full justify-center space-x-10">
                                <div className="flex flex-row space-x-2">
                                    <div className=" text-gray-400 text-lg">終了日時</div>
                                    <div className=" text-jpnft-grey text-xl font-bold">07.Nov.2022 15:26</div>
                                </div>
                                <div className="flex flex-row space-x-2">
                                    <div className=" text-gray-400 text-lg">公開日</div>
                                    <div className=" text-jpnft-grey text-xl font-bold">07.Nov.2022</div>
                                </div>
                            </div>
                            {
                                !canSell && <div className="flex justify-center space-x-4">
                                    <div className=" text-gray-400 text-lg">価格</div>
                                    <div className=" text-jpnft-grey text-xl font-bold">0.2ETH</div>
                                </div>
                            }
                            <div className="flex w-full justify-center">
                                <div className="flex w-1/2 space-x-2 justify-center items-center">
                                    <button disabled={accountState.userId === nftData.data.ownerId || nftData.data.saleStatus === 1} className="w-1/3 text-white bg-jpnft-blue py-1 mt-2 rounded-md disabled:bg-gray-400" onClick={onHandelBuy}>仮想通貨決済</button>
                                    <button disabled={!canSell} className="w-1/3 text-white bg-jpnft-blue  py-1 mt-2 rounded-md disabled:bg-gray-400" onClick={onHandelSell}>販売</button>
                                </div>
                            </div>
                            <div className="flex w-full justify-center space-x-4 mt-2">
                                <p className=" text-jpnft-blue text-base">所有者 JPNFT</p>
                            </div>
                        </div>
                    </div>
                }
                <Modal title="販売" open={isPriceModalOpen}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <div className="w-full flex flex-col">
                        <div className="text-lg font-bold text-gray-600">価格</div>
                        <div className="mt-2">
                            <Input addonBefore="ETH" addonAfter={`$${dollar}`} type="number" onChange={(e: any) => onHndleSellPriceChange(e.target.value)} />
                        </div>
                        <div className="w-full flex justify-center items-center mt-5">
                            <button className="w-2/3 bg-gray-500 text-white py-2 rounded-md" onClick={sell}>販売する</button>
                        </div>
                    </div>

                </Modal>
                <Modal title="購入" open={isApproveModalOpen}
                    footer={null}
                    onCancel={() => setIsApproveModalOpen(false)}
                >
                    <div className="w-full flex flex-col">
                        <div className="flex w-full justify-between">
                            <div className="flex space-x-2">
                                <div>
                                    <img src={normal + "/" + nftData?.data.nftImage} alt="" width={180} height={200} className=" rounded-md" />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-lg">{nftData?.data.nftDetails}</div>
                                    <div className="text-lg text-gray-500">Chain:Goerli</div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className=" font-semibold text-lg">0.00002ETH</div>
                                <div className="text-lg text-gray-500">${dollarPrice(0.2)}</div>
                            </div>
                        </div>
                        <Divider />
                        <div className="text-black font-semibold text-base">
                            Go to your wallet
                        </div>
                        <div className="text-black text-base">
                            You'll be asked to approve this purchase from your wallet.
                        </div>
                        <div className="w-full flex justify-center items-center mt-5">
                            <button className="w-2/3 bg-gray-500 text-white py-2 rounded-md" onClick={buy}>購入する</button>
                        </div>
                    </div>

                </Modal>
            </div>
        </div>
    );
};

export default NftDetail;