import { normal } from "@/constants";
import { ip_holder_info } from "@/services";
import { useAccountState } from "@/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";

const IpHolderInfo = (props: any) => {
  const { t } = useTranslation();
  const accountState = useAccountState();
  const [ipHolderInfo, setIpHolderinfo] = useState<{
    data: {
      userId: number;
      ethAddress: string;
      proxyAddress: string;
      ipholderName: string;
      postCode: string;
      address: string;
      tel: string;
      email: string;
      avatar: string;
      userType: number;
      managerName: string;
      managerDepartment: string;
      managerPosition: string;
    };
  }>();
  const getIpHolderInfo = async () => {
    const userId =
      props.userId === undefined ? accountState.userId : props.userId;
    const languageId =
      i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
    const res = await ip_holder_info(userId, languageId);
    if (res) {
      setIpHolderinfo(res);
    } else {
      showErrorMsg(res, t);
    }
  };
  useEffect(() => {
    getIpHolderInfo();
  }, [props.userId]);
  return (
    <div className="w-full">
      <div className="w-full text-left fs_h3" style={{userSelect:"text"}}>
        {t("page.IPREQUEST_applicant-information")}
      </div>
      <div className="flex w-full mt-5" style={{ height: "auto",userSelect:"text" }}>
        <div style={{userSelect:"text"}} className="flex flex-col h-full w-1/3 bg-jpnft-tbHeaderBg border-t border-b border-l border-jpnft-border rounded-tl-3xl  rounded-bl-3xl text-left">
          <div
            className="w-full border-b border-jpnft-border px-5 flex justify-start items-center"
            style={{ height: "220px" }}
          >
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_ip-profile-picture")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_company-name-individual-name")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_address")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {" "}
              {t("page.IPHOLDER_managerName")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_managerDepartment")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {" "}
              {t("page.IPHOLDER_managerPosition")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_phone-number")}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_email-address")}
            </div>
          </div>
          <div className="w-full px-5 h-20 flex justify-start items-center">
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {t("page.IPHOLDER_organization-type")}
            </div>
          </div>
        </div>
        <div className="flex flex-col h-full w-2/3 border border-jpnft-border rounded-tr-3xl  rounded-br-3xl text-left">
          <div className="w-full border-b border-jpnft-border px-5 flex justify-start items-center" style={{height:"220px",userSelect:"none"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              <img
                src={normal + "/" + ipHolderInfo?.data.avatar}
                alt=""
                h-56
                style={{maxWidth:"100%",maxHeight:"210px" }}
                className=" rounded-md"
              />
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.ipholderName}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold flex">
              {ipHolderInfo?.data.postCode !== null && ipHolderInfo?.data.postCode !== undefined ? (
                <div>{"〒" + ipHolderInfo?.data.postCode}</div>
              ) : null}
              {ipHolderInfo?.data.address !== null && ipHolderInfo?.data.address !== undefined ? (
                <div>{ipHolderInfo?.data.address}</div>
              ) : null}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.managerName !== null && ipHolderInfo?.data.managerName !== undefined
                ? ipHolderInfo?.data.managerName
                : ""}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.managerDepartment !== null && ipHolderInfo?.data.managerDepartment !== undefined
                ? ipHolderInfo?.data.managerDepartment
                : ""}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.managerPosition !== null && ipHolderInfo?.data.managerPosition !== undefined
                ? ipHolderInfo?.data.managerPosition
                : ""}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.tel !== null && ipHolderInfo?.data.tel !== undefined ? ipHolderInfo?.data.tel : ""}
            </div>
          </div>
          <div className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.email !== null && ipHolderInfo?.data.email !== undefined
                ? ipHolderInfo?.data.email
                : ""}
            </div>
          </div>
          <div className="w-full px-5 h-20 flex justify-start items-center" style={{userSelect:"text"}}>
            <div className="text-base text-jpnft-tbFtColor font-semibold">
              {ipHolderInfo?.data.userType === undefined
                ? ""
                : ipHolderInfo?.data.userType === 2
                ? t("page.IPHOLDER_individual")
                : t("page.IPHOLDER_company")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IpHolderInfo;
