import {Form, message, notification} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "@/router";
import {
    HolderInfo,
    ip_holder_list_info, ip_royalty_file_download, ip_royalty_approve, ip_royalty_details, ip_royalty_rejection,RequestDetailInfo,
} from "@/services";
import React, {useEffect, useState} from "react";
import NftMessage from "@/components/NftMessage";
import JpnftSteps from "@/components/JpnftSteps";
import {useAccountState} from "@/store";
import TextArea from "antd/lib/input/TextArea";
import {useTranslation} from "react-i18next";
import {normal} from "@/constants";
import { showErrorMsg } from "@/utils/error";
import i18n from "@/locales";
import Menu from "@/pages/Mypage/Menu";


const NftApproveSecond = () => {
    const { t } = useTranslation();
    const [ipHolderInfo, setIpHolderInfo] = useState<HolderInfo | null>();
    const navigate = useNavigate();
    const accountState = useAccountState();
    const {irRequestId} = useParams();
    const [requestDetail, setRequestDetail] = useState<RequestDetailInfo>();
    const [form] = Form.useForm();
    const languageId = i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;

    const getDetail = async function (irRequestId: string) {
        if (irRequestId) {
            const res = await ip_royalty_details(irRequestId);
            if (res) {
                setRequestDetail(res);
                if(res?.data.fromInfo.fromUserType == 3){
                    const userId = res?.data.fromInfo.fromUserId;
                    const languageId =
                    i18n.language === "ja" ? 1 : i18n.language === "en" ? 2 : 3;
                    const res01 = await ip_holder_list_info(userId);
                    if (res01) {
                        setIpHolderInfo(res01);
                    }else{
                        showErrorMsg(res,t);
                    }
                }
            }else{
                showErrorMsg(res,t);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(irRequestId, "irRequestId")
        if (irRequestId) {
            getDetail(irRequestId);
        }
    }, []);

    const onFinish = async (values: any) => {
        const id = parseInt(irRequestId!);
        const params = {
            approveFlag: true,
            irRequestId: id,
            fee: values.fee,
            royalty: values.royalty,
            royalty2: values.royalty2,
            royaltyN: values.royaltyN,
        }
        const res = await ip_royalty_approve(params);
        if (res) {
            notification.success({
                message: t("msg.NFT_4"),
            });
            navigate(RoutePaths.NFT_APPROVE_LIST);
        }else{
            showErrorMsg(res,t);
        }
    };

    const reject = async () => {
        const params = {
            approveFlag: false,
            irRequestId: parseInt(irRequestId!),
        }

        const res = await ip_royalty_rejection(params);
        if (res) {
            notification.success({
                message: t("msg.IPAPPR_6"),
            })
            navigate(RoutePaths.NFT_APPROVE_LIST);
        }else{
            message.error(t(Object.keys(res.msg)[0]));
        }
    }

    const downloadFile = async (fileId: number | undefined, fileName: string | undefined) => {
        if (!fileId) {
            return;
        }
        const fName: string | undefined = fileName;
        const result = await ip_royalty_file_download(fileId);
        if (result && result.code > 0) {
            let msg = Object.keys(result.msg)[0];
            message.error(msg);
            return;
        }
        if (result) {
            var blob = new Blob([result]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            if (typeof fName === "string") {
                downloadElement.download = fName;
            }
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
        }
    }

    return (
        <div className="minHeight">
            <div className="main-container flex">
                <Menu/>
                <div className="w-4/5 text-center pl-10 pr-30" style={{userSelect:"text"}}>
                    <div className="py-7 fs_h1">{t("page.NFTAPPROVALFIR_nft-creation-approval")}</div>

                    <div className="px-14">
                        <JpnftSteps current={3}/>
                    </div>
                    <div className="w-full pt-10">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="rounded-2xl pt-10" style={{backgroundColor: "#F8CE0F"}}>
                                <div className="fs_h2">
                                    {t("page.NFTAPPROVALSEC_confirm-application-2")}
                                </div>
                                <div className="fs_16 pb-5">
                                    {t("page.NFTAPPROVALSEC_confirm-application-2-description")}
                                </div>
                            </div>

                            <div className="rounded-2xl mt-10 pt-10" style={{backgroundColor: "#D4F6FF"}}>
                                <div className="fs_h3 text-left pl-10">{t("page.NFTAPPROVALSEC_application-2-information")}</div>
                                <div className="flex w-full mt-5 pl-10 pb-10 pr-10" style={{height: "200px"}}>
                                    <div
                                        className="flex flex-col h-full w-1/3 border-t border-b border-l border-jpnft-border bg-jpnft-tbHeaderBg rounded-tl-3xl  rounded-bl-3xl text-left">
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_finalized-creation-proposal")}
                                            </div>
                                        </div>
                                        <div className="w-full px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_finalized-nft-image")}</div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col border border-jpnft-border h-full w-2/3 rounded-tr-3xl  rounded-br-3xl text-left">
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                <div className="rounded-md w-max">
                                                    <a style={{
                                                        textDecoration: "underline",
                                                        color: "#13c1f1"
                                                    }}
                                                       className="fs_16_bold"
                                                       onClick={() => downloadFile(requestDetail?.data.nftInfo.nftDescFileId, requestDetail?.data.nftInfo.nftDescFileName)}>{requestDetail?.data.nftInfo.nftDescFileName}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="rounded-md w-max">
                                                <a style={{
                                                    textDecoration: "underline",
                                                    color: "#13c1f1"
                                                }}
                                                   className="fs_16_bold"
                                                   onClick={() => downloadFile(requestDetail?.data.nftInfo.nftImageFileId, requestDetail?.data.nftInfo.nftImageFileName)}>{requestDetail?.data.nftInfo.nftImageFileName}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Form.Item style={{paddingTop: "20px"}}>
                                <button
                                    className="text-white w-40 h-8 rounded-3xl fs-16 font-semibold"
                                    style={{
                                        background: "white",
                                        borderColor: "#13c1f1",
                                        boxShadow: "inset 0px 0px 0px 7px",
                                        color: "#13c1f1"
                                    }}
                                    onClick={() => reject()}>
                                    {t("page.IPAPPROVAL_declined")}
                                </button>
                                <button
                                    className="bg-gradient-to-r from-jpnft-buttonFrom to-jpnft-buttonTo w-40 h-8 rounded-3xl text-white ml-10 font-semibold">
                                    {t("page.NFTAPPROVALSEC_approve")}
                                </button>
                            </Form.Item>

                            <NftMessage userId={requestDetail?.data.fromInfo.fromUserId}/>

                            <div className="rounded-2xl mt-10 pt-10" style={{backgroundColor: "#D4F6FF"}}>
                                <div className="fs_h3 text-left pl-10">{t("page.NFTAPPROVALSEC_service-fee-payment")}</div>
                                <div className="flex w-full mt-5 pl-10 pb-10 pr-10" style={{height: "360px"}}>
                                    <div
                                        className="flex flex-col h-full w-2/5 border-t border-b border-l border-jpnft-border bg-jpnft-tbHeaderBg rounded-tl-3xl  rounded-bl-3xl text-left">
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_nft-creation-fee")}</div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div
                                                className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_primary-sales-royalty")+t("page.NFTAPPROVALSEC_primary-sales-royalty-description")}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div
                                                className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_secondary-sales-royalty")+t("page.NFTAPPROVALSEC_secondary-sales-royalty-description")}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALFIR_sales-royalty-distribution")}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col border border-jpnft-border h-full w-3/5 rounded-tr-3xl  rounded-br-3xl text-left">
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                {requestDetail?.data.fee}{t("page.NFTAPPROVALSEC_en")}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                {requestDetail?.data.royalty}%
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                {requestDetail?.data.royalty2}%
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                {requestDetail?.data.royaltyN}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fs_h3 text-left pl-10">{t("page.NFTAPPROVALSEC_application-1-information")}</div>
                                <div className="flex w-full mt-5 pl-10 pb-10 pr-10" style={{height: "600px"}}>
                                    <div
                                        className="flex flex-col h-full w-1/3 border-t border-b border-l border-jpnft-border bg-jpnft-tbHeaderBg rounded-tl-3xl  rounded-bl-3xl text-left">
                                        {requestDetail?.data.fromInfo.fromUserType === 2 ?
                                            <>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_username")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_twitter")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_linktree")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_openSea")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_self-introduction")}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="w-full border-b border-jpnft-border px-5 flex justify-start items-center" style={{height:"272px"}}>
                                                    <div className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_ip-profile-picture")}</div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_company-name-individual-name")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_address")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_phone-number")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_email-address")}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">{t("page.IPHOLDER_organization-type")}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div
                                                className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_application-1-information")}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div
                                                className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_nft-creation-proposal")}
                                            </div>
                                        </div>
                                        <div className="w-full px-5 h-20 flex justify-start items-center">
                                            <div
                                                className="text-base text-jpnft-tbFtColor font-semibold">{t("page.NFTAPPROVALSEC_nft-image-draft")}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col border border-jpnft-border h-full w-2/3 rounded-tr-3xl  rounded-br-3xl text-left">
                                        {requestDetail?.data.fromInfo.fromUserType == 2 ?
                                            <>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        <div className="h-16"
                                                             style={{
                                                                 display: "inline-block",
                                                                 verticalAlign: "top"
                                                             }}>
                                                            <img
                                                                className="w-full h-full" style={{userSelect:"none"}}
                                                                src={normal  + "/" + requestDetail?.data.fromInfo.fromAvatar!}/>
                                                        </div>
                                                        <div style={{
                                                            display: "inline-block",
                                                            verticalAlign: "top"
                                                        }}
                                                             className="pt-5 pl-3">{requestDetail?.data.fromInfo.fromName}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {requestDetail?.data.ipInfo.twitterUrl}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {requestDetail?.data.ipInfo.linktreeUrl}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {requestDetail?.data.ipInfo.openseaUrl}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {requestDetail?.data.fromInfo.fromDetails}
                                                    </div>
                                                </div>
                                            </>
                                            : <>
                                                <div className="w-full border-b border-jpnft-border px-5 h-56 flex justify-start items-center">
                                                    <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                        <img
                                                            src={normal + "/" + ipHolderInfo?.data.ipholders[languageId-1].avatar}
                                                            style={{height: "165px",userSelect:"none" }}
                                                            className="rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {ipHolderInfo?.data.ipholders[languageId-1].ipholderName}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {`${ipHolderInfo?.data.ipholders[languageId-1].postCode === undefined ? '' : '〒' + ipHolderInfo?.data.ipholders[languageId-1].postCode}　${ipHolderInfo?.data.ipholders[languageId-1].address === undefined ? '' : ipHolderInfo?.data.ipholders[languageId-1].address}`}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {ipHolderInfo?.data.ipholders[languageId-1]?.tel}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {ipHolderInfo?.data.ipholders[languageId-1]?.email}
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                                    <div
                                                        className="text-base text-jpnft-tbFtColor font-semibold">
                                                        {ipHolderInfo?.data.userType === undefined ? '' : ipHolderInfo?.data.userType === 2 ? languageId==1?'個人':languageId==2?"Individual":"个人"  : languageId==1?'法人':languageId==2?"Company":"法人"}
                                                    </div>
                                                </div>
                                            </>}
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                {requestDetail?.data.collectionInfo.name}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-b border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="text-base text-jpnft-tbFtColor font-semibold">
                                                <div className="rounded-md w-max">
                                                    <a style={{
                                                        textDecoration: "underline",
                                                        color: "#13c1f1"
                                                    }}
                                                       className="fs_16_bold"
                                                       onClick={() => downloadFile(requestDetail?.data.nftInfo.kariNftDescFileId, requestDetail?.data.nftInfo.kariNftDescFileName)}> {requestDetail?.data.nftInfo.kariNftDescFileName}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="w-full border-jpnft-border px-5 h-20 flex justify-start items-center">
                                            <div className="rounded-md w-max">
                                                <a style={{textDecoration: "underline", color: "#13c1f1"}}
                                                   className="fs_16_bold"
                                                   onClick={() => downloadFile(requestDetail?.data.nftInfo.kariImageFileId, requestDetail?.data.nftInfo.kariImageFileName)}> {requestDetail?.data.nftInfo.kariImageFileName}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fs_h3 pt-10 pb-2 text-left pl-10">{t("page.NFTAPPROVALSEC_ip-information")}</div>
                                <div className="grid grid-rows-3 grid-flow-col pl-10">
                                    <div className="row-span-4">
                                        <div
                                            className="w-52"
                                            style={{ borderRadius: "15px" }}
                                        >
                                            <img
                                                src={requestDetail?.data.ipInfo.ipImageUrl}
                                                className="h-full cover rounded-3xl" style={{userSelect:"none"}}
                                            />
                                        </div>
                                        <div className="text-left w-52 pt-3 pb-10">
                                            {requestDetail?.data.ipInfo.ipInfos[languageId-1].ipName}
                                        </div>
                                    </div>
                                    <div className="col-span-2 w-96 text-left ">
                                        <div className="fs_h3">{t("page.NFTREQUESTFIR_ip-holder")}</div>
                                        <div className="fs_16">
                                            {requestDetail?.data.ipInfo?.ipholders[languageId-1].ipholderName}
                                        </div>
                                    </div>
                                    <div className="row-span-2 col-span-2 text-left py-5 pr-5">
                                        <div className="fs_h3 w-96">{t("page.NFTREQUESTFIR_important-nft-creation")}</div>
                                        <TextArea readOnly
                                                  className="fs_16"
                                                  value={requestDetail?.data.ipInfo?.ipInfos[languageId-1].ipContent} rows={4}
                                                  style={{borderColor: "#D4F6FF",backgroundColor:"#D4F6FF",resize:"none"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NftApproveSecond;
