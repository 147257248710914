import { Get, Post, Sessions } from "@/utils";
import { DefaultApiUrl } from "@/constants";

/**
 * IP作成申請一覧
 *
 * @returns {AU01}
 */
export const ipRequestList = async (params: any): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/request/list", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP申請詳細
 *
 * @returns {AU01}
 */
type FileType = {
  reqFileId: number;
  fileName: string;
  filePath: string;
  filePurpose: number;
};
export type HolderInfo = {
  data:{
    userId: number;
    ethAddress: string;
    proxyAddress: string;
    ipholderName: string;
    postCode: string;
    address: string;
    tel: string;
    email: string;
    avatar: string;
    userType: number;
    managerName: string;
    managerDepartment:string;
    managerPosition:string;
    ipholders:IpHolderInfo[];
  }
};
export type IpRequestDetails = {
  data: {
    ipRequestId: number;
    requestType: number;
    fromUserId: number;
    toUserId: number;
    parentIpAddress: string;
    ipAddress: string;
    ipUsage: number;
    status: number;
    ipContent: string;
    ipLogo: string;
    ipDetails: string;
    requestTypeDisplay: string;
    ipUsageDisplay: string;
    statusDisplay: string;
    createTimeDisplay: string;
    updateTimeDisplay: string;
    files: FileType[];
    ipHash: string;
    ipBanner: string;
    userName: string;
    langvageId: string;
    nftRequestFalg: string;
    nftExternalFalg: string;
    ipTag: string;
    fileName: string;
    ipFileName: string;
    fileId: string;
    ipFileId: string;
    ipInfo:IpInfo[];
    ipFiles: FileType[];
  };
};
export const ip_details = async (ipReqId: string): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/request/" + ipReqId, {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP一覧
 *
 * @returns {AU01}
 */
export const ip_list = async (
  ipType: string | undefined,
  keyWord: string | undefined,
  userId: string | undefined,
  languageId: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  let params = {
    ipType: ipType,
    keyWord: keyWord,
    userId: userId,
    languageId: languageId,
  };
  const res = await Get("/ip/list", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP一覧TOP
 *
 * @returns {AU01}
 */
export const ip_list_top = async (page: number): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  const res = await Get("/ip/listTop", { page: page }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP詳細
 *
 * @returns {AU01}
 */
export const ip_detail = async (
  ipBlockchainId: string,
  languageId: number | null
): Promise<any | null> => {
  const res = await Get(
    "/ip/" + ipBlockchainId + "/" + languageId,
    {},
  );
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP作成完了
 * @param params
 */

export const ip_request = async (params: any): Promise<any> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let formData = new FormData();
  formData.append(
    "ipRequest",
    new Blob([JSON.stringify(params.form)], { type: "application/json" })
  );
  debugger
  formData.append("files", params.form.files[0]?.file);
  formData.append("files", params.form.files[1]?.file);
  formData.append("files", params.form.files[2]?.file);
  formData.append("ipFiles", params.form.ipFiles[0]?.ipFile);
  formData.append("ipFiles", params.form.ipFiles[1]?.ipFile);
  formData.append("ipFiles", params.form.ipFiles[2]?.ipFile);
  let res = await Post("/ip/request", {
    body: formData,
    headers: headers,
  });
  return res.ok;
};

/**
 * IP作成完了
 * @param params
 */
export const ip_complete = async (params: {
  ipRequestId: number;
  approvalSig: string;
}): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/request/complete", {
    body: JSON.stringify(params),
    headers: headers,
  });
  debugger;
  if (res.ok && res.data) {
    return res.data;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP却下
 * @param params
 */
export const ip_reject = async (params: {
  ipRequestId: number;
}): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/request/reject", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok && res.data) {
    return res.data;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * download
 *
 * @returns {AU01}
 */
export const ip_file_download = async (
  ipReqId: string
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await fetch(DefaultApiUrl + "/ip/request/file/" + ipReqId, {
    headers,
    method: "GET",
  });
  debugger;
  if (res.ok) {
    return res.blob();
  } else {
    return null;
  }
};

type NftInfo = {
  kariNftDescFileId: number;
  kariNftDescFileName: string;
  kariImageFileId: number;
  kariImageFileName: string;
  nftDescFileId: number;
  nftDescFileName: string;
  nftImageFileId: number;
  nftImageFileName: string;
};
type CollectionInfo = {
  name: string;
  description: string;
};
export type IpInfo = {
  ipAddress: string;
  ipName: string;
  ownerId: number;
  ipDetails: string;
  ipOwnerAddress: string;
  ipContent: string;
  ipImageUrl: string;
  ipholderName: string;
  twitterUrl: string;
  linktreeUrl: string;
  externalUrl: string;
  openseaUrl: string;
  ipUsage: string;
  langvageId: string;
  ipInfos:IpInfos[];
  ipholders:IpHolderType[];
};
export type IpInfos = {
  ipAddress: string;
  ipName: string;
  ownerId: number;
  ipDetails: string;
  ipOwnerAddress: string;
  ipContent: string;
  ipImageUrl: string;
  ipholderName: string;
  twitterUrl: string;
  linktreeUrl: string;
  externalUrl: string;
  openseaUrl: string;
  ipUsage: string;
  langvageId: string;
};

export type DataType = {
  irRequestId: number;
  key: string;
  ipName: string;
  userType:number;
  ipOwnerName: number;
  fromName: string;
  status: number;
  statusDisplay: string;
  issueDate: string;
  created: string;
  updated: string;
  rejectFlg: number;
  cancelFlg: number;
  collectionName: string;
  ipInfos:IpInfo[];
  ipholders:IpHolderType[];
}

export type IpHolderType = {
  ipholderName: string;
}

export type IpHolderInfo = {
  userId: number;
  languageId: number;
  ipholderName: string;
  postCode: string;
  address: string;
  tel: string;
  email: string;
  avatar: string;
  managerName: string;
  managerDepartment:string;
  managerPosition:string;
}
export type BankInfo = {
  jpBankInfo: jpBankInfo[];
  enBankInfo: enBankInfo[];
};
export type jpBankInfo = {
  bankId: number;
  language: number;
  infoIndex: number;
  bankInfo: string;
};
export type enBankInfo = {
  bankId: number;
  language: number;
  infoIndex: number;
  bankInfo: string;
};
export type FromInfo = {
  fromUserId: number;
  formAddress: number;
  fromName: number;
  fromDetails: number;
  fromAvatar: number;
  fromUserType: number;
};
export type RequestDetailInfo = {
  data: {
    irRequestId: number;
    fromOrTo: string; // "from" or "to"
    toUserId: number;
    status: number;
    statusDisplay: string;
    rejectFlg: number; // 却下フラグ
    ipInfo: IpInfo;
    fromInfo: FromInfo;
    collectionInfo: CollectionInfo;
    nftInfo: NftInfo;
    royalty: number; // ロイヤリティ情報
    fee: number; // 手数料
    paymentDueDate: string;
    royalty2: number;
    royaltyN: number;
    ipCollectionId: number;
    bankInfo: BankInfo;
    ipType: number;
  };
};
/**
 * IP使用権申請一覧
 *
 * @returns {AU01}
 */
export const ipRoyaltyReqList = async (params: any): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/royalty/request/list", params, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP IP使用権申請詳細
 *
 * @returns {AU01}
 */
export const ip_royalty_details = async (
  ipReqId: string
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/royalty/request/" + ipReqId, {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * IP IP使用権申請詳細
 *
 * @returns {AU01}
 */
export const ip_royalty_request = async (params: any): Promise<any | null> => {
  let headers = {} as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  let formData = new FormData();
  if (params.ipBlockchainId) {
    formData.append("ipBlockchainId", params.ipBlockchainId);
  }
  if (params.kariRequestFile) {
    formData.append("kariRequestFile", params.kariRequestFile);
  }
  if (params.kariNftDescFile) {
    formData.append("kariNftDescFile", params.kariNftDescFile);
  }
  if (params.irRequestId) {
    formData.append("irRequestId", String(params.irRequestId));
  }
  if (params.nftDescFile) {
    formData.append("nftDescFile", params.nftDescFile);
  }
  if (params.nftImageFile) {
    formData.append("nftImageFile", params.nftImageFile);
  }
  if (params.collectionName) {
    formData.append("collectionName", params.collectionName);
  }
  if (params.collectionDesc) {
    formData.append("collectionDesc", params.collectionDesc);
  }
  if (params.collectionId) {
    formData.append("collectionId", params.collectionId);
  }
  if (params.rejectFlg) {
    formData.append("rejectFlg", params.rejectFlg);
  }
  if (params.cancelFlg) {
    formData.append("cancelFlg", params.cancelFlg);
  }

  const res = await Post("/ip/royalty/request", {
    body: formData,
    headers: headers,
  });
  if (res.ok && res.data) {
    return res;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * download
 *
 * @returns {AU01}
 */
export const ip_royalty_file_download = async (
  reqFileId: number
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await fetch(
    DefaultApiUrl + "/ip/royalty/request/file/" + reqFileId,
    {
      headers,
      method: "GET",
    }
  );
  if (res.ok) {
    return res.blob();
  } else {
    return null;
  }
};

/**
 * IP IP使用権申請詳細
 *
 * @returns {AU01}
 */

export const ip_royalty_approve = async (params: {
  approveFlag: boolean;
  irRequestId: number;
  fee: any;
  royalty: any;
  royalty2: any;
  royaltyN: any;
}): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/royalty/request/approve", {
    body: JSON.stringify(params),
    headers: headers,
  });
  debugger
  if (res.ok && res.data) {
    return res;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

export const ip_royalty_approve01 = async (params: {
  approveFlag: boolean;
  irRequestId: number;
}): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/royalty/request/approve", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok && res.data) {
    return res;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};
/**
 * IP IP使用権申請詳細
 *
 * @returns {AU01}
 */

export const ip_royalty_rejection = async (params: {
  approveFlag: boolean;
  irRequestId: number;
}): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/royalty/request/approve", {
    body: JSON.stringify(params),
    headers: headers,
  });
  if (res.ok && res.data) {
    return res;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * collectionList
 * @param
 */
export const collectionList = async (): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/royalty/collectionList");
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    return JSON.parse(res.error);
  }
};
export type IpType = {
  ipId: number;
  ipInfos:IpInfo[];
  ipBlockchainId: string;
  ipImageUrl: string;
  ipOwnerAddress: string;
  ipAgentUsraddr: string;
  ownerId: number;
  agentUserId: number;
  ipTransactionUrl: string;
  ipUsage: number;
  ipType: number;
  ipContent: string;
  showType: number;
  ipDetails: string;
  ipRecommendUrl:string;
  tagId:string;
};
export type IprecommendList = {
  data: IpType[];
};

export const ip_recommend_list = async (): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/ipRecommendList", {}, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

/**
 * 是否为ipholder
 *
 * @returns
 */
export const ipholderSelect = async (
  params: number | undefined
): Promise<any> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }

  const res = await Post("/ip/request/ipHolder", {
    body: JSON.stringify(params),
    headers: headers,
  });
  debugger;
  if (res.ok && res.data) {
    return res.data;
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

export const getAllNftByIp = async (ipId: string): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/getAllNftByIp", { ipId: ipId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

export const getAllExternalNftByIp = async (
  ipId: string
): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get("/ip/getAllExternalNftByIp", { ipId: ipId }, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};

export const getIpholderIpNftTree = async (userId: number): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get(`/ipholder/ipNftTree/${userId}`, null, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};


export const getIpholderStatInfo = async (userId: number): Promise<any | null> => {
  let headers = {
    "content-type": "application/json",
  } as any;
  if (Sessions.read("_jpnft")) {
    headers.Authorization = Sessions.read("_jpnft");
  }
  const res = await Get(`/ipholder/statInfo/${userId}`, null, headers);
  if (res.ok && res.data) {
    return JSON.parse(res.data);
  } else {
    const list = Object.keys(res.error.msg).map((item, indx) => ({
      key: item,
      value: "",
    }));
    return list;
  }
};