import {DefaultApiUrl} from "../constants";
import {Sessions} from "@/utils/sessions";
import {Actions} from "@/store/reducers/account";
import {message} from "antd";

export type ResponseBody = {
    ok: boolean;
    data?: string;
    error?: any;
};

export async function Get(
    url: string,
    data?: any,
    headers?: any
): Promise<ResponseBody> {
    try {
        const query_string = _toQueryString(data ?? {});
        const rst = await fetch(DefaultApiUrl + url + "?" + query_string, {
            headers,
            method: "GET",
        });
        if (rst.ok) {
            const data: any = await rst.text()
            const resData = data ? JSON.parse(data) : data;
            if (resData.code > 0) {
                // data.msg
                // await appError(resData.msg);
                return {
                    ok: false,
                    error:data,
                }
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }

        } else {
            console.warn(`[Warn] Fetch ${url} failed! `);
            return {
                ok: false,
                error: await rst.text(),
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}

export async function Post(
    url: string,
    data?: RequestInit
): Promise<ResponseBody> {
    try {
        const rst = await fetch(DefaultApiUrl + url, {
            ...data,
            method: "POST",
        });
        if (rst.headers.get('authorization')) {
            Sessions.write("_jpnft", rst.headers.get('authorization'));
            Actions.login({jwt: rst.headers.get('authorization')})
        }
        if (rst.ok) {
            const data: any = await rst.json()
            if (data.code > 0) {
                // data.msg
                // await appError(data.msg);
                return {
                    ok: false,
                    error: await data,
                }
            } else {
                return {
                    ok: true,
                    data: await data,
                };
            }
        } else {
            console.warn(`[Warn] Fetch ${url} failed! `);
            return {
                ok: false,
                error: await data,
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}


export async function Delete(
    url: string,
    data?: RequestInit
): Promise<ResponseBody> {
    try {
        const rst = await fetch(DefaultApiUrl + url, {
            ...data,
            method: "DELETE",
        });
        if (rst.headers.get('authorization')) {
            Sessions.write("_jpnft", rst.headers.get('authorization'));
            Actions.login({jwt: rst.headers.get('authorization')})
        }
        if (rst.ok) {
            const data: any = await rst.json()
            if (data.code > 0) {
                // data.msg
                // await appError(data.msg);
                return {
                    ok: false,
                    error: await data,
                }
            } else {
                return {
                    ok: true,
                    data: await data,
                };
            }
        } else {
            console.warn(`[Warn] Fetch ${url} failed! `);
            return {
                ok: false,
                error: "faild",
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}

export const _toQueryString = (obj: { [x: string]: string | number }) => {
    const str: string[] = [];
    Object.keys(obj).forEach((k) => {
        if (obj[k] !== undefined) {
            str.push(k + "=" + obj[k]);
        }
    });
    return str.join("&");
};

const appError = async (msg: any) => {
    var msgmap = Object.entries(msg);
    var msgs = [];
    for (var [key, value] of msgmap) {
        msgs.push(key);
    }
    var err = msgs.join("\r\n");
    message.error(err);
}