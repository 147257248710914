import { useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./FooterArea.scss";

const AboutJpnft = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="minHeight">
            <div className="main-container">
                <div className="md:px-60 mt-20 mb-10" style={{userSelect:"text"}}>
                    <div className="font-bold text-2xl text-black md:text-4xl pb-7">
                        {t("page.FOOTER_company-profile")}
                    </div>
                    <div className="text-left w-full h-auto text-black font-bold text-lg whitespace-pre-wrap pt-3">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="fs_18 text-black w-full md:w-1/4 md:ml-5">
                                {t("page.FOOTER_company-profile-company-name-k")}
                            </div>
                            <div className="fs_16 text-black w-full md:w-3/4 company-profile-value">
                                {t("page.FOOTER_company-profile-company-name-v")}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-jpnft-border company-profile-line">
                    </div>

                    <div className="text-left w-full h-auto text-black font-bold text-lg whitespace-pre-wrap pt-3">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="fs_18 text-black w-full md:w-1/4 md:ml-5">
                                {t("page.FOOTER_company-profile-address-k")}
                            </div>
                            <div className="fs_16 text-black w-full md:w-3/4 company-profile-value">
                                {t("page.FOOTER_company-profile-address-v")}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-jpnft-border company-profile-line">
                    </div>

                    <div className="text-left w-full h-auto text-black font-bold text-lg whitespace-pre-wrap pt-3">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="fs_18 text-black w-full md:w-1/4 md:ml-5">
                                {t("page.FOOTER_company-profile-management-team-k")}
                            </div>
                            <div className="fs_16 text-black w-full md:w-3/4 company-profile-value">
                                {t("page.FOOTER_company-profile-management-team-v")}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-jpnft-border company-profile-line">
                    </div>

                    <div className="text-left w-full h-auto text-black font-bold text-lg whitespace-pre-wrap pt-3">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="fs_18 text-black w-full md:w-1/4 md:ml-5">
                                {t("page.FOOTER_company-profile-established-k")}
                            </div>
                            <div className="fs_16 text-black w-full md:w-3/4 company-profile-value">
                                {t("page.FOOTER_company-profile-established-v")}
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-jpnft-border company-profile-line">
                    </div>
                    
                    <div className="text-left w-full h-auto text-black font-bold text-lg whitespace-pre-wrap pt-3">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="fs_18 text-black w-full md:w-1/4 md:ml-5">
                                {t("page.FOOTER_company-profile-business-content-k")}
                            </div>
                            <div className="fs_16 text-black w-full md:w-3/4 company-profile-value">
                                {t("page.FOOTER_company-profile-business-content-v")}
                            </div>
                        </div>
                    </div>
                    <div className="company-profile-part">
                    </div>
                    <div className="font-bold text-2xl text-black md:text-4xl pb-7">
                        {t("page.FOOTER_company-profile-message-k")}
                    </div>
                    <div className="fs_16 text-left w-full h-auto text-black font-bold md:text-lg whitespace-pre-wrap pt-3 company-profile-value">
                        {t("page.FOOTER_company-profile-message-v")}
                    </div>
                    <div>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutJpnft;